export default class BaseResData{

    private _responseCode: number | undefined;
    private _responseMessage: string | undefined;

    public get responseCode(): number | undefined {
        return this._responseCode;
    }
    public set responseCode(value: number | undefined) {
        this._responseCode = value;
    }
    public get responseMessage(): string | undefined {
        return this._responseMessage;
    }
    public set responseMessage(value: string | undefined) {
        this._responseMessage = value;
    }
}
