import WorkRecordGroupDateItemData from "ui/components/Work/WorkRecordGroupDateList/WorkRecordGroupDateItemData";
import WorkRecordGroupAddressItemData from "../WorkRecordGroupAddressList/WorkRecordGroupAddressListItemData";
import WorkerItemData from "./WorkerList/WorkerItemData";
import WorkMemoItemData from "./WorkMemoItemData";
import WorkRecordLogData from "./WorkRecordLogData";
import WorkRecordForProductItemData from "./WorkRecordForProductItemData";
import MySubWorkLandItemData from "./MySubWorkLandItemData";

export default class WorkDetailData {
  private _productId?: string;
  private _workId!: string;
  private _workLatitude!: number;
  private _workLongitude!: number;
  private _workStartDate!: string;
  private _workEndDate!: string;
  private _workAddress1!: string;
  private _workAddress2!: string;
  private _workAddress3!: string;
  private _cropType!: string;
  private _workType!: string;
  private _workerList: Array<WorkerItemData> = new Array<WorkerItemData>();
  private _workRecordGroupDateList = new Array<WorkRecordGroupDateItemData>();
  private _landList: Array<WorkRecordGroupAddressItemData> = new Array<WorkRecordGroupAddressItemData>();
  private _workRecordListForExcelDownload!: Array<WorkRecordForProductItemData>;  
  private _workMemoList!: Array<WorkMemoItemData>;
  private _workRecordDistance!: number;
  private _lastWorkRecordLogData: WorkRecordLogData = new WorkRecordLogData();
  private _masterWorkId!: string;
  private _mySubWorkLandList!: Array<MySubWorkLandItemData>; 

  public jsonToObject(json: any) {
    json._productId && (this.productId = json._productId);
    json._workId && (this.workId = json._workId);
    json._workLatitude && (this.workLatitude = json._workLatitude);
    json._workLongitude && (this.workLongitude = json._workLongitude);
    json._workStartDate && (this.workStartDate = json._workStartDate);
    json._workEndDate && (this.workEndDate = json._workEndDate);
    json._workAddress1 && (this.workAddress1 = json._workAddress1);
    json._workAddress2 && (this.workAddress2 = json._workAddress2);
    json._workAddress3 && (this.workAddress3 = json._workAddress3);
    json._cropType && (this.cropType = json._cropType);
    json._workType && (this.workType = json._workType);
    json._workerList && json._workerList?.forEach((item: any) => {
        const workerItemData = this.jsonWorkerItemDataToObject(item);
        this.workerList.push(workerItemData);
      });
    json._workRecordGroupDateList && json._workRecordGroupDateList?.forEach((item: any) => {
        const workRecordGroupDateItemData = this.jsonWorkRecordGroupDateItemDataToObject(item);
        this.workRecordGroupDateList.push(workRecordGroupDateItemData);
      });
    json._landList && json._landList?.forEach((item: any) => {
        const workRecordGroupAddressItemData = this.jsonWorkRecordGroupAddressItemDataToObject(item);
        this.landList.push(workRecordGroupAddressItemData);
      });
  }
  private jsonWorkerItemDataToObject(jsonWorkerItemData: any): WorkerItemData {
    const workerItemData = new WorkerItemData();
    jsonWorkerItemData._equipmentName && (workerItemData.equipmentName = jsonWorkerItemData._equipmentName);
    jsonWorkerItemData._equipmentType && (workerItemData.equipmentType = jsonWorkerItemData._equipmentType);
    jsonWorkerItemData._workerName && (workerItemData.workerName = jsonWorkerItemData._workerName);
    return workerItemData;
  }
  private jsonWorkRecordGroupDateItemDataToObject(jsonWorkRecordGroupDateItemData: any): WorkRecordGroupDateItemData {
    const workRecordGroupDateItemData = new WorkRecordGroupDateItemData();
    jsonWorkRecordGroupDateItemData._dateOutput && (workRecordGroupDateItemData.dateOutput = jsonWorkRecordGroupDateItemData._dateOutput);
    jsonWorkRecordGroupDateItemData._workDate && (workRecordGroupDateItemData.workDate = jsonWorkRecordGroupDateItemData._workDate);
    return workRecordGroupDateItemData;
  }
  private jsonWorkRecordGroupAddressItemDataToObject(jsonWorkRecordGroupAddressItemData: any): WorkRecordGroupAddressItemData {
    const workRecordGroupAddressItemData = new WorkRecordGroupAddressItemData();
    jsonWorkRecordGroupAddressItemData._landAddress && (workRecordGroupAddressItemData.landAddress = jsonWorkRecordGroupAddressItemData._landAddress);
    jsonWorkRecordGroupAddressItemData._landOutput && (workRecordGroupAddressItemData.landOutput = jsonWorkRecordGroupAddressItemData._landOutput);
    jsonWorkRecordGroupAddressItemData._landSize && (workRecordGroupAddressItemData.landSize = jsonWorkRecordGroupAddressItemData._landSize);
    jsonWorkRecordGroupAddressItemData._landMap && (workRecordGroupAddressItemData.landMap = jsonWorkRecordGroupAddressItemData._landMap);
    return workRecordGroupAddressItemData;
  }
  public get productId(): string | undefined {
    return this._productId;
  }
  public set productId(value: string | undefined) {
    this._productId = value;
  }
  public get workId(): string {
    return this._workId;
  }
  public set workId(value: string) {
    this._workId = value;
  }
  public get workLatitude(): number {
    return this._workLatitude;
  }
  public set workLatitude(value: number) {
    this._workLatitude = value;
  }
  public get workLongitude(): number {
    return this._workLongitude;
  }
  public set workLongitude(value: number) {
    this._workLongitude = value;
  }
  public get workStartDate(): string {
    return this._workStartDate;
  }
  public set workStartDate(value: string) {
    this._workStartDate = value;
  }
  public get workEndDate(): string {
    return this._workEndDate;
  }
  public set workEndDate(value: string) {
    this._workEndDate = value;
  }
  public get workAddress1(): string {
    return this._workAddress1;
  }
  public set workAddress1(value: string) {
    this._workAddress1 = value;
  }
  public get workAddress2(): string {
    return this._workAddress2;
  }
  public set workAddress2(value: string) {
    this._workAddress2 = value;
  }
  public get workAddress3(): string {
    return this._workAddress3;
  }
  public set workAddress3(value: string) {
    this._workAddress3 = value;
  }
  public get cropType(): string {
    return this._cropType;
  }
  public set cropType(value: string) {
    this._cropType = value;
  }
  public get workType(): string {
    return this._workType;
  }
  public set workType(value: string) {
    this._workType = value;
  }
  public get workerList(): Array<WorkerItemData> {
    return this._workerList;
  }
  public set workerList(value: Array<WorkerItemData>) {
    this._workerList = value;
  }
  public get workRecordGroupDateList() {
    return this._workRecordGroupDateList;
  }
  public set workRecordGroupDateList(value) {
    this._workRecordGroupDateList = value;
  }
  public get workRecordListForExcelDownload(): Array<WorkRecordForProductItemData> {
    return this._workRecordListForExcelDownload;
  }
  public set workRecordListForExcelDownload(value: Array<WorkRecordForProductItemData>) {
    this._workRecordListForExcelDownload = value;
  }
  public get landList(): Array<WorkRecordGroupAddressItemData> {
    return this._landList;
  }
  public set landList(value: Array<WorkRecordGroupAddressItemData>) {
    this._landList = value;
  }
  public get workMemoList(): Array<WorkMemoItemData> {
    return this._workMemoList;
  }
  public set workMemoList(value: Array<WorkMemoItemData>) {
    this._workMemoList = value;
  }
  public get workRecordDistance(): number {
    return this._workRecordDistance;
  }
  public set workRecordDistance(value: number) {
    this._workRecordDistance = value;
  }
  public get lastWorkRecordLogData(): WorkRecordLogData  {
    return this._lastWorkRecordLogData;
  }
  public set lastWorkRecordLogData(value: WorkRecordLogData) {
    this._lastWorkRecordLogData = value;
  }
  public get masterWorkId(): string {
    return this._masterWorkId;
  }
  public set masterWorkId(value: string) {
    this._masterWorkId = value;
  }
  public get mySubWorkLandList(): Array<MySubWorkLandItemData> {
    return this._mySubWorkLandList;
  }
  public set mySubWorkLandList(value: Array<MySubWorkLandItemData>) {
    this._mySubWorkLandList = value;
  }
  public get workEditable(): boolean{
    const today = new Date();
    const endDate = new Date(this.workEndDate);
    if (endDate < today) {
        return false;
    }
    return true;
  }
}
