import BridgeCommands from './BridgeCommands';

export default class BridgeManager {
  static callbackDeviceInfoReceived: any;
  static callbackGeometryInfoReceived: any;
  static callbackChatMsgReceived: any;
  static callbackChatMsgFlagReceived: any;
  static callbackCheckPermissionsReceived: any;
  static callbackMyLocationInfoReceived: any;
  static callbackWorkingLocationInfoReceived: any;
  static callbackForecastInfoReceived: any;
  static callbackShortForecastInfoReceived: any;
  static callbackMidForecastInfoReceived: any;
  static callbackGetAlarmStatusReceived: any;
  static callbackToggleAlarmBtnReceived: any;
  static callbackNotificationClicked: any;
  static callbackTradeConfirmReceived: any;
  static callbackAuthSuccessReceived: any;
  static callbackSocketDisconnectRequestReceived: any;
  static callbackSocketReconnectRequestReceived: any;
  static callbackOpenNiceUrlReceived: any;
  static callbackCheckImageUploadPermissionsReceived: any;
  static callbackSendNavigationUrlReceived: any;
  static callbackCurrentLocationInfoReceived: any;

  init() {
    // ios 인경우
    if (this.isIos()) {
      window.removeEventListener(
        'message',
        this.onBrgMsgReceived as EventListener
      );
      window.addEventListener(
        'message',
        this.onBrgMsgReceived as EventListener
      );
    }
    // android 인경우
    else if (this.isAndroid()) {
      document.removeEventListener(
        'message',
        this.onBrgMsgReceived as EventListener
      );
      document.addEventListener(
        'message',
        this.onBrgMsgReceived as EventListener
      );
    } 
  }

  isApp(): boolean {
    return this.isMobile() && navigator.userAgent.toString().includes("-App-");
  }

  isMobile(): boolean {
    return this.isIos() || this.isAndroid();
  }
  isIos(): boolean {
    const appMode = localStorage.getItem('APP_MODE');
    if(appMode === 'SELLER' || appMode === 'BUYER') {
      const os = localStorage.getItem('DEVICE_OS_NAME')?.toLocaleLowerCase();
      if(os){
        return os.indexOf('ios') > -1;
      }
    }
    const varUA = navigator.userAgent.toLowerCase();
    return varUA.indexOf('iphone') > -1 || varUA.indexOf('ipad') > -1;
  }

  isAndroid(): boolean {
    const appMode = localStorage.getItem('APP_MODE');
    if(appMode === 'SELLER' || appMode === 'BUYER') {
      const os = localStorage.getItem('DEVICE_OS_NAME')?.toLocaleLowerCase();
      if(os){
        return os.indexOf('android') > -1;
      }
    }
    var varUA = navigator.userAgent.toLowerCase()
    return varUA.indexOf('android') > -1;
  }

  bridgeCommand(command: string, data: any, callback?: any) {
    if (!this.isApp()) {
      return;
    }

    if (callback) {
      this.setEventWithCommand(command, callback);
    }
    const message = {
      command: command,
      data: data,
    };
    const windowObject = window;
    windowObject.ReactNativeWebView.postMessage(JSON.stringify(message));
  }

  setEventWithCommand(command: string, callback: any) {
    switch (command) {
      case BridgeCommands.BRG_DEVICE_INFO: {
        this.setEventListener(
          BridgeCommands.BRG_ON_DEVICE_INFO_RECEIVED,
          callback
        );
        break;
      }
      case BridgeCommands.BRG_GEOMETRY_INFO: {
        this.setEventListener(
          BridgeCommands.BRG_ON_GEOMETRY_INFO_RECEIVED,
          callback
        );
        break;
      }
      case BridgeCommands.BRG_CHECK_PERMISSIONS: {
        this.setEventListener(
          BridgeCommands.BRG_ON_CHECK_PERMISSIONS_RECEIVED,
          callback
        );
        break;
      }
      case BridgeCommands.BRG_ON_WORKING_LOCATION_INFO_RECEIVED: {
        this.setEventListener(
          BridgeCommands.BRG_ON_WORKING_LOCATION_INFO_RECEIVED,
          callback
        );
        break;
      }
      case BridgeCommands.BRG_FORECAST_INFO: {
        this.setEventListener(
          BridgeCommands.BRG_ON_FORECAST_INFO_RECEIVED,
          callback
        );
        break;
      }
      case BridgeCommands.BRG_SHORT_FORECAST_INFO: {
        this.setEventListener(
          BridgeCommands.BRG_ON_SHORT_FORECAST_INFO_RECEIVED,
          callback
        );
        break;
      }
      case BridgeCommands.BRG_MID_FORECAST_INFO: {
        this.setEventListener(
          BridgeCommands.BRG_ON_MID_FORECAST_INFO_RECEIVED,
          callback
        );
        break;
      }

      case BridgeCommands.BRG_GET_CURRENT_ALARM_STATUS: {
        this.setEventListener(
          BridgeCommands.BRG_ON_GET_CURRENT_ALARM_STATUS_RECEVIED,
          callback
        );
        break;
      }
      case BridgeCommands.BRG_TOGGLE_ALARM_BTN: {
        this.setEventListener(
          BridgeCommands.BRG_ON_TOGGLE_ALARM_BTN_RECEIVED,
          callback
        );
        break;
      }
    }
  }

  setEventListener(callbackName: string, callback: any) {
    switch (callbackName) {
      case BridgeCommands.BRG_ON_DEVICE_INFO_RECEIVED: {
        BridgeManager.callbackDeviceInfoReceived = callback;
        break;
      }
      case BridgeCommands.BRG_ON_GEOMETRY_INFO_RECEIVED: {
        BridgeManager.callbackGeometryInfoReceived = callback;
        break;
      }
      case BridgeCommands.BRG_ON_CHAT_MSG_RECEIVED: {
        BridgeManager.callbackChatMsgReceived = callback;
        break;
      }
      case BridgeCommands.BRG_ON_CHAT_MSG_FLAG_RECEIVED: {
        BridgeManager.callbackChatMsgFlagReceived = callback;
        break;
      }
      case BridgeCommands.BRG_ON_CHECK_PERMISSIONS_RECEIVED: {
        BridgeManager.callbackCheckPermissionsReceived = callback;
        break;
      }
      case BridgeCommands.BRG_ON_WORKING_LOCATION_INFO_RECEIVED: {
        BridgeManager.callbackWorkingLocationInfoReceived = callback;
        break;
      }
      case BridgeCommands.BRG_ON_FORECAST_INFO_RECEIVED: {
        BridgeManager.callbackForecastInfoReceived = callback;
        break;
      }
      case BridgeCommands.BRG_ON_SHORT_FORECAST_INFO_RECEIVED: {
        BridgeManager.callbackShortForecastInfoReceived = callback;
        break;
      }
      case BridgeCommands.BRG_ON_MID_FORECAST_INFO_RECEIVED: {
        BridgeManager.callbackMidForecastInfoReceived = callback;
        break;
      }
      case BridgeCommands.BRG_ON_GET_CURRENT_ALARM_STATUS_RECEVIED: {
        BridgeManager.callbackGetAlarmStatusReceived = callback;
        break;
      }
      case BridgeCommands.BRG_ON_TOGGLE_ALARM_BTN_RECEIVED: {
        BridgeManager.callbackToggleAlarmBtnReceived = callback;
        break;
      }
      case BridgeCommands.BRG_ON_NOTIFICATION_CLICKED: {
        BridgeManager.callbackNotificationClicked = callback;
        break;
      }
      case BridgeCommands.BRG_ON_TRADE_CONFIRM_RECEIVED: {
        BridgeManager.callbackTradeConfirmReceived = callback;
        break;
      }

      case BridgeCommands.BRG_ON_AUTH_SUCCESS_RECEIVED: {
        BridgeManager.callbackAuthSuccessReceived = callback;
        break;
      }

      case BridgeCommands.BRG_ON_SOCKET_DISCONNECT_REQUEST_RECEIVED: {
        BridgeManager.callbackSocketDisconnectRequestReceived = callback;
        break;
      }

      case BridgeCommands.BRG_ON_SOCKET_RECONNECT_REQUEST_RECEIVED: {
        BridgeManager.callbackSocketReconnectRequestReceived = callback;
        break;
      }

      case BridgeCommands.BRG_ON_CHECK_IMAGE_UPLOAD_PERMISSIONS_RECEIVED: {
        BridgeManager.callbackCheckImageUploadPermissionsReceived = callback;
        break;
      }

      case BridgeCommands.BRG_ON_SEND_NAVIGATION_URL_RECEIVED: {
        BridgeManager.callbackSendNavigationUrlReceived = callback;
        break;
      }

      case BridgeCommands.BRG_ON_CURRENT_LOCATION_INFO_RECEIVED: {
        BridgeManager.callbackCurrentLocationInfoReceived = callback;
        break;
      }
    }
  }

  onBrgMsgReceived(event: MessageEvent) {
    const message = JSON.parse(event.data);
    switch (message.command) {
      case BridgeCommands.BRG_ON_DEVICE_INFO_RECEIVED: {
        BridgeManager.callbackDeviceInfoReceived &&
          BridgeManager.callbackDeviceInfoReceived(message);
        break;
      }
      case BridgeCommands.BRG_ON_GEOMETRY_INFO_RECEIVED: {
        BridgeManager.callbackGeometryInfoReceived &&
          BridgeManager.callbackGeometryInfoReceived(
            message.geometryInfo.addr,
            message.geometryInfo.coordinates
          );
        break;
      }
      case BridgeCommands.BRG_ON_CHAT_MSG_RECEIVED: {
        BridgeManager.callbackChatMsgReceived &&
          BridgeManager.callbackChatMsgReceived(message);
        break;
      }
      case BridgeCommands.BRG_ON_CHAT_MSG_FLAG_RECEIVED: {
        BridgeManager.callbackChatMsgFlagReceived &&
          BridgeManager.callbackChatMsgFlagReceived(message.data);
        break;
      }
      case BridgeCommands.BRG_ON_CHECK_PERMISSIONS_RECEIVED: {
        BridgeManager.callbackCheckPermissionsReceived &&
          BridgeManager.callbackCheckPermissionsReceived(message.data);
        break;
      }
      case BridgeCommands.BRG_ON_WORKING_LOCATION_INFO_RECEIVED: {
        BridgeManager.callbackWorkingLocationInfoReceived &&
          BridgeManager.callbackWorkingLocationInfoReceived(message);
        break;
      }
      case BridgeCommands.BRG_ON_FORECAST_INFO_RECEIVED: {
        BridgeManager.callbackForecastInfoReceived &&
          BridgeManager.callbackForecastInfoReceived(message.data);
        break;
      }
      case BridgeCommands.BRG_ON_MID_FORECAST_INFO_RECEIVED: {
        BridgeManager.callbackMidForecastInfoReceived &&
          BridgeManager.callbackMidForecastInfoReceived(message);
        break;
      }
      case BridgeCommands.BRG_ON_SHORT_FORECAST_INFO_RECEIVED: {
        BridgeManager.callbackShortForecastInfoReceived &&
          BridgeManager.callbackShortForecastInfoReceived(message);
        break;
      }
      case BridgeCommands.BRG_ON_GET_CURRENT_ALARM_STATUS_RECEVIED: {
        BridgeManager.callbackGetAlarmStatusReceived &&
          BridgeManager.callbackGetAlarmStatusReceived(message);
        break;  
      }
      case BridgeCommands.BRG_ON_TOGGLE_ALARM_BTN_RECEIVED: {
        BridgeManager.callbackToggleAlarmBtnReceived &&
          BridgeManager.callbackToggleAlarmBtnReceived(message);
        break;
      }

      case BridgeCommands.BRG_ON_NOTIFICATION_CLICKED: {  
        BridgeManager.callbackNotificationClicked &&
          BridgeManager.callbackNotificationClicked(message);
        break;
      }

      case BridgeCommands.BRG_ON_TRADE_CONFIRM_RECEIVED: {
        BridgeManager.callbackTradeConfirmReceived &&
          BridgeManager.callbackTradeConfirmReceived(message);
        break;
      }

      case BridgeCommands.BRG_ON_AUTH_SUCCESS_RECEIVED: {
        BridgeManager.callbackAuthSuccessReceived &&
          BridgeManager.callbackAuthSuccessReceived(message);
        break;
      }

      case BridgeCommands.BRG_ON_SOCKET_DISCONNECT_REQUEST_RECEIVED: {
        BridgeManager.callbackSocketDisconnectRequestReceived &&
          BridgeManager.callbackSocketDisconnectRequestReceived(message);
        break;
      }

      case BridgeCommands.BRG_ON_SOCKET_RECONNECT_REQUEST_RECEIVED: {
        BridgeManager.callbackSocketReconnectRequestReceived &&
          BridgeManager.callbackSocketReconnectRequestReceived(message);
        break;
      }

      case BridgeCommands.BRG_ON_CHECK_IMAGE_UPLOAD_PERMISSIONS_RECEIVED: {
        BridgeManager.callbackCheckImageUploadPermissionsReceived &&
          BridgeManager.callbackCheckImageUploadPermissionsReceived(message);
        break;
      }
      
      case BridgeCommands.BRG_ON_SEND_NAVIGATION_URL_RECEIVED: {
        BridgeManager.callbackSendNavigationUrlReceived &&
          BridgeManager.callbackSendNavigationUrlReceived(message);
        break;
      }

      case BridgeCommands.BRG_ON_CURRENT_LOCATION_INFO_RECEIVED: {
        BridgeManager.callbackCurrentLocationInfoReceived &&
          BridgeManager.callbackCurrentLocationInfoReceived(message);
        break;
      }

    }
  }
}
