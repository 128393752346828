import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class LatestAppVersionRequest extends BaseRequest{
    private readonly URL_USER_BASE = `${BaseService.API_URL}/user`;
    private readonly URL_APP_VER = `${this.URL_USER_BASE}/getAppVersion`;

    getURL(): string {
        return this.URL_APP_VER;
    }
}