import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class EquipmentDistanceRequest extends BaseRequest {
    private readonly URL_EQUIPMENT_BASE = `${BaseService.API_URL}/equipment`;
    private URL_EQUIPMENT_DISTANCE = this.URL_EQUIPMENT_BASE + "/equipmentDistance";
    
    getURL(): string {
        return this.URL_EQUIPMENT_DISTANCE;
    }
}