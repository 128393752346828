import { transactionState } from "ui/components/Tag";
import WorkRecordLogData from "../WorkDetail/WorkRecordLogData";
import { EquipmentTypeCode } from "code/EquipmentTypeCode";

/** WorkRecordGroupAddressList.tsx에서 사용함. 해당 페이지의 locaion.state로 넘긴 데이터는 클래스의 객체 형식이 아니어서 get/set이 지정되지 않는 것으로 보임. */
export interface IWorkRecordGroupAddressItemObject {
  _landAddress: string;
  _landSize: number;
  _landLatitude: number;
  _landLongitude: number;
  _landOutput: number;
  _landMap: number[][];
  _landTradeDone: string;
  _landWorkDate: string;
  _landOwner: string;
  _landOwnerTel: string;
  _workTeam: string;
  _landProgress: string;
  _workRecordLogList: Array<WorkRecordLogData>;
}

export const getLandStatusByWorkRecordGroupAddressItemData = (
  landItemData: WorkRecordGroupAddressItemData
): string => {
  const state = transactionState.workScheduled;
  if (
    landItemData.workRecordLogList.map((item) => {
      return item.polyLine;
    }).length > 0
  ) {
    return transactionState.working.toString();
  }
  if (landItemData.plannedOutput <= landItemData.landOutput) {
    return transactionState.workCompleted.toString();
  }
  return state.toString();
};
export const getLandStatusByWorkRecordGroupAddressItemObject = (
  landItemData: IWorkRecordGroupAddressItemObject
): string => {
  const state = transactionState.workScheduled;
  if (
    landItemData._workRecordLogList.map((item) => {
      return item.polyLine;
    }).length > 0
  ) {
    return transactionState.working.toString();
  }

  const plannedOutput = Math.floor(landItemData._landSize * 0.0025);
  if (plannedOutput <= landItemData._landOutput) {
    return transactionState.workCompleted.toString();
  }
  return state.toString();
};

export default class WorkRecordGroupAddressItemData {
  private _landAddress!: string;
  private _landSize!: number;
  private _landLatitude!: number;
  private _landLongitude!: number;
  private _landOutput!: number;
  private _landMap!: number[][];
  private _landTradeDone: string = "N";
  private _workRecordLogList: Array<WorkRecordLogData> =new Array<WorkRecordLogData>();
  private _landWorkDate?: string;
  private _landOwner?: string;
  private _landOwnerTel?: string;
  private _workTeam: string = "N";
  private _landProgress?: string;
  private _buttonVisiable: boolean = false;
  
  public get landAddress(): string {
    return this._landAddress;
  }
  public set landAddress(value: string) {
    this._landAddress = value;
  }
  public get landSize(): number {
    return this._landSize;
  }
  public set landSize(value: number) {
    this._landSize = value;
  }
  public get landLatitude(): number {
    return this._landLatitude;
  }
  public set landLatitude(value: number) {
    this._landLatitude = value;
  }
  public get landLongitude(): number {
    return this._landLongitude;
  }
  public set landLongitude(value: number) {
    this._landLongitude = value;
  }
  public get plannedOutput(): number {
    return Math.floor(this._landSize * 0.0025);
  }
  public get landStatus(): string {
    const state = transactionState.workScheduled;
    if(this._landTradeDone === 'Y'){
      return transactionState.DEALCOMPLETE.toString();
    }
    if (this._workRecordLogList?.length > 0) {
      return transactionState.working.toString();
    }
    if (this._landOutput > 0) {
      return transactionState.workCompleted.toString();
    }
    return state.toString();
  }
  public get landOutput(): number {
    return this._landOutput;
  }
  public set landOutput(value: number) {
    this._landOutput = value;
  }
  public get landMap(): number[][] {
    return this._landMap;
  }
  public set landMap(value: number[][]) {
    this._landMap = value;
  }
  public get landTradeDone(): string {
    return this._landTradeDone;
  }
  public set landTradeDone(value: string) {
    this._landTradeDone = value;
  }
  public get workRecordLogList(): Array<WorkRecordLogData> {
    return this._workRecordLogList;
  }
  public set workRecordLogList(value: Array<WorkRecordLogData>) {
    this._workRecordLogList = value;
  }
  public get recordAble(): boolean {
    return (
      this._workRecordLogList.findIndex((item) => {
        return ((item.workStatus === "Y") && (item.equipmentType === EquipmentTypeCode.LAPPING || item.equipmentType === EquipmentTypeCode.COMBINE));
      }) > -1
    );
  }
  public get buttonVisiable(): boolean {
    return this._landTradeDone === 'N';
  }
  public set buttonVisiable(value: boolean) {
    this._buttonVisiable = value;
  }
  public get landWorkDate(): string | undefined{
    return this._landWorkDate;
  }
  public set landWorkDate(value: string | undefined) {
    this._landWorkDate = value;
  }
  public get landOwner(): string | undefined {
    return this._landOwner;
  }
  public set landOwner(value: string | undefined) {
    this._landOwner = value;
  }
  public get landOwnerTel(): string | undefined {
    return this._landOwnerTel;
  }
  public set landOwnerTel(value: string | undefined) {
    this._landOwnerTel = value;
  }
  public get workTeam(): string {
    return this._workTeam;
  }
  public set workTeam(value: string) {
    this._workTeam = value;
  }
  public get landProgress(): string | undefined {
    return this._landProgress;
  }
  public set landProgress(value: string | undefined) {
    this._landProgress = value;
  }

}
