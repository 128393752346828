import { EquipmentTypeCode, stringToEquipmentTypeCode } from "code/EquipmentTypeCode";
import EquipmentRegistrationReqData from "data/network/req/equipment/EquipmentRegistrationReqData";
import EquipmentItemResData from "data/network/res/equipment/EquipmentItemResData";
import EquipmentItemData from "ui/pages/EquipmentManagement/EquipmentItemData";
import raykeyIcon from 'assets/images/equipment/rake.png';
import bailerIcon from 'assets/images/equipment/baler.png';
import lappingIcon from 'assets/images/equipment/wrapper.png';
import moreIcon from 'assets/images/equipment/mower.png';
import tedderIcon from 'assets/images/equipment/tedder.png';
import etcIcon from 'assets/images/equipment/etc.png';
import combineIcon from 'assets/images/equipment/combine.png';
import harvestIcon from 'assets/images/equipment/harvester.png';
import etc2Icon from 'assets/images/equipment/etc2.png';
import WorkListByUserIdResData from "data/network/res/work/WorkListByUserIdResData";
import WorkListByUserIdData from "ui/pages/EquipmentManagement/WorkListByUserIdData";
import WorkItemByUserIdData from "ui/pages/EquipmentManagement/WorkItemByUserIdData";


export function resEquipmentListToEquipmentListViewData(resEquipmentList: Array<EquipmentItemResData>): Array<EquipmentItemData> {
  
  let equipmentList: Array<EquipmentItemData> = new Array<EquipmentItemData>();
  
  if (resEquipmentList) {
    resEquipmentList.forEach((equipmentItemResData: EquipmentItemResData) => {
      let equipmentItemData: EquipmentItemData = new EquipmentItemData();
      equipmentItemData.equipmentType = stringToEquipmentTypeCode[equipmentItemResData.equipmentType];
      equipmentItemData.equipmentName = equipmentItemResData.equipmentName;
      equipmentItemData.equipmentDistance = equipmentItemResData.equipmentDistance || "50";
      equipmentList.push(equipmentItemData);
    });
  }
  
  return equipmentList;
}

export function equipmentItemDataToReqEquipmentItemData(equipmentItemData: EquipmentItemData): EquipmentRegistrationReqData {
  let equipmentItemReqData: EquipmentRegistrationReqData = new EquipmentRegistrationReqData();
  equipmentItemReqData.equipmentType = equipmentItemData.equipmentType;
  equipmentItemReqData.equipmentName = equipmentItemData.equipmentName;
  equipmentItemReqData.equipmentDistance = equipmentItemData.equipmentDistance;
  return equipmentItemReqData;
}

export function equipmentIconByEquipmentTypeString(equipmentType: string): any {
  const type = stringToEquipmentTypeCode[equipmentType];
  return equipmentIconByEquipmentType(type);
   
}
export function equipmentIconByEquipmentType(equipmentType: EquipmentTypeCode): any {
  const type = stringToEquipmentTypeCode[equipmentType];
  switch (type) {
    case EquipmentTypeCode.RAYKEY:
        return raykeyIcon;
    case EquipmentTypeCode.BAILER:
        return bailerIcon;
    case EquipmentTypeCode.LAPPING:
        return lappingIcon;
    case EquipmentTypeCode.MORE:
        return moreIcon;
    case EquipmentTypeCode.TEDDER:
        return tedderIcon;
    case EquipmentTypeCode.ETC:
        return etcIcon;
    case EquipmentTypeCode.COMBINE:
        return combineIcon;
    case EquipmentTypeCode.HARVESTER:
        return harvestIcon;
    case EquipmentTypeCode.ETC2:
        return etc2Icon;
    default:
        return raykeyIcon;
  }
}

export function colorOrderByEquipmentType(equipmentType: EquipmentTypeCode): number {
  switch (equipmentType) {
    case EquipmentTypeCode.RAYKEY:
      return 2;
    case EquipmentTypeCode.BAILER:
      return 1;
    case EquipmentTypeCode.LAPPING:
      return 0;
    case EquipmentTypeCode.MORE:
      return 3;
    case EquipmentTypeCode.TEDDER:
      return 3;
    case EquipmentTypeCode.ETC:
      return 3;
    case EquipmentTypeCode.COMBINE:
      return 0;
    case EquipmentTypeCode.HARVESTER:
      return 3;
    case EquipmentTypeCode.ETC2:
      return 3;
    default:
      return 3;
  }
}

export function workListByUserIdResDataToWorkListByUserIdData(resData: WorkListByUserIdResData): WorkListByUserIdData {
  let workListByUserIdData: WorkListByUserIdData = new WorkListByUserIdData();
  workListByUserIdData.workList = new Array<WorkItemByUserIdData>();
  resData.workList.forEach((item) => {
    let workItemByUserIdData: WorkItemByUserIdData = new WorkItemByUserIdData();
    workItemByUserIdData.workId = item.workId;
    workItemByUserIdData.workStartDate = item.workStartDate;
    workItemByUserIdData.workEndDate = item.workEndDate;
    workListByUserIdData.workList.push(workItemByUserIdData);
  })
  return workListByUserIdData;
}