import WorkLandItemData from "./WorkLandList/WorkLandItemData";
import { WorkTypeCode } from "code/WorkTypeCode";
import { CropTypeCode } from "code/CropTypeCode";
import WorkEquipmentData from "./WorkEquipment/WorkEquipmentData";

export default class WorkRegistrationData{
    public workId!: string;
    public workAddress1!: string;
    public workAddress2!: string;
    public workAddress3!: string;
    public workLandListData: Array<WorkLandItemData>=new Array<WorkLandItemData>();
    public workStartDate!: string;
    public workEndDate!: string;
    public cropType: CropTypeCode = CropTypeCode.RICE_STRAW;
    public workType: WorkTypeCode = WorkTypeCode.ROUND;
    public workEquipmentData: WorkEquipmentData = new WorkEquipmentData();
    public imagePreviewList: Array<string>=[];
    public workTeamList: Array<string>=['N'];
    public masterWorkId?: string;

    public get workDeletable(): boolean{
        const today = new Date();
        if (this.workStartDate === undefined) {
            return false;
        }
        const startDate = new Date(this.workStartDate);
        if (startDate <= today) {
            return false;
        }
        return true;
    }
}

