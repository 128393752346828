import { WeatherCode } from "code/WeatherCode";

export default class WeatherData {
  private _date!: Date;
  private _amHumidity!: number;
  private _amWeather!: WeatherCode;
  private _pmWeather!: WeatherCode;
  private _pmHumidity!: number;
  private _lowTemp!: number;
  private _highTemp!: number;

  public get date(): Date {
    return this._date;
  }
  public set date(value: Date) {
    this._date = value;
  }
  // public get weather(): WeatherOpenData {
  //   return this._weather;
  // }
  // public set weather(value: WeatherOpenData) {
  //   this._weather = value;
  // }
  public get amHumidity(): number {
    return this._amHumidity;
  }
  public set amHumidity(value: number) {
    this._amHumidity = value;
  }
  public get amWeather(): WeatherCode {
    return this._amWeather;
  }
  public set amWeather(value: WeatherCode) {
    this._amWeather = value;
  }
  public get pmWeather(): WeatherCode {
    return this._pmWeather;
  }
  public set pmWeather(value: WeatherCode) {
    this._pmWeather = value;
  }
  public get pmHumidity(): number {
    return this._pmHumidity;
  }
  public set pmHumidity(value: number) {
    this._pmHumidity = value;
  }
  public get lowTemp(): number {
    return this._lowTemp;
  }
  public set lowTemp(value: number) {
    this._lowTemp = value;
  }
  public get highTemp(): number {
    return this._highTemp;
  }
  public set highTemp(value: number) {
    this._highTemp = value;
  }
}
