import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';


const rootElement = document.getElementById('root');
const render = process.env.REACT_APP_STRICT_MODE === 'true' ? ReactDOM.render : ReactDOM.hydrate;

render(
  process.env.REACT_APP_STRICT_MODE === 'true' ? (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ) : (
    <App />
  ),
  rootElement
);
reportWebVitals();
