import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SelectedAvatarBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  span {
    font-size: 20px;
    font-weight: 500;
    line-height: 21px; /* 105% */
  }
`;

export const SelectedAvatarWrapper = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;

  img {
    display: block;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(17, 17, 17, 0.5);
  position: absolute;
  top: 0;
  right: -12px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: white;
    }
  }
`;

export const ProfileList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ProfileCheckBlock = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const InviteBox = styled.div`
  input {
    position: absolute;
    width: 0;
    height: 0;
  }
`;

export const CheckIconWrapper = styled.div<IsActive>`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 50%;

  border: 1px solid var(--gray-300, #ccc);

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid var(--green-100, #00b140);
      background: var(--green-100, #00b140);
    `}

  svg {
    path {
      fill: var(--white);
    }
  }
`;
