export function isEmpty(value: any) {
    if (value === undefined) {
      return true;
    }
    if (typeof value === 'string') {
      return !!(value === null || value === undefined || value === '');
    }
    if (typeof value === 'object') {
      return !!(value === null || value === undefined || Object.keys(value).length === 0);
    }
    return !!(value === null || value === undefined);
  }

export function landMapToString(landMap:number[][]){
    return JSON.stringify(landMap);
}
export function stringToLandMap(str:string):number[][]{
    return JSON.parse(str);
}