
import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class KeepListRequest extends BaseRequest{
    private readonly URL_MYPAGE_BASE= `${BaseService.API_URL}/myPage`;
    private URL_KEEP_LIST= this.URL_MYPAGE_BASE+"/keepList";

    getURL(): string {
        return this.URL_KEEP_LIST;
    }
}

