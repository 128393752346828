import { AppPageContainer } from 'ui/components/AppLayout/AppLayout.styles';
import useHeaderOptions from 'hooks/useHeaderOptions';
import ConfirmButton from './ConfirmButton/ConfirmButton';
import {
  InviteBox,
  ProfileCheckBlock,
  ProfileList,
} from './WorkerListPage.styles';
import WorkerItemData from '../WorkDetail/WorkerList/WorkerItemData';

interface WorkerListProps {
  workId:string;
  wokerListData:Array<WorkerItemData>;
}

const WorkerListPage = (props : WorkerListProps) => {
  useHeaderOptions({
    title: '작업자 관리',
    headerRight: <ConfirmButton />,
  });

  const onClickInviteBtn = () => {
    //작업자 초대 링크 생성
  }; 

  return (
    <>
      <AppPageContainer>
        <ProfileList>
          {props.wokerListData.map((worker, i) => (
            <ProfileCheckBlock key={i}>
              <span>{worker.workerName}</span>
              <InviteBox onClick={onClickInviteBtn}>
                <span>초대하기</span>
              </InviteBox>
            </ProfileCheckBlock>
          ))}
        </ProfileList>
      </AppPageContainer>
    </>
  );
};

export default WorkerListPage;
