import WorkRecordDetailGroupDateItemData from "./WorkRecordDetailGroupDateItemData";

export default class WorkRecordDetailGroupDateData {
  private _WorkRecordDetailGroupDateListData!: Array<WorkRecordDetailGroupDateItemData>; 

  public get WorkRecordDetailGroupDateListData(): Array<WorkRecordDetailGroupDateItemData> {
    return this._WorkRecordDetailGroupDateListData;
  }
  public set WorkRecordDetailGroupDateListData(
    value: Array<WorkRecordDetailGroupDateItemData>
  ) {
    this._WorkRecordDetailGroupDateListData = value;
  }
}
