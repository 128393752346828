export default class WorkRecordLogData  {
  public workId!: string;
  public landAddress!: string;
  public workerName!: string;
  public equipmentName!: string;
  public equipmentType!: string;
  public equipmentOrder: number = 0;
  public workStatus: string = "N";
  public polyLine: number[][] = [];
  public recordId: string = "";
  public colorOrder!: number;
  public workTeam: string = "N";
}
