import { WorkAvaliableStatusCode } from "code/WorkAvaliableStatusCode";

export default class WorkMemoItemData  {

  public workId!: string;
  public landAddress!: string;
  public commentLatitude!: number;
  public commentLongitude!: number;
  public workerName!: string;
  public comment!: string;
  public availableStatus: WorkAvaliableStatusCode = WorkAvaliableStatusCode.CAUTION;

  // private _workId!: string;
  // private _landAddress!: string;
  // private _commentLatitude!: number;
  // private _commentLongitude!: number;
  // private _workerName!: string;
  // private _comment!: string;
  // private _availableStatus: WorkAvaliableStatusCode = WorkAvaliableStatusCode.CAUTION;

  // public get workId(): string {
  //   return this._workId;
  // }
  // public set workId(value: string) {
  //   this._workId = value;
  // }
  // public get landAddress(): string {
  //   return this._landAddress;
  // }
  // public set landAddress(value: string) {
  //   this._landAddress = value;
  // }
  // public get commentLatitude(): number {
  //   return this._commentLatitude;
  // }
  // public set commentLatitude(value: number) {
  //   this._commentLatitude = value;
  // }
  // public get commentLongitude(): number {
  //   return this._commentLongitude;
  // }
  // public set commentLongitude(value: number) {
  //   this._commentLongitude = value;
  // }
  // public get workerName(): string {
  //   return this._workerName;
  // }
  // public set workerName(value: string) {
  //   this._workerName = value;
  // }
  // public get comment(): string {
  //   return this._comment;
  // }
  // public set comment(value: string) {
  //   this._comment = value;
  // }
  // public get availableStatus(): WorkAvaliableStatusCode {
  //   return this._availableStatus;
  // }
  // public set availableStatus(value: WorkAvaliableStatusCode) {
  //   this._availableStatus = value;
  // }
}
