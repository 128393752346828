export default class WorkRecordWorkerItemData {
  private _equipmentName!: string;
  private _equipmentType!: string;
  private _workerName!: string;

  public get equipmentName(): string {
    return this._equipmentName;
  }
  public set equipmentName(value: string) {
    this._equipmentName = value;
  }
  public get equipmentType(): string {
    return this._equipmentType;
  }
  public set equipmentType(value: string) {
    this._equipmentType = value;
  }
  public get workerName(): string {
    return this._workerName;
  }
  public set workerName(value: string) {
    this._workerName = value;
  }
}
