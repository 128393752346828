import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppBody } from "./AppLayout.styles";
import { useEffect, useState } from "react";
import Header from "ui/components/Header";
import { useRecoilState, useRecoilValue } from "recoil";
import { headerOptionsState } from "atoms/headerOptions";
import MapComponent from "../MapComponent/MapComponent";
import MapPageData from "./MapPageData";
import {
  firebaseDataToPolygonData,
  workDetailResDataToWorkDetailData,
  workDetailToWorkPlanData,
  workDetailToWorkPlanDetailData,
  workDetailToWorkPlanOriginData,
  workDetailToWorkRegistrationData,
  workLandItemDataToWorkRecordGroupAddressItemData,
  workListResDataToWorkListData,
} from "converter/WorkConverter";
import WorkListRequest from "network/work/WorkListRequest";
import { mapComponentMode, MapLevel, responseCode } from "code/Enumerator";
import WorkDetailReqData from "data/network/req/work/WorkDetailReqData";
import WorkDetailRequest from "network/work/WorkDetailRequest";
import BridgeCommands from "bridge/BridgeCommands";
import UserData from "data/UserData";
import WorkRecordDetailGroupDateRequest from "network/workRecord/WorkRecordDetailGroupDateRequest";
import {
  workRecordDetailGroupDateResDataToWorkRecordDetailGroupDateData,
} from "converter/WorkRecordConverter";
import PolygonData from "../MapComponent/PolygonData";
import WorkRecordDetailGroupDateReqData from "data/network/req/workRecord/WorkRecordDetailGroupDateReqData";
import WorkRegistrationData from "ui/pages/WorkRegistration/WorkRegistrationData";
import WorkLandItemData from "ui/pages/WorkRegistration/WorkLandList/WorkLandItemData";
import BridgeManager from "bridge/BridgeManager";
import WorkListReqData from "data/network/req/work/WorkListReqData";
import WorkListResData from "data/network/res/work/WorkListResData";
import WorkRecordDetailGroupDateResData from "data/network/res/workRecord/WorkRecordDetailGroupDateResData";
import WorkDetailResData from "data/network/res/work/WorkDetailResData";
import {
  getColorWithTeamCode,
  landListToPolygonList,
  memoListToMarkerList,
  productListToBuyerMarkerList,
  workListToWorkMarkerList,
  workRecordGroupAddressListToPolygonList,
  workRecordLogListToPolylineList,
} from "converter/KakaoConverter";
import MarkerData from "../MapComponent/MarkerData";
import PolyLineData from "../MapComponent/PolyLineData";
import ActionSheet from "../ActionSheet/ActionSheet";
import CircleButton from "../CircleButton";
import { ILocation, latlngToXy, weatherShortResponseToWeatherData } from "converter/WeatherConverter";
import { WeatherCode } from "code/WeatherCode";
import { atom__shortWeatherCode } from "atoms/selectedLocation";
import { dateToFormmattedStr } from "converter/DateConverter";
import { CropTypeCode } from "code/CropTypeCode";
import WorkRecordLogListReqData from "data/network/req/workRecord/WorkRecordLogListReqData";
import WorkMemoItemData from "ui/pages/WorkDetail/WorkMemoItemData";
import { WorkMemoModalProps } from "../Modals/WorkMemoModal/WorkMemoModal";
import { useAlertModal, useConfirmModal, useWorkMemoModal, useWorkModal } from "hooks/useModal";
import LandRegistrationItemReqData from "data/network/req/work/LandRegistrationItemReqData";
import LandAddRegistReqData from "data/network/req/work/LandAddRegistReqData";
import LandRegistrationRequest from "network/work/LandRegistrationRequest";
import BaseResData from "data/network/res/BaseResData";
import BuyListByFilterItemResData from "data/network/res/trade/BuyListByFilterItemResData";
import { WorkModalProps } from "../Modals/WorkModal/WorkModal";
import WorkRecordGroupAddressItemData from "ui/pages/WorkRecordGroupAddressList/WorkRecordGroupAddressListItemData";
import { Socket, io } from "socket.io-client";
import SocketCommands from "command/SocketCommands";
import WorkRecordLogData from "ui/pages/WorkDetail/WorkRecordLogData";
import WorkMemoRegistrationReqData from "data/network/req/workMemo/WorkMemoRegistrationReqData";
import WorkRecordRegistrationReqData from "data/network/req/workRecord/WorkRecordRegistrationReqData";
import WorkRecordLogListRequest from "network/workRecord/WorkRecordLogListRequest";
import WorkRecordLogListResData from "data/network/res/workRecord/WorkRecordLogListResData";
import { workRecordLogListResDataToWorkRecordLogListData } from "converter/WorkRecordLogConverter";
import WorkRecordGroupDateItemData from "../Work/WorkRecordGroupDateList/WorkRecordGroupDateItemData";
import BaseService from "network/BaseService";
import { ActionModeCode } from "code/ActionModeCode";
import { ChatTypeCode } from "code/ChatTypeCode";
import { Unsubscribe, collection, doc, getDocs, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import {
  fireStoreManager,
} from '../../../firebase/FireStoreManager';
import { stringToLandMap } from "common/utils/StringUtils";
import axios from "axios";
import { actionSheetState } from "atoms/actionSheet";
import OtherSubWorkListReqData from "data/network/req/work/OtherSubWorkListReqData";
import OtherSubWorkListRequest from "network/work/OtherSubWorkListRequest";
import OtherSubWorkListResData from "data/network/res/work/OtherSubWorkListResData";
import dayjs from "dayjs";
import Loading from "../Loading";
import WorkTeamPlanData from "ui/pages/WorkTeamPlan/WorkTeamPlanData";
import { isLoadingState } from "atoms/loadingState";
import { BaseRequest } from "network/BaseRequest";
import { isLoading } from "types/isLoading.types";
import MasterWorkDetailRequest from "network/work/MasterWorkDetailRequest";
import SubWorkDetailRequest from "network/work/SubWorkDetailRequest";

let mapPageData: MapPageData = new MapPageData();
let bridgeManager: BridgeManager = new BridgeManager();
let tempWorkLandItemData: WorkLandItemData;
let socket: Socket;
let debugLogList: Array<string> = [];
const MapPage = () => {
  const workListRequest: WorkListRequest = new WorkListRequest();
  const workDetailRequest: WorkDetailRequest = new WorkDetailRequest();
  const workRecordLogListRequest: WorkRecordLogListRequest = new WorkRecordLogListRequest();
  const workRecordDetailGroupDateRequest: WorkRecordDetailGroupDateRequest = new WorkRecordDetailGroupDateRequest();
  const landRegistrationRequest: LandRegistrationRequest = new LandRegistrationRequest();
  const [viewModel, setViewModel] = useState({ mapPageData });
  const props = useRecoilValue(headerOptionsState);
  const location = useLocation();
  const navigate = useNavigate();
  const { openWorkMemoModal } = useWorkMemoModal();
  const { openWorkModal } = useWorkModal();
  const { openConfirmModal } = useConfirmModal();
  const [r__shortWeatherCode, r__setShortWeatherCode] = useRecoilState(atom__shortWeatherCode);
  const [sheetState, setSheetState] = useRecoilState(actionSheetState);
  const [mapLevel, setMapLevel] = useState<MapLevel>(MapLevel.ZOOM_LEVEL_DETAIL);
  const [mapCenter, setMapCenter] = useState<number[]>([37.566535, 126.9779692]);
  const [myLatLng, setMyLatLng] = useState<number[]>([37.566535, 126.9779692]);
  const [markerList, setMarkerList] = useState<MarkerData[]>([]);
  const [polygonList, setPolygonList] = useState<PolygonData[]>([]);
  const [polylineList, setPolylineList] = useState<PolyLineData[]>([]);
  const [workerMarkerList, setWorkerMarkerList] = useState<MarkerData[]>([]);
  const [pinLatLng, setPinLatLng] = useState<number[]>([0, 0]);
  const [planTeam, setPlanTeam] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { openAlertModal } = useAlertModal();
  const [socketConnect, setSocketConnect] = useState<boolean>(false);
  let hasConnectError = false;

  const geometryBaseUrl = `${BaseService.API_URL}/open/geometry`;
  const shortWeatherBaseUrl = `${BaseService.API_URL}/open/weather/short`;
  mapPageData.location = location.pathname;

  useEffect(() => {
    mapPageData.location = location.pathname;
    async function init() {

        setMarkerList([]);
        setPolygonList([]);
        setPolylineList([]);
        setWorkerMarkerList([]);
        mapPageData.workRegistrationData = new WorkRegistrationData();
        mapPageData.originWorkRegistrationData = new WorkRegistrationData();
        setPlanTeam(undefined);

      switch (mapPageData.mapComponentMode) {
        case mapComponentMode.WORK_LIST:
          await reqWorkList();
          break;
        case mapComponentMode.WORK_DETAIL:
          await reqWorkDetail();
          await reqWorkRecordLogList();
          break;
        case mapComponentMode.NOTIFY_LANDOWNER:
          await reqWorkRecordLogList();
          break;
        case mapComponentMode.WORK_RECORD_GROUP_ADDRESS_LIST:
          // await reqWorkDetail();
          await reqWorkRecordLogList();
          const workDocRef = doc(fireStoreManager, "workerWork", location.state.workId as string);
          const landAddressCollectionRef = collection(workDocRef, "landAddress");

          const getLandAddressDocs = async () => {
            const landAddressSnapshot = await getDocs(landAddressCollectionRef);
            const landAddressList = landAddressSnapshot.docs.map(doc => ({
              id: doc.id,
              data: doc.data()
            }));
            return landAddressList;
          };
      
          getLandAddressDocs().then(landAddressList => {
            const polygonDataList = firebaseDataToPolygonData(landAddressList);
            setPolygonList(polygonDataList);
          });
          break;
        case mapComponentMode.WORK_RECORD_DAILY_LIST:
          await reqWorkRecordDetailGroupDate();
          const workDailyDocRef = doc(fireStoreManager, "workerWork", location.state.workId as string);
          const dailyLandAddressCollectionRef = collection(workDailyDocRef, "landAddress");

          const getDailyLandAddressDocs = async () => {
            const dailyLandAddressSnapshot = await getDocs(dailyLandAddressCollectionRef);
            const dailyLandAddressList = dailyLandAddressSnapshot.docs.map(doc => ({
              id: doc.id,
              data: doc.data()
            }));
            return dailyLandAddressList;
          };
      
          getDailyLandAddressDocs().then(dailyLandAddressList => {
            const polygonDataList = firebaseDataToPolygonData(dailyLandAddressList);
            setPolygonList(polygonDataList);
          });
          break;
        case mapComponentMode.WORK_RECORD_DETAIL_GROUP_DATE:
          reqWorkRecordDetailGroupDate();
          break;       
        case mapComponentMode.WORK_REGISTRATION:
          mapPageData.workRegistrationData = new WorkRegistrationData();
          mapPageData.originWorkRegistrationData = new WorkRegistrationData();
          if(location.state?.excelData !== undefined){
            mapPageData.workRegistrationData.workAddress1 = location.state.excelData.workAddress1;
            mapPageData.workRegistrationData.workAddress2 = location.state.excelData.workAddress2;
            mapPageData.workRegistrationData.workAddress3 = location.state.excelData.workAddress3;
            mapPageData.workRegistrationData.workStartDate = location.state.excelData.workStartDate;
            mapPageData.workRegistrationData.workEndDate = location.state.excelData.workEndDate;
            mapPageData.workRegistrationData.workLandListData = location.state.excelData.workLandListData;
            mapPageData.workRegistrationData.workTeamList = [...location.state.excelData.workTeamList, 'N'];
            let landList: Array<PolygonData> = [];
            mapPageData.workRegistrationData.workLandListData.forEach((workLandItem, i) => {
              let polygonData = new PolygonData();
              polygonData.pointList = workLandItem.landMap;
              polygonData.polygonAddress = workLandItem.landFullAddress!
              polygonData.polygonLineColor = getColorWithTeamCode(workLandItem.workTeam!);
              landList.push(polygonData);
            });
            setPolygonList(landList);
          }
          setViewModel({ mapPageData });
          break;
        case mapComponentMode.WORK_UPDATE:
          mapPageData.originWorkRegistrationData = new WorkRegistrationData();
          mapPageData.workRegistrationData = new WorkRegistrationData();
          mapPageData.originWorkRegistrationData = workDetailToWorkRegistrationData(mapPageData.workDetailData);
          mapPageData.workRegistrationData = workDetailToWorkRegistrationData(mapPageData.workDetailData);
          setMapCenter([mapPageData.workDetailData.workLatitude, mapPageData.workDetailData.workLongitude]);


          setPolygonList(workRecordGroupAddressListToPolygonList(mapPageData.workDetailData.landList,mapPageData.selectedWorkLandIdxMap));
          setViewModel({ mapPageData });
          break;
        case mapComponentMode.WORK_TEAM_PLAN:
          setMapCenter([mapPageData.workDetailData.workLatitude, mapPageData.workDetailData.workLongitude]);
          mapPageData.workRegistrationData = workDetailToWorkPlanOriginData(mapPageData.workDetailData);
          mapPageData.workRegistrationData.workId = location.state.workId;
          mapPageData.workRegistrationData.masterWorkId = location.state.masterWorkId;
          mapPageData.workTeamPlanData = workDetailToWorkPlanData(mapPageData.workDetailData);
          
          await reqOtherSubWorkList();
          if(mapPageData.workDetailData.landList.length > 0){
            let polygonList: Array<PolygonData> = [];
            mapPageData.workRegistrationData.workLandListData.forEach((workLandItem, i) => {
              let polygonData = new PolygonData();
              polygonData.pointList = workLandItem.landMap;
              polygonData.polygonAddress = workLandItem.landAddress;
              polygonData.polygonLineColor = getColorWithTeamCode(workLandItem.workTeam!);
              polygonList.push(polygonData);
            });
            setPolygonList(polygonList);
            setViewModel({ mapPageData });
          }
          break;
        case mapComponentMode.WORK_TEAM_PLAN_UPDATE:
          mapPageData.workTeamPlanData = new WorkTeamPlanData();
          mapPageData.originWorkTeamPlanData = new WorkTeamPlanData();
          await reqMasterWorkDetail();
          mapPageData.workRegistrationData = workDetailToWorkPlanOriginData(mapPageData.workDetailData);
          mapPageData.workRegistrationData.workId = location.state.workId;
          mapPageData.workRegistrationData.masterWorkId = location.state.masterWorkId;
          await reqOtherSubWorkList();
          await reqWorkPlanDetail();
          if(mapPageData.workDetailData.landList.length > 0){
            let polygonList: Array<PolygonData> = [];
            mapPageData.workRegistrationData.workLandListData.forEach((workLandItem, i) => {
              let polygonData = new PolygonData();
              polygonData.pointList = workLandItem.landMap;
              polygonData.polygonAddress = workLandItem.landAddress;
              polygonData.polygonLineColor = getColorWithTeamCode(workLandItem.workTeam!);
              polygonList.push(polygonData);
            });
            mapPageData.workTeamPlanData.landList.forEach((landItem) => {
              let findPolygon = polygonList.find((polygon) => {
                return polygon.polygonAddress === landItem.landAddress;
              });
              findPolygon && (findPolygon.polygonColor = getColorWithTeamCode(landItem.workTeam!));
            })
            setPolygonList(polygonList);
            setViewModel({ mapPageData });
          }
          break;
      }
      setIsLoading(false);
      if (bridgeManager.isApp()) {
        bridgeManager.init();
        bridgeManager.setEventListener(BridgeCommands.BRG_ON_SOCKET_DISCONNECT_REQUEST_RECEIVED, onSocketDisconnectRequestReceived);
        bridgeManager.setEventListener(BridgeCommands.BRG_ON_SOCKET_RECONNECT_REQUEST_RECEIVED, onSocketReconnectRequestReceived);
      }

    }
    setIsLoading(true);
    init();

    let fireStoreUnsub: Unsubscribe;
    if ([mapComponentMode.WORK_DETAIL].includes(mapPageData.mapComponentMode)) {
      if (mapPageData.location === "/work/detail") {
        socket = io(BaseService.CHAT_URL,{
          reconnection: true,
          reconnectionDelay: 1000,
          reconnectionAttempts: 10
        });

        socket.io.on("reconnect", () => {
          setSocketConnect(!socketConnect);
        });
        
        socket.on("connect", () => {
          socket.emit(SocketCommands.JOIN_ROOM, location.state.workId);
        });

        socket.on("disconnect", (reason) => {
        });

        socket.on("connect_error", () => {
          setTimeout(() => {
            hasConnectError = true;
          }, 1000);
        });

        // socket receiver
        // 소켓 1 점의위치 생성 시 receiver
        socket.on(SocketCommands.ON_WORKER_LOCATION_UPDATE_RECEIVED, (data: any) => {
            onWorkRecordLogRecieved(data.workRecordLogData);
          }
        );

        // fireStroe 로 대체
        // 소켓 2 필지 장비 작업 완료 receiver
        socket.on(
          SocketCommands.ON_LAND_EQUIP_WORK_DONE_RECEIVED, (data: any) => {
            onLandEquipWorkDoneRecieved(data.workRecordLogData);
          }
        );

        socket.on(SocketCommands.ON_MEMO_REGISTED_RECEIVED, (data: any) => {
          onWorkMemoItemRegistRecieved(data.workMemoData);
        });

        // 소켓 4. 메모 기록 receiver
        socket.on(SocketCommands.ON_MEMO_UPDATE_RECEIVED, (data: any) => {
          onWorkMemoItemUpdateRecieved(data.workMemoData);
        });

        // socket.on(SocketCommands.ON_MEMO_DELETED_RECEIVED, (data: any) => {
        //   onWorkMemoItemDeleteReceived(data.workMemoData);
        // });

        // 소켓 5. 작업 기록 receiver
        socket.on(SocketCommands.ON_WORK_RECORD_DONE_RECEIVED, (data: any) => {
          onWorkRecordRegistRecieved(data.workRecordData, data.workRecordGroupDateItemData);
        });

        socket.on(SocketCommands.ON_WORKER_MARKER_UPDATE_RECEIVED, (data: any) => {
          onWorkerMarkerDataRecieved(data.workerMarkerData);
        });

        socket.on(SocketCommands.ON_WORKER_LEAVE_ROOM_RECEIVED, (data: any, ack) => {
          onWorkerLeaveRoomRecieved(data.workerName);
        });
      }

      const workDocRef = doc(fireStoreManager, "workerWork", location.state.workId as string);
      const landAddressCollectionRef = collection(workDocRef, "landAddress");

      const getLandAddressDocs = async () => {
        const landAddressSnapshot = await getDocs(landAddressCollectionRef);
        const landAddressList = landAddressSnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        }));
        return landAddressList;
      };
  
      getLandAddressDocs().then(landAddressList => {
        const polygonDataList = firebaseDataToPolygonData(landAddressList);
        setPolygonList(polygonDataList);
      });

      fireStoreUnsub = onSnapshot(landAddressCollectionRef, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const isDuplicate = mapPageData.workDetailData.landList.some((landItem) => {
              return (
                landItem.landAddress === change.doc.data().polygonAddress
              ); // 메세지 내용도 비교
            });
            
            if(!isDuplicate) {
              const polygonData = new PolygonData();
              polygonData.polygonAddress = change.doc.data().polygonAddress;  
              polygonData.pointList = stringToLandMap(change.doc.data().pointList);
              polygonData.polygonColor = change.doc.data().polygonColor;
              polygonData.polygonLineColor = change.doc.data().polygonLineColor;
              setPolygonList(prevPolygonList => [...prevPolygonList, polygonData]);
            }            
          } else if (change.type === "modified") {
            setPolygonList(prevPolygonList => {
              const updatedList = prevPolygonList.map(polygon => {
                if (polygon.polygonAddress === change.doc.data().polygonAddress) {
                  return {
                    polygonAddress: change.doc.data().polygonAddress,
                    polygonColor: change.doc.data().polygonColor,
                    pointList: stringToLandMap(change.doc.data().pointList),
                    polygonLineColor: change.doc.data().polygonLineColor
                  } as PolygonData;
                }
                return polygon;
              });
              return updatedList;
            });
          }
        });
      });
    }

    return () => {
      if (socket && socket.connected) {
        socket.disconnect();
      }
      if(fireStoreUnsub !== undefined) {
        fireStoreUnsub();
      }
      if (
        [
          mapComponentMode.WORK_REGISTRATION,
          mapComponentMode.WORK_UPDATE,
        ].includes(mapPageData.mapComponentMode)
      ) {
        onChangeSelectedLocation(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, socketConnect]);

  useEffect(() => {
  }, [viewModel]);

  // 소켓 퇴장 리시버
  function onSocketDisconnectRequestReceived() {
    if (![mapComponentMode.WORK_DETAIL].includes(mapPageData.mapComponentMode)) {return;}
    socket.disconnect();
  }

  // 소켓 재연결 리시버
  function onSocketReconnectRequestReceived() {
    if (![mapComponentMode.WORK_DETAIL].includes(mapPageData.mapComponentMode)) {return;}
    setSocketConnect(!socketConnect);
  }

  // 작업 목록 연도별 필터 이벤트
  function handleSelectYearBtnClick(year: number) {
    window.dataLayer.push({
      event: "click_tab_filter",
      year: `${year.toString()}`,
      filter: undefined,
    });

    mapPageData.workListYear = year;   
    reqWorkList();
  }

  // 작업 목록 작물 필터 이벤트
  function handleChangeSearchCropTypeList(cropTypeList: Array<CropTypeCode>) {
    window.dataLayer.push({
      event: "click_tab_filter",
      year: undefined,
      filter: `${cropTypeList.toString()}`,
    });

    mapPageData.workListSearchCropTypeList = cropTypeList;
    if (cropTypeList.length === 0) {
      mapPageData.workListData = [];
      setMarkerList([]);
      setMapLevel(MapLevel.ZOOM_LEVEL_DETAIL);
      setViewModel({ mapPageData });
      return;
    };
    reqWorkList();
  }

  // 작업 목록 REQUEST
  async function reqWorkList() {
    let workListReqData = new WorkListReqData();
    workListReqData.year = mapPageData.workListYear;
    workListReqData.searchCropKindList = mapPageData.workListSearchCropTypeList;
    const workListResData = (await workListRequest.send(workListReqData)) as WorkListResData;
    mapPageData.workListData = workListResDataToWorkListData(workListResData.workList);
    setMarkerList(workListToWorkMarkerList(mapPageData.workListData));
    setMapLevel(MapLevel.ZOOM_LEVEL_DETAIL);
    setViewModel({ mapPageData });
  }

  //작업 상세 REQUEST
  async function reqWorkDetail() {
    let workDetailReqData = new WorkDetailReqData();
    workDetailReqData.workId = location.state.workId;
    const workDetailResData = (await workDetailRequest.send(workDetailReqData)) as WorkDetailResData;
    mapPageData.workDetailData = workDetailResDataToWorkDetailData(workDetailResData);
    mapPageData.workDetailData.workId = location.state.workId;
    if(mapPageData.mapComponentMode === mapComponentMode.WORK_UPDATE){
      setPolygonList(workRecordGroupAddressListToPolygonList(mapPageData.workDetailData.landList,mapPageData.selectedWorkLandIdxMap));
    }
    setMarkerList(memoListToMarkerList(mapPageData.workDetailData.workMemoList));
    setMapLevel(MapLevel.ZOOM_LEVEL_DETAIL);
    mapPageData.mapComponentData.mapCenter = [mapPageData.workDetailData.workLatitude, mapPageData.workDetailData.workLongitude];
    setMapCenter([mapPageData.workDetailData.workLatitude, mapPageData.workDetailData.workLongitude]);

    //디버그를 위한 코드
    // const now = new Date();
    // const hours = now.getHours().toString().padStart(2, '0');
    // const minutes = now.getMinutes().toString().padStart(2, '0');
    // const seconds = now.getSeconds().toString().padStart(2, '0');
    // let time = `${hours}:${minutes}:${seconds}`;
    // setCount(count + 1);
    // debugLogList.unshift(`${count}번째 호출, 시간 :${time}`);
    //디버그 코드 끝
    setViewModel({ mapPageData });
  }

    //작업 상세 REQUEST
    async function reqMasterWorkDetail() {
      const masterWorkDetailRequest = new MasterWorkDetailRequest();
      let workDetailReqData = new WorkDetailReqData();
      workDetailReqData.workId = location.state.masterWorkId;
      const workDetailResData = (await masterWorkDetailRequest.send(workDetailReqData)) as WorkDetailResData;
      mapPageData.workDetailData = workDetailResDataToWorkDetailData(workDetailResData);
    }

  async function reqOtherSubWorkList() { 
    const otherSubWorkListReqData: OtherSubWorkListReqData = new OtherSubWorkListReqData();
    const otherSubWorkRequest: OtherSubWorkListRequest = new OtherSubWorkListRequest();
    otherSubWorkListReqData.masterWorkId = location.state.masterWorkId;
    const response: any = (await otherSubWorkRequest.send(otherSubWorkListReqData)) as OtherSubWorkListResData;
    if(response.responseCode !== undefined) {
      let newResponse = new OtherSubWorkListResData();
      newResponse.otherSubWorkDateList = response.otherSubWorkDateList;
      newResponse.otherSubWorkLandList = response.otherSubWorkLandList;

      if (newResponse !== null && newResponse !== undefined) {
        newResponse!.otherSubWorkDateList.forEach((item) => {
          let startDate = dayjs(item.workStartDate);
          let endDate = dayjs(item.workEndDate);
          for (let date = startDate; date.isSameOrBefore(endDate); date = date.add(1, 'day')) {
            mapPageData.workTeamPlanData.unavailableDateList.push(date);
          }
        });
  
        newResponse!.otherSubWorkLandList.forEach((item) => {
          mapPageData.workTeamPlanData.unavailableLandList.push(item.landAddress);
        });
        mapPageData.workRegistrationData.workLandListData = mapPageData.workRegistrationData.workLandListData.filter((landItem) => {
          return !mapPageData.workTeamPlanData.unavailableLandList.includes(landItem.landAddress)
        });
      }
    }
  }

  //작업 계획 상세 REQUEST
  async function reqWorkPlanDetail() {
    const subWorkDetailRequest = new SubWorkDetailRequest();
    let workDetailReqData = new WorkDetailReqData();
    workDetailReqData.workId = location.state.workId;
    const workDetailResData = (await subWorkDetailRequest.send(workDetailReqData)) as WorkDetailResData;
    let mySubworkTeamPlanResData = workDetailToWorkPlanDetailData(workDetailResDataToWorkDetailData(workDetailResData));
      
    mySubworkTeamPlanResData.landList.forEach((landItem) => {
      mapPageData.workTeamPlanData.landList.push(landItem);
      mapPageData.originWorkTeamPlanData.landList.push(landItem);
      mapPageData.workRegistrationData.workLandListData.push(landItem);
    });
    
    mapPageData.originWorkTeamPlanData!.landList.forEach((landItem) => {
      if (landItem.workTeam && !mapPageData.originWorkTeamPlanData.originTeamList.includes(landItem.workTeam)) {       
        mapPageData.originWorkTeamPlanData.originTeamList.push(landItem.workTeam);
      }
    });

    
    mapPageData.workTeamPlanData.workStartDate = mySubworkTeamPlanResData.workStartDate;
    mapPageData.workTeamPlanData.workEndDate = mySubworkTeamPlanResData.workEndDate;
    mapPageData.originWorkTeamPlanData.workStartDate = mySubworkTeamPlanResData.workStartDate;
    mapPageData.originWorkTeamPlanData.workEndDate = mySubworkTeamPlanResData.workEndDate;
    setViewModel({ mapPageData})
  }

  
  //작업 로그 REQUEST
  async function reqWorkRecordLogList() {
    const workRecordLogListReqData: WorkRecordLogListReqData = new WorkRecordLogListReqData();
    workRecordLogListReqData.workId = mapPageData.workDetailData.workId;
    const workRecordLogListResData = (await workRecordLogListRequest.send(workRecordLogListReqData)) as WorkRecordLogListResData;
    const workRecordLogListData: Array<WorkRecordLogData> = workRecordLogListResDataToWorkRecordLogListData(workRecordLogListResData);

    if (!(workRecordLogListData && workRecordLogListData.length > 0)) {return;};

    mapPageData.workDetailData.landList.forEach((landItem) => {
      const findedLog = workRecordLogListData.filter((logItem) => {
        return landItem.landAddress === logItem.landAddress;
      });
      landItem.workRecordLogList = findedLog;
    });
    
    // 서버 데이터 -> 화면 데이터
    for(let i = 0; i < mapPageData.workDetailData.landList.length; i++){
      let workerNameList = "";
      for(let j=0; j < mapPageData.workDetailData.landList[i].workRecordLogList.length; j++){
        workerNameList += mapPageData.workDetailData.landList[i].workRecordLogList[j].workerName + ", ";
      }
      debugLogList.unshift(`[서버 데이터] addr = ${mapPageData.workDetailData.landList[i].landAddress},length = ${mapPageData.workDetailData.landList[i].workRecordLogList.length}, workerNameList = ${workerNameList}` );
    }    
  }

  // 날짜별 작업 이력 REQUEST
  async function reqWorkRecordDetailGroupDate() {
    const workRecordDetailGroupDateReqData: WorkRecordDetailGroupDateReqData = new WorkRecordDetailGroupDateReqData();
    workRecordDetailGroupDateReqData.workId = location.state.workId;
    const workRecordDetailGroupDateResData = (await workRecordDetailGroupDateRequest.send(workRecordDetailGroupDateReqData)) as WorkRecordDetailGroupDateResData;
    mapPageData.workRecordDetailGroupDateData = workRecordDetailGroupDateResDataToWorkRecordDetailGroupDateData(workRecordDetailGroupDateResData);
    setViewModel({ mapPageData });
  }

  // 지도 클릭 이벤트
  function handleOnMapClick(latitude: number, longitude: number) {
    if (mapPageData.mapComponentMode === mapComponentMode.WORK_REGISTRATION || mapPageData.mapComponentMode === mapComponentMode.WORK_UPDATE){    
      if (bridgeManager.isApp()) {
        tempWorkLandItemData = new WorkLandItemData();
        tempWorkLandItemData.landLatitude = latitude;
        tempWorkLandItemData.landLongitude = longitude;
        tempWorkLandItemData.workTeam = "N";
        bridgeManager.bridgeCommand(
          BridgeCommands.BRG_GEOMETRY_INFO,
          { lat: latitude, lng: longitude },
          handleBrgGeometryInfoReceived
        );
      }
      else {
        const geometryApiUrl = `${geometryBaseUrl}?lng=${longitude}&lat=${latitude}`;
        tempWorkLandItemData = new WorkLandItemData();
        tempWorkLandItemData.landLatitude = latitude;
        tempWorkLandItemData.landLongitude = longitude;
        tempWorkLandItemData.workTeam = "N";
        axios.get(geometryApiUrl)
        .then((res) => {
          const address: string = res.data.data.addr;
          const coordinates: Array<Array<number>> = res.data.data.coordinates;
          handleBrgGeometryInfoReceived(address, coordinates);
        })
        .catch((e: Error) => {
          console.log("geometryApiUrlError: " + e.message);
        });
      }
    } else {
      return;
    }
  }

  // 지도 클릭 시 pin 표시
  function handlePin (latitude: number, longitude: number) {
    setSheetState('minimum');
    setPinLatLng([latitude, longitude]);
  };

  // 필지 추가 이벤트
  function addWorkLand(address: string, coordinates: Array<Array<number>>) {
    openConfirmModal({
      text: "필지를 추가로 등록하시겠습니까?", 
      onConfirm: () => {
        handleBrgGeometryInfoReceived(address, coordinates);
        landAddRegist();
      },
    });
  }

  // 필지 데이터 리시버
  function handleBrgGeometryInfoReceived(address: string, coordinates: Array<Array<number>>) {
    mapPageData.workRegistrationData.workAddress1 = address.split(" ")[0];
    mapPageData.workRegistrationData.workAddress2 = address.split(" ")[1];
    mapPageData.workRegistrationData.workAddress3 = address.split(" ")[2];
    tempWorkLandItemData.landFullAddress = address;
    tempWorkLandItemData.landMap = coordinates;
    makeWorkLandListDataWithTempData();
    findCenterPoint(coordinates);
    setViewModel({ mapPageData });
    
    window.dataLayer.push({
      event: "select_work_location",
      work_id: `${mapPageData.workRegistrationData.workId ? mapPageData.workRegistrationData.workId : undefined}`,
      work_location: `${address}`,
    });     
    
  }

  // 필지 날씨 정보 함수
  function makeWorkLandListDataWithTempData() {
    tempWorkLandItemData.landSize = calcLandSize(tempWorkLandItemData.landMap);

    /** 캘린더에 날씨 표시를 위해 위경도 set */
    const { landLatitude, landLongitude } = tempWorkLandItemData;
    onChangeSelectedLocation({latitude: landLatitude, longitude: landLongitude });

    switch (mapPageData.mapComponentMode) {
      case mapComponentMode.WORK_DETAIL:
        mapPageData.workDetailData.landList.push(workLandItemDataToWorkRecordGroupAddressItemData(tempWorkLandItemData));
        setPolygonList(workRecordGroupAddressListToPolygonList(mapPageData.workDetailData.landList));
        break;
      default:
        if (mapPageData.workRegistrationData.workLandListData.filter((item) => item.landFullAddress === tempWorkLandItemData.landFullAddress).length === 0) {
        mapPageData.workRegistrationData.workLandListData.push(tempWorkLandItemData);
        setPolygonList(landListToPolygonList(mapPageData.workRegistrationData.workLandListData));
        }
        break;
    }
  }

  // 필지 면적 함수
  function calcLandSize(landPolygon: number[][]) {
    const polygonPath = convertLandMapToPolygonPath(landPolygon);
    const polygon = new kakao.maps.Polygon({
      path: polygonPath,
    });
    return Math.floor(polygon.getArea());
  }

  // LandMap을 PolygonPath로 변환
  function convertLandMapToPolygonPath(landMap: Array<Array<number>>) {
    return landMap.map((coordinate: any) => {
      return new kakao.maps.LatLng(coordinate[1], coordinate[0]);
    });
  }

  // 필지 중심 좌표 함수
  function findCenterPoint(coordinates: Array<Array<number>>) {
    if (!coordinates || coordinates.length === 0) {
      return null; // 입력된 좌표 리스트가 비어있을 경우 중심점을 찾을 수 없음
    }

    // 모든 좌표의 위도와 경도 합을 초기화
    let totalLat = 0;
    let totalLng = 0;

    // 모든 좌표의 개수를 초기화
    let pointCount = 0;

    // 주어진 pointList의 각 좌표를 반복하면서 합산
    coordinates.forEach((point) => {
      if (point && point.length === 2) {
        // 좌표가 올바른 형식인 경우에만 합산
        totalLat += point[1];
        totalLng += point[0];
        pointCount += 1;
      }
    });

    // 유효한 좌표가 하나도 없을 경우 중심점을 찾을 수 없음
    if (pointCount === 0) {return null;};
    tempWorkLandItemData.landLatitude = totalLat / pointCount;
    tempWorkLandItemData.landLongitude = totalLng / pointCount;
  }

  // 마커 클릭 이벤트
  function handleOnMarkerClick(markerId: string) {
    if (mapPageData.mapComponentMode === mapComponentMode.WORK_LIST) {
      navigate("/work/detail", { state: { workId: markerId } });
    } else if (mapPageData.mapComponentMode === mapComponentMode.PRODUCT_LIST) {
      const finded = mapPageData.buyListData.find((item) => item.farmId === markerId);
      if (!finded) {return;}

      const delemiter = "_";
      const path = `/chat/${ChatTypeCode.BUY_CHAT}/`+markerId+delemiter+finded.userId+delemiter+localStorage.getItem('USER_ID');
      navigate(path);
    } else if (mapPageData.mapComponentMode === mapComponentMode.WORK_DETAIL) {
      const clickedMarker = markerList.find((marker) => marker.markerId === markerId);
      if (!clickedMarker) {return;}

      let newWorkMemoItemData: WorkMemoItemData = new WorkMemoItemData();
      const memoIndex = mapPageData.workDetailData.workMemoList?.findIndex((item) => item.landAddress === markerId);
      if (memoIndex! < 0) {return;}
      newWorkMemoItemData = mapPageData.workDetailData.workMemoList![memoIndex!];      
      let subWorkId = findSubWorkId(newWorkMemoItemData.landAddress);
      const modalProps: Omit<WorkMemoModalProps, "onClose"> = {
        workMemoItemData: newWorkMemoItemData,
        subWorkId: subWorkId,
        onSave: handleSaveWorkMemo,
        onDelete: handleDeleteWorkMemo  
      };
      openWorkMemoModal(modalProps);
    }
  }

  // 폴리곤 클릭 이벤트
  function handleOnPolygonClick(polygonAddress: string) {
    /**
     * [작업상세] workModal 출력
     * [토지주알리기] 필지 선택/선택취소 처리 + 폴리곤 리렌더링
     * [작업등록] 필지 추가 + 폴리곤 리렌더링
     */
    if (polygonAddress === undefined) {return;}
    switch (mapPageData.mapComponentMode) {
      case mapComponentMode.WORK_DETAIL :
        let newWorkRecordGroupAddressItemData: WorkRecordGroupAddressItemData = new WorkRecordGroupAddressItemData();

        if (mapPageData.workDetailData.landList?.length) {
          const landIndex = mapPageData.workDetailData.landList?.findIndex(
            (item) => item.landAddress === polygonAddress
          );
          if (landIndex! > -1) {
            newWorkRecordGroupAddressItemData = mapPageData.workDetailData.landList![landIndex!];
          }
        }

        const modalProps: Omit<WorkModalProps, "onClose"> = {
          workRecordGroupAddressItemData: newWorkRecordGroupAddressItemData,
          workDetailData: mapPageData.workDetailData,
          onWorkRecordRegist: handleOnWorkRecordRegist,
          onWorkRecordUpdate: handleOnWorkRecordUpdate,
          handleTradeDoneBtnClick: handleTradeDoneBtnClick,
          onSaveWorkMemo: handleSaveWorkMemo,
          onDeleteWorkMemo: handleDeleteWorkMemo
        };

        openWorkModal(modalProps);
        break;
      case mapComponentMode.NOTIFY_LANDOWNER:
        if (mapPageData.workDetailData.landList?.length) {
          const landIndex = mapPageData.workDetailData.landList?.findIndex(
            (item) => item.landAddress === polygonAddress
          );
          // 선택취소
          if (mapPageData.selectedWorkLandIdxMap[landIndex]) {
            delete mapPageData.selectedWorkLandIdxMap[landIndex];
          }
          // 선택
          else {
            mapPageData.selectedWorkLandIdxMap[landIndex] = true;
          }
          setViewModel({ mapPageData });
          setPolygonList(workRecordGroupAddressListToPolygonList(mapPageData.workDetailData.landList, mapPageData.selectedWorkLandIdxMap));
        }

        break;
      case mapComponentMode.WORK_REGISTRATION:
      case mapComponentMode.WORK_UPDATE:
        if (mapPageData.mapComponentMode === mapComponentMode.WORK_UPDATE && !mapPageData.workRegistrationData.workDeletable) { 
          openAlertModal({text: '작업이 진행중이므로 삭제할 수 없습니다.'});
          return;
        }
        const index = mapPageData.workRegistrationData.workLandListData.findIndex((workLandItemData: WorkLandItemData) =>
              workLandItemData.landFullAddress === polygonAddress
          );
        if (index !== -1) {
          mapPageData.workRegistrationData.workLandListData.splice(index, 1);
        }
        setPolygonList(landListToPolygonList(mapPageData.workRegistrationData.workLandListData));
        /** 위 코드에서 workLandListData의 항목을 지우고있으므로, 캘린더의 날씨 표시를 위한 recoil state도 초기화 */
        onChangeSelectedLocation(null);
        setViewModel({ mapPageData });
        break;
      case mapComponentMode.WORK_TEAM_PLAN:
      case mapComponentMode.WORK_TEAM_PLAN_UPDATE:
        handlePlanPolygonClick(polygonAddress);
        setViewModel({mapPageData});
        setPolygonList([...polygonList]);
        break;
    }
  }

  // 필지 삭제 버튼 클릭 이벤트
  function handleLandDeleteBtnClick(landAddress: string) {
    if (mapPageData.mapComponentMode === mapComponentMode.WORK_TEAM_PLAN || mapPageData.mapComponentMode === mapComponentMode.WORK_TEAM_PLAN_UPDATE) {
      mapPageData.workTeamPlanData.landList = mapPageData.workTeamPlanData.landList.filter((workLandItemData: WorkLandItemData) =>
        workLandItemData.landFullAddress !== landAddress
      );
      const disableLand = polygonList.findIndex((item: PolygonData) => {
        return item.polygonAddress === landAddress
      });
      if(disableLand === -1) {return;}
      polygonList[disableLand].polygonColor = "#81DAF5";
      setPolygonList([...polygonList]);      
      setViewModel({ mapPageData });
    } else {
      mapPageData.workRegistrationData.workLandListData = mapPageData.workRegistrationData.workLandListData.filter((workLandItemData: WorkLandItemData) =>
            workLandItemData.landFullAddress !== landAddress
        );
      setPolygonList(landListToPolygonList(mapPageData.workRegistrationData.workLandListData));
      
      window.dataLayer.push({
        event: "delete_work_location",
        work_id: `${mapPageData.workRegistrationData.workId ?? undefined}`,        
        work_location:  `${landAddress}`,
      });

      /** 선택한 작업지가 삭제되었으면 캘린더의 날씨도 지워야하므로 위경도 초기화 */
      onChangeSelectedLocation(null);
      setViewModel({ mapPageData });
    }
  }

  // 필지로 지도 이동 이벤트
  function handleMoveToLand(latitude : number, longitude: number) {
    setMapCenter([latitude, longitude]);
  }

  // 작업 계획 필지 선택 이벤트
  function handlePlanPolygonClick(polygonAddress: string) {
    if (planTeam === undefined) return;
    if (polygonAddress === undefined) return;
    const clickedPolygon = polygonList.find((polygon) => polygon.polygonAddress === polygonAddress);
    let clickedLand =  mapPageData.workRegistrationData.workLandListData.find((land) => land.landAddress === polygonAddress);
    // clickedLand!.workTeam = clickedLand!.workTeam === planTeam ? "N" : planTeam;
    clickedPolygon!.polygonColor = getColorWithTeamCode(clickedLand!.workTeam);
    if (!mapPageData.workTeamPlanData.landList.includes(clickedLand!)) {
      clickedLand!.workTeam = planTeam;
      clickedPolygon!.polygonColor = getColorWithTeamCode(planTeam);
      mapPageData.workTeamPlanData.landList.push(clickedLand!);
    } else if(mapPageData.workTeamPlanData.landList.includes(clickedLand!) && (clickedLand!.workTeam !== planTeam)) {
      clickedLand!.workTeam = planTeam;
      clickedPolygon!.polygonColor = getColorWithTeamCode(planTeam);
    } else {
      mapPageData.workTeamPlanData.landList = mapPageData.workTeamPlanData.landList.filter((land) => land.landAddress !== polygonAddress);
      clickedLand!.workTeam = "N";
      clickedPolygon!.polygonColor = "#81DAF5";
    }
    setViewModel({ mapPageData });
  }

  // 거래 완료 버튼 이벤트
  async function handleTradeDoneBtnClick(landAddress: string) {
    let workId = location.state.workId;

    let findedLand = mapPageData.workDetailData.landList.find((landItem) => {
      return landItem.landAddress === landAddress;
      }
    );

    if(!findedLand) {return;}
    findedLand.landTradeDone = "Y";    

    const targetAddress = findedLand.landAddress;
    const polygonList = workRecordGroupAddressListToPolygonList(mapPageData.workDetailData.landList);
    await updateFireStoreLandInfo(workId, targetAddress, polygonList);    

    socket.emit(SocketCommands.LAND_TRADE_DONE, { workId: workId, data: landAddress });
    let subWorkId = findSubWorkId(landAddress);
    if(subWorkId !== undefined && subWorkId !== null) {
      await updateFireStoreLandInfo(subWorkId, targetAddress, polygonList);
      socket.emit(SocketCommands.LAND_TRADE_DONE, { workId: subWorkId, data: landAddress });
    }
  }

  // 파이어 스토어 등록 함수
  async function updateFireStoreLandInfo(workId:string, targetAddress:string, polygonList:PolygonData[]) {
    const matchedPolygon = polygonList.find((polygon) => polygon.polygonAddress === targetAddress);
    if(matchedPolygon){
      const polygonColor = matchedPolygon?.polygonColor;
      try {
        const newRef = doc(fireStoreManager, `workerWork`, workId as string, 'landAddress', matchedPolygon!.polygonAddress as string);
        const data = {
          polygonAddress: matchedPolygon!.polygonAddress,
          polygonColor: matchedPolygon!.polygonColor,
        };
        await updateDoc(newRef, data);
      } catch (error) {
        // setDoc 메서드가 실패한 경우, 에러를 처리하고 필요한 작업 수행
        console.error('Error occurred while saving the document:', error);
      }
    }
  } 

  // 날씨 정보를 위한 필지 선택 함수
  function onChangeSelectedLocation(point: ILocation | null) {
    if (!point) {
      r__setShortWeatherCode(null);
      return;
    }
    const now = new Date();
    const today = dateToFormmattedStr();
    const amPm = now.getHours() >= 12 ? "pm" : "am";
    const { x: targetX, y: targetY } = latlngToXy(point);
    if (bridgeManager.isApp()) {
      bridgeManager.bridgeCommand(
        BridgeCommands.BRG_SHORT_FORECAST_INFO,
        {
          targetDate: today, // '20230829',
          targetX,
          targetY,
        },
        (message: any) => {
          const shortDataRes = message.response.body.items.item;
          const weatherDataList = weatherShortResponseToWeatherData(shortDataRes);
          const weatherPerDate: Record<string, WeatherCode> = {};
          weatherDataList.forEach((w) => {
            const wDate = dateToFormmattedStr({ date: w.date });
            if (wDate === today) {
              weatherPerDate[wDate] = amPm === "am" ? w.amWeather : w.pmWeather;
            } else {
              weatherPerDate[wDate] = w.amWeather;
            }
          });
          r__setShortWeatherCode(weatherPerDate);
        }
      );
    }
    else {
      const shortWeatherApiUrl = `${shortWeatherBaseUrl}?date=${today}&nx=${targetX}&ny=${targetY}`;
      axios.get(shortWeatherApiUrl)
      .then((res) => {
        const shortDataRes = res.data.data.response.body.items;
        const weatherDataList = weatherShortResponseToWeatherData(shortDataRes);
        const weatherPerDate: Record<string, WeatherCode> = {};
        weatherDataList.forEach((w) => {
          const wDate = dateToFormmattedStr({ date: w.date });
          if (wDate === today) {
            weatherPerDate[wDate] = amPm === "am" ? w.amWeather: w.pmWeather;
          } else {
            weatherPerDate[wDate] = w.amWeather;
          }
        });
        r__setShortWeatherCode(weatherPerDate);
      })
      .catch((e: Error) => {
        console.log("ShortWeatherApiUrlError: " + e.message);
      });
    }
  }

  // 롱 클릭 이벤트 함수
  const handleOnMapLongClick = (latitude: number, longitude: number) => {
    if (mapPageData.mapComponentMode !== mapComponentMode.WORK_DETAIL) {return;};

    tempWorkLandItemData = new WorkLandItemData();
    tempWorkLandItemData.landLatitude = Number(latitude.toFixed(5));
    tempWorkLandItemData.landLongitude = Number(longitude.toFixed(5));

    if (bridgeManager.isApp()) {
      bridgeManager.bridgeCommand(
        BridgeCommands.BRG_GEOMETRY_INFO,
        { lat: latitude, lng: longitude },
        addWorkLand
      );
    }
    else {
      const geometryApiUrl = `${geometryBaseUrl}?lng=${longitude}&lat=${latitude}`;
      axios.get(geometryApiUrl)
      .then((res) => {
        const address: string = res.data.data.addr;
        const coordinates: Array<Array<number>> = res.data.data.coordinates;
        addWorkLand(address, coordinates);
      })
      .catch((e: Error) => {
        console.log("geometryApiUrlError: " + e.message);
      });
    }
  };

  // 필지 추가 REQUEST
  async function landAddRegist() {
    let landAddRegistReqData: LandAddRegistReqData = new LandAddRegistReqData();
    const landAddListData: Array<LandRegistrationItemReqData> = [];
    landAddRegistReqData.workId = mapPageData.workDetailData.workId;

    switch (mapPageData.mapComponentMode) {
      case mapComponentMode.WORK_DETAIL:
        // landList의 마지막 항목 (추가하는 과정에서 방금 push된 필지정보)
        const addingLand = mapPageData.workDetailData.landList.at(-1);
        if (!addingLand) return;

        const landAddItem: LandRegistrationItemReqData = new LandRegistrationItemReqData();
        landAddItem.landAddress = addingLand.landAddress;
        landAddItem.landLatitude = addingLand.landLatitude;
        landAddItem.landLongitude = addingLand.landLongitude;
        landAddItem.landMap = JSON.stringify(addingLand.landMap);
        landAddItem.landSize = addingLand.landSize;
        landAddListData.push(landAddItem);
        break;
      default:
        mapPageData.workRegistrationData.workLandListData.forEach((item) => {
          const landAddItem: LandRegistrationItemReqData = new LandRegistrationItemReqData();
          landAddItem.landAddress = item.landAddress;
          landAddItem.landLatitude = item.landLatitude;
          landAddItem.landLongitude = item.landLongitude;
          landAddItem.landMap = JSON.stringify(item.landMap);
          landAddItem.landSize = item.landSize;
          landAddListData.push(landAddItem);
        });
    }
    landAddRegistReqData.landList = landAddListData;
    

    const response: any = (await landRegistrationRequest.send(landAddRegistReqData)) as BaseResData;
    if (response.responseCode !== responseCode.OK) {
      openAlertModal({text:"필지 등록에 실패하였습니다."});
      return;
    }

    // setDoc 
    try {
      const newRef = doc(fireStoreManager, `workerWork`, landAddRegistReqData.workId as string, 'landAddress', landAddRegistReqData.landList[0].landAddress as string);
      // 해당 문서의 스냅샷을 가져옵니다.
      const data = {
        polygonAddress: landAddRegistReqData.landList[0].landAddress,
        polygonColor: "#81DAF5",
        pointList: landAddRegistReqData.landList[0].landMap,
      };
      await setDoc(newRef, data);
    } catch (error) {
      // setDoc 메서드가 실패한 경우, 에러를 처리하고 필요한 작업 수행
      console.error('Error occurred while checking/saving the document:', error);
    }
  }

  // 판매자 매매 목록 필터 이벤트
  const handleChangeBuyList = (list: BuyListByFilterItemResData[]) => {
    // 지도 마커 update
    if (list) {
      mapPageData.buyListData = list;
      setMarkerList(productListToBuyerMarkerList(list.map((buyItem) => ({
            productId: buyItem.farmId,
            userId: buyItem.userId,
            latitude: buyItem.farmLatitude,
            longitude: buyItem.farmLongitude,
          }))
        )
      );
    }
  }; 

  // 작업 기록 소켓 리시버
  function onWorkRecordLogRecieved(workRecordLogData: WorkRecordLogData) {
    let newLandList : Array<WorkRecordGroupAddressItemData> = [...mapPageData.workDetailData.landList];
    const findedLandItem = newLandList.find(
      (landItem) => {
        return landItem.landAddress === workRecordLogData.landAddress;
      }
    );

    const findedWorkRecordLogIndex = findedLandItem?.workRecordLogList.findIndex((item) => {
        return (
          item.workerName === workRecordLogData.workerName &&
          item.equipmentType === workRecordLogData.equipmentType &&
          item.equipmentName === workRecordLogData.equipmentName
        );
      });

    if (findedWorkRecordLogIndex! > -1) {      
      findedLandItem!.workRecordLogList[findedWorkRecordLogIndex!].polyLine = workRecordLogData.polyLine;
    } else {     
      findedLandItem!.workRecordLogList.unshift(workRecordLogData);     
    }
    mapPageData.workDetailData.landList = newLandList;

    const allWorkRecordLogList: Array<WorkRecordLogData> = [];
    mapPageData.workDetailData.landList.forEach((landItem) => {
      landItem.workRecordLogList.forEach((workRecordLogItem) => {
        if (workRecordLogItem.workStatus === "N") {
          allWorkRecordLogList.push(workRecordLogItem);
        }
      });
    });
    
    setPolylineList(workRecordLogListToPolylineList(allWorkRecordLogList));
  }

  let originWorkerMarkerList : Array<MarkerData> = [];
  // 작업자 마커 소켓 리시버
  function onWorkerMarkerDataRecieved(workerMarkerData: MarkerData) {
    const foundWorkerIndex = originWorkerMarkerList.findIndex((item) => {
      return (
        item.markerId === workerMarkerData.markerId &&
        item.markerIcon === workerMarkerData.markerIcon
      );
    });
    if (foundWorkerIndex! > -1) {
      const updatedWorkerMarkerList = [...originWorkerMarkerList];
      updatedWorkerMarkerList[foundWorkerIndex] = workerMarkerData;
      setWorkerMarkerList(updatedWorkerMarkerList);
      originWorkerMarkerList = updatedWorkerMarkerList;
    } else {
      const updatedWorkerMarkerList = [...originWorkerMarkerList, workerMarkerData];
      setWorkerMarkerList(updatedWorkerMarkerList);
      originWorkerMarkerList = updatedWorkerMarkerList; 
    };
  }

  // 작업자 퇴장 소켓 리시버
  function onWorkerLeaveRoomRecieved(workerName: string) {
    const foundWorkerIndex = originWorkerMarkerList.findIndex((item) => item.markerId === workerName);
   
    if (foundWorkerIndex > -1) {
      const updatedWorkerMarkerList = [...originWorkerMarkerList];
      updatedWorkerMarkerList.splice(foundWorkerIndex, 1);
      setWorkerMarkerList(updatedWorkerMarkerList);
      originWorkerMarkerList = updatedWorkerMarkerList;
    }

    let newLandList : Array<WorkRecordGroupAddressItemData> = [...mapPageData.workDetailData.landList];
    
    newLandList.forEach((landItem) => {
      landItem.workRecordLogList = landItem.workRecordLogList.filter(workRecordLogItem => {
        return !(workRecordLogItem.workerName === workerName && workRecordLogItem.workStatus === "N");
      });      
    });
    
    mapPageData.workDetailData.landList = newLandList;

    const allWorkRecordLogList: Array<WorkRecordLogData> = [];
    mapPageData.workDetailData.landList.forEach((landItem) => {
      landItem.workRecordLogList.forEach((workRecordLogItem) => {
        if (workRecordLogItem.workStatus === "N") {
          allWorkRecordLogList.push(workRecordLogItem);
        }
      });
    });
    setPolylineList(workRecordLogListToPolylineList(allWorkRecordLogList));
  }

  // 장비 작업 완료 소켓 리시버
  function onLandEquipWorkDoneRecieved(workRecordLogData: WorkRecordLogData) {
    let newLandList = [...mapPageData.workDetailData.landList];
    const findedLandItem = newLandList.find((landItem) => {
        return landItem.landAddress === workRecordLogData.landAddress;
      }
    );
    const findedWorkRecordLogIndex = findedLandItem?.workRecordLogList.findIndex((item) => {
      return (
        item.workerName === workRecordLogData.workerName &&
        item.equipmentType === workRecordLogData.equipmentType &&
        item.equipmentName === workRecordLogData.equipmentName
      );
    });

    if (findedWorkRecordLogIndex! > -1) {
      const doneWorkRecordLog = findedLandItem!.workRecordLogList[findedWorkRecordLogIndex!];
      doneWorkRecordLog.workStatus = workRecordLogData.workStatus;
      findedLandItem!.workRecordLogList.splice(findedWorkRecordLogIndex!, 1);
      findedLandItem!.workRecordLogList.unshift(doneWorkRecordLog);
      debugLogList.unshift(`[찾았다.]`);
      // findedLandItem!.workRecordLogList[findedWorkRecordLogIndex!] = workRecordLogData
    } else {
      findedLandItem!.workRecordLogList.unshift(workRecordLogData);
      debugLogList.unshift(`[못찾았다.]`);
    };

   
    mapPageData.workDetailData.landList = newLandList;

    for(let i = 0; i < mapPageData.workDetailData.landList.length; i++){
      let workerNameList = "";
      for(let j=0; j < mapPageData.workDetailData.landList[i].workRecordLogList.length; j++){
        workerNameList += mapPageData.workDetailData.landList[i].workRecordLogList[j].workerName + ", ";
      }
      debugLogList.unshift(`[workRecordLog lenght] addr = ${mapPageData.workDetailData.landList[i].landAddress},length = ${mapPageData.workDetailData.landList[i].workRecordLogList.length}, workerNameList = ${workerNameList}` );
   }

    // setPolygonList(workRecordGroupAddressListToPolygonList(mapPageData.workDetailData.landList));
    debugLogList.unshift("[setPolygonList Called]");

    const allWorkRecordLogList: Array<WorkRecordLogData> = [];
    mapPageData.workDetailData.landList.forEach((landItem) => {
      landItem.workRecordLogList.forEach((workRecordLogItem) => {
        if (workRecordLogItem.workStatus === "N") {
          allWorkRecordLogList.push(workRecordLogItem);
        }
      });
    });
    setPolylineList(workRecordLogListToPolylineList(allWorkRecordLogList));
  }

  // 작업 메모 등록 소켓 센더
  function sendWorkMemoItemRegist(workMemoData: WorkMemoRegistrationReqData) {
    if (socket == null || socket.disconnected) {return;};
    socket.emit(SocketCommands.MEMO_REGISTED, {workId: mapPageData.workDetailData.workId, workMemoData: workMemoData});
    let subWorkId = findSubWorkId(workMemoData.landAddress);
    if (subWorkId !== undefined && subWorkId !== null) {
      socket.emit(SocketCommands.MEMO_REGISTED, {workId: subWorkId, workMemoData: workMemoData});
    }
  }

  // 작업 메모 수정 소켓 센더
  function sendWorkMemoItemUpdate(workMemoData: WorkMemoRegistrationReqData) {
    if (socket == null || socket.disconnected) {return;};
    socket.emit(SocketCommands.MEMO_UPDATE, {workId: mapPageData.workDetailData.workId, workMemoData: workMemoData});
    let subWorkId = findSubWorkId(workMemoData.landAddress);
    if (subWorkId !== undefined && subWorkId !== null) {
      socket.emit(SocketCommands.MEMO_UPDATE, {workId: subWorkId, workMemoData: workMemoData});
    }
  }

  // 작업 메모 등록 함수
  function handleSaveWorkMemo(workMemo: WorkMemoItemData) {
    const findedIndex = mapPageData.workDetailData.workMemoList.findIndex((item) => {
      return item.landAddress === workMemo.landAddress;
    });
    if(findedIndex > -1){
      mapPageData.workDetailData.workMemoList[findedIndex] = workMemo;
      sendWorkMemoItemUpdate(workMemo);
    }
    else{
      mapPageData.workDetailData.workMemoList.push(workMemo);
      sendWorkMemoItemRegist(workMemo);
    }
    setMarkerList(memoListToMarkerList(mapPageData.workDetailData.workMemoList));
  }

  function handleDeleteWorkMemo(workMemo: WorkMemoItemData) {
    const findedIndex = mapPageData.workDetailData.workMemoList.findIndex((item) => {
      return item.landAddress === workMemo.landAddress;
    });
    if (findedIndex > -1) {
      mapPageData.workDetailData.workMemoList[findedIndex] = workMemo;
      mapPageData.workDetailData.workMemoList = mapPageData.workDetailData.workMemoList.filter((item) => {
        return item.landAddress !== workMemo.landAddress;
      });
      sendWorkMemoItemUpdate(workMemo);
    }
    setMarkerList(memoListToMarkerList(mapPageData.workDetailData.workMemoList));
  }

  // 작업 기록 등록 함수
  function handleOnWorkRecordRegist(workRecordRegistrationReqData: WorkRecordRegistrationReqData) {
    const finedLandIndex = mapPageData.workDetailData.landList.findIndex((landItem) => {
        return workRecordRegistrationReqData.landAddress === landItem.landAddress;
      }
    );
    if (finedLandIndex < 0) {return;}

    mapPageData.workDetailData.landList[finedLandIndex].landOutput = workRecordRegistrationReqData.landOutput;
    
    const findedWorkRecordGroupDate = mapPageData.workDetailData.workRecordGroupDateList.findIndex((item) => {
      return item.workDate === workRecordRegistrationReqData.workDate;
    });
    
    let workRecordGroupDateItemData:WorkRecordGroupDateItemData;
    if(findedWorkRecordGroupDate > -1){
      mapPageData.workDetailData.workRecordGroupDateList[findedWorkRecordGroupDate].dateOutput += workRecordRegistrationReqData.landOutput;
      workRecordGroupDateItemData = mapPageData.workDetailData.workRecordGroupDateList[findedWorkRecordGroupDate];
    }
    else{      
      workRecordGroupDateItemData = new WorkRecordGroupDateItemData();
      workRecordGroupDateItemData.amHumidity = workRecordRegistrationReqData.amHumidity;
      workRecordGroupDateItemData.highTemp = workRecordRegistrationReqData.highTemp;
      workRecordGroupDateItemData.lowTemp = workRecordRegistrationReqData.lowTemp;
      workRecordGroupDateItemData.pmHumidity = workRecordRegistrationReqData.pmHumidity;
      workRecordGroupDateItemData.workDate = workRecordRegistrationReqData.workDate;
      workRecordGroupDateItemData.dateOutput = workRecordRegistrationReqData.landOutput;
      workRecordGroupDateItemData.amWeather = workRecordRegistrationReqData.amWeather;
      workRecordGroupDateItemData.pmWeather = workRecordRegistrationReqData.pmWeather;
      mapPageData.workDetailData.workRecordGroupDateList.push(workRecordGroupDateItemData);      
    }
    setViewModel({ mapPageData });
    sendWorkRecordRegist(workRecordRegistrationReqData, workRecordGroupDateItemData);
  }

  // 작업 기록 수정 함수
  function handleOnWorkRecordUpdate(workRecordRegistrationReqData: WorkRecordRegistrationReqData, oldLandOutput: number): void {
    const findedLandIndex = mapPageData.workDetailData.landList.findIndex((landItem) => {
        return workRecordRegistrationReqData.landAddress === landItem.landAddress;
      }
    );
    if (findedLandIndex < 0) { return;}

    mapPageData.workDetailData.landList[findedLandIndex].landOutput = workRecordRegistrationReqData.landOutput;
    const findedWorkRecordGroupDate = mapPageData.workDetailData.workRecordGroupDateList.findIndex((item) => {
      return item.workDate === workRecordRegistrationReqData.workDate;
    });
    
    let workRecordGroupDateItemData:WorkRecordGroupDateItemData;
    if(findedWorkRecordGroupDate < 0){return;}
    mapPageData.workDetailData.workRecordGroupDateList[findedWorkRecordGroupDate].dateOutput += (workRecordRegistrationReqData.landOutput - oldLandOutput);
    workRecordGroupDateItemData = mapPageData.workDetailData.workRecordGroupDateList[findedWorkRecordGroupDate];    

    setViewModel({ mapPageData });
    sendWorkRecordRegist(workRecordRegistrationReqData, workRecordGroupDateItemData);
  }

  // 작업 메모 등록 소켓 리시버
  function onWorkMemoItemRegistRecieved(workMemoData: WorkMemoItemData) {
    mapPageData.workDetailData.workMemoList.push(workMemoData);
    setMarkerList(memoListToMarkerList(mapPageData.workDetailData.workMemoList));
  }  

  // 작업 메모 수정 소켓 리시버
  function onWorkMemoItemUpdateRecieved(workMemoData: WorkMemoItemData) {
    const findedMemoIndex = mapPageData.workDetailData.workMemoList.findIndex((item) => {
        return (item.landAddress === workMemoData.landAddress);      
  });
    if (findedMemoIndex < 0) {return;}
    if (workMemoData.comment === "") {
      mapPageData.workDetailData.workMemoList = mapPageData.workDetailData.workMemoList.filter((item) => {
        return item.landAddress !== workMemoData.landAddress;
      });
    } else {
      mapPageData.workDetailData.workMemoList[findedMemoIndex] = workMemoData;
    }
    setMarkerList(memoListToMarkerList(mapPageData.workDetailData.workMemoList));
  }

  // 작업 기록 등록 소켓 센더
  function sendWorkRecordRegist(workRecordData: WorkRecordRegistrationReqData, workRecordGroupDateItemData: WorkRecordGroupDateItemData) {
    if (socket == null || socket.disconnected) {return;};
    socket.emit(SocketCommands.WORK_RECORD_DONE, {
      workId: mapPageData.workDetailData.workId,
      workRecordData: workRecordData,
      workRecordGroupDateItemData : workRecordGroupDateItemData
    });
    let subWorkId = findSubWorkId(workRecordData.landAddress);
    if (subWorkId !== undefined && subWorkId !== null) {
      socket.emit(SocketCommands.WORK_RECORD_DONE, {
        workId: subWorkId,
        workRecordData: workRecordData,
        workRecordGroupDateItemData : workRecordGroupDateItemData
      });
    }
  }

  // 작업 기록 등록 소켓 리시버
  function onWorkRecordRegistRecieved(workRecordRegistrationReqData: WorkRecordRegistrationReqData, workRecordGroupDateItemData: WorkRecordGroupDateItemData) {
    const findLandIndex = mapPageData.workDetailData.landList.findIndex((item) => {
        return item.landAddress === workRecordRegistrationReqData.landAddress;
      }
    );
    if (findLandIndex < 0) { return;} 
    mapPageData.workDetailData.landList[findLandIndex].landOutput = workRecordRegistrationReqData.landOutput;
    
    const findedWorkRecordGroupDateIndex = mapPageData.workDetailData.workRecordGroupDateList.findIndex((item) => {
      // 같은 날짜의 작업이 있는지 확인
      return item.workDate === workRecordRegistrationReqData.workDate;
    });

    if(findedWorkRecordGroupDateIndex < 0){
      mapPageData.workDetailData.workRecordGroupDateList.push(workRecordGroupDateItemData);
    }
    else{
      mapPageData.workDetailData.workRecordGroupDateList[findedWorkRecordGroupDateIndex] = workRecordGroupDateItemData;
    }
    
    setViewModel({ mapPageData });
  }

  // 필지 추가 소켓 센더
  function sendLandAddRegist(landAddRegistReqData: LandAddRegistReqData) {
    socket.emit(SocketCommands.NEW_LAND_ADD, {workId: mapPageData.workDetailData.workId, landAddRegistReqData: landAddRegistReqData});
  }

  //하위 작업 ID 찾기
  function findSubWorkId(landAddress: string) {
    const findIndex = mapPageData.workDetailData.mySubWorkLandList.findIndex((item) => {
      return item.landAddress === landAddress;
    });
    if (findIndex < 0) {return;}
    return mapPageData.workDetailData.mySubWorkLandList[findIndex].subWorkId;
  }

  return (
    <>
      <Header {...props} />
      <AppBody appMode={UserData.instance.appMode}>
        <MapComponent
          mapCenter={mapCenter}
          mapLevel={mapLevel}
          myLatLng={myLatLng}
          markerList={markerList}
          polygonList={polygonList}
          polylineList={polylineList}
          workerMarkerList={workerMarkerList}
          planTeam={planTeam}
          pinLatLng={pinLatLng}
          handleOnMapClick={handleOnMapClick}
          handleOnMarkerClick={handleOnMarkerClick}
          handleOnPolygonClick={handleOnPolygonClick}
          handleOnMapLongClick={handleOnMapLongClick}
          handleMoveToLand={handleMoveToLand}
        /> 
        <CircleButton />
        <ActionSheet>
          {isLoading ? <Loading /> : (() => {
            switch (viewModel.mapPageData.mapComponentMode) {
              case mapComponentMode.WORK_LIST:
                return (
                  <Outlet context={{
                      workListData: viewModel.mapPageData.workListData,
                      mapComponentModeData: viewModel.mapPageData.mapComponentMode,
                      handleSelectYearBtnClick: handleSelectYearBtnClick,
                      onChangeSearchCropTypeList: handleChangeSearchCropTypeList,
                    }}
                  />
                );
              case mapComponentMode.PRODUCT_LIST:
                return (
                  <Outlet context={{
                      productListData: viewModel.mapPageData.productListData,
                      mapComponentModeData: viewModel.mapPageData.mapComponentMode,
                      onChangeBuyList: handleChangeBuyList,
                    }}
                  />
                );
              case mapComponentMode.WORK_DETAIL:
                return (
                  <Outlet context={{
                      workDetailData: viewModel.mapPageData.workDetailData,
                      debugLogList: debugLogList
                    }}
                  />
                );
              case mapComponentMode.NOTIFY_LANDOWNER:
                return (
                  <Outlet context={{
                      workDetailData: viewModel.mapPageData.workDetailData,
                      selectedWorkLandIdxMap: viewModel.mapPageData.selectedWorkLandIdxMap,
                      setPolygonList: setPolygonList,
                      setPolylineList: setPolylineList,
                    }}
                  />
                );
              case mapComponentMode.WORK_REGISTRATION:
                return (
                  <Outlet context={{
                      actionModeCode : ActionModeCode.CREATE,
                      workRegistrationData: viewModel.mapPageData.workRegistrationData,
                      onLandDeleteBtnClick: handleLandDeleteBtnClick,
                      onHandlePin : handlePin,
                    }}
                  />
                );
              case mapComponentMode.WORK_UPDATE:
                return (
                  <Outlet context={{
                      actionModeCode : ActionModeCode.UPDATE,
                      originWorkRegistrationData : mapPageData.originWorkRegistrationData,
                      workRegistrationData: viewModel.mapPageData.workRegistrationData,
                      onLandDeleteBtnClick: handleLandDeleteBtnClick,
                      onHandlePin : handlePin,                    
                    }}
                  />
                );            
              case mapComponentMode.WORK_RECORD_DAILY_LIST:
                  return (
                    <Outlet context={{
                      workDetailData: viewModel.mapPageData.workDetailData,
                      workRecordDetailGroupDate: viewModel.mapPageData.workRecordDetailGroupDateData,
                      }}
                    />
                  );
              case mapComponentMode.WORK_RECORD_GROUP_ADDRESS_LIST:
                return (
                  <Outlet context={{
                      workDetailData: viewModel.mapPageData.workDetailData,
                      handleMoveToLand: handleMoveToLand,
                      onHandlePinClick: handlePin,
                    }}
                  />
                );
              case mapComponentMode.WORK_TEAM_PLAN:
                return (
                  <Outlet context={{
                      actionModeCode : ActionModeCode.CREATE,
                      workRegistrationData: viewModel.mapPageData.workRegistrationData,
                      workTeamPlanData: mapPageData.workTeamPlanData,
                      onLandDeleteBtnClick: handleLandDeleteBtnClick,
                      setPlanTeam: setPlanTeam,
                      onHandlePin: handlePin
                    }}
                  />
                );
              case mapComponentMode.WORK_TEAM_PLAN_UPDATE:
                return (
                  <Outlet context={{
                    actionModeCode : ActionModeCode.UPDATE,
                    workRegistrationData: viewModel.mapPageData.workRegistrationData,
                    workTeamPlanData: mapPageData.workTeamPlanData,
                    originWorkTeamPlanData: mapPageData.originWorkTeamPlanData,
                    onLandDeleteBtnClick: handleLandDeleteBtnClick,
                    setPlanTeam: setPlanTeam,
                    onHandlePin: handlePin
                    }}
                  />
                );
              default:
                return <></>;
            }
          })()}
        </ActionSheet>
      </AppBody>
    </>
  );
};

export default MapPage;
