import dayjs from "dayjs";
import { useEffect, useState } from "react";

import {
  HightLightTitle,
  SearchResultAddress,
  SearchResultCategory,
  SearchResultContainer,
  SearchResultDescWrapper,
  SearchResultDistance,
  SearchResultItem,
  SearchResultTitle,
  SearchResultTitleWrapper,
  SearchSuggestDivider,
  SearchSuggestItem,
  SearchSuggestText,
} from "./SearchResult.styles";
import { SearchResultIcon } from "../WorkRegistrationSearch.styles";
import type {
  GeoCoderResult,
  UserSearchHistory,
} from "../WorkRegistrationSearch";
import { ReactComponent as SearchIcon } from "assets/images/search.svg";

type SearchResultProps = {
  setOpenSearch: React.Dispatch<React.SetStateAction<boolean>>;
  searchAddress: (
    latitude: number,
    longitude: number,
    address?: string
  ) => void;
  search: string;
  searchList: Array<
    kakao.maps.services.PlacesSearchResultItem | GeoCoderResult
  >;
  addToHistory: (item: UserSearchHistory) => void;
  searchSuggestList: Array<UserSearchHistory>;
};

const SearchResult: React.FC<SearchResultProps> = ({
  setOpenSearch,
  searchAddress,
  search,
  searchList,
  addToHistory,
  searchSuggestList,
}) => {
  const [location, setLocation] = useState<null | GeolocationCoordinates>(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation(position.coords);
      });
    }
  }, []);

  const highlightText = (text: string) => {
    if (!search) return text;
    // 특수문제 제거
    search = text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const splitText = text.split(new RegExp(`(${search})`, "gi"));
    return splitText.map((part, index) =>
      part.toLowerCase() === search.toLowerCase() ? (
        <HightLightTitle key={index}>{part}</HightLightTitle>
      ) : (
        part
      )
    );
  };

  const getDistanceInKM = (x: string, y: string) => {
    if (!location) return null;
    const polyline = new kakao.maps.Polyline({
      path: [
        new kakao.maps.LatLng(location.latitude, location.longitude),
        new kakao.maps.LatLng(parseFloat(y), parseFloat(x)),
      ],
    });
    return `${Math.round(polyline.getLength() / 1000)}km`;
  };

  return (
    <>
      <SearchResultContainer>
        {searchSuggestList.map((suggest) => (
          <SearchSuggestItem
            key={suggest.searchAt}
            onClick={() => {
              if(suggest.type === "placeResult" || suggest.type === "geoResult"){
                let x = parseFloat(suggest.search.x);
                let y = parseFloat(suggest.search.y);
                searchAddress(
                  y,
                  x,
                  suggest.search.address_name
                );
                window.dataLayer.push({
                  event: "search",
                  search_term: `${suggest.search.address_name}`,
                });
              }
              }             
            }
          >
            <SearchIcon width={24} height={24} />
            <SearchSuggestText>
              {highlightText(
                suggest.type === "search"
                  ? suggest.search
                  : suggest.type === "placeResult"
                  ? suggest.search.place_name
                  : suggest.type === "geoResult"
                  ? suggest.search.address_name
                  : ""
              )}
            </SearchSuggestText>
          </SearchSuggestItem>
        ))}
        {searchSuggestList.length > 0 && <SearchSuggestDivider />}
        {searchList.map((result) => {
          if ("place_name" in result) {
            return (
              <SearchResultItem
                key={result.id}
                onClick={() => {
                  setOpenSearch(false);
                  let x = parseFloat(result.x);
                  let y = parseFloat(result.y);
                  searchAddress(
                    y,
                    x,
                    result.address_name
                  );
                  window.dataLayer.push({
                    event: "search",
                    search_term: `${result.address_name}`,
                  });
                  addToHistory({
                    type: "placeResult",
                    searchAt: dayjs().toISOString(),
                    search: result,
                  });
                }}
              >
                <SearchResultIcon />
                <SearchResultTitleWrapper>
                  <SearchResultTitle>
                    {highlightText(result.place_name)}
                  </SearchResultTitle>
                  <SearchResultAddress>
                    {result.road_address_name || result.address_name}
                  </SearchResultAddress>
                </SearchResultTitleWrapper>
                <SearchResultDescWrapper>
                  <SearchResultCategory>
                    {result.category_name.split(" > ").slice(-1)[0]}
                  </SearchResultCategory>
                  <SearchResultDistance>
                    {getDistanceInKM(result.x, result.y)}
                  </SearchResultDistance>
                </SearchResultDescWrapper>
              </SearchResultItem>
            );
          } else {
            return (
              <SearchResultItem
                key={result.address_name}
                onClick={() => {
                  setOpenSearch(false);
                  addToHistory({
                    type: "geoResult",
                    searchAt: dayjs().toISOString(),
                    search: result,
                  });
                  let x = parseFloat(result.x);
                  let y = parseFloat(result.y);
                  searchAddress(
                    y,
                    x,
                    result.address_name
                  );
                  window.dataLayer.push({
                    event: "search",
                    search_term: `${result.address_name}`,
                  });
                }}
              >
                <SearchResultIcon />
                <SearchResultTitleWrapper>
                  <SearchResultTitle>
                    {highlightText(result.address_name)}
                  </SearchResultTitle>
                  <SearchResultAddress>
                    {result.address_name}
                  </SearchResultAddress>
                </SearchResultTitleWrapper>
              </SearchResultItem>
            );
          }
        })}
      </SearchResultContainer>
    </>
  );
};

export default SearchResult;
