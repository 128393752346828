export default class WorkLandItemData{

    public landLatitude!: number;
    public landLongitude!: number;
    public landSize!: number;
    public landMap!: number[][];
    public landFullAddress?: string | undefined;
    public landWorkDate?: string;
    public workTeam: string = "N";
    public landOwner: string = "";
    public landOwnerTel: string = "";
    public landProgress: string = "N";

    public get landAddress(): string {
        // return this._landFullAddress?.split(" ")[4] || this._landFullAddress?.split(" ")[3] ||"";
        return this.landFullAddress!;
    }
    // public get plannedOutput(): number {
    //     return Math.floor(this.landSize * 0.0025);
    // }     
    
    // private _landLatitude!: number;
    // private _landLongitude!: number;
    // private _landSize!: number;
    // // private _landMap!: number[][];
    // public landMap!: number[][];
    // private _landFullAddress?: string | undefined;
    // private _landWorkDate?: string;
    // private _workTeam?: string;
    // private _landOwner?: string;
    // private _landOwnerTel?: string;
    // private _landProgress?: string;

    // public get landAddress(): string {
    //     // return this._landFullAddress?.split(" ")[4] || this._landFullAddress?.split(" ")[3] ||"";
    //     return this._landFullAddress!;
    // }
    // public get landLatitude(): number {
    //     return this._landLatitude;
    // }
    // public set landLatitude(value: number) {
    //     this._landLatitude = value;
    // }
    // public get landLongitude(): number {
    //     return this._landLongitude;
    // }
    // public set landLongitude(value: number) {
    //     this._landLongitude = value;
    // }
    // public get landSize(): number {
    //     return this._landSize;
    // }
    // public set landSize(value: number) {
    //     this._landSize = value;
    // }
    // public get plannedOutput(): number {
    //     return Math.floor(this._landSize * 0.0025);
    // }       
    // // public get landMap(): number[][] {
    // //     return this._landMap;
    // // }
    // // public set landMap(value: number[][]) {
    // //     this._landMap = value;
    // // }
    // public get landFullAddress(): string | undefined {
    //     return this._landFullAddress;
    // }
    // public set landFullAddress(value: string | undefined) {
    //     this._landFullAddress = value;
    // }
    // public get landWorkDate(): string | undefined {
    //     return this._landWorkDate;
    // }
    // public set landWorkDate(value: string | undefined) {
    //     this._landWorkDate = value;
    // }
    // public get workTeam(): string | undefined{
    //     return this._workTeam;
    // }
    // public set workTeam(value: string | undefined) {
    //     this._workTeam = value;
    // }
    // public get landOwner(): string | undefined{
    //     return this._landOwner;
    // }
    // public set landOwner(value: string | undefined) {
    //     this._landOwner = value;
    // }
    // public get landOwnerTel(): string | undefined {
    //     return this._landOwnerTel;
    // }
    // public set landOwnerTel(value: string | undefined) {
    //     this._landOwnerTel = value;
    // }
    // public get landProgress(): string | undefined {
    //     return this._landProgress;
    // }
    // public set landProgress(value: string | undefined) {
    //     this._landProgress = value;
    // }

}

