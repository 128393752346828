import WorkRecordDetailGroupDateResData from "data/network/res/workRecord/WorkRecordDetailGroupDateResData";
import WorkRecordWorkerListResData from "data/network/res/workRecord/WorkRecordWorkerListResData";
import WorkRecordDetailGroupDateData from "ui/pages/WorkRecordDetailGroupDate/WorkRecordDetailGroupDateData";
import WorkRecordDetailGroupDateItemData from "ui/pages/WorkRecordDetailGroupDate/WorkRecordDetailGroupDateItemData";
import WorkRecordWorkerItemData from "ui/pages/WorkRecordRegistration/WorkRecordWorkerItemData";

export function workRecordDetailGroupDateResDataToWorkRecordDetailGroupDateData(workRecordDetailGroupDateResData:WorkRecordDetailGroupDateResData):WorkRecordDetailGroupDateData{
    const workRecordDetailGroupDateData:WorkRecordDetailGroupDateData=new WorkRecordDetailGroupDateData();
    const workRecordDetailGroupDateListData:Array<WorkRecordDetailGroupDateItemData> =new Array<WorkRecordDetailGroupDateItemData> ();
   
    workRecordDetailGroupDateResData.workRecordDetailGroupDateList.forEach(item=>{
        const workRecordDetailGroupDateItemData:WorkRecordDetailGroupDateItemData=new WorkRecordDetailGroupDateItemData();
        workRecordDetailGroupDateItemData.landAddress=item.landAddress;
        workRecordDetailGroupDateItemData.landOutput=item.landOutput;
        workRecordDetailGroupDateItemData.landSize=item.landSize;
        workRecordDetailGroupDateItemData.landTradeDone=item.landTradeDone;
        workRecordDetailGroupDateItemData.amHumidity=item.amHumidity;
        workRecordDetailGroupDateItemData.pmHumidity=item.pmHumidity;
        workRecordDetailGroupDateItemData.lowTemp=item.lowTemp;
        workRecordDetailGroupDateItemData.highTemp=item.highTemp;
        workRecordDetailGroupDateItemData.amWeather=item.amWeather;
        workRecordDetailGroupDateItemData.pmWeather=item.pmWeather;
        workRecordDetailGroupDateItemData.workDate=item.workDate;
        workRecordDetailGroupDateItemData.workerName=item.workerName;
        workRecordDetailGroupDateItemData.equipmentName=item.equipmentName;
        workRecordDetailGroupDateItemData.equipmentType=item.equipmentType;       
        workRecordDetailGroupDateListData.push(workRecordDetailGroupDateItemData);
    })
    workRecordDetailGroupDateData.WorkRecordDetailGroupDateListData=workRecordDetailGroupDateListData;
return workRecordDetailGroupDateData;
}

export function workerRecordWorkerListResDataToWorkerRecordWorkerListData(workRecordWorkerListResData : WorkRecordWorkerListResData): Array<WorkRecordWorkerItemData>{
    const workRecordWorkerListData=new Array<WorkRecordWorkerItemData>();
    workRecordWorkerListResData.workerList?.forEach(item=>{
        const workRecordWorkerItemData:WorkRecordWorkerItemData=new WorkRecordWorkerItemData();
        workRecordWorkerItemData.equipmentName=item.equipmentName;
        workRecordWorkerItemData.equipmentType=item.equipmentType;
        workRecordWorkerItemData.workerName=item.workerName;
        workRecordWorkerListData.push(workRecordWorkerItemData);
    });
    return workRecordWorkerListData;
}