import styled from "@emotion/styled";

export const SearchResultContainer = styled.ul`
  margin-top: 50px;
  height: calc(100vh - 160px);
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  z-index: 3;
`;

export const SearchResultItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 13px 16px 13px 26px;
  cursor: pointer;
  border-bottom: 1px solid var(--gray-200);
`;

export const SearchResultTitleWrapper = styled.div`
  flex: 1;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
`;

export const SearchResultTitle = styled.div`
  font-size: 20px;
`;

export const SearchResultAddress = styled.div`
  font-size: 16px;
  margin-top: 12px;
  color: var(--gray-400, #111111);
`;

export const SearchResultDescWrapper = styled.div`
  display: flex;
  padding-left: 16px;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
`;

export const SearchResultCategory = styled.div`
  font-size: 18px;
  color: var(--gray-600, #111111);
`;

export const SearchResultDistance = styled.div`
  font-size: 16px;
  margin-top: 12px;
`;

export const HightLightTitle = styled.span`
  color: #1966c7;
`;

export const SearchSuggestItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 13px 16px 13px 26px;
  height: 50px;
  cursor: pointer;
  border-bottom: 1px solid var(--gray-200);
  align-items: center;
`;

export const SearchSuggestText = styled.div`
  flex: 1;
  font-size: 20px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const SearchSuggestDivider = styled.div`
  padding: 5px;
  background: var(--gray-200);
`;
