import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class OtherSubWorkListRequest extends BaseRequest {
  // FIXME: URL 수정 필요
  private readonly URL_WORK_BASE = `${BaseService.API_URL}/work`;
  private readonly URL_OTHER_SUB_WORK_LIST = `${this.URL_WORK_BASE}/otherSubWorkList`;

  getURL(): string {
    return this.URL_OTHER_SUB_WORK_LIST;
  }
}
