export function getObjectEquals(obj1: any, obj2: any): boolean {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1).sort();
  const keys2 = Object.keys(obj2).sort();
  if (keys1.length !== keys2.length) {
    return false;
  }

  if (!keys1.every((key, index) => key === keys2[index])) {
    return false;
  }

  for (const key of keys1) {
    const value1 = obj1[key];
    const value2 = obj2[key];

    if (value1 !== value2) {
      return false;
    }
  }

  return true;
}

export function isObject(value:any) {
  return value != null && typeof value === 'object' && !Array.isArray(value);
}