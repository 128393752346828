import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppBody } from "./AppLayout.styles";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { headerOptionsState } from "atoms/headerOptions";
import { mapComponentMode, MapLevel } from "code/Enumerator";
import UserData from "data/UserData";
import BridgeManager from "bridge/BridgeManager";
import { farmListToMarkerList, productListToMarkerList } from "converter/KakaoConverter";
import ActionSheet from "../ActionSheet/ActionSheet";
import CircleButton from "../CircleButton";
import { atom__shortWeatherCode } from "atoms/selectedLocation";
import BuyerMapPageData from "./BuyerMapPageData";
import FarmListReqData from "data/network/req/farm/FarmListReqData";
import FarmListRequest from "network/farm/FarmListRequest";
import FarmListResData from "data/network/res/farm/FarmListResData";
import { farmListResDataToFarmListData } from "converter/FarmConverter";
import BuyerMapComponent from "../BuyerMapComponent/BuyerMapComponent";
import MarkerData from "../MapComponent/MarkerData";
import PolygonData from "../MapComponent/PolygonData";
import BuyerHeader from "../BuyerHeader";
import ProductListByFilterItemResData from "data/network/res/trade/ProductListByFilterItemResData";

let buyerMapPageData: BuyerMapPageData = new BuyerMapPageData();
let bridgeManager: BridgeManager = new BridgeManager();
const BuyerMapPage = () => {
  const [viewModel, setViewModel] = useState({ buyerMapPageData });
  const props = useRecoilValue(headerOptionsState);
  const location = useLocation();
  const navigate = useNavigate();
  const [r__shortWeatherCode, r__setShortWeatherCode] = useRecoilState(atom__shortWeatherCode);
  const [mapLevel, setMapLevel] = useState<MapLevel>(MapLevel.ZOOM_LEVEL_LIST);
  const [mapCenter, setMapCenter] = useState<number[]>([37.566535, 126.9779692]);
  const [myLatLng, setMyLatLng] = useState<number[]>([37.566535, 126.9779692]);
  const [markerList, setMarkerList] = useState<MarkerData[]>([]);
  const [polygonList, setPolygonList] = useState<PolygonData[]>([]);
  buyerMapPageData.location = location.pathname;

  useEffect(() => {
    buyerMapPageData = new BuyerMapPageData();
    buyerMapPageData.location = location.pathname;
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  function init() {
    buyerMapPageData.mapComponentData.searchLandMarker = null;
    switch (buyerMapPageData.mapComponentMode) {
      case mapComponentMode.FARM_LIST:
        reqFarmList();
        break;
      case mapComponentMode.PRODUCT_LIST:
        break;
    }
    if (bridgeManager.isApp()) {
      bridgeManager.init();
    }
  }

  // 급이 목록 REQUEST
  async function reqFarmList() {
    let farmListRequest = new FarmListRequest();
    const farmListReqData = new FarmListReqData();
    const farmListResData = (await farmListRequest.send(farmListReqData)) as FarmListResData;
    buyerMapPageData.farmListData = farmListResDataToFarmListData(farmListResData.farmList);
    setMarkerList(farmListToMarkerList(buyerMapPageData.farmListData));
    setMapLevel(MapLevel.ZOOM_LEVEL_LIST);
    setViewModel({ buyerMapPageData });
  }  

  // 마커 클릭
  function handleOnMarkerClick(markerId: string) {   
    if (buyerMapPageData.mapComponentMode === mapComponentMode.PRODUCT_LIST) {
      navigate(`/product/${markerId}`);
    } else if (buyerMapPageData.mapComponentMode === mapComponentMode.FARM_LIST) {
      window.dataLayer.push({
        event: "click_map_farm",
        farm_id: `${markerId}`,
      });     
      navigate("/farm/edit", { state: { farmId: markerId } });
    }
  }

  // 구매자 매매 목록 필터 이벤트
  const handleChangeProductList = (list: ProductListByFilterItemResData[]) => {
    setMarkerList(productListToMarkerList(list.map((productItem) => ({
          productId: productItem.productId,
          latitude: productItem.workLatitude,
          longitude: productItem.workLongitude,
        }))
      )
    );
  };

  return (
    <>
      <BuyerHeader {...props} />

      <AppBody appMode={UserData.instance.appMode}>
        <BuyerMapComponent
          mapCenter={mapCenter}
          mapLevel={mapLevel}
          myLatLng={myLatLng}
          markerList={markerList}
          polygonList={polygonList}
          handleOnMarkerClick={handleOnMarkerClick}
        />
        <CircleButton />
        <ActionSheet>
          {(() => {
            switch (viewModel.buyerMapPageData.mapComponentMode) {
              case mapComponentMode.FARM_LIST:
                return (
                  <Outlet context={{
                      farmListData: viewModel.buyerMapPageData.farmListData,
                      mapComponentModeData: viewModel.buyerMapPageData.mapComponentMode
                    }}
                  />
                );
              case mapComponentMode.PRODUCT_LIST:
                return (
                  <Outlet
                    context={{
                      productListData: viewModel.buyerMapPageData.productListData,
                      mapComponentModeData: viewModel.buyerMapPageData.mapComponentMode,
                      onChangeProductList: handleChangeProductList,
                    }}
                  />
                );
              default:
                return <></>;
            }
          })()}
        </ActionSheet>
      </AppBody>
    </>
  );
};

export default BuyerMapPage;
