import { useEffect, useState, useRef } from 'react';
import { MapLevel } from 'code/Enumerator';
import MapComponentData from '../MapComponent/MapComponentData';
import MarkerData from '../MapComponent/MarkerData';
import PolygonData from '../MapComponent/PolygonData';
import PolyLineData from '../MapComponent/PolyLineData';
import { IKakaoMouseEvent } from 'types/kakaoMap.types';
import { MapContainer } from 'styles/common.styles';
import myLocationIcon from 'assets/images/markerIcon/myLocation.jpg';
import currentIcon from 'assets/images/currentIcon.png';
import navigationModeIcon from 'assets/images/navigationModeIcon.png';
import { getColorWithTeamCode } from 'converter/KakaoConverter';
import searchAddressPin from 'assets/images/markerIcon/searchAddressPin.svg';
import WorkRegistrationSearch from 'ui/pages/WorkRegistrationSearch/WorkRegistrationSearch';


declare global {
  interface Window {
    daum: any;
    kakao: any;
  }
}
const { kakao } = window;
let workerMapComponentData: MapComponentData = new MapComponentData();

interface WorkerMapComponentProps {
  mapCenter?: number[];
  myLatLng?: number[];
  myTeam?: string;
  markerList?: Array<MarkerData>;
  workerMarkerList?: Array<MarkerData>;
  polygonList?: Array<PolygonData>;
  polylineList?: Array<PolyLineData>;
  myMarker?: MarkerData;
  pinLatLng?: number[];
  // landInfoList? : Array<LandInfo>;
  handleOnMapClick?: (latitude: number, longitude: number) => void;
  onClickMarker?: (markerId: string) => void;
  onClickMap?: (event: IKakaoMouseEvent) => void;
  onClickPolygon?: (polygonId: string) => void;
  handleMapCenter?: (latitude: number, longitude: number) => void;
}
const WorkerMapComponent = (props: WorkerMapComponentProps) => {
  const [iconString, setIconString] = useState<string> (navigationModeIcon);
  const [isMapNavigationMode, setIsMapNavigationMode] = useState<boolean> (true);
  const [isUsing, setIsUsing] = useState<boolean> (false);
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    init();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    workerMapComponentData.markerList = props.markerList || new Array<MarkerData>();

    workerMapComponentData.kakaoMarkerList?.forEach((item) => {
      kakao.maps.event.addListener(item, 'click', function () {
        props.onClickMarker && props.onClickMarker(item.markerId);
      });
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.markerList]);

  useEffect(() => {
    workerMapComponentData.polylineList = props.polylineList || new Array<PolyLineData>();

    let myTeamPolyLineList = new Array<PolyLineData>();
    if (props.polylineList && props.myTeam){
      myTeamPolyLineList = props.polylineList.filter((item) => item.polyLineTeam === props.myTeam);
      workerMapComponentData.polylineList = myTeamPolyLineList;
    }    
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.polylineList]);

  useEffect(() => {
    if (!isUsing) {
      if (timeoutRef.current !== null) return;
      timeoutRef.current = window.setTimeout(() => {
        workerMapComponentData.mapCenter = workerMapComponentData.myLatLng;
        setIsMapNavigationMode(true);
      }, 30000);
    } else {
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
        setIsMapNavigationMode(false);
      }
    }
  }, [isUsing]);

  useEffect(()=> {
    if(props.pinLatLng){
      let lat = props.pinLatLng[0];
      let lng = props.pinLatLng[1];
      handleMoveToMap(lat,lng); 
    }
  },[props.pinLatLng]);

  useEffect(() => {
    if (props.polygonList === undefined) return;
    workerMapComponentData.polygonList = props.polygonList || new Array<PolygonData>();
    let myTeamPolygonList = new Array<PolygonData>();
    if (props.polygonList && props.myTeam){
      myTeamPolygonList = props.polygonList.filter((item) => item.polygonTeam === props.myTeam);
      workerMapComponentData.polygonList = myTeamPolygonList;
    }

    workerMapComponentData.kakaoPolygonList?.forEach((item) => {
      kakao.maps.event.addListener(item, 'click', function () {
        kakao.maps.event.preventMap();
        props.onClickPolygon && props.onClickPolygon(item.polygonId);
      });
    });
  }, [props.polygonList, props.myTeam]);

  useEffect(() => {
    if (props.myLatLng === undefined || props.myLatLng[0] === undefined || props.myLatLng[1] === undefined) {return};
    workerMapComponentData.myLatLng = props.myLatLng;
    if (isMapNavigationMode) {
      workerMapComponentData.mapCenter = props.myLatLng;
    }
  }, [props.myLatLng]);
 
  useEffect(() => {
    if (props.workerMarkerList === undefined) {
      return;
    }
    let myWorkerMarkerList = new Array<MarkerData>();
    if (props.workerMarkerList && props.myTeam){
      myWorkerMarkerList = props.workerMarkerList.filter((item) => item.workTeam === props.myTeam);
      workerMapComponentData.workerMarkerList = myWorkerMarkerList;
    }    
    workerMapComponentData.workerMarkerList = props.workerMarkerList;
  }, [props.workerMarkerList]);

  useEffect(() => {
    if (props.myMarker === undefined) {
      return;
    }
    const icon = new kakao.maps.MarkerImage(
      props.myMarker?.markerIcon || myLocationIcon,
      new kakao.maps.Size(30, 30)
    );
    workerMapComponentData.myLocationMarker.setMap(null);
    workerMapComponentData.myInfoWindow &&
      workerMapComponentData.myInfoWindow.setMap(null);

    workerMapComponentData.myLocationMarker = new kakao.maps.Marker({
      position: new kakao.maps.LatLng(
        workerMapComponentData.myLatLng[0],
        workerMapComponentData.myLatLng[1]
      ),
      clickable: false,
      // image : props.myMarker?.markerIcon || myLocationIcon,
      image: icon,
      zIndex: 4,
    });
    workerMapComponentData.myLocationMarker.setMap(workerMapComponentData.kakaoMap);

    
    if(props.myTeam === 'N'){
    const info = new kakao.maps.CustomOverlay({
      map: workerMapComponentData.kakaoMap,
      content:
      `<div style="padding: 4px 8px; display: flex;  gap: 10px; width: fit-content; background-color: white; transform:translateY(-100%); margin-top:-30px; border: 1px solid;">`
      + `<span>`+ props.myMarker.info +`</span>`
      + `</div>`,
      position: new kakao.maps.LatLng(workerMapComponentData.myLatLng[0], workerMapComponentData.myLatLng[1]),
      zIndex: 4,
    });   
    workerMapComponentData.myInfoWindow = info;
   } else {    
      const info = new kakao.maps.CustomOverlay({
        map: workerMapComponentData.kakaoMap,
        content:
        `<div style="padding: 4px 8px; display: flex;  gap: 10px; width: fit-content; background-color: white; transform:translateY(-100%); margin-top:-30px; border: 1px solid;">`
        + `<span><span style="color: ${getColorWithTeamCode(props.myTeam!)}; font-weight: bold;">`+ props.myTeam + `</span> | ` + props.myMarker.info +`</span>`
        + `</div>`,
        position: new kakao.maps.LatLng(workerMapComponentData.myLatLng[0], workerMapComponentData.myLatLng[1]),
        zIndex: 4,
      });   
      workerMapComponentData.myInfoWindow = info;      
   }


    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.myMarker]);

  useEffect(() => {
    if (props.mapCenter === undefined || props.mapCenter[0] === undefined || props.mapCenter[1] === undefined) {return};
    workerMapComponentData.mapCenter = props.mapCenter;
  }, [props.mapCenter]);

  useEffect(() => {
    if (isMapNavigationMode) {
      setIconString(navigationModeIcon);
    } else {
      setIconString(currentIcon);
    }
  }, [isMapNavigationMode]);

  function init() {
    const container = document.getElementById('map'); // 지도를 표시할 div
    workerMapComponentData = new MapComponentData();
    const option = {
      center: new kakao.maps.LatLng(workerMapComponentData.myLatLng[0], workerMapComponentData.myLatLng[1]),
      level: MapLevel.ZOOM_LEVEL_WORKER,
    };

    workerMapComponentData.kakaoMap = new kakao.maps.Map(container, option);
    navigator.geolocation.getCurrentPosition(function (position) {
      workerMapComponentData.myLatLng = [position.coords.latitude, position.coords.longitude];
      workerMapComponentData.mapCenter = [position.coords.latitude, position.coords.longitude];
    });

    if (props.onClickMap)
      kakao.maps.event.addListener(
        workerMapComponentData.kakaoMap,
        'click',
        props.onClickMap
      );
  }

  function handleMyLocationClick() {
    if (!isMapNavigationMode) {
      workerMapComponentData.mapCenter = workerMapComponentData.myLatLng;
      setIconString(navigationModeIcon);
      setIsMapNavigationMode(true);
    }
    else {
      setIconString(currentIcon);
      setIsMapNavigationMode(false);
    }
    window.dataLayer.push({
      event: "click_my_location",
    });   
  }

  function handleMoveToMap(lat: number, lng: number) {
    workerMapComponentData.moveToMap(lat, lng);
    if(workerMapComponentData.searchLandMarker){workerMapComponentData.searchLandMarker.setMap(null)};
    
    workerMapComponentData.searchLandMarker = new kakao.maps.Marker({
      position: new kakao.maps.LatLng(lat, lng),
      clickable: false,
      image : new kakao.maps.MarkerImage(
        searchAddressPin,
        new kakao.maps.Size(20, 30),
      ),
      zIndex: 4
    });
    workerMapComponentData.searchLandMarker.setMap(workerMapComponentData.kakaoMap);
    setIsUsing(false);
  }

  function handleOnTouchStart() {
    setIsUsing(true);
  }

  function handleOnTouchEnd() {
    setIsUsing(false);
  }

  return (
    <>
      <div style={{
        width: "32px",
        height: "32px",
        position: "fixed", 
        top: "53px",
        left: "3px",
        padding: "5px",
        overflow: "hidden",
        zIndex: "3",
        borderRadius: "20%",
        backgroundColor: "white",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 4px 0px'
      }}>
        <img src={iconString} 
          alt="" 
          onClick={handleMyLocationClick} 
          style={{width: "26px", height: "26px"}}
        />
      </div>
      <WorkRegistrationSearch searchAddress={handleMoveToMap} onClick={() => {setIsUsing(true); setIsMapNavigationMode(false);}}/>
      <MapContainer id="map" onTouchStart={handleOnTouchStart} onTouchEnd={handleOnTouchEnd} />
    </>
  );
};

export default WorkerMapComponent;
