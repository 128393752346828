import { useEffect } from 'react';
import { headerOptionsState } from 'atoms/headerOptions';
import { useSetRecoilState } from 'recoil';
import { HeaderProps } from 'ui/components/Header';

type UseHeaderOptionsParams = HeaderProps;

const useHeaderOptions = (params: UseHeaderOptionsParams) => {
  const setHeaderOptions = useSetRecoilState(headerOptionsState);

  useEffect(() => {
    setHeaderOptions(params);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHeaderOptions]);
};

export default useHeaderOptions;
