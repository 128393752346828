import FarmRegistrationReqData, {
  ICowListReqItem,
  ICropListReqItem,
} from "data/network/req/farm/FarmRegistrationReqData";
import FarmItemResData from "data/network/res/farm/FarmItemResData";
import FarmItemData from "ui/pages/ForageManagement/ForageManagementItem/FarmItemData";
import { ILocation } from "./WeatherConverter";
import { CropTypeCode } from "code/CropTypeCode";
import { CowTypeCode } from "code/CowTypeCode";
import { ICropListItem } from "ui/pages/FarmRegistration/FarmRegistration.types";
import { CowScaleCode } from "code/CowScaleCode";
import FarmDetailResData from "data/network/res/farm/FarmDetailResData";
import FarmBuyStatusReqData from "data/network/req/farm/FarmBuyStatusReqData";

export function farmListResDataToFarmListData(farmListResData: Array<FarmItemResData>): Array<FarmItemData> {
  const farmList = new Array<FarmItemData>();
  farmListResData?.forEach((farmItemResData) => {
    const farmItemData = new FarmItemData();
    farmItemData.farmId = farmItemResData.farmId;
    farmItemData.farmName = farmItemResData.farmName;
    farmItemData.farmAddress = farmItemResData.farmAddress;
    farmItemData.farmAddress1 = farmItemResData.farmAddress1;
    farmItemData.farmAddress2 = farmItemResData.farmAddress2;
    farmItemData.farmAddress3 = farmItemResData.farmAddress3;
    farmItemData.farmLatitude = farmItemResData.farmLatitude;
    farmItemData.farmLongitude = farmItemResData.farmLongitude;
    farmItemData.farmCowType = farmItemResData.farmCowType;
    farmItemData.farmCowCount = farmItemResData.farmCowCount;
    farmItemData.farmYearConsumption = farmItemResData.farmYearConsumption;
    farmItemData.farmInven = farmItemResData.farmInven;
    farmList.push(farmItemData);
  });
  return farmList;
}

export interface IConvertToFarmRegistrationReqDataOptions {
  farmId?: string;
  /** 검색된 농장 주소 및 위경도 */
  farmLocation: { address: string; location: ILocation | null };
  /** 농장이름 */
  farmName: string;
  /** 소 종류별 두수 */
  cntCow: Record<CowTypeCode, string>;
  /** 작물별 개수값들 (연간소요, 월별급이, 현재재고, 구매게시 거래량) */
  cropList: Record<string, ICropListItem>;

  comment: string;
}

/** 농장등록/상세/수정화면(FarmRegistration.tsx) 화면에서 사용하는 각종 값들을 기반으로 농장등록 request data 생성 */
export const farmRegistrationPageDataToFramRegistrationReqData = (options: IConvertToFarmRegistrationReqDataOptions): FarmRegistrationReqData => {
  const { farmId, farmLocation, farmName, cntCow, cropList } = options;
  const cntTotalCow =
    Number(cntCow[CowTypeCode.BEEF_COW] || 0) +
    Number(cntCow[CowTypeCode.KOR_COW] || 0) +
    Number(cntCow[CowTypeCode.MILK_COW] || 0);

  // 가장 많은 소의 종류와 두수 계산
  const cntCowEntries = Object.entries(cntCow);
  let maxIdx = 0;
  let maxEntry = cntCowEntries[0];
  for (let i = 1; i < cntCowEntries.length; i++) {
    const current = cntCowEntries[i];

    if (Number(current[1] || 0) > Number(maxEntry[1] || 0)) {
      maxIdx = i;
      maxEntry = current;
    }
  }
  const maxCowType = cntCowEntries[maxIdx][0] as CowTypeCode;
  const maxCowCount = Number(cntCowEntries[maxIdx][1] || 0);

  const crops = Object.values(cropList);
  const {
    totalYearConsumption,
    totalInven,
    list: parsedCropList,
  } = crops.reduce(
    (acc, cur) => {
      const year = Number(cur.cntPredict || 0); // 연간소비
      const current = Number(cur.cntCurrent || 0); // 현재 재고
      const listItem: ICropListReqItem = {
        cropKind:
          cur.name === "볏짚"
            ? CropTypeCode.RICE_STRAW
            : cur.name === "IRG"
            ? CropTypeCode.IRG
            : CropTypeCode.ETC,
        etcName: cur.isCustom ? cur.name : undefined,
        yearConsumption: Number(cur.cntPredict || 0),
        cropRecord: Number(cur.cntPerMonth || 0),
        inven: Number(cur.cntCurrent || 0),
        buyCount: Number(cur.cntTrade || 0),
      };
      return {
        totalYearConsumption: acc.totalYearConsumption + year,
        totalInven: acc.totalInven + current,
        list: [...acc.list, listItem],
      };
    },
    { totalYearConsumption: 0, totalInven: 0, list: [] as ICropListReqItem[] }
  );

  const [address1, address2, address3] = farmLocation.address.split(" ");

  const reqData = new FarmRegistrationReqData();
  reqData.farmId = farmId;
  reqData.farmLatitude = farmLocation.location?.latitude ?? 0;
  reqData.farmLongitude = farmLocation.location?.longitude ?? 0;
  reqData.farmAddress = farmLocation.address;
  reqData.farmAddress1 = address1;
  reqData.farmAddress2 = address2;
  reqData.farmAddress3 = address3;
  reqData.farmCowType = maxCowType;
  reqData.farmCowCount = maxCowCount;
  reqData.farmName = farmName;
  reqData.farmYearConsumption = totalYearConsumption;
  reqData.farmInven = totalInven;
  reqData.cowList = Object.entries(cntCow).map((entry) => ({
    cowType: entry[0],
    cowCount: Number(entry[1] || 0),
  })) as ICowListReqItem[];
  reqData.cowScale =
    cntTotalCow < 100
      ? CowScaleCode.S
      : cntTotalCow < 300
      ? CowScaleCode.M
      : CowScaleCode.L;
  reqData.cropList = parsedCropList;

  return reqData;
};

/** 농장상세 응답데이터를 기반으로 농장등록/상세/수정화면(FarmRegistration.tsx) 화면에서 사용하는 각종 값들을 생성 */
export const farmDetailResDataToFarmRegistrationPageData = (farmDetailResData: FarmDetailResData): IConvertToFarmRegistrationReqDataOptions & { buyStatus: "Y" | "N" } => {
  /**
   * 농장의 구매게시 상태 뿐만 아니라, 구매게시 시 입력된 거래량도 함께 FarmDetailResData에 포함되어야 할 듯함.
   */

  /**
   * [FarmDetailResData.cowList 배열 --> Record<소 종류, 두수> 객체]
   * 두수는 화면에서 input의 value로 다루기 때문에 API호출부에서만 number로 변환시키고, 나머지는 전부 string타입으로 다룸.
   */
  const parsedCowList = farmDetailResData.cowList.reduce<
    Record<CowTypeCode, string>
  >(
    (acc, cur) => {
      acc[cur.cowType] = cur.cowCount.toString();
      return acc;
    },
    {
      [CowTypeCode.BEEF_COW]: "0",
      [CowTypeCode.KOR_COW]: "0",
      [CowTypeCode.MILK_COW]: "0",
    }
  );

  /**
   * [FarmDetailResData.cropList 배열 --> Record<작물이름, 작물정보> 객체]
   * FarmRegistration.tsx 화면에서는 Object.entries()를 사용해서 객체를 배열로 만든 후 화면에 뿌리기 때문에, 기본값으로 볏짚과 IRG를 객체에 넣어두고 파싱 시작. (안그러면 화면에서 볏짚 - IRG - 나머지 순으로 출력되지 않을지도 모름).
   */
  const defaultCropList: Record<string, ICropListItem> = {
    볏짚: {
      name: "볏짚",
      cntCurrent: "",
      cntPerMonth: "",
      cntPredict: "",
      cntTrade: "",
      isCustom: false,
    },
    IRG: {
      name: "IRG",
      cntCurrent: "",
      cntPerMonth: "",
      cntPredict: "",
      cntTrade: "",
      isCustom: false,
    },
  };

  const parsedCropList = farmDetailResData.cropList.reduce((acc, cur) => {
    const isCustomCrop = cur.cropKind === CropTypeCode.ETC;
    const cropName = isCustomCrop ? cur.etcName : cur.cropKind;
    if (!cropName) return acc;

    let detailCntTrade: string;

    if (farmDetailResData.buyStatus === "Y") {
      detailCntTrade = cur.buyCount.toString() + ""; // 예시: cur.tradeAmount는 구매게시할 때 설정한 값
    } else {
      // 구매게시 전인 경우 (cur.yearConsumption - cur.inven) 값을 계산하여 할당
      detailCntTrade = (cur.yearConsumption - cur.inven).toString() + "";
    }

    acc[cropName] = {
      name: cropName,
      cntCurrent: cur.inven + "",
      cntPerMonth: cur.cropRecord + "",
      cntPredict: cur.yearConsumption + "",
      cntTrade: detailCntTrade,
      isCustom: isCustomCrop,
    };

    return acc;
  }, defaultCropList);

  return {
    comment: farmDetailResData.comment,
    buyStatus: farmDetailResData.buyStatus as "Y" | "N",
    cntCow: parsedCowList,
    cropList: parsedCropList,
    farmName: farmDetailResData.farmName,
    farmLocation: {
      address: farmDetailResData.farmAddress,
      location: {
        latitude: farmDetailResData.farmLatitude,
        longitude: farmDetailResData.farmLongitude,
      },
    },
  };
};

export interface IConvertToFarmBuyStatusReqDataOptions {
  farmId: string;
  buyStatus: "Y" | "N";
  cropList: Record<string, ICropListItem>;
  comment: string;
  buyTitle: string;
}

export const farmBuyStatusPageDataToFarmBuyStatusReqData = (options: IConvertToFarmBuyStatusReqDataOptions): FarmBuyStatusReqData => {
  const { farmId, buyStatus, cropList, comment, buyTitle } = options;

  const crops = Object.values(cropList);
  const {
    totalYearConsumption,
    totalInven,
    list: parsedCropList,
  } = crops.reduce(
    (acc, cur) => {
      const year = Number(cur.cntPredict || 0); // 연간소비
      const current = Number(cur.cntCurrent || 0); // 현재 재고
      const listItem: ICropListReqItem = {
        cropKind:
          cur.name === "볏짚"
            ? CropTypeCode.RICE_STRAW
            : cur.name === "IRG"
            ? CropTypeCode.IRG
            : CropTypeCode.ETC,
        etcName: cur.isCustom ? cur.name : undefined,
        yearConsumption: Number(cur.cntPredict || 0),
        cropRecord: Number(cur.cntPerMonth || 0),
        inven: Number(cur.cntCurrent || 0),
        buyCount: Number(cur.cntTrade || 0),
      };
      return {
        totalYearConsumption: acc.totalYearConsumption + year,
        totalInven: acc.totalInven + current,
        list: [...acc.list, listItem],
      };
    },
    { totalYearConsumption: 0, totalInven: 0, list: [] as ICropListReqItem[] }
  );

  const reqData = new FarmBuyStatusReqData();
  reqData.farmId = farmId;
  reqData.buyStatus = buyStatus;
  reqData.cropList = parsedCropList;
  reqData.comment = comment;
  reqData.buyTitle = buyTitle;

  return reqData;
};
