import BaseReqData from "../BaseReqData";

export default class WorkRecordLogReqData extends BaseReqData {
  public workId!: string;
  public landAddress!: string;
  public workerName!: string;
  public equipmentName!: string;
  public equipmentType!: string;
  public path!: string;
  public equipmentStatus!: string;
  public equipmentOrder!: number;
  public colorOrder!: number;
  public landProgress!: string;
}
