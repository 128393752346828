import { useEffect, useState } from 'react';
import { CategoryTitle, Container, SubtitleBox,  TitleBox,  WeatherBox, WorkContainer, WorkInfo } from './ProductRecordDailyList.styles';
import useHeaderOptions from "hooks/useHeaderOptions";
import { WeatherCode, stringToWeatherCode } from "code/WeatherCode";
import { ReactComponent as SunnyIcon } from "assets/images/weather/sunny.svg";
import { ReactComponent as CloudyIcon } from "assets/images/weather/cloudy.svg";
import { ReactComponent as RainIcon } from "assets/images/weather/rain.svg";
import { useLocation } from 'react-router-dom';
import ProductRecordDailyListData from './ProductRecordDailyListData';
import { WeatherDegreeBlock } from 'styles/work.styles';
import ProductRecordDailyItemData, { LandItem } from './ProductRecordDailyItemData';
import WorkRecordGroupDateItemData from 'ui/components/Work/WorkRecordGroupDateList/WorkRecordGroupDateItemData';

let productRecordDailyListData = new ProductRecordDailyListData();
const ProductRecordDailyList = () => {

  useHeaderOptions({ title: "날짜별 작업이력", hasBack: true });
  const location = useLocation();
  const [viewModel, setViewModel] = useState<Array<ProductRecordDailyItemData>>([]);
  let recordList : Array<ProductRecordDailyItemData> = new Array<ProductRecordDailyItemData>();

  useEffect(() => {
    document.title = "카우잇츠 - 생산자 상품 날짜별 이력";
  }, []);

  useEffect(() => {
    if (!location.state.workRecordForProductList) {return;}
    let propsData : any;

    propsData = location.state.workRecordForProductList;
    if (propsData) {
      let prevRecord = new ProductRecordDailyItemData();
      prevRecord.workDate = propsData[0].workDate.slice(0, 10);
      prevRecord.amHumidity = propsData[0].amHumidity;
      prevRecord.amWeather = propsData[0].amWeather;
      prevRecord.pmWeather = propsData[0].pmWeather;
      prevRecord.pmHumidity = propsData[0].pmHumidity;
      prevRecord.lowTemp = propsData[0].lowTemp;
      prevRecord.highTemp = propsData[0].highTemp;
      let landItem = new LandItem();
      landItem.landAddress = propsData[0].landAddress;
      landItem.recordOutput = propsData[0].recordOut;
      prevRecord.landList.push(landItem);
      prevRecord.equipmentNames.push(propsData[0].equipmentName);
      recordList.push(prevRecord);

      for (let i = 1; i < propsData.length; i++) {
        if (prevRecord.workDate === propsData[i].workDate.slice(0, 10)) {
          let newItem = new LandItem();         
          newItem.landAddress = propsData[i].landAddress;
          newItem.recordOutput = propsData[i].recordOut;
          prevRecord.landList.push(newItem);                  
          prevRecord.equipmentNames.push(propsData[i].equipmentName);
          if(propsData[i].amHumidity !== null){
            prevRecord.amHumidity = propsData[i].amHumidity;
            prevRecord.amWeather = propsData[i].amWeather;
            prevRecord.pmWeather = propsData[i].pmWeather;
            prevRecord.pmHumidity = propsData[i].pmHumidity;
            prevRecord.lowTemp = propsData[i].lowTemp;
            prevRecord.highTemp = propsData[i].highTemp;
          }
        }
        else {
          let newRecord = new ProductRecordDailyItemData();
          newRecord.workDate = propsData[i].workDate.slice(0, 10);
          newRecord.amHumidity = propsData[i].amHumidity;
          newRecord.amWeather = propsData[i].amWeather;
          newRecord.pmWeather = propsData[i].pmWeather;
          newRecord.pmHumidity = propsData[i].pmHumidity;
          newRecord.lowTemp = propsData[i].lowTemp;
          newRecord.highTemp = propsData[i].highTemp;
          let landItem = new LandItem();
          landItem.landAddress = propsData[i].landAddress;
          landItem.recordOutput = propsData[i].recordOut;
          newRecord.landList.push(landItem);
          newRecord.equipmentNames.push(propsData[i].equipmentName);
          prevRecord = newRecord;
          recordList.push(prevRecord);
        }
      }
    }
    recordList.forEach((record) => {
      record.landList = mergeObjects(record.landList);
    });
    recordList.forEach((record) => {
      record.equipmentNames = Array.from(new Set(record.equipmentNames)).filter((item) => item !== '생산자');
    });
    setViewModel(recordList);
    // init();
  }, [location.state]);

  function mergeObjects(landList: LandItem[]): LandItem[] {
    const resultMap: { [key: string]: number } = {};

    // 중복된 a 값을 찾아서 b 값을 더함
    for (const obj of landList) {
        if (resultMap[obj.landAddress]) {
            resultMap[obj.landAddress] += obj.recordOutput;
        } else {
            resultMap[obj.landAddress] = obj.recordOutput;
        }
    }

    // 중복되지 않는 a 값만 있는 배열 생성
    const resultArr: LandItem[] = [];
    for (const key in resultMap) {
        resultArr.push({ landAddress: key, recordOutput: resultMap[key] });
    }

    return resultArr;
}

  const RenderWeatherIcon = ({ weatherCode }: { weatherCode: WeatherCode }) => {
    switch (weatherCode) {
      case WeatherCode.SUNNY:
        return <SunnyIcon />;
      case WeatherCode.CLOUDY:
        return <CloudyIcon />;
      case WeatherCode.RAINY: 
        return <RainIcon />;
      default:
        return null;
    }
  };

  return (
    <>
    {viewModel && viewModel.length > 0 ? (viewModel.map((workRecord, i) => (
      <Container key={i}>
        <WorkContainer>
          <WorkInfo>
            <TitleBox>
              <CategoryTitle>작업 기간: </CategoryTitle>
              {workRecord.workDate}
              </TitleBox>
            <SubtitleBox className='equipment-box'>
              <CategoryTitle>장비: </CategoryTitle>
              <div>
                {workRecord.equipmentNames.map((equipment, i) => (
                  <p key={i}>{equipment}</p>
                ))}
               {/* 데이터 불러올 때 장비타입(장비명) 으로 들어와야되고 각 기기 사이에 , 가 있으면 엔터 처리돼서 리스트에 보이게됩니다 */}
              </div>
            </SubtitleBox>
            <SubtitleBox className='address-box'>
              <CategoryTitle>작업 필지 주소:</CategoryTitle>
              <div>
                {workRecord.landList.map((address, i) => (
                  <p key={i}>{address.landAddress}
                    <span>({address.recordOutput}개)</span>
                  </p>
                ))}
              </div>
            </SubtitleBox>           
            <WeatherBox>
              <CategoryTitle>날씨: </CategoryTitle>
              <div className="weather">
                  <RenderWeatherIcon
                    weatherCode={stringToWeatherCode[workRecord.amWeather]}
                  />
                  <span>{workRecord.amHumidity}%</span>
              </div>
              <div className="weather">
                <RenderWeatherIcon
                  weatherCode={stringToWeatherCode[workRecord.pmWeather]}
                />
                <span>{workRecord.pmHumidity}%</span>
              </div>
              <WeatherDegreeBlock>
                <span className="highest_temperature">{workRecord.highTemp}°</span>
                <span> / </span>
                <span className="lowest_temperature">{workRecord.lowTemp}°</span>
              </WeatherDegreeBlock>            
            </WeatherBox>           
          </WorkInfo>
        </WorkContainer>        
      </Container>
    ))) : null}
    </>
  );
};

export default ProductRecordDailyList;