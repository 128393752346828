import EquipmentItemData from "ui/pages/EquipmentManagement/EquipmentItemData";
import WorkerItemData from "ui/pages/WorkDetail/WorkerList/WorkerItemData";

export default class WorkEquipmentData{
    private _equipmentManagementList: Array<EquipmentItemData> = new Array<EquipmentItemData>();
    private _workerList: Array<WorkerItemData> = new Array<WorkerItemData>();
    public get equipmentManagementList(): Array<EquipmentItemData> {
        return this._equipmentManagementList;
    }
    public set equipmentManagementList(value: Array<EquipmentItemData>) {
        this._equipmentManagementList = value;
    }    
    public get workerList(): Array<WorkerItemData> {
        return this._workerList;
    }
    public set workerList(value: Array<WorkerItemData>) {
        this._workerList = value;
    }
}