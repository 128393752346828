import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactComponent as BackIcon } from 'assets/images/back.svg';
import { ReactComponent as HomeIcon } from 'assets/images/bottomTab/home.svg';
import useGoBack from 'hooks/useGoBack';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

export interface HeaderProps {
  title: string;
  hasBack? : boolean;
  headerRight?: ReactNode;
  transparent?: boolean;
}

const BuyerHeader = ({ 
  title,
  hasBack = true,
  headerRight,
  transparent = false,
}: HeaderProps) => {
  const navigate = useNavigate();  
  const goBack = useGoBack();
  const handleOnBackBtnClick = () => {
    window.dataLayer.push({
      event: 'click_back',
    })
    goBack();
  };
  function goMain(){
    navigate('/buyerMain');
    window.dataLayer.push({
      event: "click_home",
    });   
  };

  return (
    <HeaderBlock transparent={transparent}>
      {hasBack ? <BackIcon onClick={handleOnBackBtnClick} /> : <HomeIcon onClick={goMain} /> }
      <h1>{title}</h1>
      <HeaderRightBlock>{headerRight}</HeaderRightBlock>
    </HeaderBlock>
  );
};

const HeaderBlock = styled.header<Pick<HeaderProps, 'transparent'>>`
  position: unset;
  top: 0;
  
  display: flex;
  align-items: center;

  width: 100%;
  max-width: 1080px;
  height: 50px;
  
  margin: 0 auto;
  padding: 0 16px;

  border-bottom: 1px solid var(--gray-200);
  background: var(--white, #fff);
  z-index: 100;

  ${({ transparent }) =>
    transparent &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background: transparent;
      border: none;

      svg {
        path {
          fill: var(--white, #fff);
        }
      }
    `}

  h1 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    font-weight: 700;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const HeaderRightBlock = styled.div`
  position: absolute;
  right: 16px;
`;

export default BuyerHeader;
