import { SubTitleWrapper } from "styles/work.styles";
import WorkLandItemData from "../WorkRegistration/WorkLandList/WorkLandItemData";
import { LandAddButton, TeamButtonContainer, TeamIndexList, TeamItem, TeamTopContainer, WorkPlannedLandContainer } from "./WorkTeamPlan.styles";
import { SubTitle } from "styles/common.styles";
import { ArrowDownWrapper, FoldButton, LandNumberBlock } from "../WorkRegistration/WorkLandList/WorkLandList.styles";
import { ReactComponent as CloseCircleIcon } from 'assets/images/close_circle.svg';
import { getColorWithTeamCode } from "converter/KakaoConverter";
import { useState } from "react";
import { ReactComponent as ChevronDownIcon } from 'assets/images/chevron_down.svg';

interface WorkPlanLandProps {
    onLandDeleteBtnClick?:(landAddress:string)=>void;
    plannedLandList:Array<WorkLandItemData>
    workTeamList: string[];
    setPlanTeam: (team: string | undefined) => void;
    onHandlePin?: (latitude: number, longitude: number) => void;
    workId: string;
}

const WorkTeamPlanLandList = (props: WorkPlanLandProps) => {
    const [editTeamMode, setEditTeamMode] = useState(false);
    const [currentEditTeam, setCurrentEditTeam] = useState<string | undefined>(undefined);
    const [isActive, setIsActive] = useState(false);

    function handleTeam(team : string) {
        if(editTeamMode){
            setEditTeamMode(false);
            setCurrentEditTeam(undefined);
            props.setPlanTeam(undefined);
            window.dataLayer.push({
                event: "register_team_location",
                work_id: `${props.workId}`,
            });  
        } else {
            setEditTeamMode(true);
            setCurrentEditTeam(team);
            props.setPlanTeam(team);
            window.dataLayer.push({
                event: "click_add_team_location",
                work_id: `${props.workId}`,
            });  
        }
        if(team === "N"){
            window.dataLayer.push({
                event: "click_unallocated_team",
                work_id: `${props.workId}`,
            });             
        }       
    };

    function handleOnLandListClick(latitude: number, longitude: number) {
        props.onHandlePin && props.onHandlePin(latitude, longitude);
    }

    function TeamEditButton({ team }: { team: string }) {
        return (
        editTeamMode === false
        ? <LandAddButton onClick={() => handleTeam(team)}>필지 관리</LandAddButton>
        : editTeamMode === true && currentEditTeam === team
            ? <LandAddButton onClick={() => handleTeam(team)}>적용</LandAddButton>
            : null
        );
    }

    function foldButtonClickEventHandler() {
        setIsActive(!isActive);
    }

    function getLandSizeString(landSize: number): string{
        if (landSize === undefined) {
            return "(㎡ / 평)";
        }
        return `(${landSize}㎡ / ${Math.floor(landSize / 3.3058)}평)`;
    }

    return (
        <WorkPlannedLandContainer>
        <SubTitleWrapper>
            <SubTitle>작업 필지</SubTitle>
            <FoldButton onClick={foldButtonClickEventHandler}>
                <span>
                    {isActive ? '펼치기' : '접기'}  
                </span>
                <ArrowDownWrapper isActive={isActive}>
                    <ChevronDownIcon />
                </ArrowDownWrapper>
            </FoldButton>
        </SubTitleWrapper> 
        {isActive ?
            null
            :      
            <TeamButtonContainer>
                <TeamItem key={"N"}>
                    <TeamTopContainer>
                    <TeamIndexList style={{backgroundColor : getColorWithTeamCode("N")}}>
                        <div>{"팀 미지정"}</div>
                        <TeamEditButton team={"N"} />
                    </TeamIndexList>
                    </TeamTopContainer>
                    { props.plannedLandList?.filter((land) => land.workTeam === "N").map((item, i) => (
                        <LandNumberBlock key={i}  onClick={() => handleOnLandListClick(item.landLatitude, item.landLongitude)}>
                        <p>{item.landFullAddress} {getLandSizeString(item.landSize)} </p>
                        <span>계획생산량 {Math.floor(item.landSize * 0.0025)}개</span>
                        {editTeamMode &&
                        <CloseCircleIcon onClick={(e)=>{props.onLandDeleteBtnClick&&props.onLandDeleteBtnClick(item.landFullAddress!)
                        e.stopPropagation();
                        }}/>}
                        </LandNumberBlock>
                    )) }
                </TeamItem>
                {props.workTeamList && props.workTeamList.length > 0 ? ( 
                    props.workTeamList.map((team, index) => (
                    team !== "N" &&
                    <TeamItem key={index}>
                        <TeamTopContainer>
                        <TeamIndexList style={{backgroundColor : getColorWithTeamCode(team)}}>
                            <div>{team}팀</div>
                            <TeamEditButton team={team} />
                        </TeamIndexList>
                        </TeamTopContainer>
                        { props.plannedLandList?.filter((land) => land.workTeam === team).map((item, i) => (
                        <LandNumberBlock key={i}  onClick={() => handleOnLandListClick(item.landLatitude, item.landLongitude)}>
                        <p>{item.landFullAddress} ({item.landSize} ㎡) </p>
                        <span>계획생산량 {Math.floor(item.landSize * 0.0025)}개</span>
                        <CloseCircleIcon onClick={(e)=>{props.onLandDeleteBtnClick&&props.onLandDeleteBtnClick(item.landFullAddress!)
                        e.stopPropagation();    
                        }}/>
                        </LandNumberBlock>
                    )) }
                    </TeamItem>
                )))           
                    : null
                }
            </TeamButtonContainer>
        }
    </WorkPlannedLandContainer>
    );
};

export default WorkTeamPlanLandList;