export default class WorkRecordGroupDateItemData {
  public workDate!: string;
  public amHumidity!: number;
  public amWeather!: string;
  public pmWeather!: string;
  public pmHumidity!: number;
  public lowTemp!: number;
  public highTemp!: number;
  public dateOutput!: number;
  public landAddress! :string;
  public equipmentName!: string;
}
