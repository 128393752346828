export const dateToFormmattedStr = (options?: {
  date?: Date;
  seperator?: string;
}) => {
  const date = options?.date ?? new Date();
  const seperator = options?.seperator ?? "";

  const y = date.getFullYear();
  const m = (date.getMonth() + 1 + "").padStart(2, "0");
  const d = (date.getDate() + "").padStart(2, "0");
  const today = `${y}${seperator}${m}${seperator}${d}`;

  return today;
};
