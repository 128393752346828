import { css } from '@emotion/react';

export const customOverlayStyle = css`
  .map--custom-overlay {
    padding: 4px 8px;
    display: flex;
    gap: 10px;
    background: var(--white, #fff);
    width: fit-content;

    font-size: 14px;

    .map--custom-overlay-block1 {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 6px;
    }

    .map--custom-overlay-block2 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 4px 0;
    }
  }
`;
