import React from "react";

class BaseService extends React.Component {
  private static readonly PROTOCAL_HTTPS = 'https://';
  private static readonly BASE_URL_DEV =  "coweats.monster";
  private static readonly BASE_URL_PRD =  "coweats.com";
  private static get HOST_URL(): string {
    // return BaseService.HOST_URL_LOCAL;
    switch (process.env.REACT_APP_BUILD_ENV) {
          
      case 'dev': {
        return BaseService.BASE_URL_DEV;
      }
      case 'pro': {
        return BaseService.BASE_URL_PRD;
      }
      default: {
          return BaseService.BASE_URL_DEV;
      }
    }
  }
  public static readonly API_URL = `${BaseService.PROTOCAL_HTTPS}api.` + BaseService.HOST_URL;
  public static readonly IMG_URL = `${BaseService.PROTOCAL_HTTPS}` + BaseService.HOST_URL+'/images';
  public static readonly CHAT_URL = `${BaseService.PROTOCAL_HTTPS}chat.` + BaseService.HOST_URL;
  public static readonly WEB_URL = `${BaseService.PROTOCAL_HTTPS}` + BaseService.HOST_URL;
}

export default BaseService; //다른 JS파일에서 불러올 수 있도록 내보내주기