import styled from '@emotion/styled';

export const AuthPagesHeaderBlock = styled.div`
  height: 50px;
  border-bottom: '1px solid var(--gray-200, #ebebeb)';
  position: sticky;
  top: 0;
  z-index: 100;
  background: var(--white, #fff);
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 12px 16px;

  h2 {
    font-size: 18px;
    font-weight: 700;
  }  
`;

export const AuthPagesBodyBlock = styled.div`

  display: flex;
  flex-direction: column;
  gap: 16px;

  b {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p {
    color: var(--gray-500, #666);
    font-size: 14px;
    font-style: normal;
    line-height: normal;
  }
`;