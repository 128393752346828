export enum CropTypeCode{
    ALL = "전체",
    RICE_STRAW = "볏짚",
    IRG = "IRG",
    ETC = "기타",
}
export const stringToCropTypeCode: { [key: string]: CropTypeCode } = {
    "볏짚": CropTypeCode.RICE_STRAW,
    "IRG": CropTypeCode.IRG,
    "기타": CropTypeCode.ETC,
    "전체": CropTypeCode.ALL,
  };