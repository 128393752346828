export enum CowScaleCode {
  S = "소형",
  M = "중형",
  L = "대형",
}
export const stringToCowTypeCode: { [key: string]: CowScaleCode } = {
  소형: CowScaleCode.S,
  중형: CowScaleCode.M,
  대형: CowScaleCode.L,
};
