import styled from '@emotion/styled';
import { AppPageContainer } from 'ui/components/AppLayout/AppLayout.styles';

export const WorkRegistarionContainer = styled.div`
  margin-bottom: 24px;
`;

export const EquipmentRegistionContainer = styled.div`
  padding-top: 24px;
  margin-bottom: 24px;
  border-top: 1px solid var(--gray-200);
`

export const ImageUploadBlock = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  background: var(--gray-200, #ebebeb);

  svg {
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
`;

export const WorkRegistrationPageContainer = styled(AppPageContainer)`
  padding: 0 16px;
`;

export const WorkRegistrationButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

export const WorkRegistrationButton = styled.button`
  border-radius: 8px;
  width: 100%;

  display: flex;
  height: 48px;
  padding: 13px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  font-size: 16px;
  font-weight: 500;
  line-height: normal;
`;

export const WorkRegistrationDeleteButton = styled(WorkRegistrationButton)`
  color: var(--white, #ffffff);
  background: var(--red-100, #e61919 );
`;

export const WorkRegistrationSaveButton = styled(WorkRegistrationButton)`
  color: var(--white, #ffffff);
  background: var(--green-100, #00b140);
`;

export const WorkRegistrationCancelButton = styled(WorkRegistrationButton)`
  color: var(--gray-400, #999);
  border: 1px solid var(--gray-300, #ccc);
`;

export const WorkRegistrationRemoveButton = styled(WorkRegistrationButton)`
  border: 1px solid var(--red, #f22d2d);
  color: var(--red, #f22d2d);
`;
export const WorkRegistrationImgAddButton = styled.button`
  color: var(--green-100, #00b140);
  font-size: 12px;
  font-weight: 500;
  line-height: 21px; /* 116.667% */
  background: var(--gray-200, #ebebeb);

  border-radius: 6px;
  border: 1px solid var(--green-100, #00b140);
  display: flex;
  padding: 5px 5px;
  justify-content: center;
  align-items: center;
  svg {

  }
`;

