import { useEffect, useRef } from "react";
import MapComponentData from "./MapComponentData";
import { MapLevel } from "code/Enumerator";
import PolygonData from "./PolygonData";
import WorkRegistrationSearch from "ui/pages/WorkRegistrationSearch/WorkRegistrationSearch";
import WorkRegistration from "ui/pages/WorkRegistration/WorkRegistration";
import PolyLineData from "./PolyLineData";
import MarkerData from "./MarkerData";
import { MapContainer } from "styles/common.styles";
import { IKakaoLatLngBounds } from "types/kakaoMap.types";
import currentIcon from 'assets/images/currentIcon.png';
import searchAddressPin from 'assets/images/markerIcon/searchAddressPin.svg';
import { MapBodyContainer } from "./MapComponent.styles";
import styled from "@emotion/styled";
import { map } from "lodash";

declare global {
  interface Window {
    daum: any;
    kakao: any;
    jQuery: any;
    Kakao: any;
  }
}
const { kakao } = window;
let mapComponentData: MapComponentData = new MapComponentData();

interface MapComponentProps {
  mapLevel?: MapLevel;
  myLatLng?: number[];
  markerList?: Array<MarkerData>;
  workerMarkerList?:Array<MarkerData>;
  polygonList?: Array<PolygonData>;
  polylineList?: Array<PolyLineData>;
  mapCenter?: number[];
  planTeam: string | undefined;
  pinLatLng?: number[];
  // landInfoList? : Array<LandInfo>;
  handleOnMapClick?: (latitude: number, longitude: number) => void;
  handleOnMapLongClick?: (latitude: number, longitude: number) => void;
  handleOnMarkerClick?: (markerId: string) => void;
  handleOnPolygonClick?: (polygonId: string) => void;
  handleMoveToLand: (latitude: number, longitude: number) => void;
  initMapPage?: () => void;
}
const MapComponent = (props: MapComponentProps) => {
  // mouseDown 시의 (mouseEvent.clientX)와 (mouseEvent.clientY). mouseUp 시 검사를 위해 사용.
  const refPressStartPoint = useRef({ x: 0, y: 0 });
  // mouseDown 시의 timestamp. mouseUp 시 검사를 위해 사용.
  const refPressStartTime = useRef(0);

  useEffect(() => {
    init();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(props.markerList === undefined){return;};
    mapComponentData.markerList = props.markerList || new Array<MarkerData>();
    mapComponentData.kakaoMarkerList?.forEach((item) => {
      kakao.maps.event.addListener(item, "click", function () {
        handleOnMarkerClick(item.markerId);
      });
    });
    if(mapComponentData.searchLandMarker){mapComponentData.searchLandMarker.setMap(null)};
    
  }, [props.markerList]);

  useEffect(() => {
    if(props.polygonList === undefined){return;};
    mapComponentData.polygonList = props.polygonList || new Array<PolygonData>();

    mapComponentData.kakaoPolygonList?.forEach((item) => {
      kakao.maps.event.addListener(item, "click", function () {
        kakao.maps.event.preventMap();
        props.handleOnPolygonClick &&
          props.handleOnPolygonClick(item.polygonId);
      });
    });
    if(mapComponentData.searchLandMarker){mapComponentData.searchLandMarker.setMap(null)};
    
  }, [props.polygonList, props.planTeam]);
  
  // useEffect(() => {
  //   mapComponentData.removeLandInfo();
  //   props.landInfoList?.forEach((item) => {
  //     mapComponentData.addLandInfo(item);
  //   });
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.landInfoList]);

  useEffect(() => {
    if(props.workerMarkerList === undefined){return;};
    mapComponentData.workerMarkerList = props.workerMarkerList;
  }, [props.workerMarkerList]);

  useEffect(() => {
    if (props.mapLevel === undefined) {return;};
    mapComponentData.kakaoMap.setLevel(props.mapLevel);
  }, [props.mapLevel]);

  useEffect(() => {
    if (props.myLatLng === undefined || props.myLatLng[0] === undefined || props.myLatLng[1] === undefined) {return;};
    mapComponentData.myLatLng = props.myLatLng;
  }, [props.myLatLng]);

  useEffect(() => {
    if (props.mapCenter === undefined || props.mapCenter[0] === undefined || props.mapCenter[1] === undefined) {return;};
    mapComponentData.mapCenter = props.mapCenter;
  }, [props.mapCenter]);

  useEffect(() => {
    mapComponentData.polylineList = props.polylineList || new Array<PolyLineData>();
  }, [props.polylineList]);

  // 지영
  useEffect(()=> {
    if(props.pinLatLng){
      let lat = props.pinLatLng[0];
      let lng = props.pinLatLng[1];
      handleMoveToMap(lat,lng); 
    }
  },[props.pinLatLng]);


  function init() {
    const container = document.getElementById("map"); // 지도를 표시할 div
    mapComponentData = new MapComponentData();
    
    const option = {
      center: new kakao.maps.LatLng(mapComponentData.myLatLng[0], mapComponentData.myLatLng[1]),
      level: mapComponentData.mapLevel,
    };

    mapComponentData.kakaoMap = new kakao.maps.Map(container, option); // 지도를 생성합니다

    kakao.maps.event.addListener(
      mapComponentData.kakaoMap,
      "click",
      function (mouseEvent: any) {
        const latlng = mouseEvent.latLng;
        const lat = latlng.getLat();
        const lng = latlng.getLng();
        handleOnMapClick(lat, lng);
      }
    );

    // let pressTimer : any = null;

    // kakao.maps.event.addListener(
    //   mapComponentData.kakaoMap,
    //   "touchstart",
    //   function (mouseEvent: any) {
    //     pressTimer = window.setTimeout(function () { 
    //     }, 1000);
    //   });

    //   kakao.maps.event.addListener(
    //     mapComponentData.kakaoMap,
    //     "touchend",
    //     function (mouseEvent: any) {
    //       if(pressTimer != null) {
    //         clearTimeout(pressTimer);
    //         pressTimer = null;
    //       }
    //     });
    

    // long click event 구현 -->
    const $map = document.querySelector("#map") as Element;
    const mapWidth = $map.clientWidth;
    const mapHeight = $map.clientHeight;
    $map.addEventListener(
      "touchstart",
      (e) => {
        // @ts-ignore
        const { clientX, clientY } = e.changedTouches[0];
        refPressStartPoint.current = {x: clientX as number, y: clientY as number};
        refPressStartTime.current = Date.now();
      },
      false
    );
    $map.addEventListener(
      "touchend",
      (e) => {
        const diffSecond = (Date.now() - refPressStartTime.current) / 1000;
        if (diffSecond < 0.5) return; // 0.5초 이상 누르고 있었다면 long click으로 판단 (1초로 해봤는데 체감상 꽤 긴 시간이었음)

        // mouse down 시의 화면좌표
        const { x: beforeX, y: beforeY } = refPressStartPoint.current;

        // mouse up 시의 화면좌표
        // @ts-ignore
        const afterX: number = e.changedTouches[0].clientX;
        // @ts-ignore
        const afterY: number = e.changedTouches[0].clientY;

        // 롱클릭이 아니라 드래그였는지 검사
        if (Math.abs(afterX - beforeX) > 10) return;
        if (Math.abs(afterY - beforeY) > 10) return;

        const bounds: IKakaoLatLngBounds =
          mapComponentData.kakaoMap.getBounds();
        const boundSW = bounds.getSouthWest();
        const boundNE = bounds.getNorthEast();

        const sw = { lat: boundSW.getLat(), lng: boundSW.getLng() };
        const ne = { lat: boundNE.getLat(), lng: boundNE.getLng() };

        const boundLatLength = ne.lat - sw.lat; // 화면상 지도에 보이는 만큼의 위도 길이 (북동쪽 모서리의 위도 - 남서쪽 모서리의 위도)
        const boundLngLength = ne.lng - sw.lng; // 화면상 지도에 보이는 만큼의 경도 길이 (북동쪽 모서리의 경도 - 남서쪽 모서리의 경도)

        /**
         * [수학시간에 배운 비율 공식] a:b = c:d  --> a/b = c/d --> c = d * a/b
         * (mouse up 시의 x좌표 : map 전체 width) = (mouse up 시 맵 가장 좌측부터 잰 경도 길이 : 화면상 총 경도 길이)
         */
        const deltaX = boundLngLength * (afterX / mapWidth);
        const deltaY = boundLatLength * ((afterY - 50) / mapHeight);

        const pointLat = ne.lat - deltaY; // mouse up한 위도 = 맵 가장 위쪽의 위도(ne.lat) - mouse up 시 맵 가장 위에서부터 잰 위도 길이(deltaY)
        const pointLng = sw.lng + deltaX; // mouse up한 경도 = 맵 가장 좌측의 경도(sw.lng) + mouse up 시 맵 가장 좌측부터 잰 경도 길이(deltaX)

        /**
         * NOTE: 화면좌표계와 정규좌표계를 혼용한 계산이므로 이해가 어려우실 수 있습니다. long click 이벤트 디버깅 또는 보수 중 도저히 이해가 안되시는 부분이 있다면 말씀해주세요. 펜과 종이로 설명드리겠습니다. (김현우)
         */

        props.handleOnMapLongClick && props.handleOnMapLongClick(pointLat, pointLng);
      },
      false
    );

    // <-- long click event 구현

    navigator.geolocation.getCurrentPosition(function (position) {
      mapComponentData.myLatLng = [position.coords.latitude, position.coords.longitude];
      mapComponentData.mapCenter = [position.coords.latitude, position.coords.longitude];
    });
  }
  function handleOnMapClick(lat: number, lng: number) {
    props.handleOnMapClick && props.handleOnMapClick(lat, lng);
  }
  function handleOnMarkerClick(markerId: string) {
    props.handleOnMarkerClick && props.handleOnMarkerClick(markerId);
  } 

  function handleMoveToMap(lat: number, lng: number) {
    mapComponentData.moveToMap(lat, lng);
    if(mapComponentData.searchLandMarker){mapComponentData.searchLandMarker.setMap(null)};
    
    mapComponentData.searchLandMarker = new kakao.maps.Marker({
      position: new kakao.maps.LatLng(lat, lng),
      clickable: false,
      image : new kakao.maps.MarkerImage(
        searchAddressPin,
        new kakao.maps.Size(20, 30),
      ),
      zIndex: 4
    });
    mapComponentData.searchLandMarker.setMap(mapComponentData.kakaoMap);
  }

  

  function handleMyLocationClick() {
    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      mapComponentData.mapCenter = [lat, lng];
    });
    window.dataLayer.push({
      event: "click_my_location",
    });   
  }

  return (   
    <MapBodyContainer>
      <CurrentIconContainer>
        <img src={currentIcon} 
          alt="" 
          onClick={handleMyLocationClick} 
          style={{width: "30px", height: "30px"}}
        />
      </CurrentIconContainer>
      <WorkRegistrationSearch searchAddress={handleMoveToMap} />
      <MapContainer id="map" />
    </MapBodyContainer>
  );
};

const CurrentIconContainer = styled.div`
  width: 36px;
  height: 36px;
  position: absolute;
  top: 3px;
  left: 3px;
  padding: 5px;
  overflow: hidden;
  z-index: 3;
  border-radius: 20%;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
`;

export default MapComponent;
