import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class WorkRecordLogRegistrationRequest extends BaseRequest{
    private readonly URL_WORKRECORDLOG_BASE = `${BaseService.API_URL}/workRecordLog`;
    private readonly URL_WORKRECORDLOG_REGISTRATION = `${this.URL_WORKRECORDLOG_BASE}/workRecordLogRegist`;

    getURL(): string {
        return this.URL_WORKRECORDLOG_REGISTRATION;
    }
}