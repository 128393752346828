export default class FarmItemData {
    private _farmId!: string;
    private _farmName!: string;
    private _farmAddress!: string;
    private _farmAddress1!: string;
    private _farmAddress2!: string;
    private _farmAddress3!: string;
    private _farmLatitude!: number;
    private _farmLongitude!: number;    
    private _farmCowType!: string;
    private _farmCowCount!: number;
    private _farmYearConsumption!: number;
    private _farmInven!: number;
  
    public get farmId(): string {
      return this._farmId;
    }
    public set farmId(value: string) {
      this._farmId = value;
    }
    public get farmName(): string {
      return this._farmName;
    }
    public set farmName(value: string) {
      this._farmName = value;
    }
    public get farmAddress(): string {
      return this._farmAddress;
    }
    public set farmAddress(value: string) {
      this._farmAddress = value;
    }
    public get farmAddress1(): string {
      return this._farmAddress1;
    }
    public set farmAddress1(value: string) {
      this._farmAddress1 = value;
    }
    public get farmAddress2(): string {
      return this._farmAddress2;
    }
    public set farmAddress2(value: string) {
      this._farmAddress2 = value;
    }
    public get farmAddress3(): string {
      return this._farmAddress3;
    }
    public set farmAddress3(value: string) {
      this._farmAddress3 = value;
    }
    public get farmLatitude(): number {
      return this._farmLatitude;
    }
    public set farmLatitude(value: number) {
      this._farmLatitude = value;
    }  
    public get farmLongitude(): number {
      return this._farmLongitude;
    }
    public set farmLongitude(value: number) {
      this._farmLongitude = value;
    }
    public get farmCowType(): string {
      return this._farmCowType;
    }
    public set farmCowType(value: string) {
      this._farmCowType = value;
    }
    public get farmCowCount(): number {
      return this._farmCowCount;
    }
    public set farmCowCount(value: number) {
      this._farmCowCount = value;
    }
    public get farmYearConsumption(): number {
      return this._farmYearConsumption;
    }
    public set farmYearConsumption(value: number) {
      this._farmYearConsumption = value;
    }
    public get farmInven(): number {
      return this._farmInven;
    }
    public set farmInven(value: number) {
      this._farmInven = value;
    }   
}