export default class PolygonData {
  private _polygonAddress!: string;  
  private _pointList: number[][] = [];
  private _polygonColor!: string;
  private _polygonLineColor: string = "#39DE2A";
  private _polygonTeam: string = "N";

  public get polygonAddress(): string {
    return this._polygonAddress;
  }
  public set polygonAddress(value: string) {
    this._polygonAddress = value;
  }
  public get pointList(): number[][] {
    return this._pointList;
  }
  public set pointList(value: number[][]) {
    this._pointList = value;
  }
  public get polygonColor(): string {
    return this._polygonColor;
  }
  public set polygonColor(value: string) {
    this._polygonColor = value;
  }
  public get polygonLineColor(): string {
    return this._polygonLineColor;
  }
  public set polygonLineColor(value: string) {
    this._polygonLineColor = value;
  }
  public get polygonTeam(): string {
    return this._polygonTeam;
  }
  public set polygonTeam(value: string) {
    this._polygonTeam = value;
  }
}
