import { headerOptionsState } from 'atoms/headerOptions';
import Header from 'ui/components/Header';
import { Outlet } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AppBody } from './AppLayout.styles';
import BuyerHeader from '../BuyerHeader';
import { isLoadingState } from 'atoms/loadingState';
import Loading from '../Loading';
import {isLoading} from 'types/isLoading.types';

const AppLayout = () => {
  const props = useRecoilValue(headerOptionsState);
  // const [isLoading, setIsLoading] = useRecoilState(isLoadingState);

  function getAppModeFromLocalStorage() {
    return localStorage.getItem('APP_MODE');
  }

  const appMode = getAppModeFromLocalStorage();

  const appLayoutStyle = {
    paddingBottom: appMode === 'WORKER' ? '0' : '60px', // Set the padding dynamically
  };

  return (
    <>
      {appMode === 'SELLER' ? <Header {...props} /> : <BuyerHeader {...props}/>}
      <AppBody appMode={appMode}>
        <Outlet />
      </AppBody>
    </>
  );
};

export default AppLayout;
