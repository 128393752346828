import { mapComponentMode } from "code/Enumerator";
import WorkDetailData from "ui/pages/WorkDetail/WorkDetailData";
import WorkRecordDetailGroupDateData from "ui/pages/WorkRecordDetailGroupDate/WorkRecordDetailGroupDateData";
import MapComponentData from "../MapComponent/MapComponentData";

export default class WorkerMapPageData {
  private _workId!: string;
  private _location!: string;
  private _mapComponentData: MapComponentData = new MapComponentData();
  private _workDetailData: WorkDetailData = new WorkDetailData();
  private _workRecordDetailGroupDateData: WorkRecordDetailGroupDateData = new WorkRecordDetailGroupDateData();

  public get workId(): string {
    return this._workId;
  }
  public set workId(value: string) {
    this._workId = value;
  }
  public get location(): string {
    return this._location;
  }
  public set location(value: string) {
    this._location = value;
  }
  public get mapComponentMode(): mapComponentMode {
    if (this._location?.includes("/workerWork")) {
      return mapComponentMode.WORKER_WORK_DETAIL;
    } else if (this._location?.includes("/workRecordDetailGroupDate")) {
      return mapComponentMode.WORK_RECORD_DETAIL_GROUP_DATE;
    } else if (this._location?.includes("/workerWorkRecordGroupAddressList")) {
      return mapComponentMode.WORK_RECORD_GROUP_ADDRESS_LIST;
    }
    return mapComponentMode.WORKER_WORK_DETAIL;
  }
  public get mapComponentData(): MapComponentData {
    return this._mapComponentData;
  }
  public set mapComponentData(value: MapComponentData) {
    this._mapComponentData = value;
  }
  public get workDetailData(): WorkDetailData {
    return this._workDetailData;
  }
  public set workDetailData(value: WorkDetailData) {
    this._workDetailData = value;
  }  
  public get workRecordDetailGroupDateData(): WorkRecordDetailGroupDateData {
    return this._workRecordDetailGroupDateData;
  }
  public set workRecordDetailGroupDateData(
    value: WorkRecordDetailGroupDateData
  ) {
    this._workRecordDetailGroupDateData = value;
  }  
}
