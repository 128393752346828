import { useRecoilValue } from 'recoil';
import { modalState } from 'atoms/modal';
import { useModal } from 'hooks/useModal';

const Modals = () => {
  const opendModals = useRecoilValue(modalState);
  const { closeModal } = useModal();

  return (
    <>
      {opendModals.map((modal, index) => {
        const { Component, props } = modal;
        const { onConfirm: confirm, ...restProps } = props ?? {};

        const onClose = () => closeModal(Component);

        const onConfirm = (...params: any[]) => {
          if (typeof confirm === 'function') {
            confirm(...params);
          }
          onClose();
        };

        return (
          <Component
            key={index}
            onClose={onClose}
            onConfirm={onConfirm}
            {...restProps}
          />
        );
      })}
    </>
  );
};

export default Modals;
