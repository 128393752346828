import { workStatus } from "code/Enumerator";

export default class WorkItemData {
  private _workId!: string;
  private _workStartDate!: string;
  private _workEndDate!: string;
  private _workAddress1!: string ;
  private _workAddress2!: string ;
  private _workAddress3!: string ;
  private _workLatitude!: number ;
  private _workLongitude!: number ;
  private _cropType!: string;
  private _workStatus!: workStatus;
  private _workLandSize!: number;    
  private _workOutput: number = 0;  
  private _userProfileImage?: string | undefined;
  private _userName!: string;
  private _totalLandCount: number = 0;   
  private _totalLandProgressCount: number = 0;
  private _masterWorkId!: string;

  public get workId(): string {
    return this._workId;
  }
  public set workId(value: string) {
    this._workId = value;
  }
  get workStartDate(): string {
    return this._workStartDate;
  }

  set workStartDate(value: string) {
    this._workStartDate = value;
  }

  get workEndDate(): string {
    return this._workEndDate;
  }

  set workEndDate(value: string) {
    this._workEndDate = value;
  }

  get workAddress1(): string {
    return this._workAddress1;
  }

  set workAddress1(address: string) {
    this._workAddress1 = address;
  }

  get workAddress2(): string {
    return this._workAddress2;
  }

  set workAddress2(address: string) {
    this._workAddress2 = address;
  }

  get workAddress3(): string {
    return this._workAddress3;
  }

  set workAddress3(address: string) {
    this._workAddress3 = address;
  }

  get workLatitude(): number {
    return this._workLatitude;
  }

  set workLatitude(latitude: number) {
    this._workLatitude = latitude;
  }

  get workLongitude(): number {
    return this._workLongitude;
  }
  set workLongitude(longitude: number) {
    this._workLongitude = longitude;
  }
  public get cropType(): string {
    return this._cropType;
  }
  public set cropType(value: string) {
    this._cropType = value;
  }
  public get workStatus(): workStatus {
    return this._workStatus;
  }
  public set workStatus(value: workStatus) {
    this._workStatus = value;
  }
  public get workLandSize(): number {
    return this._workLandSize;
  }
  public set workLandSize(value: number) {
      this._workLandSize = value;
  }
  public get plannedOutput(): number {
      return Math.floor(this._workLandSize * 0.0025);
  }   
  public get workOutput(): number {
    return this._workOutput;
  }
  public set workOutput(value: number) {
    this._workOutput = value;
  }
  public get userProfileImage(): string | undefined {
    return this._userProfileImage;
  }
  public set userProfileImage(value: string | undefined) {
    this._userProfileImage = value;
  }
  get userName(): string {
    return this._userName;
  }

  set userName(name: string) {
    this._userName = name;
  }
  public get totalLandCount(): number {
    return this._totalLandCount;
  }
  public set totalLandCount(value: number) {
      this._totalLandCount = value;
  }
  public get totalLandProgressCount(): number {
      return this._totalLandProgressCount;
  }
  public set totalLandProgressCount(value: number) {
      this._totalLandProgressCount = value;
  }
  public get masterWorkId(): string {
    return this._masterWorkId;
  }
  public set masterWorkId(value: string) {
    this._masterWorkId = value;
  }
}


