import styled from '@emotion/styled';
import { colors } from 'styles/colors';
import { imageWrapperStyle } from 'styles/common.styles';

export const ProductListItemBlock = styled.li`
  height: 100px;
  display: flex;
  gap: 14px;
`;

export const ProductListItemImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 8px;

  ${imageWrapperStyle};
`;

export const ProductListItemMetaBlock = styled.div`
  position: relative;
  flex: 1;

  display: flex;
  flex-direction: column;

  line-height: 21px;

  h4 {
    font-size: 16px;
  }

  span {
    color: ${colors.gray400};
    font-size: 14px;
    margin-bottom: 2px;
  }

  strong {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 15px;
  }
`;

export const ProductListItemCountContainer = styled.div`
  display: flex;
  gap: 6px;
`;

export const ProductListItemCountBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;

  span {
    color: ${colors.gray300};
    font-size: 14px;
  }
`;

export const MedalIconWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const MapBodyContainer = styled.div`
  max-width: 1080px;
  min-width: 350px;
  margin-left: auto;
  margin-right: auto;
`;