import ProductItemData from "../ProductItem/ProductItemData";
import { mapComponentMode } from "code/Enumerator";
import MapComponentData from "../MapComponent/MapComponentData";
import FarmItemData from "ui/pages/ForageManagement/ForageManagementItem/FarmItemData";

export default class BuyerMapPageData {
  private _location!: string;
  private _mapComponentData: MapComponentData = new MapComponentData();
  private _farmListData: Array<FarmItemData> = new Array<FarmItemData>();
  private _productListData: Array<ProductItemData> = new Array<ProductItemData>();

  public get location(): string {
    return this._location;
  }
  public set location(value: string) {
    this._location = value;
  }
  public get mapComponentMode(): mapComponentMode {
    if (this._location === "/farm") {
      return mapComponentMode.FARM_LIST;   
    } else if (this._location?.includes("/products")) {
      return mapComponentMode.PRODUCT_LIST;
    } 
    return mapComponentMode.FARM_LIST;
  }

  public get mapComponentData(): MapComponentData {
    return this._mapComponentData;
  }
  public set mapComponentData(value: MapComponentData) {
    this._mapComponentData = value;
  }
  public get farmListData(): Array<FarmItemData> {
    return this._farmListData;
  }
  public set farmListData(value: Array<FarmItemData>) {
    this._farmListData = value;
  }
  public get productListData(): Array<ProductItemData> {
    return this._productListData;
  }
  public set productListData(value: Array<ProductItemData>) {
    this._productListData = value;
  }
}
