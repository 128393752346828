import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useGoBack = () => {
  const navigate = useNavigate();
  const goBack = useCallback(() => {
    navigate(-1);
    // window.dataLayer.push({
    //   event: "click_back",
    // }); 
  }, [navigate]); 
  return goBack;
};

export default useGoBack;
