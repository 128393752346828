import styled from '@emotion/styled';

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    font-size: 15px;
    font-weight: 500;
    line-height: normal;

    span {
      color: var(--red, #f22d2d);
    }
  }

  input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background: var(--gray-100, #f5f6f8);
  }

  textarea {
    width: 100%;
    height: 120px;
    background: var(--gray-100, #f5f6f8);
    resize: none;
    padding: 8px;
    border-radius: 4px;
  }
`;

export const DatePickerGroup = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  div {
    position: relative;
    flex: 1;
    height: 42px;
    padding: 8px 10px;

    border-radius: 4px;
    border: 1px solid var(--gray-300, #ccc);

    display: flex;
    align-items: center;
    span {
      font-size: 14px;
    }

    svg {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 8px;
    }
  }
`;

export const FormButtonGroup = styled.div`
  display: flex;
  gap: 16px;

  button {
    flex: 1;
    border-radius: 4px;
    height: 44px;
    font-size: 15px;
    font-weight: 700;
  }

  button:first-of-type {
    border: 1px solid var(--gray-300, #cccccc);
    color: var(--gray-400, #666666);
  }

  button:last-of-type {
    background-color: var(--green-100, #00b140);
    color: var(--white, #ffffff);
  }
`;
