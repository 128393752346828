import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class WorkRecordLogListRequest extends BaseRequest{
    private readonly URL_WORKRECORDLOG_BASE = `${BaseService.API_URL}/workRecordLog`;
    private readonly URL_WORKRECORDLOG_LIST = `${this.URL_WORKRECORDLOG_BASE}/workRecordLogList`;

    getURL(): string {
        return this.URL_WORKRECORDLOG_LIST;
    }
}