import { useEffect } from 'react';
import BuyerMapComponentData from './BuyerMapComponentData';
import { MapLevel } from 'code/Enumerator';
import WorkRegistrationSearch from 'ui/pages/WorkRegistrationSearch/WorkRegistrationSearch';
import MarkerData from '../MapComponent/MarkerData';
import PolygonData from '../MapComponent/PolygonData';
import { MapContainer } from 'styles/common.styles';
import currentIcon from 'assets/images/currentIcon.png';
import searchAddressPin from 'assets/images/markerIcon/searchAddressPin.svg';

declare global {
  interface Window {
    daum: any;
    kakao: any;
  }
}
const { kakao } = window;
let buyerMapComponentData: BuyerMapComponentData = new BuyerMapComponentData();

interface BuyerMapComponentProps {
  mapCenter?: number[];
  mapLevel?: MapLevel;
  myLatLng?: number[];
  markerList?: Array<MarkerData>;
  polygonList?: Array<PolygonData>;
  handleOnMarkerClick?: (markerId: string) => void;
}
const BuyerMapComponent = (props: BuyerMapComponentProps) => {
  useEffect(() => {
    init();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    buyerMapComponentData.markerList = props.markerList || new Array<MarkerData>();
    buyerMapComponentData.polygonList = props.polygonList || new Array<PolygonData>();
    buyerMapComponentData.kakaoMarkerList?.forEach((item) => {
      kakao.maps.event.addListener(item, 'click', function () {
        handleOnMarkerClick(item.markerId);
      });
    });
    if(buyerMapComponentData.searchLandMarker){buyerMapComponentData.searchLandMarker.setMap(null)};

  }, [props.markerList, props.polygonList]);

  useEffect(() => {
    if (!props.mapLevel) {return;};
    buyerMapComponentData.kakaoMap.setLevel(props.mapLevel);
  }, [props.mapLevel]);

  useEffect(() => {
    if (!props.myLatLng) {return;};
    buyerMapComponentData.myLatLng = props.myLatLng;
  }, [props.myLatLng]);

  useEffect(() => {
    if (!props.mapCenter) {return;};
    buyerMapComponentData.mapCenter = props.mapCenter;
  }, [props.mapCenter]);

  function init() {
    const container = document.getElementById('map'); // 지도를 표시할 div
    buyerMapComponentData = new BuyerMapComponentData();
    const option = {
      center: new kakao.maps.LatLng( buyerMapComponentData.myLatLng[0], buyerMapComponentData.myLatLng[1]),
      level: buyerMapComponentData.mapLevel,
    };

    buyerMapComponentData.kakaoMap = new kakao.maps.Map(container, option); // 지도를 생성합니다

    navigator.geolocation.getCurrentPosition(function (position) {
      buyerMapComponentData.myLatLng = [ position.coords.latitude, position.coords.longitude ];
      buyerMapComponentData.mapCenter = [ position.coords.latitude, position.coords.longitude ];
    });
  }
  function handleOnMarkerClick(markerId: string) {
    props.handleOnMarkerClick && props.handleOnMarkerClick(markerId);
  }
  function handleMoveToMap(lat: number, lng: number) {
    buyerMapComponentData.moveToMap(lat, lng);
    if(buyerMapComponentData.searchLandMarker){buyerMapComponentData.searchLandMarker.setMap(null)};

    buyerMapComponentData.searchLandMarker = new kakao.maps.Marker({
      position: new kakao.maps.LatLng(lat, lng),
      clickable: false,
      image : new kakao.maps.MarkerImage(
        searchAddressPin,
        new kakao.maps.Size(20, 30),
      ),
      zIndex: 4
    });
    buyerMapComponentData.searchLandMarker.setMap(buyerMapComponentData.kakaoMap);
  }
  function handleMyLocationClick() {   
    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      buyerMapComponentData.moveToMap(lat, lng);
    });      
  }

  return (
    <>
      <div style={{
              width: "36px",
              height: "36px",
              position: "absolute", 
              top: "3px",
              left: "3px",
              padding: "5px",
              overflow: "hidden",
              zIndex: "3",
              borderRadius: "20%",
              backgroundColor: "white",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              boxShadow: 'rgba(0, 0, 0, 0.25) 0px 4px 4px 0px'
             }}>
        <img src={currentIcon} 
             alt="" 
             onClick={handleMyLocationClick} 
             style={{width: "30px", height: "30px"}}
            />
      </div>
      <WorkRegistrationSearch searchAddress={handleMoveToMap} />
      <MapContainer id="map" />
    </>
  );
};

export default BuyerMapComponent;
