import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { modalState } from 'atoms/modal';
import { LoadableComponent } from '@loadable/component';
import { CommonModalProps } from 'ui/components/Modals/Modals.types';
import loadable from '@loadable/component';
import { TransactionConfirmModalProps } from 'ui/components/Modals/TransactionConfirmModal/TransactionConfirmModal';
import { ProductRegistModalProps } from 'ui/components/Modals/ProductPostingModal/ProductPostingModal';
import { WorkMemoModalProps } from 'ui/components/Modals/WorkMemoModal/WorkMemoModal';
import { CropAddModalProps } from 'ui/components/Modals/CropAddModal/CropAddModal';
import { ConfirmModalProps } from 'ui/components/Modals/ConfirmModal/ConfirmModal';
import { SearchAddressModalProps } from 'ui/components/Modals/SearchAddressModal/SearchAddressModal';
import { AlertModalProps } from 'ui/components/Modals/AlertModal/AlertModal';
import { TermsModalProps } from 'ui/components/Modals/TermsModal/TermsModal';
import { FilterModalProps } from 'ui/components/Modals/FilterModal/FilterModal';
import { WorkModalProps } from 'ui/components/Modals/WorkModal/WorkModal';
import { WorkRecordRegistModalProps } from 'ui/pages/WorkRecordRegistration/WorkRecordRegistration';
import { TradeConfirmModalProps } from 'ui/components/Modals/TradeConfirmModal/TradeConfirmModal';
import { OneButtonModalProps } from 'ui/components/Modals/OneButtonModal/OneButtonModal';
import { AgreeTermsModalProps } from 'ui/components/Modals/TermsModal/AgreeTermsModal';
import { TesterModalProps } from 'ui/components/Modals/TesterModal/TesterModal';
import { TwoButtonModalProps } from 'ui/components/Modals/TwoButtonModal/TwoButtonModal';
import { AgreeUseTermsModalProps } from 'ui/components/Modals/TermsModal/AgreeUseTermsModal';
import { CalendarModalProps } from 'ui/components/Modals/CalendarModal/CalendarModal';

const ImageUploadModal = loadable(
  () => import('ui/components/Modals/ImageUploadModal/ImageUploadModal')
);
const TransactionConfirmModal = loadable(
  () =>
    import(
      'ui/components/Modals/TransactionConfirmModal/TransactionConfirmModal'
    )
);
const ProductPostingModal = loadable(
  () => import('ui/components/Modals/ProductPostingModal/ProductPostingModal')
);
const WorkRecordModal = loadable(
  () => import('ui/components/Modals/WorkRecordModal/WorkRecordModal')
);

const WorkerExitModal = loadable(
  () => import('ui/components/Modals/WorkerExitModal/WorkerExitModal')
);
const CalendarModal = loadable(
  () => import('ui/components/Modals/CalendarModal/CalendarModal')
);
const EquipmentManagementModal = loadable(
  () =>
    import(
      'ui/components/Modals/EquipmentManagementModal/EquipmentManagementModal'
    )
);
const WorkMemoModal = loadable(
  () => import('ui/components/Modals/WorkMemoModal/WorkMemoModal')
);
const TradeDoneModal = loadable(
  () => import('ui/components/Modals/TradeDoneModal/TradeDoneModal')
);
const WorkRecordRegistModal = loadable(
  () => import('ui/pages/WorkRecordRegistration/WorkRecordRegistration')
);
const CropAddModal = loadable(
  () => import('ui/components/Modals/CropAddModal/CropAddModal')
);
const ConfirmModal = loadable(
  () => import('ui/components/Modals/ConfirmModal/ConfirmModal')
);
const TesterModal = loadable(
  () => import('ui/components/Modals/TesterModal/TesterModal')
);
const SearchAddressModal = loadable(
  () => import('ui/components/Modals/SearchAddressModal/SearchAddressModal')
);
const FilterModal = loadable(
  () => import('ui/components/Modals/FilterModal/FilterModal')
);
const AlertModal = loadable(
  () => import('ui/components/Modals/AlertModal/AlertModal')
);
const TermsModal = loadable(
  () => import('ui/components/Modals/TermsModal/TermsModal')
);
const AgreeTermsModal = loadable(
  () => import('ui/components/Modals/TermsModal/AgreeTermsModal')
);
const AgreeUseTermsModal = loadable(
  () => import('ui/components/Modals/TermsModal/AgreeUseTermsModal')
);

const WorkModal = loadable(
  () => import('ui/components/Modals/WorkModal/WorkModal')
);
const PurchaseConfirmationModal = loadable(
  () =>
    import(
      'ui/components/Modals/TradeConfirmModal/TradeConfirmModal'
    )
);

const OneButtonModal = loadable(
  () => import('ui/components/Modals/OneButtonModal/OneButtonModal')
);

const TwoButtonModal = loadable(
  () => import('ui/components/Modals/TwoButtonModal/TwoButtonModal')
);

export const useModal = () => {
  const setModal = useSetRecoilState(modalState);

  const openModal = useCallback(
    <T extends CommonModalProps>(
      Component: LoadableComponent<T>,
      props?: Omit<T, 'onClose'>
    ) => {
      setModal((modals) => [...modals, { Component, props }]);
    },
    [setModal]
  );

  const closeModal = useCallback(
    <T extends CommonModalProps>(Component: LoadableComponent<T>) => {
      setModal((modals) => {
        return modals.filter((modal) => modal.Component !== Component);
      });
    },
    [setModal]
  );

  return { openModal, closeModal };
};

export const useImageUploadModal = () => {
  const { openModal, closeModal } = useModal();

  const openImageUploadModal = useCallback(() => {
    openModal(ImageUploadModal);
  }, [openModal]);

  const closeImageUploadModal = useCallback(() => {
    closeModal(ImageUploadModal);
  }, [closeModal]);

  return { openImageUploadModal, closeImageUploadModal };
};

export const useTransactionConfirmModal = () => {
  const { openModal, closeModal } = useModal();

  const openTransactionConfirmModal = useCallback(
    (props: Omit<TransactionConfirmModalProps, 'onClose'>) => {
      openModal(TransactionConfirmModal, props);
    },
    [openModal]
  );

  const closeTransactionConfirmModal = useCallback(() => {
    closeModal(TransactionConfirmModal);
  }, [closeModal]);

  return { openTransactionConfirmModal, closeTransactionConfirmModal };
};

// 추가 부분
export const useProductPostingModal = () => {
  const { openModal, closeModal } = useModal();

  const openProductPostingModal = useCallback(
    (props: Omit<ProductRegistModalProps, 'onClose'>) => {
      openModal(ProductPostingModal, props);
    },
    [openModal]
  );

  const closeProductPostingModal = useCallback(() => {
    closeModal(ProductPostingModal);
  }, [closeModal]);

  return { openProductPostingModal, closeProductPostingModal };
};

export const useWorkRecordModal = () => {
  const { openModal, closeModal } = useModal();

  const openWorkRecordModal = useCallback(() => {
    openModal(WorkRecordModal);
  }, [openModal]);

  const closeWorkRecordModal = useCallback(() => {
    closeModal(WorkRecordModal);
  }, [closeModal]);

  return { openWorkRecordModal, closeWorkRecordModal };
};

export const useTradeDoneModal = () => {
  const { openModal, closeModal } = useModal();

  const openTradeDoneModal = useCallback(() => {
    openModal(TradeDoneModal);
  }, [openModal]);

  const closeTradeDoneModal = useCallback(() => {
    closeModal(TradeDoneModal);
  }, [closeModal]);

  return { openTradeDoneModal, closeTradeDoneModal };
};

export const useWorkRecordRegistModal = () => {
  const { openModal, closeModal } = useModal();

  const openWorkRecordRegistModal = useCallback(
    (props: Omit<WorkRecordRegistModalProps, 'onClose'>) => {
      openModal(WorkRecordRegistModal, props);
    },
    [openModal]
  );

  const closeWorkRecordRegistModal = useCallback(() => {
    closeModal(WorkRecordRegistModal);
  }, [closeModal]);

  return { openWorkRecordRegistModal, closeWorkRecordRegistModal };
};

export const useWorkerExitModal = () => {
  const { openModal, closeModal } = useModal();

  const openWorkerExitModal = useCallback(() => {
    openModal(WorkerExitModal);
  }, [openModal]);

  const closeWorkerExitModal = useCallback(() => {
    closeModal(WorkerExitModal);
  }, [closeModal]);

  return { openWorkerExitModal, closeWorkerExitModal };
};

export const useCalendarModal = () => {
  const { openModal, closeModal } = useModal();

  const openCalendarModal = useCallback(
    (props: Omit<CalendarModalProps, 'onClose'>) => {
      openModal(CalendarModal, props);    
  }, [openModal]);

  const closeCalendarModal = useCallback(() => {
    closeModal(CalendarModal);
  }, [closeModal]);

  return { openCalendarModal, closeCalendarModal };
};

export const useEquipmentManagementModal = () => {
  const { openModal, closeModal } = useModal();

  const openEquipmentManagementModal = useCallback(() => {
    window.dataLayer.push({
      event: "click_mypage_menu",
      menu_name: "장비 관리",
    });
    openModal(EquipmentManagementModal);
  }, [openModal]);

  const closeEquipmentManagementModal = useCallback(() => {
    closeModal(EquipmentManagementModal);
  }, [closeModal]);

  return { openEquipmentManagementModal, closeEquipmentManagementModal };
};

export const useWorkMemoModal = () => {
  const { openModal, closeModal } = useModal();

  const openWorkMemoModal = useCallback(
    (props: Omit<WorkMemoModalProps, 'onClose'>) => {
      openModal(WorkMemoModal, props);
    },
    [openModal]
  );

  const closeWorkMemoModal = useCallback(() => {
    closeModal(WorkMemoModal);
  }, [closeModal]);

  return { openWorkMemoModal, closeWorkMemoModal };
};

export const useCropAddModal = () => {
  const { openModal, closeModal } = useModal();

  const openCropAddModal = useCallback(
    (props: Omit<CropAddModalProps, 'onClose'>) => {
      openModal(CropAddModal, props);
    },
    [openModal]
  );

  const closeCropAddModal = useCallback(() => {
    closeModal(CropAddModal);
  }, [closeModal]);

  return { openCropAddModal, closeCropAddModal };
};

export const useTesterModal = () => {
  const { openModal, closeModal } = useModal();

  const openTesterModal = useCallback(
    (props: Omit<TesterModalProps, 'onClose'>) => {
      openModal(TesterModal, props);
    },
    [openModal]
  );

  const closeTesterModal = useCallback(() => {
    closeModal(TesterModal);
  }, [closeModal]);

  return { openTesterModal, closeTesterModal };
}

export const useConfirmModal = () => {
  const { openModal, closeModal } = useModal();

  const openConfirmModal = useCallback(
    (props: Omit<ConfirmModalProps, 'onClose'>) => {
      openModal(ConfirmModal, props);
    },
    [openModal]
  );

  const closeConfirmModal = useCallback(() => {
    closeModal(ConfirmModal);
  }, [closeModal]);

  return { openConfirmModal, closeConfirmModal };
};
export const useSearchAddressModal = () => {
  const { openModal, closeModal } = useModal();

  const openSearchAddressModal = useCallback(
    (props: Omit<SearchAddressModalProps, 'onClose'>) => {
      openModal(SearchAddressModal, props);
    },
    [openModal]
  );

  const closeSearchAddressModal = useCallback(() => {
    closeModal(SearchAddressModal);
  }, [closeModal]);

  return { openSearchAddressModal, closeSearchAddressModal };
};

export const useFilterModal = () => {
  const { openModal, closeModal } = useModal();

  const openFilterModal = useCallback(
    (props: Omit<FilterModalProps, 'onClose'>) => {
      openModal(FilterModal, props);
    },
    [openModal]
  );

  const closeFilterModal = useCallback(() => {
    closeModal(FilterModal);
  }, [closeModal]);

  return { openFilterModal, closeFilterModal };
};

export const useAlertModal = () => {
  const { openModal, closeModal } = useModal();

  const openAlertModal = useCallback(
    (props: Omit<AlertModalProps, 'onClose'>) => {
      openModal(AlertModal, props);
    },
    [openModal]
  );

  const closeAlertModal = useCallback(() => {
    closeModal(AlertModal);
  }, [closeModal]);

  return { openAlertModal, closeAlertModal };
};

export const useAgreeTermsModal = () => {
  const { openModal, closeModal } = useModal();

  const openAgreeTermsModal = useCallback(
    (props: Omit<AgreeTermsModalProps, 'onClose'>) => {
      openModal(AgreeTermsModal, props);
    },
    [openModal]
  );

  const closeAgreeTermsModal = useCallback(() => {
    closeModal(AgreeTermsModal);
  }, [closeModal]);

  return { openAgreeTermsModal, closeAgreeTermsModal };
};

export const useAgreeUseTermsModal = () => {
  const { openModal, closeModal } = useModal();

  const openAgreeUseTermsModal = useCallback(
    (props: Omit<AgreeUseTermsModalProps, 'onClose'>) => {
      openModal(AgreeUseTermsModal, props);
    },
    [openModal]
  );

  const closeAgreeUseTermsModal = useCallback(() => {
    closeModal(AgreeUseTermsModal);
  }, [closeModal]);

  return { openAgreeUseTermsModal, closeAgreeUseTermsModal };
};

export const useTermsModal = () => {
  const { openModal, closeModal } = useModal();

  const openTermsModal = useCallback(
    (props: Omit<TermsModalProps, 'onClose'>) => {
      openModal(TermsModal, props);
    },
    [openModal]
  );

  const closeTermsModal = useCallback(() => {
    closeModal(TermsModal);
  }, [closeModal]);

  return { openTermsModal, closeTermsModal };
};

export const useWorkModal = () => {
  const { openModal, closeModal } = useModal();

  const openWorkModal = useCallback(
    (props: Omit<WorkModalProps, 'onClose'>) => {
      openModal(WorkModal, props);
    },
    [openModal]
  );

  const closeWorkModal = useCallback(() => {
    closeModal(WorkModal);
  }, [closeModal]);

  return { openWorkModal, closeWorkModal };
};

export const useOneButtonModal = () => {
  const { openModal, closeModal } = useModal();

  const openOneButtonModal = useCallback(
    (props: Omit<OneButtonModalProps, 'onClose'>) => {
      openModal(OneButtonModal, props);
    },
    [openModal]
  );

  const closeOneButtonModal = useCallback(() => {
    closeModal(OneButtonModal);
  }, [closeModal]);

  return { openOneButtonModal, closeOneButtonModal };
};

export const useTwoButtonModal = () => {
  const { openModal, closeModal } = useModal();

  const openTwoButtonModal = useCallback(
    (props: Omit<TwoButtonModalProps, 'onClose'>) => {
      openModal(TwoButtonModal, props);
    },
    [openModal]
  );

  const closeTwoButtonModal = useCallback(() => {
    closeModal(TwoButtonModal);
  }, [closeModal]);

  return { openTwoButtonModal, closeTwoButtonModal };
};

export const usePurchaseConfirmationModal = () => {
  const { openModal, closeModal } = useModal();

  const openPurchaseConfirmationModal = useCallback(
    (props: Omit<TradeConfirmModalProps, 'onClose'>) => {
    openModal(PurchaseConfirmationModal, props);
  }, [openModal]);

  const closePurchaseConfirmationModal = useCallback(() => {
    closeModal(PurchaseConfirmationModal);
  }, [closeModal]);

  return { openPurchaseConfirmationModal, closePurchaseConfirmationModal };
};
