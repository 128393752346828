import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class LandRegistrationRequest extends BaseRequest{
    private readonly URL_LAND_BASE = `${BaseService.API_URL}/land`;
    private readonly URL_LAND_REGISTRATION = `${this.URL_LAND_BASE}/landRegist`;

    getURL(): string {
        return this.URL_LAND_REGISTRATION;
    }
}