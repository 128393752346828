import { css } from '@emotion/react';

export const reactSelectContainerStyle = css`
  .react-select-container {
    flex: 1;

    .react-select__control {
      padding: 0 16px;
      height: 42px;
      background: var(--white);
      border: 1px solid var(--gray-300);
      border-radius: 4px;

      .react-select__value-container {
        font-size: 14px;

        .react-select__placeholder {
          color: var(--gray-400);
        }

        .react-select__single-value {
          color: #000;
          line-height: normal;
        }
      }

      .react-select__indicators {
        .react-select__indicator-separator {
          display: none;
        }
      }
    }

    .react-select__control--menu-is-open {
      border-color: var(--green-100);
    }
  }
`;

export const reactSelectMenuStyle = css`
  .react-select-container {
    .react-select__menu {
      top: calc(100% + 4px);
      padding: 4px 0px;
      background: var(--white);
      border-radius: 6px;
      filter: drop-shadow(0px 6px 20px rgba(48, 48, 48, 0.25));

      .react-select__menu-list {
        overflow-y: auto;
        max-height: 250px;
        scroll-behavior: smooth;
        /* overflow-y: unset; // default값 제거 */
      }
      // 메뉴 리스트 아이템
      .react-select__option {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 13px 12px;
        height: 48px;
        font-size: 14px;

        // hacky code
        &:hover {
          background: #c1f5d5;
        }
      }

      // 선택된 리스트 아이템
      .react-select__option--is-focused.react-select__option--is-selected {
        background: #c1f5d5;
      }
    }
  }
`;
