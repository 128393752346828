export default class WorkerItemData{
    private _equipmentName!: string;
    private _equipmentType!: string;
    private _workerName:string = "";
    private _workTeam: string = "N";
    
    public get equipmentName(): string {
        return this._equipmentName;
    }
    public set equipmentName(value: string) {
        this._equipmentName = value;
    }
    
    public get equipmentType(): string {
        return this._equipmentType;
    }
    public set equipmentType(value: string) {
        this._equipmentType = value;
    }
    
    public get workerName(): string {
        return this._workerName;
    }
    public set workerName(value: string) {
        this._workerName = value;
    }
    public get workTeam(): string{
        return this._workTeam;
    }
    public set workTeam(value: string) {
        this._workTeam = value;
    }
}