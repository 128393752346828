import styled from '@emotion/styled';
import { actionSheetState } from 'atoms/actionSheet';
import BridgeManager from 'bridge/BridgeManager';
import { useTwoButtonModal, useAlertModal } from 'hooks/useModal';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';


const CircleButton = () => {
  const navigate = useNavigate();
  const [sheetState, setSheetState] = useRecoilState(actionSheetState);
  const { openTwoButtonModal } = useTwoButtonModal();
  const { openAlertModal } = useAlertModal();
  const bridgeManager = new BridgeManager();

  const onClick = useCallback(() => {
    if (sheetState === 'minimum') {
      window.dataLayer.push({
        event: "click_work_registration",
      });
     
      if (!bridgeManager.isMobile()) {
        openTwoButtonModal({title: "새 작업을 생성합니다.", topText: '지도 클릭하여 생성하기', bottomText: '엑셀 업로드 하기', onConfirm: () => {}});
      } else {
        // openAlertModal({text: '작업을 등록합니다. \n필지를 먼저 지정해 주십시오.'});
        navigate('/work/registration');
      }
      setSheetState('middle');
    } else {
      window.dataLayer.push({
        event: "click_map_view",
      });     
      setSheetState('minimum');
    }
  }, [sheetState]);

  const onProductClick = useCallback(() => {
      setSheetState('minimum');
  }, [sheetState]);

  const location = useLocation();
  const { pathname } = location;

  const buttonVisible = useMemo(() => {    
    return pathname === '/work' || pathname === '/products';
  }, [location]);

  if (!buttonVisible) {
    return null;
  }

  if(pathname === '/work') {
    return (
      <CircleButtonBlock onClick={onClick}>
        {sheetState === 'minimum' ? '작업\n등록' : '지도\n보기'}
      </CircleButtonBlock>
    );
  }

  if (pathname === '/products' && sheetState !== 'minimum') {
    return (
      <CircleButtonBlock onClick={onProductClick}>
        지도<br/>보기
      </CircleButtonBlock>
    );
  }

  return null;
};

const CircleButtonBlock = styled.button`
  position: fixed;
  bottom: 88px;
  @media all and (min-width:1080px) {
    right: calc(50% - 524px);
  }

  @media all and (max-width:1080px) {
    right: 16px;
  }
  z-index: 150;

  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--green-100, #00b140);

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: opacity 0.2s;

  white-space: pre-wrap;
  font-size: 12px;
  color: var(--white);
`;

export default CircleButton;
