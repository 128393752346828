export default class PolyLineData {
  private _polyLineId!: string;  
  private _pointList: number[][] = [];
  private _polyLineColor!: string;
  private _polyLineTeam: string = "N";

  public get polyLineId(): string {
    return this._polyLineId;
  }
  public set polyLineId(value: string) {
    this._polyLineId = value;
  }  
  public get pointList(): number[][] {
    return this._pointList;
  }
  public set pointList(value: number[][]) {
    this._pointList = value;
  }
  public get polyLineColor(): string {
    return this._polyLineColor;
  }
  public set polyLineColor(value: string) {
    this._polyLineColor = value;
  }
  public get polyLineTeam(): string {
    return this._polyLineTeam;
  }
  public set polyLineTeam(value: string) {
    this._polyLineTeam = value;
  }
}
