import useHeaderOptions from "hooks/useHeaderOptions";
import { WorkScheduleDateContainer, WorkTeamPlanContainer } from "./WorkTeamPlan.styles";
import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { getColorWithTeamCode } from "converter/KakaoConverter";
import { useRecoilState } from "recoil";
import { selectedDateState } from "atoms/selectedDate";
import WorkScheduleDate from "../WorkRegistration/WorkScheduleDate/WorkScheduleDate";
import WorkTeamPlanLandList from "./WorkTeamPlanLandList";
import WorkRegistrationData from "../WorkRegistration/WorkRegistrationData";
import { WorkRegistrationButtonGroup, WorkRegistrationCancelButton, WorkRegistrationDeleteButton, WorkRegistrationSaveButton } from "../WorkRegistration/WorkRegistration.styles";
import { WorkLandListValidator } from "validator/WorkValidator";
import { useAlertModal } from 'hooks/useModal';
import moment from "moment";
import WorkRegistrationReqData from "data/network/req/work/WorkRegistrationReqData";
import { workPlanRegistrationDataToWorkRegistrationReqData } from "converter/WorkConverter";
import WorkRegistrationRequest from "network/work/WorkRegistrationRequest";
import BaseResData from "data/network/res/BaseResData";
import { responseCode } from "code/Enumerator";
import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import WorkDeleteRequest from "network/work/WorkDeleteRequest";
import WorkDeleteReqData from "data/network/req/work/WorkDeleteReqData";
import WorkUpdateReqData from "data/network/req/work/WorkUpdateReqData";
import WorkUpdateRequest from "network/work/WorkUpdateRequest";
import {
  fireStoreManager,
} from '../../../firebase/FireStoreManager';
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from 'dayjs/plugin/isBetween';
import { ActionModeCode } from "code/ActionModeCode";
import { Socket, io } from "socket.io-client";
import BaseService from "network/BaseService";
import SocketCommands from "command/SocketCommands";
import WorkTeamPlanData from "./WorkTeamPlanData";
import LandUpdateListReqData from "data/network/req/work/LandUpdateListReqData";
import LandUpdateRequest from "network/work/LandUpdateRequest";
import LandUpdateReqData from "data/network/req/work/LandUpdateReqData";
import LandDeleteItemReqData from "data/network/req/work/LandDeleteItemReqData";
import LandRegistrationItemReqData from "data/network/req/work/LandRegistrationItemReqData";
import { landMapToString } from "common/utils/StringUtils";
import WorkerDeleteItemReqData from "data/network/req/worker/WorkerDeleteItemReqData";
import WorkerRegistrationItemReqData from "data/network/req/worker/WorkerRegistrationItemReqData";
import Loading from "ui/components/Loading";
interface WorkTeamPlanProps {
  actionModeCode: ActionModeCode;
  workRegistrationData: WorkRegistrationData;
  workTeamPlanData: WorkTeamPlanData;
  originWorkTeamPlanData?: WorkTeamPlanData;
  setPlanTeam: (team : string | undefined) => void;
  onLandDeleteBtnClick?: (landAddress: string) => void;
  onHandlePin: (latitude: number, longitude: number) => void;
}

let socket: Socket;
const WorkTeamPlan = () => {
  const props = useOutletContext<WorkTeamPlanProps>();
  const { openAlertModal } = useAlertModal();
  const workRegistrationRequest: WorkRegistrationRequest = new WorkRegistrationRequest();
  const workDeleteRequest: WorkDeleteRequest = new WorkDeleteRequest();
  const navigate = useNavigate();
  const [workTeamList, setWorkTeamList] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDateState);
  const [socketConnect, setSocketConnect] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  let masterWorkStartDate = dayjs(props.workRegistrationData.workStartDate);
  let masterWorkEndDate = dayjs(props.workRegistrationData.workEndDate);
  let hasConnectError = false;

  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isBetween);

  useHeaderOptions({
    title: props.actionModeCode === ActionModeCode.CREATE ? '팀 작업 계획' : '팀 작업 수정',
    hasBack: true
  });

  useEffect(() => {
    if(props.actionModeCode === ActionModeCode.CREATE){
    document.title = "카우잇츠 - 생산자 팀 작업 계획";
    } else {
      document.title = "카우잇츠 - 생산자 팀 작업 계획 수정";
    }
    return (() => {
      setSelectedDate({startDate: null, endDate: null});
    });
  }, []);

  useEffect(() => {
    init();
    
  }, [props.workRegistrationData]);

  function init() {
    if (!props.workRegistrationData) {
      return;
    }
    let propsTeamList = Array.from(new Set(props.workRegistrationData.workEquipmentData.workerList.map((worker) => worker.workTeam)));
    setWorkTeamList(propsTeamList.sort((a, b) => a.localeCompare(b)));
    if(props.actionModeCode === ActionModeCode.CREATE){
      if (props.workRegistrationData.workStartDate && props.workRegistrationData.workEndDate) {
        setSelectedDate({startDate: new Date(props.workRegistrationData.workStartDate), endDate: new Date(props.workRegistrationData.workEndDate)});
      }
    }

    if(props.actionModeCode === ActionModeCode.UPDATE){
      if (props.originWorkTeamPlanData && props.originWorkTeamPlanData!.workStartDate && props.originWorkTeamPlanData!.workEndDate) {
        setSelectedDate({startDate: new Date(props.originWorkTeamPlanData.workStartDate), endDate: new Date(props.originWorkTeamPlanData.workEndDate)});
      }
    }

    socket = socket = io(BaseService.CHAT_URL, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionAttempts: 10
    });

    socket.io.on("reconnect", () => {
      setSocketConnect(!socketConnect);
    })

    socket.on("connect", () => {
      socket.emit(SocketCommands.JOIN_ROOM, props.workRegistrationData.workId);
    });

    socket.on("disconnect", (reason) => {

    });
    socket.on("connect_error", () => {
      setTimeout(() => {
        hasConnectError = true;
      }, 1000);
    });
  }  

  async function reqWorkPlanRegist() {        
    if (selectedDate.startDate === null || selectedDate.endDate === null) {
      openAlertModal({text: '작업 예정일을 설정해주세요.'});
      setIsLoading(false);
      return;
    }
    if (!dayjs(selectedDate.startDate).isBetween(masterWorkStartDate, masterWorkEndDate, 'day', "[]") || !dayjs(selectedDate.endDate).isBetween(masterWorkStartDate, masterWorkEndDate, 'day', "[]")) {
      openAlertModal({text: '상위 작업 예정일 내에서 작업 예정일을 설정해주세요.'});
      setIsLoading(false);
      return;
    }
    let planWorkStartDate = dayjs(selectedDate.startDate);
    let planWorkEndDate = dayjs(selectedDate.endDate);
    if(props.workTeamPlanData.unavailableDateList){
      for (let i = 0; i < props.workTeamPlanData.unavailableDateList.length; i++) {
        if (props.workTeamPlanData.unavailableDateList[i].isSame(planWorkStartDate, "day") || props.workTeamPlanData.unavailableDateList[i].isSame(planWorkEndDate, "day")) {
          openAlertModal({text: '다른 작업과 겹치는 날짜가 있습니다.'});
          setIsLoading(false);
          return;
        }
      }
    }

    let workPlanRegistrationReqData : WorkRegistrationData = new WorkRegistrationData();
    workPlanRegistrationReqData.masterWorkId = props.workRegistrationData.workId;
    workPlanRegistrationReqData.workStartDate = moment(selectedDate.startDate).format('YYYY-MM-DD');
    workPlanRegistrationReqData.workEndDate = moment(selectedDate.endDate).format('YYYY-MM-DD');
    workPlanRegistrationReqData.workType = props.workRegistrationData.workType;
    workPlanRegistrationReqData.cropType = props.workRegistrationData.cropType;
    workPlanRegistrationReqData.workAddress1 = props.workTeamPlanData.landList[0].landFullAddress!.split(" ")[0];
    workPlanRegistrationReqData.workAddress2 = props.workTeamPlanData.landList[0].landFullAddress!.split(" ")[1];
    workPlanRegistrationReqData.workAddress3 = props.workTeamPlanData.landList[0].landFullAddress!.split(" ")[2];
    workPlanRegistrationReqData.workLandListData = props.workTeamPlanData.landList;
    workPlanRegistrationReqData.workEquipmentData.workerList =props.workTeamPlanData.workerList;

    const workRegistrationReqData: WorkRegistrationReqData = workPlanRegistrationDataToWorkRegistrationReqData(workPlanRegistrationReqData);
    const landUpdateListReqData : LandUpdateListReqData = new LandUpdateListReqData();
    const landUpdateRequest : LandUpdateRequest = new LandUpdateRequest();
    workRegistrationReqData.landList.forEach((landItem) => {
      const landUpdateReqData = new LandUpdateReqData();
      landUpdateReqData.landAddress = landItem.landAddress;
      landUpdateReqData.workTeam = landItem.workTeam!;
      landUpdateListReqData.landUpdateReqDataList.push(landUpdateReqData);
    });
    landUpdateListReqData.masterWorkId = props.workRegistrationData.workId;

    let teamList : string[] = [];

    workRegistrationReqData.landList.forEach((landItem) => {
      if (landItem.workTeam) {
        if(!teamList.includes(landItem.workTeam)){
        teamList.push(landItem.workTeam);
        }
      }
    });

    const filteredWorkerList = workRegistrationReqData.workerList.filter((workerItem) => {
      return teamList.includes(workerItem.workTeam);
    });
    workRegistrationReqData.workerList = filteredWorkerList;

    const response: any = (await workRegistrationRequest.send(workRegistrationReqData)) as BaseResData;
    if (response.responseCode !== responseCode.OK) {
      openAlertModal({text: '작업 계획 등록에 실패하였습니다.'});
      setIsLoading(false);
      return;
    } 

    const res2 : any = (await landUpdateRequest.send(landUpdateListReqData)) as BaseResData;
    if (res2.responseCode !== responseCode.OK) {
      openAlertModal({text: '작업 계획 등록에 실패하였습니다.'})
      setIsLoading(false);
      return;
    }

    window.dataLayer.push({
      event: "register_team_work",
      work_id: `${props.workRegistrationData.workId}`,
    });
    openAlertModal({text: '작업 계획이 등록되었습니다.'})

    workRegistrationReqData.landList.map(async (landItem) => {
      try {
        const newRef = doc(fireStoreManager, 'workerWork', response.workId as string, 'landAddress', landItem.landAddress as string);
        const docSnapshot = await getDoc(newRef);
        if (docSnapshot.exists()) {

        } else {
          const data = {
            polygonAddress: landItem.landAddress,
            polygonColor: "#81DAF5",
            pointList: landItem.landMap,
            polygonLineColor: getColorWithTeamCode(landItem.workTeam) || "#39DE2A",
            polygonTeam: landItem.workTeam || "N"
          };
          await setDoc(newRef, data);
        }
      } catch (error) {
        console.error('Error occurred while checking/saving the document: ', error);
      }
    });

    workRegistrationReqData.landList.map(async (landItem) => {   
      const polygonLineColor = getColorWithTeamCode(landItem.workTeam);
      try {
        const newRef = doc(fireStoreManager, `workerWork`, props.workRegistrationData.masterWorkId as string, 'landAddress', landItem.landAddress as string);
        const data = {
          polygonAddress: landItem.landAddress,
          polygonLineColor: polygonLineColor,
        };
        await updateDoc(newRef, data);
      } catch (error) {
        // setDoc 메서드가 실패한 경우, 에러를 처리하고 필요한 작업 수행
        console.error('Error occurred while saving the document:', error);
      }
    
    });

    socket.emit(SocketCommands.WORK_REFRESH, {workId: props.workRegistrationData.workId});
    setIsLoading(false);
    navigate('/work');
  }

  async function reqWorkPlanDelete() {
    const workDeleteReqData: WorkDeleteReqData = new WorkDeleteReqData();
    workDeleteReqData.workId = props.workRegistrationData.workId;
    const response: any = (await workDeleteRequest.send(workDeleteReqData)) as BaseResData;
    if (response.responseCode !== responseCode.OK) {
      openAlertModal({text: '작업 삭제에 실패하였습니다.'});
      return;
    }
    window.dataLayer.push({
      event: "delete_subwork",
      work_id: `${props.workRegistrationData.workId}`,
    });
    openAlertModal({text: '작업이 삭제되었습니다.'});
    navigate('/work');
  }

  async function reqWorkPlanUpdate() {
    const workPlanUpdateReqData: WorkUpdateReqData = new WorkUpdateReqData();
    const workPlanUpdateRequest: WorkUpdateRequest = new WorkUpdateRequest();
    
    if (selectedDate.startDate === null || selectedDate.endDate === null) {
      openAlertModal({text: '작업 예정일을 설정해주세요.'});
      setIsLoading(false);
      return;
    }

    if (!dayjs(selectedDate.startDate).isBetween(masterWorkStartDate, masterWorkEndDate, 'day', "[]") || !dayjs(selectedDate.endDate).isBetween(masterWorkStartDate, masterWorkEndDate, 'day', "[]")) {
      openAlertModal({text: '상위 작업 예정일 내에서 작업 예정일을 설정해주세요.'});
      return;
    }
    let planWorkStartDate = dayjs(selectedDate.startDate);
    let planWorkEndDate = dayjs(selectedDate.endDate);
    
    let oldWorkDateList : Array<dayjs.Dayjs> = [];
    for (let date = dayjs(props.workTeamPlanData.workStartDate); date.isSameOrBefore(dayjs(props.workTeamPlanData.workEndDate)); date = date.add(1, 'day')) {
      oldWorkDateList.push(date);
    }
    let updateUnavailableDateList = props.workTeamPlanData.unavailableDateList.filter((date) => {
      return !oldWorkDateList.some((oldDate) => oldDate.isSame(date, 'day'));
    })
    if(updateUnavailableDateList){
      for (let i = 0; i < updateUnavailableDateList.length; i++) {
        if (updateUnavailableDateList[i].isSame(planWorkStartDate, "day") || updateUnavailableDateList[i].isSame(planWorkEndDate, "day")) {
          openAlertModal({text: '다른 작업과 겹치는 날짜가 있습니다.'});
          setIsLoading(false);
          return;
        }
      }
    }

    const deletedLandList = props.originWorkTeamPlanData!.landList.filter((landItem) => {
      return !props.workTeamPlanData.landList.some((item) => landItem.landAddress === item.landAddress);
    });

    const addedLandList = props.workTeamPlanData.landList.filter((landItem) => {
      return !props.originWorkTeamPlanData!.landList.some((item) => landItem.landAddress === item.landAddress);
    });

    const editLandList = [...props.workTeamPlanData!.landList].filter((item) => (!deletedLandList.some((deleteItem) => deleteItem.landAddress === item.landAddress)) && (!addedLandList.some((addItem) => addItem.landAddress === item.landAddress)));

    let editedTeamList: string[] = [];

    props.workTeamPlanData!.landList.forEach((landItem) => {
      if (landItem.workTeam && !editedTeamList.includes(landItem.workTeam)) {       
        editedTeamList.push(landItem.workTeam);
      }
    });
    

    const addedTeamList = editedTeamList.filter((team) => {
      return !props.originWorkTeamPlanData!.originTeamList.includes(team);
    });

    const deletedTeamList = props.originWorkTeamPlanData!.originTeamList.filter((team) => {
      return !editedTeamList.includes(team);
    });

    const addedWorkerList = props.workRegistrationData.workEquipmentData.workerList.filter((workerItem) => {
      return addedTeamList.includes(workerItem.workTeam);
    });

    const deletedWorkerList = props.workRegistrationData.workEquipmentData.workerList.filter((workerItem) => {
      return deletedTeamList.includes(workerItem.workTeam);
    });

    deletedLandList.forEach((landItem) => {
      const landDeleteItemReqData = new LandDeleteItemReqData();
      landDeleteItemReqData.workId = props.workRegistrationData.workId;
      landDeleteItemReqData.landAddress = landItem.landAddress;
      workPlanUpdateReqData.deleteLandList.push(landDeleteItemReqData);
    });

    addedLandList.forEach((landItem) => {
      const landAddItemReqData = new LandRegistrationItemReqData();
      landAddItemReqData.landAddress = landItem.landAddress;
      landAddItemReqData.landLatitude = landItem.landLatitude;
      landAddItemReqData.landLongitude = landItem.landLongitude;
      landAddItemReqData.landMap = landMapToString(landItem.landMap);
      landAddItemReqData.landSize = landItem.landSize;
      landAddItemReqData.workTeam = landItem.workTeam!;
      workPlanUpdateReqData.landList.push(landAddItemReqData);
    });

    editLandList.forEach((landItem) => {
      const landUpdateItemReqData = new LandUpdateReqData();
      landUpdateItemReqData.landAddress = landItem.landAddress;
      landUpdateItemReqData.workId = props.workRegistrationData.workId;
      landUpdateItemReqData.workTeam = landItem.workTeam!;
      workPlanUpdateReqData.editLandList.push(landUpdateItemReqData);
    });

    deletedWorkerList.forEach((item) => {
      const workerDeleteItemReqData = new WorkerDeleteItemReqData();
      workerDeleteItemReqData.workId = props.workRegistrationData.workId;
      workerDeleteItemReqData.workerName = item.workerName;
      workPlanUpdateReqData.deleteWorkerList.push(workerDeleteItemReqData);
    });

    addedWorkerList.forEach((item) => {
      const workerAddItemReqData = new WorkerRegistrationItemReqData();
      workerAddItemReqData.workerName = item.workerName;
      workerAddItemReqData.equipmentName = item.equipmentName;
      workerAddItemReqData.equipmentType = item.equipmentType;
      workerAddItemReqData.workTeam = item.workTeam;
      workPlanUpdateReqData.workerList.push(workerAddItemReqData);
    });

    workPlanUpdateReqData.workId = props.workRegistrationData.workId;
    workPlanUpdateReqData.workAddress1 = props.workRegistrationData.workAddress1;
    workPlanUpdateReqData.workAddress2 = props.workRegistrationData.workAddress2;
    workPlanUpdateReqData.workAddress3 = props.workRegistrationData.workAddress3;
    if (workPlanUpdateReqData.landList.length > 0) {
      workPlanUpdateReqData.workLatitude = workPlanUpdateReqData.landList[0].landLatitude;
      workPlanUpdateReqData.workLongitude = workPlanUpdateReqData.landList[0].landLongitude;
    } else {
      workPlanUpdateReqData.workLatitude = props.workTeamPlanData.landList[0].landLatitude;
      workPlanUpdateReqData.workLongitude = props.workTeamPlanData.landList[0].landLongitude;
    }
    workPlanUpdateReqData.workStartDate = moment(selectedDate.startDate).format('YYYY-MM-DD');
    workPlanUpdateReqData.workEndDate = moment(selectedDate.endDate).format('YYYY-MM-DD');
    workPlanUpdateReqData.cropKind = props.workRegistrationData.cropType;
    workPlanUpdateReqData.workType = props.workRegistrationData.workType;

    //마스터 작업 필지 폴리곤 데이터 변경
    let landListForFireBase : Array<typeof landItemForFireBase> = [];
    let landItemForFireBase : {landAddress : string, workTeam : string };

    workPlanUpdateReqData.landList.forEach((land) => {
      let newLand : typeof landItemForFireBase = {landAddress : land.landAddress, workTeam : land.workTeam!};
      landListForFireBase.push(newLand);
    });
    workPlanUpdateReqData.deleteLandList.forEach((land) => {
      let newLand : typeof landItemForFireBase = {landAddress : land.landAddress, workTeam : "N"};
      landListForFireBase.push(newLand);
    });
    workPlanUpdateReqData.editLandList.forEach((land) => {
      let newLand : typeof landItemForFireBase = {landAddress : land.landAddress, workTeam : land.workTeam!};
      landListForFireBase.push(newLand);
    });

    const landUpdateListReqData : LandUpdateListReqData = new LandUpdateListReqData();
    const landUpdateRequest : LandUpdateRequest = new LandUpdateRequest();
    landListForFireBase.forEach((landItem) => {
      const landUpdateReqData = new LandUpdateReqData();
      landUpdateReqData.landAddress = landItem.landAddress;
      landUpdateReqData.workTeam = landItem.workTeam!;
      landUpdateListReqData.landUpdateReqDataList.push(landUpdateReqData);
    });
    landUpdateListReqData.masterWorkId = props.workRegistrationData.workId;

    const response: any = (await workPlanUpdateRequest.send(workPlanUpdateReqData)) as BaseResData;
    if (response.responseCode !== responseCode.OK) {
      openAlertModal({text: '작업 계획 수정에 실패하였습니다.'});
      setIsLoading(false);
      return;
    } 

    const res2 : any = (await landUpdateRequest.send(landUpdateListReqData)) as BaseResData;
    if (res2.responseCode !== responseCode.OK) {
      openAlertModal({text: '작업 계획 수정에 실패하였습니다.'})
      setIsLoading(false);
      return;
    }

    openAlertModal({text: '작업 계획이 수정되었습니다.'});

    let deletePromises = workPlanUpdateReqData.deleteLandList.map(async (land) => {
      try {
        const docRef = doc(fireStoreManager, 'workerWork', workPlanUpdateReqData.workId as string, 'landAddress', land.landAddress as string);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          setIsLoading(false);
          return deleteDoc(docRef);
        }
      } catch (error) {
        console.error('Error occurred while deleting the document: ', error);
      }
    });

    //수정된 필지 폴리곤? 데이터 변경
    let editPromises = workPlanUpdateReqData.editLandList.map(async (land) => {
      const polygonLineColor = getColorWithTeamCode(land.workTeam);
      try {
        const newRef = doc(fireStoreManager, 'workerWork', props.workRegistrationData.workId as string, 'landAddress', land.landAddress as string);
        const data = {
          polygonAddress: land.landAddress,
          polygonLineColor: polygonLineColor,
          polygonTeam: land.workTeam
        };
        await updateDoc(newRef, data);
      } catch (error) {
        console.error('Error occurred while saving the document:', error);
      }
    })

    let addPromises = workPlanUpdateReqData.landList.map(async (land) => {
      try {
        const newRef = doc(fireStoreManager, 'workerWork', workPlanUpdateReqData.workId as string, 'landAddress', land.landAddress as string);
        const docSnapshot = await getDoc(newRef);
        if (!docSnapshot.exists()) {
          const data = {
            polygonAddress: land.landAddress,
            polygonColor: "#81DAF5",
            pointList: land.landMap,
            polygonLineColor: getColorWithTeamCode(land.workTeam) || "#39DE2A",
            polygonTeam: land.workTeam || "N"
          };
          setIsLoading(false);
          return setDoc(newRef, data);
        }
      } catch (error) {
        console.error('Error occurred while checking/saving the document: ', error);
      }
    });

    let addMasterPromises = workPlanUpdateReqData.landList.map(async (land) => {
      try {
        const newRef = doc(fireStoreManager, 'workerWork', props.workRegistrationData.masterWorkId as string, 'landAddress', land.landAddress as string);
        const docSnapshot = await getDoc(newRef);
        const data = {
          polygonLineColor: getColorWithTeamCode(land.workTeam),
          polygonTeam: land.workTeam
        };
        setIsLoading(false);
        return updateDoc(newRef, data);
      } catch (error) {
        console.error('Error occurred while checking/saving the document: ', error);
      }
    });

    let editMasterPromises = workPlanUpdateReqData.editLandList.map(async (land) => {
      try {
        const newRef = doc(fireStoreManager, 'workerWork', props.workRegistrationData.masterWorkId as string, 'landAddress', land.landAddress as string);
        const docSnapshot = await getDoc(newRef);        
        const data = {
          polygonLineColor: getColorWithTeamCode(land.workTeam),
          polygonTeam: land.workTeam
        };
        setIsLoading(false);
        return updateDoc(newRef, data);
      } catch (error) {
        console.error('Error occurred while checking/saving the document: ', error);
      }
    });

    let deleteMasterPromises = workPlanUpdateReqData.deleteLandList.map(async (land) => {
      try {
        const docRef = doc(fireStoreManager, 'workerWork', props.workRegistrationData.masterWorkId as string, 'landAddress', land.landAddress as string);
        const docSnapshot = await getDoc(docRef);
        const data = {
          polygonLineColor: getColorWithTeamCode("N"),
          polygonTeam: "N"
        };
        setIsLoading(false);
        return updateDoc(docRef, data);
      } catch (error) {
        console.error('Error occurred while deleting the document: ', error);
      }
    });

    //...addMasterPromises
    await Promise.all([...deletePromises, ...addPromises, ...editPromises, ...deleteMasterPromises, ...addMasterPromises, ...editMasterPromises]);
    socket.emit(SocketCommands.WORK_REFRESH, {workId: props.workRegistrationData.workId});
    setIsLoading(false);
    navigate('/work');
  }

  // function handleLandDeleteBtnClick(landAddress: string) {
  //   props.onLandDeleteBtnClick && props.onLandDeleteBtnClick(landAddress);
  // }

  function handleOnSaveBtnClick() {
    setIsLoading(true);
    if (WorkLandListValidator.validate(props.workTeamPlanData.landList) === false) {
      openAlertModal({text: '작업 필지를 등록해주세요.'});
      setIsLoading(false)
    }

    props.workRegistrationData.workStartDate = moment(selectedDate.startDate).format('YYYY-MM-DD');
    props.workRegistrationData.workEndDate = moment(selectedDate.endDate).format('YYYY-MM-DD');

    if(props.actionModeCode === ActionModeCode.UPDATE){
      reqWorkPlanUpdate();
    }
    else{
      reqWorkPlanRegist();
    }
  }

  function handleOnDeleteBtnClick() {
    reqWorkPlanDelete();
  }

  function handleCancelBtnClick() {
    navigate(-1);
  }

  // function teamButtonClickHandler(team: string) {
  //   if (props.planTeam === team) {
  //     props.setPlanTeam(undefined);
  //   } else {
  //     props.setPlanTeam(team);
  //   }
  // }

  return (
    <>
    {isLoading ? <Loading /> :
      <WorkTeamPlanContainer>
        <WorkTeamPlanLandList 
          onLandDeleteBtnClick = {props.onLandDeleteBtnClick}
          plannedLandList = {props.workTeamPlanData.landList}
          workTeamList = {workTeamList}
          setPlanTeam={props.setPlanTeam}
          onHandlePin={props.onHandlePin}
          workId={props.workRegistrationData.workId}
        />
        <WorkScheduleDateContainer>
          <WorkScheduleDate
            workId={props.workRegistrationData.workId}
          />
        </WorkScheduleDateContainer>
        <WorkRegistrationButtonGroup>
        {((props.actionModeCode === ActionModeCode.UPDATE) && props.originWorkTeamPlanData!.workDeletable === true) &&
          <WorkRegistrationDeleteButton onClick={handleOnDeleteBtnClick}>
            삭제
          </WorkRegistrationDeleteButton>
        }
          <WorkRegistrationSaveButton onClick={handleOnSaveBtnClick}>
            저장
          </WorkRegistrationSaveButton>
          <WorkRegistrationCancelButton onClick={handleCancelBtnClick}>
            취소
          </WorkRegistrationCancelButton>
        </WorkRegistrationButtonGroup>
      </WorkTeamPlanContainer>
      }
    </>
    );
    
};
export default WorkTeamPlan;
