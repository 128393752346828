import './fonts.css';
import './reset.css';
import './colors.css';

import { css } from '@emotion/react';
import modalStyles from './modal.styles';
import {
  reactSelectContainerStyle,
  reactSelectMenuStyle,
} from './react-select.styles';
import { customOverlayStyle } from './customOverlay.styles';

const globalStyles = css`
  ${modalStyles};
  ${reactSelectContainerStyle};
  ${reactSelectMenuStyle};
  ${customOverlayStyle}

  * {
    box-sizing: border-box;
    font-family: 'Pretendard' !important;
  }

  button {
    cursor: pointer;
    border: none;
    background-color: unset;
    &:disabled {
      cursor: not-allowed;
    }
  }

  input,
  textarea {
    outline: none;
    border: none;

    color: var(--gray-700);
    font-size: 14px;
    caret-color: var(--gray-300, #cccccc);
    padding: 0 16px;
    line-height: normal;
  }

  input::placeholder {
    color: var(--gray-400, #999999);
    font-size: 14px;
  }

  a {
    text-decoration: none;
    color: unset;
    cursor: pointer;
  }

  svg {
    display: block;
    cursor: pointer;
  }

  input[type='radio'] {
    position: relative;
    appearance: none;
    margin: 0;
    padding: 0;

    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid var(--gray-300);

    &:checked {
      border: 2px solid var(--green-100, #00b140);
    }

    &:checked::after {
      position: absolute;
      top: 3px;
      left: 3px;

      content: '';
      width: 14px;
      height: 14px;
      background: var(--green-100, #00b140);
      border-radius: 50%;
    }
  }
`;

export default globalStyles;
