export default class MyPurchaseItemData {
  private _salesDtm!: string;
  private _cropType!: string;
  private _tradeQnt!: number;
  private _tradePrice!: number;

  public get cropType(): string {
      return this._cropType;
  }
  public set cropType(value: string) {
      this._cropType = value;
  }
  public get tradeQnt(): number {
      return this._tradeQnt;
  }
  public set tradeQnt(value: number) {
      this._tradeQnt = value;
  }
  public get tradePrice(): number {
      return this._tradePrice;
  }
  public set tradePrice(value: number) {
      this._tradePrice = value;
  }
  public get salesDtm(): string {
      return this._salesDtm;
  }
  public set salesDtm(value: string) {
      this._salesDtm = value;
  }

}
