import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { SearchButton, SearchForm } from "./WorkRegistrationSearch.styles";
import useHeaderOptions from "hooks/useHeaderOptions";
import { ReactComponent as SearchIcon } from "assets/images/search.svg";
import { ReactComponent as BackIcon } from "assets/images/back.svg";
import SearchResult from "./SearchResult/SearchResult";
import SearchHistory from "./SearchHistory/SearchHistory";

export type UserSearchHistory =
  | { type: "search"; search: string; searchAt: string }
  | {
      type: "placeResult";
      search: kakao.maps.services.PlacesSearchResultItem;
      searchAt: string;
    }
  | {
      type: "geoResult";
      search: GeoCoderResult;
      searchAt: string;
    };

export type GeoCoderResult = {
  address_name: string;
  address_type: "REGION" | "ROAD" | "REGION_ADDR" | "ROAD_ADDR";
  x: string;
  y: string;
  address: kakao.maps.services.Address;
  road_address: kakao.maps.services.RoadAaddress;
};

interface WorkRegistrationSearchProps {
  searchAddress : (latitude : number, longitude : number) => void;
  onClick?: () => void;
}

const WorkRegistrationSearch = (props: WorkRegistrationSearchProps) => {
  const [search, setSearch] = useState("");
  const [openSearch, setOpenSearch] = useState(false);
  const [searchList, setSearchList] = useState<
    Array<kakao.maps.services.PlacesSearchResultItem | GeoCoderResult>
  >([]);
  const [searchHistoryList, setSearchHistoryList] = useState<
    Array<UserSearchHistory>
  >([]);
  const [searchSuggestList, setSearchSuggestList] = useState<
    Array<UserSearchHistory>
  >([]);

  useHeaderOptions({
    title: "작업등록",
    hasBack: true
  });

  useEffect(() => {
    const storedHistory = localStorage.getItem("userSearchHistory");
    if (storedHistory) {
      setSearchHistoryList(JSON.parse(storedHistory));
    }
  }, []);

  const searchKakaoMap = (searchString: string) => {
    const ps = new kakao.maps.services.Places();
    const gs = new kakao.maps.services.Geocoder();
    let psFound = false;
    let gsFound = false;

    ps.keywordSearch(searchString, (data, status, pagination) => {
      if (status === kakao.maps.services.Status.OK) {
        setSearchList(data);
        psFound = true;
      } else if (status === kakao.maps.services.Status.ZERO_RESULT) {
        gs.addressSearch(searchString, (gsData, gsStatus, pagination) => {
          if (gsStatus === kakao.maps.services.Status.OK) {
            setSearchList(gsData);
            gsFound = true;
          }
        });
      }
      if (!psFound && !gsFound) {
        setSearchList([]);
      }
    });   
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    e.target.value.length > 0 && searchKakaoMap(e.target.value);
    setSearchSuggestList(filterHistoryByInput(e.target.value));
  };

  const filterHistoryByInput = (input: string) => {
    const lowercaseInput = input.toLowerCase();

    return searchHistoryList.filter((history) => {
      switch (history.type) {
        case "search":
          return history.search.toLowerCase().includes(lowercaseInput);
        case "placeResult":
          return history.search.place_name
            .toLowerCase()
            .includes(lowercaseInput);
        case "geoResult":
          return history.search.address_name
            .toLowerCase()
            .includes(lowercaseInput);
        default:
          return false;
      }
    });
  };

  const addToHistory = (item: UserSearchHistory) => {
    const existingIndex = searchHistoryList.findIndex((historyItem) => {
      if (item.type === "search" && historyItem.type === "search") {
        return historyItem.search === item.search;
      } else if (
        item.type === "placeResult" &&
        historyItem.type === "placeResult"
      ) {
        return historyItem.search.id === item.search.id;
      } else if (
        item.type === "geoResult" &&
        historyItem.type === "geoResult"
      ) {
        return historyItem.search.address === item.search.address;
      }
      return false;
    });

    let newHistory;

    if (existingIndex !== -1) {
      newHistory = [...searchHistoryList];
      newHistory.splice(existingIndex, 1);
    } else {
      newHistory = [...searchHistoryList];
    }

    newHistory.unshift(item);

    setSearchHistoryList(newHistory);
    localStorage.setItem("userSearchHistory", JSON.stringify(newHistory));
  };

  const removeFromHistory = (index: number) => {
    const newHistory = [...searchHistoryList];
    newHistory.splice(index, 1);
    setSearchHistoryList(newHistory);
    localStorage.setItem("userSearchHistory", JSON.stringify(newHistory));
  };

  const onClickHistory = (searchString: string) => {
    setSearch(searchString);
    searchKakaoMap(searchString);
    setSearchSuggestList(filterHistoryByInput(searchString));
  };

  return (
    <>
      {!openSearch && (
        <SearchButton onClick={() => {props.onClick && props.onClick(); setOpenSearch(true);}}>
          장소 검색
        </SearchButton>
      )}
      {openSearch && (
        <SearchForm
          onSubmit={(e) => {
            e.preventDefault();
            searchKakaoMap(search);
            addToHistory({
              type: "search",
              searchAt: dayjs().toISOString(),
              search,
            });
          }}
        >
          <button
            onClick={() => {
              setSearch("");
              setOpenSearch(false);
            }}
            type="button"
          >
            <BackIcon />
          </button>
          <input
            autoFocus
            value={search}
            onChange={onChange}
            type="text"
            placeholder="주소를 입력해주세요."
          />
          <button type="submit">
            <SearchIcon />
          </button>
        </SearchForm>
      )}
      <>
        {!openSearch ? (
          <>          

          </>
        ) : (
          <>
            {search ? (
              <SearchResult
                setOpenSearch={setOpenSearch}
                searchAddress={props.searchAddress}
                search={search}
                searchList={searchList}
                searchSuggestList={searchSuggestList}
                addToHistory={addToHistory}
              />
            ) : (
              <SearchHistory
                onClickHistory={onClickHistory}
                setOpenSearch={setOpenSearch}
                searchAddress={props.searchAddress}
                searchHistoryList={searchHistoryList}
                removeFromHistory={removeFromHistory}
              />
            )}
          </>
        )}
      </>
    </>
  );
};

export default WorkRegistrationSearch;