import KeepListResData from "data/network/res/myPage/KeepListResData";
import PurchaseListResData from "data/network/res/myPage/PurchaseListResData";
import SalesListResData from "data/network/res/myPage/SalesListResData";
import KeepItemData from "ui/pages/KeepList/KeepItemData";
import MyPurchaseItemData from "ui/pages/MyPurchaseList/MyPurchaseItem/MyPurchaseItemData";
import MySalesItemData from "ui/pages/MySalesList/MySalesItem/MySalesItemData";

export function KeepListResDataToKeepListData(keepListResData:KeepListResData){
    const keepList=new Array<KeepItemData>()
    keepListResData.keepList.forEach(item=>{
        const keepItemData=new KeepItemData();
        keepItemData.productId=item.productId;
        keepItemData.productImage=item.productImage;
        keepItemData.productPrice=item.productPrice;
        keepItemData.productTitle=item.productTitle;
        keepItemData.updDtm=item.updDtm;
        keepItemData.workLatitude=item.workLatitude;
        keepItemData.workLongitude=item.workLongitude;
        keepList.push(keepItemData);
    })
    return keepList;
}

export function resSalesListToSalesListData(salesListResData : SalesListResData) : Array<MySalesItemData>{
    let salesList :Array<MySalesItemData> = new Array<MySalesItemData>();
    salesListResData.salesList?.forEach(item=>{
        const salesItemData : MySalesItemData = new MySalesItemData();
        salesItemData.cropType = item.cropKind;
        salesItemData.salesDtm = item.tradeDate;
        salesItemData.tradePrice = item.tradePrice;
        salesItemData.tradeQnt = item.tradeQuantity;
        salesList.push(salesItemData);
    })
    return salesList;
}

export function resPurchaseListToPurchaseListData(purchaseListResData : PurchaseListResData) : Array<MyPurchaseItemData>{
    let purchaseList :Array<MyPurchaseItemData> = new Array<MyPurchaseItemData>();
    purchaseListResData.purchaseList?.forEach(item=>{
        const purchaseItemData : MyPurchaseItemData = new MyPurchaseItemData();
        purchaseItemData.cropType = item.cropKind;
        purchaseItemData.salesDtm = item.tradeDate;
        purchaseItemData.tradePrice = item.tradePrice;
        purchaseItemData.tradeQnt = item.tradeQuantity;
        purchaseList.push(purchaseItemData);
    })
    return purchaseList;
}