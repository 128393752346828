export default class KeepItemData{
    private _productId!: string;
    private _productImage!: string;
    private _productTitle!: string;
    private _workLatitude!: number;
    private _workLongitude!: number;
    private _updDtm!: Date;
    private _productPrice!: number;
  
    public get productId(): string {
        return this._productId;
    }
    public set productId(value: string) {
        this._productId = value;
    }
    public get productImage(): string {
        return this._productImage;
    }
    public set productImage(value: string) {
        this._productImage = value;
    }
    public get productTitle(): string {
        return this._productTitle;
    }
    public set productTitle(value: string) {
        this._productTitle = value;
    }
    public get workLatitude(): number {
        return this._workLatitude;
    }
    public set workLatitude(value: number) {
        this._workLatitude = value;
    }
    public get workLongitude(): number {
        return this._workLongitude;
    }
    public set workLongitude(value: number) {
        this._workLongitude = value;
    }
    public get updDtm(): Date {
        return this._updDtm;
    }
    public set updDtm(value: Date) {
        this._updDtm = value;
    }
    public get productPrice(): number {
        return this._productPrice;
    }
    public set productPrice(value: number) {
        this._productPrice = value;
    }

}