import { workerLocalDataCode } from "code/Enumerator";
import { isObject } from "common/utils/ObjectUtils";

export default class WorkerLocalData{
    private static _instance: WorkerLocalData;
    private constructor() {}    
    public static get instance(): WorkerLocalData {return this._instance || (this._instance = new this());}

    public get workId(){
        const result = localStorage.getItem(workerLocalDataCode.WORK_ID) as any; 
        return isObject(result)?JSON.parse(result) : result;        
    }
    public set workId(workId: string) {
        if(!workId) workId = "";
        localStorage.setItem(workerLocalDataCode.WORK_ID, isObject(workId)?JSON.stringify(workId):workId);
    }

    public get workerName(){
    const result = localStorage.getItem(workerLocalDataCode.WORKER_NAME) as any; 
    return isObject(result)?JSON.parse(result) : result;        
    }

    public set workerName(workerName: string) {
        if(!workerName) workerName = "";
        localStorage.setItem(workerLocalDataCode.WORKER_NAME, isObject(workerName)?JSON.stringify(workerName):workerName);
    }
}