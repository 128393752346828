export enum EquipmentTypeCode{
    RAYKEY = "레이키",
    BAILER = "베일러",
    LAPPING = "랩핑기",
    MORE = "모어",
    TEDDER = "테더",
    ETC = "기타",
    COMBINE = "복합기",
    HARVESTER = "수확기",
    ETC2 = "기타2"
}

export const stringToEquipmentTypeCode: { [key: string]: EquipmentTypeCode } = {
    "레이키": EquipmentTypeCode.RAYKEY,
    "베일러": EquipmentTypeCode.BAILER,
    "랩핑기": EquipmentTypeCode.LAPPING,
    "모어": EquipmentTypeCode.MORE,
    "테더": EquipmentTypeCode.TEDDER,
    "기타": EquipmentTypeCode.ETC,
    "복합기": EquipmentTypeCode.COMBINE,
    "수확기": EquipmentTypeCode.HARVESTER,
    "기타2": EquipmentTypeCode.ETC2
  };