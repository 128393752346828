export const getTimeAgoString = (date:Date) => {
    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate.getTime() - date.getTime());
    
    const minutes = Math.floor(timeDiff / (1000 * 60));
    if(minutes < 1){
      return '조금 전';
    }
    if (minutes < 60) {
      return `${minutes}분 전`;
    }
    
    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return `${hours}시간 전`;
    }
    
    const days = Math.floor(hours / 24);
    return `${days}일 전`;
  };
  