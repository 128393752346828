import styled from '@emotion/styled';
import { imageWrapperStyle } from 'styles/common.styles';

export const SList = styled.div`
  display: flex;
  flex-direction: column;
  margin : 10px;
  gap: 16px;
`;

export const MyPurchaseListItemContainer = styled.div`
  display: block;
  gap: 12px;
`;

export const MyPurchaseListItemContentBlock = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 15px;

  b {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 87.5% */
    letter-spacing: -0.1px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  span {
    color: var(--gray-400, #999);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
    letter-spacing: -0.1px;
  }

  strong {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 87.5% */
    letter-spacing: -0.1px;
    float: right;
  }
`;
