import AppLayout from 'ui/components/AppLayout/AppLayout';
import MapPage from 'ui/components/AppLayout/MapPage';
import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import KeepList from 'ui/pages/KeepList/KeepList';
import WorkerListPage from 'ui/pages/WorkerListPage/WorkerListPage';
import WorkerMapPage from 'ui/components/AppLayout/WorkerMapPage';
import BuyerMapPage from 'ui/components/AppLayout/BuyerMapPage';
import { EPageMode } from 'code/Enumerator';
import { useNavigate } from 'react-router-dom';
import BridgeManager from 'bridge/BridgeManager';
import BridgeCommands from 'bridge/BridgeCommands';
import PrivacyPolicy from 'ui/pages/AuthPages/PrivacyPolicy';
import WithDrawGuide from 'ui/pages/AuthPages/WithDrawGuide';
import MyPurchaseList from 'ui/pages/MyPurchaseList/MyPurchaseList';
import WorkTeamPlan from 'ui/pages/WorkTeamPlan/WorkTeamPlan';
import ProductRecordDailyList from 'ui/pages/ProductRecordDailyList/ProductRecordDailyList';

const ChatList = lazy(() => import('ui/pages/ChatList/ChatList'));
const ChatDetail = lazy(() => import('ui/pages/ChatDetail/ChatDetail'));
const MyPage = lazy(() => import('ui/pages/MyPage/MyPage'));
const WorkList = lazy(() => import('ui/pages/WorkList/WorkList'));
const ProductList = lazy(() => import('ui/pages/ProductList/ProductList'));
const MyProductList = lazy(() => import('ui/pages/MyProductList/MyProductList'));
const MySalesList = lazy(() => import('ui/pages/MySalesList/MySalesList'));
const FarmList = lazy(() => import('ui/pages/ForageManagement/ForageManagement'));
const BuyerProductList = lazy(() => import('ui/pages/BuyerProductList/BuyerProductList'));
const WorkDetail = lazy(() => import('ui/pages/WorkDetail/WorkDetail'));
const NotifyLandOwner = lazy(() => import('ui/pages/NotifyLandOwner/NotifyLandOwner'));
const WorkRecordGroupAddressList = lazy(() => import('ui/pages/WorkRecordGroupAddressList/WorkRecordGroupAddressList'));
const WorkerWorkRecordGroupAddressList = lazy(() => import('ui/pages/WorkRecordGroupAddressList/WorkerWorkRecordGroupAddressList'));
const WorkRecordDetailGroupDate = lazy(() => import('ui/pages/WorkRecordDetailGroupDate/WorkRecordDetailGroupDate'));
const WorkRecordDailyList = lazy(() => import('ui/pages/WorkRecordDailyList/WorkRecordDailyList'));
const WorkRegistration = lazy(() => import('ui/pages/WorkRegistration/WorkRegistration'));
const ProductDetail = lazy(() => import('ui/pages/ProductDetail/ProductDetail'));
const WorkerManagement = lazy(() => import('ui/pages/WorkerManagement/WorkerManagement'));
const WorkerWorkDetail = lazy(() => import('ui/pages/WorkerWorkDetail/WorkerWorkDetail'));
const Notification = lazy(() => import('ui/pages/Notification/Notification'));
const NotificationDetail = lazy(() => import('ui/pages/Notification/NotificationDetail/NotificationDetail'));
const Splash = lazy(() => import('ui/pages/Splash/Splash'));
const ForWorker = lazy(() => import('ui/pages/ForWorker/ForWorker'));
const ModeSelect = lazy(() => import('ui/pages/ModeSelect/ModeSelect'));
const Login = lazy(() => import('ui/pages/Login/Login'));
const LoginProcess = lazy(() => import('ui/pages/Login/LoginProcess'));
const LoginRegistration = lazy(() => import('ui/pages/Login/LoginRegistration'));
const Main = lazy(() => import('ui/pages/Main/Main'));
const BuyerMain = lazy(() => import('ui/pages/Main/BuyerMain'));
const FarmRegistration = lazy(() => import('ui/pages/FarmRegistration/FarmRegistration'));
const FarmRegistrationComplete = lazy(() => import('ui/pages/FarmRegistrationComplete/FarmRegistrationComplete'));
const SalesList = lazy(() => import('ui/pages/SalesList/SalesList'));
const AgreementList = lazy(() => import('ui/pages/AgreementList/AgreementList'));
const OneOnOneInquiry = lazy(() => import('ui/pages/OneOnOneInquiry/OneOnOneInquiry'));
const OneOnOneInquiryWrite = lazy(() => import('ui/pages/OneOnOneInquiryWrite/OneOnOneInquiryWrite'));
const LoginInfo = lazy(() => import('ui/pages/LoginInfo/LoginInfo'));
const ExcelUpload = lazy(() => import('ui/pages/ExcelUpload/ExcelUpload'));

let bridgeManager: BridgeManager = new BridgeManager();
const AppRoutes = () => {
  const navigate = useNavigate();
  function goToChatDetail(message: any) {
    // sell or buy 구분 필요
    navigate(`/chat/${message.chatType}/${message.chatId}`);
  }
  function goToTagetPage(message: any) {
    // sell or buy 구분 필요
    navigate(message.toUrl);
  }
  useEffect(() => {
    init();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function init() {
    if (bridgeManager.isApp()) {
      bridgeManager.init();
      bridgeManager.setEventListener(BridgeCommands.BRG_ON_NOTIFICATION_CLICKED, goToChatDetail);
      bridgeManager.setEventListener(BridgeCommands.BRG_ON_SEND_NAVIGATION_URL_RECEIVED, goToTagetPage);
    }
  }
  return (
    <Suspense fallback={<div />}>
      <Routes>
        {/* 판매자 메인 */}
        <Route path="/main" element={<Main />} />
        {/* 구매자 메인 */}
        <Route path="/buyerMain" element={<BuyerMain />} />
        {/* 스플래쉬 */}
        <Route path="/" element={<Splash />} />
        {/* 모드 선택 */}
        <Route path="/mode" element={<ModeSelect />} />
        {/* 로그인 */}
        <Route path="/login" element={<Login />} />
        {/* 로그인 프로세스 */}
        <Route path="/login/process/:snsType" element={<LoginProcess />} />
        {/* 로그인 등록 */}
        <Route path="/login/registration" element={<LoginRegistration />} />
        {/* 작업자 링크*/}
        <Route path="/forWorker/:workId/:workerName" element={<ForWorker />} />
        {/* 개인정보방침 */}
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        {/* 회원탈퇴 가이드 */}
        <Route path="/withdrawGuide" element={<WithDrawGuide />} /> 
        {/* 엑셀 업로드 */}
        <Route path="/excel" element={<ExcelUpload />} />      

        {/* Bottom Action Sheet에 페이지가 들어가는 경우 */}
        <Route path="/" element={<MapPage />}>
          {/* 작업목록 */}
          <Route path="/work" element={<WorkList />} />
          {/* 작업상세 */}
          <Route path="/work/detail/" element={<WorkDetail />} />
          {/* 상품목록 */}
          <Route path="/products" element={<ProductList />} />
          {/* 작업등록 */}
          <Route path="/work/registration" element={<WorkRegistration />} />
          {/* 작업수정 */}
          <Route path="/work/update" element={<WorkRegistration />} />
          {/* 토지주 알림 */}
          <Route path="/work/notifyLandOwner" element={<NotifyLandOwner />} />
          {/* 필지 목록 */}
          <Route path="/work/workRecordGroupAddressList" element={<WorkRecordGroupAddressList />} />
          {/* 작업상세 날짜별 상세 이력 */}
          <Route path="/work/workRecordDailyList" element={<WorkRecordDailyList />} />
          {/* 날짜별 작업 이력 */}
          <Route path="/work/workRecordDetailGroupDate" element={<WorkRecordDetailGroupDate />} /> 
          {/* 팀 작업 게획 */}
          <Route path="/work/workTeamPlan" element={<WorkTeamPlan />} />
          {/* 팀 작업 게획 */}
          <Route path="/work/workTeamUpdate" element={<WorkTeamPlan />} />
        </Route>

        {/* Header만 있는 레이아웃 */}
        <Route path="/" element={<AppLayout />}>
          {/* 채팅 목록 */}
          <Route path="/chatList" element={<ChatList />} />
          {/* 마이 페이지 */}
          <Route path="/myPage" element={<MyPage />} />
          {/* 찜 목록 */}
          <Route path="/my/jjimList" element={<KeepList />} />
          {/* 구매 목록 */}
          <Route path="/my/myPurchaseList" element={<MyPurchaseList />} />
          {/* 상품 상세 */}
          <Route path="/product/:productId" element={<ProductDetail />} />
          {/* 작업자 페이지 */}
          <Route path="/work/workerList" element={<WorkerListPage workId={''} wokerListData={[]} />} />
          {/* 작업자 관리 */}
          <Route path="/work/management" element={<WorkerManagement workId={''} wokerListData={[]} />} />
          {/* 이용약관 */}
          <Route path="/agreementList" element={<AgreementList />} />
          {/* 1:1 문의 */}
          <Route path="/inquiry" element={<OneOnOneInquiry />} />
          {/* 1:1 문의 작성 */}
          <Route path="/inquiry/write" element={<OneOnOneInquiryWrite />} />
          {/* 1:1 문의 수정 */}
          <Route path="/inquiryUpdate/:inquiryId" element={<OneOnOneInquiryWrite />} />
          {/* 공지사항 */}
          <Route path="/notice" element={<Notification />} />
          {/* 공지사항 상세 */}
          <Route path="/notice/:notificationId" element={<NotificationDetail />} />
          {/* 상품관리 */}
          <Route path="/my/myProductList" element={<MyProductList />} />
          {/* 판매내역 */}
          <Route path="/my/mySalesList" element={<MySalesList />} />
          {/* 로그인 정보 */}
          <Route path="/login-info" element={<LoginInfo />} />
          {/* 상품상세 날짜별 이력 */}
          <Route path="/productRecordDailyList" element={<ProductRecordDailyList  />} />
  
        </Route>

        {/* 상품 채팅 */}
        <Route path="/chat/prdchat/:chatId" element={<ChatDetail chatType="prdchat" />} />
        {/* 구매 채팅 */}
        <Route path="/chat/buychat/:chatId" element={<ChatDetail chatType="buychat" />} />

        {/* 작업자 작업 페이지 */}
        <Route path="/" element={<WorkerMapPage />}>
          {/* 작업자 작업 상세 */}
          <Route path="/workerWork" element={<WorkerWorkDetail />} />
          <Route path="/workerWork/:workId/:workerName" element={<WorkerWorkDetail />} />
          {/* 작업자 필지 목록 */}
          <Route path="/workerWork/workerWorkRecordGroupAddressList" element={<WorkerWorkRecordGroupAddressList />} />
        </Route>

        {/* 구매자 페이지 */}
        <Route path="/" element={<BuyerMapPage />}>
          {/* 급이 목록 */}
          <Route path="/farm" element={<FarmList />} />
          {/* 구매자 매매 목록 */}
          <Route path="/buyer/products" element={<BuyerProductList />} />
        </Route>

        {/* 농장 등록 */}
        <Route path="/farm/registration" element={<FarmRegistration pageMode={EPageMode.CREATE} />} />
        {/* 농장 상세 및 수정 */}
        <Route path="/farm/edit" element={<FarmRegistration pageMode={EPageMode.UPDATE} />} />
        {/* 농장 등록 완료 */}
        <Route path="/farm/registration/complete" element={<FarmRegistrationComplete />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
