import { stringToCropTypeCode } from "code/CropTypeCode";
import { stringToWorkAvaliableStatusCode } from "code/WorkAvaliableStatusCode";
import { stringToWorkTypeCode } from "code/WorkTypeCode";
import { stringToLandMap } from "common/utils/StringUtils";
import LandItemReqData from "data/network/req/work/LandRegistrationItemReqData";
import WorkRegistrationReqData from "data/network/req/work/WorkRegistrationReqData";
import WorkerRegistrationItemReqData from "data/network/req/worker/WorkerRegistrationItemReqData";
import WorkDetailResData from "data/network/res/work/WorkDetailResData";
import WorkItemResData from "data/network/res/work/WorkItemResData";
import WorkRecordGroupDateItemData from "ui/components/Work/WorkRecordGroupDateList/WorkRecordGroupDateItemData";
import WorkDetailData from "ui/pages/WorkDetail/WorkDetailData";
import WorkMemoItemData from "ui/pages/WorkDetail/WorkMemoItemData";
import WorkRecordGroupAddressItemData from "ui/pages/WorkRecordGroupAddressList/WorkRecordGroupAddressListItemData";
import WorkerItemData from "ui/pages/WorkDetail/WorkerList/WorkerItemData";
import WorkItemData from "ui/pages/WorkList/WorkItemData";
import WorkEquipmentData from "ui/pages/WorkRegistration/WorkEquipment/WorkEquipmentData";
import WorkLandItemData from "ui/pages/WorkRegistration/WorkLandList/WorkLandItemData";
import WorkRegistrationData from "ui/pages/WorkRegistration/WorkRegistrationData";
import PolygonData from "ui/components/MapComponent/PolygonData";
import ExcelUploadItemData from "ui/pages/ExcelUpload/ExcelUploadItemData";
import EquipmentItemReqData from "data/network/req/myPage/EquipmentItemReqData";
import EquipmentItemData from "ui/pages/EquipmentManagement/EquipmentItemData";
import EquipmentDistanceUpdateReqData from "data/network/req/myPage/EquipmentDistanceUpdateReqData";
import SubWorkListResData from "data/network/res/work/SubWorkListResData";
import MasterWorkDetailData from "ui/pages/WorkDetail/MasterWorkDetailData";
import WorkTeamPlanData from "ui/pages/WorkTeamPlan/WorkTeamPlanData";
import WorkRecordForProductItemData from "ui/pages/WorkDetail/WorkRecordForProductItemData";
import MySubWorkLandItemData from "ui/pages/WorkDetail/MySubWorkLandItemData";

export function workRegistrationDataToWorkRegistrationReqData(workRegistrationData: WorkRegistrationData) {
  const workRegistrationReqData: WorkRegistrationReqData = new WorkRegistrationReqData();
  const landListReqData: Array<LandItemReqData> = new Array<LandItemReqData>();
  const workerRegistrationListReqData: Array<WorkerRegistrationItemReqData> = Array<WorkerRegistrationItemReqData>();
  workRegistrationData.workLandListData.forEach((item) => {
    const landItemReqData: LandItemReqData = new LandItemReqData();
    landItemReqData.landAddress = item.landFullAddress!;
    landItemReqData.landLatitude = item.landLatitude;
    landItemReqData.landLongitude = item.landLongitude;
    landItemReqData.landSize = item.landSize;
    landItemReqData.landMap = JSON.stringify(item.landMap);
    landItemReqData.workTeam = item.workTeam;
    landItemReqData.landOwner = item.landOwner;
    landItemReqData.landOwnerTel = item.landOwnerTel;
    landListReqData.push(landItemReqData);
  });
  workRegistrationReqData.workAddress1 = workRegistrationData.workAddress1;
  workRegistrationReqData.workAddress2 = workRegistrationData.workAddress2;
  workRegistrationReqData.workAddress3 = workRegistrationData.workAddress3;
  workRegistrationReqData.landList = landListReqData;
  workRegistrationReqData.workLatitude = landListReqData[0].landLatitude;
  workRegistrationReqData.workLongitude = landListReqData[0].landLongitude;
  workRegistrationReqData.workStartDate = workRegistrationData.workStartDate;
  workRegistrationReqData.workEndDate = workRegistrationData.workEndDate;
  workRegistrationReqData.cropKind = workRegistrationData.cropType;
  workRegistrationReqData.workType = workRegistrationData.workType;
  // debugger;
  workRegistrationData.workEquipmentData.workerList.forEach((item) => {
    const workerRegistrationItemReqData: WorkerRegistrationItemReqData = new WorkerRegistrationItemReqData();
    workerRegistrationItemReqData.equipmentName = item.equipmentName;
    workerRegistrationItemReqData.equipmentType = item.equipmentType;
    workerRegistrationItemReqData.workerName = item.workerName.replace(/ /g, '');
    workerRegistrationItemReqData.workTeam = item.workTeam;
    workerRegistrationListReqData.push(workerRegistrationItemReqData);
  });
  workRegistrationReqData.workerList = workerRegistrationListReqData;
  return workRegistrationReqData;
}

export function workListResDataToWorkListData(workListResData: Array<WorkItemResData>): Array<WorkItemData> {
  const workList = new Array<WorkItemData>();
  workListResData && workListResData.forEach((item) => {
    const workItemData = new WorkItemData();
    workItemData.workId = item.workId;
    workItemData.workStartDate = item.workStartDate;
    workItemData.workEndDate = item.workEndDate;
    workItemData.workAddress1 = item.workAddress1;
    workItemData.workAddress2 = item.workAddress2;
    workItemData.workAddress3 = item.workAddress3;
    workItemData.workLatitude = item.workLatitude;
    workItemData.workLongitude = item.workLongitude;
    workItemData.cropType = item.cropKind;
    workItemData.userProfileImage = item.userProfileImage;
    workItemData.userName = item.userName;
    workItemData.workLandSize = item.workLandSize;
    workItemData.workOutput = item.workOutput;
    workItemData.totalLandCount = item.totalLandCount;
    workItemData.totalLandProgressCount = item.totalLandProgressCount;
    workItemData.masterWorkId = item.masterWorkId;
    workList.push(workItemData);
  });
  return workList;
}

export function workDetailResDataToWorkDetailData(workDetailResData: WorkDetailResData) {
  const workerList = new Array<WorkerItemData>();
  const workHistoryByAddressListData = new Array<WorkRecordGroupAddressItemData>();
  const workDetailData = new WorkDetailData();
  const WorkRecordGroupDateListData = new Array<WorkRecordGroupDateItemData>();
  const workRecordListForExcelDownload = new Array<WorkRecordForProductItemData>();
  const workMemoListData = new Array<WorkMemoItemData>();
  const mySubWorkLandListData = new Array<MySubWorkLandItemData>();
  workDetailData.productId = workDetailResData.productId;
  workDetailData.workLatitude = workDetailResData.workLatitude;
  workDetailData.workLongitude = workDetailResData.workLongitude;
  workDetailData.workStartDate = workDetailResData.workStartDate;
  workDetailData.workEndDate = workDetailResData.workEndDate;
  workDetailData.workAddress1 = workDetailResData.workAddress1;
  workDetailData.workAddress2 = workDetailResData.workAddress2;
  workDetailData.workAddress3 = workDetailResData.workAddress3;
  workDetailData.cropType = workDetailResData.cropKind;
  workDetailData.workType = workDetailResData.workType;
  workDetailData.workRecordDistance = workDetailResData.workRecordDistance;
  workDetailResData.workerList?.forEach((item) => {
    const wokerItemData = new WorkerItemData();
    wokerItemData.equipmentName = item.equipmentName;
    wokerItemData.equipmentType = item.equipmentType;
    wokerItemData.workerName = item.workerName;
    wokerItemData.workTeam = item.workTeam!;
    workerList.push(wokerItemData);
  });
  workDetailResData.workRecordGroupDateList?.forEach((item) => {
    const workRecordGroupDateItemData: WorkRecordGroupDateItemData = new WorkRecordGroupDateItemData();
    workRecordGroupDateItemData.workDate = item.workDate;
    workRecordGroupDateItemData.amHumidity = item.amHumidity;
    workRecordGroupDateItemData.amWeather = item.amWeather;
    workRecordGroupDateItemData.pmWeather = item.pmWeather;
    workRecordGroupDateItemData.pmHumidity = item.pmHumidity;
    workRecordGroupDateItemData.lowTemp = item.lowTemp;
    workRecordGroupDateItemData.highTemp = item.highTemp;
    workRecordGroupDateItemData.dateOutput = item.recordOut;
    WorkRecordGroupDateListData.push(workRecordGroupDateItemData);
  });
  workDetailResData.workRecordListForExcelDownload?.forEach((item) => {
    const workRecordForProductItemData : WorkRecordForProductItemData = new WorkRecordForProductItemData();
    workRecordForProductItemData.workDate = item.workDate;
    workRecordForProductItemData.amHumidity = item.amHumidity;
    workRecordForProductItemData.amWeather = item.amWeather;
    workRecordForProductItemData.pmWeather = item.pmWeather;
    workRecordForProductItemData.pmHumidity = item.pmHumidity;
    workRecordForProductItemData.lowTemp = item.lowTemp;
    workRecordForProductItemData.highTemp = item.highTemp;
    workRecordForProductItemData.recordOut = item.recordOut;
    workRecordForProductItemData.landAddress = item.landAddress;
    workRecordForProductItemData.equipmentName = item.equipmentName;
    workRecordForProductItemData.equipmentType = item.equipmentType;
    workRecordForProductItemData.workerName = item.workerName;
    workRecordListForExcelDownload.push(workRecordForProductItemData);
  });
  workDetailResData.landList?.forEach((item) => {
    const workRecordGroupAddressItemData = new WorkRecordGroupAddressItemData();
    workRecordGroupAddressItemData.landAddress = item.landAddress;
    workRecordGroupAddressItemData.landOutput = item.landOutput;
    workRecordGroupAddressItemData.landSize = item.landSize;
    workRecordGroupAddressItemData.landTradeDone = item.landTradeDone;
    workRecordGroupAddressItemData.landLatitude = item.landLatitude;
    workRecordGroupAddressItemData.landLongitude = item.landLongitude;
    workRecordGroupAddressItemData.landMap = stringToLandMap(item.landMap);
    workRecordGroupAddressItemData.workTeam = item.workTeam;
    workRecordGroupAddressItemData.landProgress = item.landProgress;
    workRecordGroupAddressItemData.landOwner = item.landOwner;
    workRecordGroupAddressItemData.landOwnerTel = item.landOwnerTel;
    workHistoryByAddressListData.push(workRecordGroupAddressItemData);
  });
  workDetailResData.workMemoList?.forEach((item) => {
    const workMemoItemData = new WorkMemoItemData();
    workMemoItemData.workId = item.workId;
    workMemoItemData.commentLatitude = item.commentLatitude;
    workMemoItemData.commentLongitude = item.commentLongitude;
    workMemoItemData.landAddress = item.landAddress;
    workMemoItemData.workerName = item.workerName;
    workMemoItemData.comment = item.comment;
    workMemoItemData.availableStatus = stringToWorkAvaliableStatusCode[item.availableStatus];
    workMemoListData.push(workMemoItemData);
  });
  workDetailResData.mySubWorkLandList?.forEach((item) => {
    const mySubWorkLandItemData = new MySubWorkLandItemData();
    mySubWorkLandItemData.subWorkId = item.subWorkId;
    mySubWorkLandItemData.landAddress = item.landAddress;
    mySubWorkLandListData.push(mySubWorkLandItemData);
  });

  workDetailData.workerList = workerList;
  workDetailData.workRecordGroupDateList = WorkRecordGroupDateListData;
  workDetailData.workRecordListForExcelDownload = workRecordListForExcelDownload;
  workDetailData.landList = workHistoryByAddressListData;
  workDetailData.workMemoList = workMemoListData;
  workDetailData.masterWorkId = workDetailResData.masterWorkId;
  workDetailData.mySubWorkLandList = mySubWorkLandListData;
  return workDetailData;
}


export function workDetailResDataToMasterWorkDetailData(workDetailResData: WorkDetailResData) {
  const workerList = new Array<WorkerItemData>();
  const workHistoryByAddressListData = new Array<WorkRecordGroupAddressItemData>();
  const masterWorkDetailData = new MasterWorkDetailData();
  masterWorkDetailData.workStartDate = workDetailResData.workStartDate;
  masterWorkDetailData.workEndDate = workDetailResData.workEndDate;
  workDetailResData.workerList?.forEach((item) => {
    const wokerItemData = new WorkerItemData();
    wokerItemData.equipmentName = item.equipmentName;
    wokerItemData.equipmentType = item.equipmentType;
    wokerItemData.workerName = item.workerName;
    wokerItemData.workTeam = item.workTeam!;
    workerList.push(wokerItemData);
  });
  workDetailResData.landList?.forEach((item) => {
    const workRecordGroupAddressItemData = new WorkRecordGroupAddressItemData();
    workRecordGroupAddressItemData.landAddress = item.landAddress;
    workRecordGroupAddressItemData.landOutput = item.landOutput;
    workRecordGroupAddressItemData.landSize = item.landSize;
    workRecordGroupAddressItemData.landTradeDone = item.landTradeDone;
    workRecordGroupAddressItemData.landLatitude = item.landLatitude;
    workRecordGroupAddressItemData.landLongitude = item.landLongitude;
    workRecordGroupAddressItemData.landMap = stringToLandMap(item.landMap);
    workRecordGroupAddressItemData.workTeam = item.workTeam;
    workRecordGroupAddressItemData.landProgress = item.landProgress;
    workHistoryByAddressListData.push(workRecordGroupAddressItemData);
  });
  masterWorkDetailData.workerList = workerList;
  masterWorkDetailData.landList = workHistoryByAddressListData;
  return masterWorkDetailData;
}

export function workDetailToWorkRegistrationData(workDetailData: WorkDetailData): WorkRegistrationData {
  const workRegistrationData = new WorkRegistrationData();
  workRegistrationData.workId = workDetailData.workId;
  workRegistrationData.workAddress1 = workDetailData.workAddress1;
  workRegistrationData.workAddress2 = workDetailData.workAddress2;
  workRegistrationData.workAddress3 = workDetailData.workAddress3;
  let newLandList = new Array<WorkLandItemData>();
  workDetailData.landList?.forEach((item) => {    
    newLandList.push(workRecordGroupAddressItemDataToUpdateWorkLandItemData(item));
    }
  );
  workRegistrationData.workLandListData = newLandList;
  workRegistrationData.workStartDate = workDetailData.workStartDate;
  workRegistrationData.workEndDate = workDetailData.workEndDate;
  workRegistrationData.cropType = stringToCropTypeCode[workDetailData.cropType];
  workRegistrationData.workType = stringToWorkTypeCode[workDetailData.workType];
  workRegistrationData.workEquipmentData = new WorkEquipmentData();
  let newWorkerList = Array<WorkerItemData>();
  workDetailData.workerList?.forEach((worker) => {
    const workerItemData = new WorkerItemData();
    workerItemData.equipmentName = worker.equipmentName;
    workerItemData.equipmentType = worker.equipmentType;
    workerItemData.workerName = worker.workerName;
    workerItemData.workTeam = worker.workTeam;
    newWorkerList.push(workerItemData);
  });
  workRegistrationData.workEquipmentData.workerList = newWorkerList;
  let teamList = new Array<string>();
  workDetailData.workerList.forEach((worker) => {
    if (!teamList.includes(worker.workTeam!)) {
      teamList.push(worker.workTeam!);
    }
  });
  workRegistrationData.workTeamList = teamList.sort((a, b) => a.localeCompare(b));
  return workRegistrationData;
}
export function workDetailToWorkPlanOriginData(workDetailData: WorkDetailData): WorkRegistrationData {
  const workRegistrationData = new WorkRegistrationData();
  workRegistrationData.workId = workDetailData.workId;
  workRegistrationData.workAddress1 = workDetailData.workAddress1;
  workRegistrationData.workAddress2 = workDetailData.workAddress2;
  workRegistrationData.workAddress3 = workDetailData.workAddress3;
  let plannedLandList = workDetailData.landList?.filter((item) => item.landProgress === "N");
  workRegistrationData.workLandListData = plannedLandList?.map((item) => {    
    return workRecordGroupAddressItemDataToUpdateWorkLandItemData(item);
    }
  );
  workRegistrationData.workStartDate = workDetailData.workStartDate;
  workRegistrationData.workEndDate = workDetailData.workEndDate;
  workRegistrationData.cropType = stringToCropTypeCode[workDetailData.cropType];
  workRegistrationData.workType = stringToWorkTypeCode[workDetailData.workType];
  workRegistrationData.workEquipmentData = new WorkEquipmentData();
  workRegistrationData.workEquipmentData.workerList = workDetailData.workerList?.map((worker) => {
    const workerItemData = new WorkerItemData();
    workerItemData.equipmentName = worker.equipmentName;
    workerItemData.equipmentType = worker.equipmentType;
    workerItemData.workerName = worker.workerName;
    workerItemData.workTeam = worker.workTeam;
    return workerItemData;
  });
  let teamList = new Array<string>();
  workDetailData.workerList?.forEach((worker) => {
    if (!teamList.includes(worker.workTeam!)) {
      teamList.push(worker.workTeam!);
    }
  });
  workRegistrationData.workTeamList = teamList.sort((a, b) => a.localeCompare(b));
  return workRegistrationData;
}

export function workDetailToWorkPlanData(workDetailData: WorkDetailData): WorkTeamPlanData {
  const workTeamPlanData = new WorkTeamPlanData(); 
  workTeamPlanData.workStartDate = workDetailData.workStartDate;
  workTeamPlanData.workEndDate = workDetailData.workEndDate;
  workTeamPlanData.workerList = new Array<WorkerItemData>();
  workTeamPlanData.workerList = workDetailData.workerList?.map((worker) => {
    const workerItemData = new WorkerItemData();
    workerItemData.equipmentName = worker.equipmentName;
    workerItemData.equipmentType = worker.equipmentType;
    workerItemData.workerName = worker.workerName;
    workerItemData.workTeam = worker.workTeam;
    return workerItemData;
  }); 
  return workTeamPlanData;
}

export function workDetailToWorkPlanDetailData(workDetailData: WorkDetailData): WorkTeamPlanData {
  const workTeamPlanData = new WorkTeamPlanData(); 
  workTeamPlanData.workStartDate = workDetailData.workStartDate;
  workTeamPlanData.workEndDate = workDetailData.workEndDate;
  workTeamPlanData.workerList = new Array<WorkerItemData>();
  workTeamPlanData.workerList = workDetailData.workerList?.map((worker) => {
    const workerItemData = new WorkerItemData();
    workerItemData.equipmentName = worker.equipmentName;
    workerItemData.equipmentType = worker.equipmentType;
    workerItemData.workerName = worker.workerName;
    workerItemData.workTeam = worker.workTeam;
    return workerItemData;
  });
  workTeamPlanData.landList = workDetailData.landList?.map((item) => {    
    return workRecordGroupAddressItemDataToUpdateWorkLandItemData(item);
  });
  return workTeamPlanData;
}

export function workRecordGroupAddressItemDataToUpdateWorkLandItemData(workRecordGroupAddressItemData: WorkRecordGroupAddressItemData): WorkLandItemData {
  const workLandItemData: WorkLandItemData = new WorkLandItemData();
  workLandItemData.landLatitude = workRecordGroupAddressItemData.landLatitude;
  workLandItemData.landLongitude = workRecordGroupAddressItemData.landLongitude;
  workLandItemData.landSize = workRecordGroupAddressItemData.landSize;
  workLandItemData.landMap = workRecordGroupAddressItemData.landMap;
  workLandItemData.landFullAddress = workRecordGroupAddressItemData.landAddress;
  workLandItemData.workTeam = workRecordGroupAddressItemData.workTeam;
  return workLandItemData;
}

export function workLandItemDataToWorkRecordGroupAddressItemData(workLandItemData: WorkLandItemData): WorkRecordGroupAddressItemData {
  const workRecordGroupAddressItemData = new WorkRecordGroupAddressItemData();

  workRecordGroupAddressItemData.landAddress = workLandItemData.landAddress;
  workRecordGroupAddressItemData.landLatitude = workLandItemData.landLatitude;
  workRecordGroupAddressItemData.landLongitude = workLandItemData.landLongitude;
  workRecordGroupAddressItemData.landMap = workLandItemData.landMap;
  workRecordGroupAddressItemData.landOutput = 0;
  workRecordGroupAddressItemData.landSize = workLandItemData.landSize;
  workRecordGroupAddressItemData.workRecordLogList = [];

  return workRecordGroupAddressItemData;
}

interface Point {
  longitude: number;
  latitude: number;
}

export function arrayToObject(data: number[][]): Point[]{
  return data.map(item => ({ longitude: item[0], latitude: item[1] }));
}

export function objectToArray(data: Point[]): number[][] {
  return data.map(item => [item.longitude, item.latitude]);
}

export const firebaseDataToPolygonData = (landAddressList: Array<any>): Array<PolygonData> => {
  const polygonDataList: Array<PolygonData> = [];
  landAddressList.forEach(landAddress => {
    const polygonData = new PolygonData();
    polygonData.polygonAddress = landAddress.data.polygonAddress;
    polygonData.polygonColor = landAddress.data.polygonColor;
    polygonData.pointList = stringToLandMap(landAddress.data.pointList);
    polygonData.polygonLineColor = landAddress.data.polygonLineColor;
    polygonData.polygonTeam = landAddress.data.polygonTeam;
    polygonDataList.push(polygonData);
  });
  return polygonDataList;
};

export const excelUploadDataToWorkRegistrationData = (excelData: Array<ExcelUploadItemData>): WorkRegistrationData => {
  const workRegistrationData = new WorkRegistrationData();
  workRegistrationData.workLandListData = excelData.map((item) => {
    const workLandItemData = new WorkLandItemData();
    workLandItemData.landFullAddress = item.landAddress;
    workLandItemData.landLatitude = item.landLatitude!;
    workLandItemData.landLongitude = item.landLongitude!;
    workLandItemData.landSize = item.landSize!;
    workLandItemData.landMap = stringToLandMap(item.landMap!);
    workLandItemData.landOwner = item.landOwner;
    workLandItemData.landOwnerTel = item.landOwnerTel;
    workLandItemData.workTeam = item.workTeam;
    return workLandItemData;
  });
  workRegistrationData.workLandListData.sort((a, b) => a.workTeam.localeCompare(b.workTeam));
  return workRegistrationData;
}

export const equipmentListToEquipmentDistanceUpdateReqData = (equipmentList: Array<EquipmentItemData>): EquipmentDistanceUpdateReqData => {
  const equipmentDistanceUpdateData = new EquipmentDistanceUpdateReqData();
  equipmentList.forEach((item) => {
    const equipmentItemReqData = new EquipmentItemReqData();
    equipmentItemReqData.equipmentType = item.equipmentType;
    equipmentItemReqData.equipmentName = item.equipmentName;
    equipmentItemReqData.equipmentDistance = item.equipmentDistance;
    equipmentDistanceUpdateData.equipmentList.push(equipmentItemReqData);
  });
  return equipmentDistanceUpdateData;
}

export const workPlanRegistrationDataToWorkRegistrationReqData = (workPlanRegistrationData: WorkRegistrationData): WorkRegistrationReqData => {
  const workRegistrationReqData = new WorkRegistrationReqData();
  const landListReqData: Array<LandItemReqData> = new Array<LandItemReqData>();
  const workerRegistrationListReqData: Array<WorkerRegistrationItemReqData> = Array<WorkerRegistrationItemReqData>();
  workPlanRegistrationData.workLandListData.forEach((item) => {
    const landItemReqData = new LandItemReqData();
    landItemReqData.landAddress = item.landFullAddress!;
    landItemReqData.landLatitude = item.landLatitude;
    landItemReqData.landLongitude = item.landLongitude;
    landItemReqData.landSize = item.landSize;
    landItemReqData.landMap = JSON.stringify(item.landMap);
    landItemReqData.workTeam = item.workTeam;
    landListReqData.push(landItemReqData);
  });
  workRegistrationReqData.landList = landListReqData;
  workRegistrationReqData.workAddress1 = workPlanRegistrationData.workAddress1;
  workRegistrationReqData.workAddress2 = workPlanRegistrationData.workAddress2;
  workRegistrationReqData.workAddress3 = workPlanRegistrationData.workAddress3;
  workRegistrationReqData.workLatitude = workPlanRegistrationData.workLandListData[0].landLatitude;
  workRegistrationReqData.workLongitude = workPlanRegistrationData.workLandListData[0].landLongitude;
  workRegistrationReqData.workStartDate = workPlanRegistrationData.workStartDate;
  workRegistrationReqData.workEndDate = workPlanRegistrationData.workEndDate;
  workRegistrationReqData.cropKind = workPlanRegistrationData.cropType;
  workRegistrationReqData.workType = workPlanRegistrationData.workType;
  workRegistrationReqData.masterWorkId = workPlanRegistrationData.masterWorkId;
  workPlanRegistrationData.workEquipmentData.workerList.forEach((item) => {
    const workerRegistrationItemReqData = new WorkerRegistrationItemReqData();
    workerRegistrationItemReqData.equipmentName = item.equipmentName;
    workerRegistrationItemReqData.equipmentType = item.equipmentType;
    workerRegistrationItemReqData.workerName = item.workerName.replace(/ /g, '');;
    workerRegistrationItemReqData.workTeam = item.workTeam;
    workerRegistrationListReqData.push(workerRegistrationItemReqData);
  });
  workRegistrationReqData.workerList = workerRegistrationListReqData;
  return workRegistrationReqData;
}

export const subWorkListResDataToSubWorkListData = (subWorkListResData: SubWorkListResData): Array<WorkItemData> => {
  const subWorkList = new Array<WorkItemData>();
  subWorkListResData.subWorkList?.forEach((item) => {
    const workItemData = new WorkItemData();
    workItemData.workId = item.workId;
    workItemData.workStartDate = item.workStartDate;
    workItemData.workEndDate = item.workEndDate;
    workItemData.workAddress1 = item.workAddress1;
    workItemData.workAddress2 = item.workAddress2;
    workItemData.workAddress3 = item.workAddress3;
    workItemData.workLatitude = item.workLatitude;
    workItemData.workLongitude = item.workLongitude;
    workItemData.userProfileImage = item.userProfileImage;
    workItemData.userName = item.userName;
    workItemData.workLandSize = item.workLandSize;
    workItemData.workOutput = item.workOutput;
    workItemData.totalLandCount = item.totalLandCount;
    workItemData.totalLandProgressCount = item.totalLandProgressCount;
    workItemData.masterWorkId = item.masterWorkId;
    subWorkList.push(workItemData);
  });
  return subWorkList;
}

// export const otherSubWorkListResDataToSubWorkListData = (otherSubWorkListResData : OtherSubWorkListResData) : Array<OtherSubWorkItemResData> => {
//   const otherSubWorkList = new Array<OtherSubWorkItemResData>();
//   otherSubWorkListResData.otherSubWorkList?.forEach((item) => {
//     const otherSubWorkItemData = new OtherSubWorkItemResData();
//     otherSubWorkItemData.workStartDate = item.workStartDate;
//     otherSubWorkItemData.workEndDate = item.workEndDate;
//     otherSubWorkItemData.subLandList = item.subLandList;
//     otherSubWorkList.push(otherSubWorkItemData);
//   });

//   return otherSubWorkList;
// }