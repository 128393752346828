import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class FarmListRequest extends BaseRequest {
    private readonly URL_FARM_BASE = `${BaseService.API_URL}/farm`;
    private readonly URL_FARM_LIST = `${this.URL_FARM_BASE}/farmList`;

    getURL(): string {
        return this.URL_FARM_LIST;
    }    
}
