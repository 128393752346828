import { SubTitle } from 'styles/common.styles';
import { WorkRegistarionContainer } from '../WorkRegistration.styles';
import { DatePickerGroup } from 'styles/form.styles';

import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { useCalendarModal } from 'hooks/useModal';
import { useRecoilValue } from 'recoil';
import { selectedDateState } from 'atoms/selectedDate';
import { formatDate } from 'common/utils/formatDate';
import { CalendarModalProps } from 'ui/components/Modals/CalendarModal/CalendarModal';

interface WorkScheduleDateProps {
  workId?: string;
}

const WorkScheduleDate = (props : WorkScheduleDateProps) => {
  const { openCalendarModal } = useCalendarModal();
  const selectedDate = useRecoilValue(selectedDateState);

  function handleOnCalendarBtnClick(){
    const modalProps: Omit<CalendarModalProps, "onClose"> = {
      workId: props.workId ?? "",
    };
    openCalendarModal(modalProps);
  }
  
  return (
    <WorkRegistarionContainer>
      <SubTitle>작업 예정일</SubTitle>
      <DatePickerGroup>
        <div onClick={handleOnCalendarBtnClick}>
          <span>{formatDate(selectedDate.startDate)}</span>
          <CalendarIcon />
        </div>
        <span>~</span>
        <div onClick={handleOnCalendarBtnClick}>
          <span>{formatDate(selectedDate.endDate)}</span>
          <CalendarIcon />
        </div>
      </DatePickerGroup>
    </WorkRegistarionContainer>
  );
};

export default WorkScheduleDate;
