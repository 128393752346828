import styled from '@emotion/styled';

export const Container = styled.div`
  margin: 40px;
`;

export const WorkContainer = styled.div`
  margin-bottom: 15px
`;

export const WorkInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: 3px solid var(--grenn-100, #44c431);
  border-radius: 40px;
  padding: 30px;
  margin-bottom: 10px;

  & > div:nth-of-type(2),
  & > div:nth-of-type(3) {
    flex-direction: column !important;
    align-items: flex-start !important;
    
    & > div:last-of-type {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
`;

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
`;

export const SubtitleBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CategoryTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-500, #666)
`

export const WeatherBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  > p {
    margin-right: 10px;
  }

  .weather {
    display: flex;
    flex-direction: column;
    margin-right: 10px;

    svg {
      width: 30px;
      height: 30px;
      cursor: unset;
    }
  }

  .Celsius {
    display: flex;
    align-items: center;  
  }
`;

