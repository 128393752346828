import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class WorkRecordDetailGroupDateRequest extends BaseRequest{
    private readonly URL_WORKRECORD_BASE = `${BaseService.API_URL}/workRecord`;
    private readonly URL_WORKRECORD_DETAIL_GROUP_DATE = `${this.URL_WORKRECORD_BASE}/workRecordDetailGroupDate`;

    getURL(): string {
        return this.URL_WORKRECORD_DETAIL_GROUP_DATE;
    }
}