import styled from '@emotion/styled';

export const SearchButton = styled.button`
  position: fixed;
  top: 92px;
  min-width: 318px;
  max-width: 1074px;
  width: calc(100% - 6px);
  margin: 0px 3px;
  overflow: hidden;
  display: block;
  align-items: center;
  background-color: var(--white, #ffffff);
  border-radius: 8px;
  overflow: hidden;
  padding: 12px 16px;
  font-size: 16px;
  color: var(--gray-400, #111111);
  z-index: 3;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const SearchForm = styled.form`
  max-width: 1080px;
  min-width: 350px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  background-color: var(--white, #ffffff);
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 1px solid var(--gray-200);
  z-index: 3;

  input {
    flex: 1;
    min-width: 0;
    padding: 13px 16px;
  }

  button {
    padding: 0;
  }
`;

export const LandNumberSelectButton = styled.button`
  position: absolute;
  bottom: 110px;
  right: 16px;

  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background: var(--green-100, #00b140);

  color: var(--white, #ffffff);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.1px;
`;

export const SearchResultIcon = styled.span`
  display: inline-block;
  overflow: hidden;
  width: 24px;
  height: 24px;
  font-size: 0;
  line-height: 0;
  background: url(//t1.daumcdn.net/localimg/localimages/07/2018/pc/favorite/ico_search_history.png)
    no-repeat;
  vertical-align: top;
`;
