import WorkItemByUserIdData from "./WorkItemByUserIdData";

export default class WorkListByUserIdData {
    private _workList!: Array<WorkItemByUserIdData>;

    public get workList(): Array<WorkItemByUserIdData> {
        return this._workList;
    }
    public set workList(value: Array<WorkItemByUserIdData>) {
        this._workList = value;
    }
}