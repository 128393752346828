import styled from "@emotion/styled";
import { WorkDetailButton } from "../WorkDetail/WorkDetail.styles";


export const WorkTeamPlanContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    max-width: 1080px;

    & > p {
        font-size: 22px;
        font-weight: 500;
    }
`;
export const WorkPlannedLandContainer = styled.div`
    width: 100%;
    max-width: 1080px;
    margin-bottom: 24px;
`;

export const AddPlanButton = styled(WorkDetailButton)`
    align-self: flex-end;
    /* border: 1px solid var(--gray-300, #ccc);
    color: var(--gray-400, #666666); */
    /* 회색을 원한다면 아래 지우고 위에 주석 푸세요 */

    border: 1px solid var(--green-100, #00b140);
    background: var(--green-100, #00b140);
    color: var(--white, #ffffff);
`

export const TeamButtonContainer = styled.div`
    width: 100%;
`

export const TeamItemButton = styled.button`
    width: 140px;
    height: 50px;
    border-radius: 12px;
    /* color: var(--white, #ffffff); */
    color: black;
    background-color: white;
    font-size: 18px;
    font-weight: 600;
    transition: 0.2s;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.0);

    :hover {
        opacity: 70%;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    }
`

export const TeamItem = styled.div`
    margin: 20px 0px 10px;
`

export const TeamTopContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
`;

export const TeamIndexList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 45px;
    padding: 8px 8px 8px 20px;
    /* border: 1px solid #222; */
    border-radius: 10px;


    & > div:first-of-type {
        font-size: 16px;
        font-weight: bold;
        color: var(--white, #ffffff);
    }
`;

export const LandAddButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 32px;
    font-size: 14px;
    border-radius: 6px;
    background-color: var(--white, #fff);
    cursor: pointer;
    transition: 0.2s;
    color: #555;

    :hover {
        background: #f1f1f1;
    }
`;

export const WorkScheduleDateContainer = styled.div`
    width: 100%;
`;