import dayjs from "dayjs";

import { SearchResultIcon } from "../WorkRegistrationSearch.styles";
import {
  SearchHistoryContainer,
  SearchHistoryDate,
  SearchHistoryItem,
  SearchHistoryText,
} from "./SearchHistory.styles";
import { ReactComponent as SearchIcon } from "assets/images/search.svg";
import { ReactComponent as CloseIcon } from "assets/images/close.svg";
import type { UserSearchHistory } from "../WorkRegistrationSearch";

type SearchHistoryProps = {
  setOpenSearch: React.Dispatch<React.SetStateAction<boolean>>;
  searchAddress: (
    latitude: number,
    longitude: number,
    address?: string
  ) => void;
  searchHistoryList: Array<UserSearchHistory>;
  removeFromHistory: (index: number) => void;
  onClickHistory: (search: string) => void;
};

const SearchHistory: React.FC<SearchHistoryProps> = ({
  setOpenSearch,
  searchAddress,
  searchHistoryList,
  removeFromHistory,
  onClickHistory,
}) => {
  return (
    <SearchHistoryContainer>
      {searchHistoryList.map((history, idx) => (
        <SearchHistoryItem
          key={history.searchAt}
          onClick={() => {
            if (history.type === "placeResult" || history.type === "geoResult") {
              searchAddress(
                Number(history.search.y),
                Number(history.search.x),
                history.search.address_name
              );
              setOpenSearch(false);
            } else if (history.type === "search") {
              onClickHistory(history.search);
            }
          }}
        >
          {history.type === "search" ? (
            <SearchIcon width={24} height={24} />
          ) : (
            <SearchResultIcon />
          )}
          <SearchHistoryText>
            {history.type === "search"
              ? history.search
              : history.type === "placeResult"
              ? history.search.place_name
              : history.type === "geoResult"
              ? history.search.address_name
              : ""}
          </SearchHistoryText>
          <SearchHistoryDate>
            {dayjs(history.searchAt).format("MM.DD.")}
          </SearchHistoryDate>
          <button onClick={() => removeFromHistory(idx)}>
            <CloseIcon width={24} height={24} />
          </button>
        </SearchHistoryItem>
      ))}
    </SearchHistoryContainer>
  );
};

export default SearchHistory;
