import styled from '@emotion/styled';
import useGoBack from 'hooks/useGoBack';

const ConfirmButton = () => {
  const goBack = useGoBack();
  return (
    <ConfirmButtonBlock>
      <button onClick={goBack}>확인</button>
    </ConfirmButtonBlock>
  );
};

const ConfirmButtonBlock = styled.div`
  display: flex;
  gap: 4px;

  span {
    color: var(--green-100, #00b140);
    font-family: Pretendard Variable;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    align-self: flex-end;
  }

  button {
    color: #000;
    font-family: Pretendard Variable;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    padding: 0;
  }
`;

export default ConfirmButton;
