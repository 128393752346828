export enum responseCode {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
}

export enum userDataCode {
  APP_MODE = "APP_MODE",
  APP_VERSION = "APP_VERSION",
  USER_ID = "USER_ID",
  USER_NAME = "USER_NAME",
  USER_TEL = "USER_TEL",
  SNS_TYPE = "SNS_TYPE",
  ACCESS_TOKEN = "ACCESS_TOKEN",
  DEVICE_TOKEN = "DEVICE_TOKEN",
  SNS_ID = "SNS_ID",
  DEVICE_TEL = "DEVICE_TEL",
  DEVICE_OS_NAME = "DEVICE_OS_NAME",
  DEVICE_OS_VERSION = "DEVICE_OS_VERSION",
  DEVICE_MODEL = "DEVICE_MODEL",
  AGREEMENT_REGIST_LIST = "AGREEMENT_REGIST_LIST"
}

export enum workerLocalDataCode {
  WORK_ID = "WORK_ID",
  WORKER_NAME = "WORKER_NAME",
}

export enum snsType {
  KAKAO = "kakao",
  NAVER = "naver",
  APPLE = "apple",
  GOOGLE = "google",
}

export enum appMode {
  SELLER = "SELLER",
  BUYER = "BUYER",
  WORKER = "WORKER",
}

export enum productCode {
  PRODUCT_STATUS_TRADEABLE = "TRADEABLE",
  PRODUCT_STATUS_COMPLETE = "COMPLETE",
  PRODUCT_STATUS_CANCEL = "CANCEL",
}
export enum workStatus {
  WORK_STATUS_NOT_STARTED = "NOTSTARTED",
  WORK_STATUS_IN_PROGRESS = "PROGRESS",
  WORK_STATUS_COMPLETE = "COMPLETE",
}
export enum landStatus {
  LAND_STATUS_NOT_STARTED = "NOTSTARTED",
  LAND_STATUS_IN_PROGRESS = "PROGRESS",
  LAND_STATUS_COMPLETE = "COMPLETE",
}

export enum MapLevel {
  ZOOM_LEVEL_LIST = 6,
  ZOOM_LEVEL_DETAIL = 3,
  ZOOM_LEVEL_WORKER = 1,
}

export enum mapComponentMode { //맵 화면에 바텀시트로 같이 나오는 컴포넌트 구분자
  WORK_LIST = "work",
  PRODUCT_LIST = "product",
  WORK_DETAIL = "workDetail",
  WORK_REGISTRATION = "workRegistration",
  WORK_UPDATE = "workUpdate",
  WORK_RECORD_DETAIL_GROUP_DATE = "workRecordDetailGroupDate",
  WORK_RECORD_DAILY_LIST = "workRecordDailyList",
  WORK_RECORD_GROUP_ADDRESS_LIST = "workRecordGroupAddressList",
  NOTIFY_LANDOWNER = "notifyLandOwner",
  WORKER_WORK_DETAIL = "workerWorkDetail",
  WORKER_WORK_RECORD_GROUP_ADDRESS_LIST = "workerWorkRecordGroupAddressList",
  WORK_TEAM_PLAN = "workTeamPlan",
  WORK_TEAM_PLAN_UPDATE = "workTeamPlanUpdate",
  FARM_LIST = "farm",
  FARM_DETAIL = "farmDetail",
}

/** 여러 화면이 하나의 파일로 구현될 때, 화면의 용도를 나타내기 위한 enum.  */
export enum EPageMode {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  READ_LIST = "READ_LIST",
  READ_DETAIL = "READ_DETAIL",
}
