import { EquipmentTypeCode } from "code/EquipmentTypeCode";
import { equipmentIconByEquipmentType } from "converter/EquipmentConverter";

export default class EquipmentItemData {
    private _equipmentType: EquipmentTypeCode = EquipmentTypeCode.RAYKEY;
    private _equipmentName: string = "";
    private _equipmentDistance: string = "";
  
    public get equipmentType(): EquipmentTypeCode {
        return this._equipmentType;
    }
    public set equipmentType(value: EquipmentTypeCode) {
        this._equipmentType = value;
    }
    public get equipmentName(): string {
        return this._equipmentName;
    }
    public set equipmentName(value: string) {
        this._equipmentName = value;
    }
    public get equipmentIcon(): any {
        return equipmentIconByEquipmentType(this._equipmentType);
    }
    public get equipmentDistance(): string {
        return this._equipmentDistance;
    }
    public set equipmentDistance(value: string) {
        this._equipmentDistance = value;
    }
    
}