import BaseResData from "../BaseResData";
import OtherSubWorkItemResData from "./OtherSubWorkItemResData";

export default class OtherSubWorkListResData extends BaseResData{
    private _otherSubWorkDateList: Array<{ workId: string; workStartDate: string; workEndDate: string; }> = [];    
    private _otherSubWorkLandList: Array<{ landAddress: string }> = [];

    public get otherSubWorkDateList(): Array<{ workId: string; workStartDate: string; workEndDate: string; }> {
        return this._otherSubWorkDateList;
    }
    public set otherSubWorkDateList(value: Array<{ workId: string; workStartDate: string; workEndDate: string; }>) {
        this._otherSubWorkDateList = value;
    }
    public get otherSubWorkLandList(): Array<{ landAddress: string; }> {
        return this._otherSubWorkLandList;
    }
    public set otherSubWorkLandList(value: Array<{ landAddress: string; }>) {
        this._otherSubWorkLandList = value;
    }
    // private _otherSubWorkList!: OtherSubWorkItemResData;

    // public get otherSubWorkList(): OtherSubWorkItemResData {
    //     return this._otherSubWorkList;
    // }
    // public set otherSubWorkList(value: OtherSubWorkItemResData) {
    //     this._otherSubWorkList = value;
    // }

}