import { appMode, userDataCode } from "code/Enumerator";
import { isObject } from "common/utils/ObjectUtils";
import { de } from "date-fns/locale";

export default class UserData{
    private static _instance: UserData;
    private constructor() {}    
    public static get instance(): UserData {return this._instance || (this._instance = new this());}

    public set deviceToken(deviceToken: string) {
        if(!deviceToken) deviceToken = "";
        localStorage.setItem(userDataCode.DEVICE_TOKEN, isObject(deviceToken)?JSON.stringify(deviceToken):deviceToken);
    }
    public get deviceToken(): string {
        const result = localStorage.getItem(userDataCode.DEVICE_TOKEN) as any; 
        return isObject(result)?JSON.parse(result) : result;
    }
    public set deviceOsName(deviceOsName: string) {
        if(!deviceOsName) deviceOsName = "";
        localStorage.setItem(userDataCode.DEVICE_OS_NAME,isObject(deviceOsName)?JSON.stringify(deviceOsName):deviceOsName);
    }
    public get deviceOsName(): string {
        const result = localStorage.getItem(userDataCode.DEVICE_OS_NAME) as any; 
        return isObject(result)?JSON.parse(result) : result;
    }
    public set deviceOsVersion(deviceOsVersion: string) {
        if(!deviceOsVersion) deviceOsVersion = "";
        localStorage.setItem(userDataCode.DEVICE_OS_VERSION,isObject(deviceOsVersion)?JSON.stringify(deviceOsVersion):deviceOsVersion);
    }
    public get deviceOsVersion(): string {
        const result = localStorage.getItem(userDataCode.DEVICE_OS_VERSION) as any; 
        return isObject(result)?JSON.parse(result) : result;
    }
    public set deviceModel(deviceModel: string) {
        if(!deviceModel) deviceModel = "";
        localStorage.setItem(userDataCode.DEVICE_MODEL,isObject(deviceModel)?JSON.stringify(deviceModel):deviceModel);
    }
    public get deviceModel(): string {
        const result = localStorage.getItem(userDataCode.DEVICE_MODEL) as any; 
        return isObject(result)?JSON.parse(result) : result;
    }
    public set appVersion(appVersion: string) {
        if(!appVersion) appVersion = "";
        localStorage.setItem(userDataCode.APP_VERSION,isObject(appVersion)?JSON.stringify(appVersion):appVersion);
    }

    public get appVersion(): string {
        const result = localStorage.getItem(userDataCode.APP_VERSION) as any; 
        return isObject(result)?JSON.parse(result) : result;
    }
}