import KeepItemData from "./KeepItemData";

export default class KeepListData{
    private _keepList: Array<KeepItemData> = new Array<KeepItemData>();
    private _userLatitude: number=0;
    private _userLongitude: number=0;

    public get userLatitude(): number {
        return this._userLatitude;
    }
    public set userLatitude(value: number) {
        this._userLatitude = value;
    }
    public get userLongitude(): number {
        return this._userLongitude;
    }
    public set userLongitude(value: number) {
        this._userLongitude = value;
    }
    public get keepList(): Array<KeepItemData> {
        return this._keepList;
    }
    public set keepList(value: Array<KeepItemData>) {
        this._keepList = value;
    }
}