export default class WorkRecordForProductItemData{
    public workDate!: string;  
    public amHumidity!: number;
    public amWeather!: string;
    public pmWeather!: string;
    public pmHumidity!: number;
    public lowTemp!: number;
    public highTemp!: number;
    public recordOut!: number;
    public landAddress!: string;
    public equipmentName!: string;
    public equipmentType!: string;
    public workerName!: string;
}

