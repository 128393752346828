import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class LandUpdateRequest extends BaseRequest {
    private readonly URL_LAND_BASE = `${BaseService.API_URL}/land`;
    private readonly URL_LAND_PLAN = `${this.URL_LAND_BASE}/landPlan`;

    getURL(): string {
        return this.URL_LAND_PLAN;
    }
}