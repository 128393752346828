import WorkRecordGroupAddressItemData from "ui/pages/WorkRecordGroupAddressList/WorkRecordGroupAddressListItemData";
import WorkRecordLogData from "ui/pages/WorkDetail/WorkRecordLogData";
import WorkerItemData from "ui/pages/WorkDetail/WorkerList/WorkerItemData";
import { WorkRecordLogValidator } from "validator/WorkValidator";

export function findMyWorkRecordLogByLandAddress(
  worker: WorkerItemData,
  workRecordLogList: Array<WorkRecordLogData>
): number {
  let finedWorkRecoredLogIndex: number = workRecordLogList.findIndex((item) => {
    return (
      item.workerName === worker.workerName &&
      item.equipmentName === worker.equipmentName &&
      item.equipmentType === worker.equipmentType
    );
  });
  return finedWorkRecoredLogIndex;
}

export function finedInLandList(
  landDataList: Array<WorkRecordGroupAddressItemData>,
  lat: number,
  lng: number
): number {
  let finedLand: number = landDataList.findIndex((landData) => {
    return WorkRecordLogValidator.validateInLand(landData.landMap, lat, lng);
  });
  return finedLand;
}
