import useHeaderOptions from "hooks/useHeaderOptions";
import { useEffect, useState } from "react";
import MyPurchaseItemData from "./MyPurchaseItem/MyPurchaseItemData";
import MyPurchaseItem from "./MyPurchaseItem/MyPurchaseItem";
import { SList } from "./MyPurchaseList.styles";
import PurchaseListReqData from "data/network/req/myPage/PurchaseListReqData";
import PurchaseListResData from "data/network/res/myPage/PurchaseListResData";
import PurchaseListRequest from "network/myPage/PurchaseListRequest";
import { resPurchaseListToPurchaseListData } from "converter/MyPageConverter";


let myPurchaseListData : Array<MyPurchaseItemData> = new Array<MyPurchaseItemData>();
const MyPurchaseList = () => {
  useHeaderOptions({ title: "구매내역" });
  const [viewModel, setViewModel] = useState({ myPurchaseListData });
  const purchaseListRequest : PurchaseListRequest = new PurchaseListRequest();

  useEffect(() => {
    document.title = "카우잇츠 - 한우농가 구매내역";
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function init() {
    reqMySalesList();
  }

  async function reqMySalesList() {
    const purchaseListReqData = new PurchaseListReqData();
    const purchaseListResData = await purchaseListRequest.send(purchaseListReqData) as PurchaseListResData;
    myPurchaseListData = resPurchaseListToPurchaseListData(purchaseListResData);
    setViewModel({ myPurchaseListData });
  }

  return (
    <>     
      {/* 상품 목록 */}
      <SList>
        {viewModel.myPurchaseListData.length > 0 ? (
          viewModel.myPurchaseListData.map((item, i) => (
          <MyPurchaseItem key={i} myPurchaseItemData={item} />
        ))
        ) : (
          <div>상품이 없습니다.</div>
        )}
      </SList>
    </>
  );
};

export default MyPurchaseList;
