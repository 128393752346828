import {
  MyPurchaseListItemContainer,
  MyPurchaseListItemContentBlock,
} from '../MyPurchaseList.styles';
import { formatPrice } from 'common/utils/FormatPrice';
import MyPurchaseItemData from './MyPurchaseItemData';
import { HorizontalBar } from 'styles/common.styles';
import moment from 'moment';

interface MyPurchaseItemProps {
  myPurchaseItemData: MyPurchaseItemData;
}

const MyPurchaseItem = (props : MyPurchaseItemProps) => {
  return (
    <MyPurchaseListItemContainer>
      <MyPurchaseListItemContentBlock>
        <b>구매 확정일 : {moment(props.myPurchaseItemData.salesDtm).format("YYYY.MM.DD")}</b>
      </MyPurchaseListItemContentBlock>
      <MyPurchaseListItemContentBlock>
        <span>{props.myPurchaseItemData.cropType} | {props.myPurchaseItemData.tradeQnt}개 </span>
        <strong>{formatPrice(props.myPurchaseItemData.tradePrice)}원/개당</strong>      
      </MyPurchaseListItemContentBlock>
      <HorizontalBar />
    </MyPurchaseListItemContainer>
  );
};

export default MyPurchaseItem;
