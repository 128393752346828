import { CowScaleCode } from "code/CowScaleCode";
import BaseReqData from "../BaseReqData";
import { CowTypeCode } from "code/CowTypeCode";
import { CropTypeCode } from "code/CropTypeCode";

export interface ICowListReqItem {
  cowType: CowTypeCode;
  cowCount: number;
}

export interface ICropListReqItem {
  cropKind: CropTypeCode;
  etcName?: string | undefined;
  yearConsumption: number;
  cropRecord: number;
  inven: number;
  buyCount: number;
}

export default class FarmRegistrationReqData extends BaseReqData {
  public farmId?: string;
  public farmName!: string;
  public farmAddress!: string;
  public farmAddress1!: string;
  public farmAddress2!: string;
  public farmAddress3!: string;
  public farmLatitude!: number;
  public farmLongitude!: number;
  public cowScale!: CowScaleCode;
  public farmCowType!: CowTypeCode;
  public farmCowCount!: number;
  public farmYearConsumption!: number;
  public farmInven!: number;
  public cropList!: ICropListReqItem[];
  public cowList!: ICowListReqItem[];
}
