import BaseReqData from "../BaseReqData";

export default class FarmListReqData extends BaseReqData {
  private _userId!: string;

  public get userId(): string {
    return this._userId;
  }
  public set userId(value: string) {
    this._userId = value;
  }
}
