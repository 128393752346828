export enum WeatherCode{
    SUNNY = "맑음",
    CLOUDY = "흐림",
    RAINY = "비"
}

export const stringToWeatherCode : { [key: string]: WeatherCode }= {
    "맑음": WeatherCode.SUNNY,
    "흐림": WeatherCode.CLOUDY,
    "비": WeatherCode.RAINY
}