export default class WorkItemByUserIdData {
    private _workId!: string;
    private _workStartDate!: string;
    private _workEndDate!: string;

    public get workId(): string {
        return this._workId;
    }
    public set workId(value: string) {
        this._workId = value;
    }
    public get workStartDate(): string {
        return this._workStartDate;
    }
    public set workStartDate(value: string) {
        this._workStartDate = value;
    }
    public get workEndDate(): string {
        return this._workEndDate;
    }
    public set workEndDate(value: string) {
        this._workEndDate = value;
    }
}