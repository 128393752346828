import { HeaderProps } from 'ui/components/Header';
import { atom } from 'recoil';

type HeaderOptions = HeaderProps;

export const headerOptionsState = atom<HeaderOptions>({
  key: 'headerOptionsState',
  default: {
    title: '',
    hasBack: true,
    headerRight: null,
  },
});
