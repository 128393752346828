import WorkRecordLogListResData from "data/network/res/workRecord/WorkRecordLogListResData";
import WorkRecordLogData from "ui/pages/WorkDetail/WorkRecordLogData";

export function workRecordLogListResDataToWorkRecordLogListData(workRecordLogListResData : WorkRecordLogListResData):Array<WorkRecordLogData> {
    const workRecordLogListData : Array<WorkRecordLogData> = new Array<WorkRecordLogData>();
    workRecordLogListResData.workRecordLogList?.forEach(item => {
        const workRecordLogData : WorkRecordLogData = new WorkRecordLogData();
        workRecordLogData.landAddress = item.landAddress;
        workRecordLogData.workerName = item.workerName;
        workRecordLogData.equipmentName = item.equipmentName;
        workRecordLogData.equipmentType = item.equipmentType;
        workRecordLogData.equipmentOrder = item.equipmentOrder;
        workRecordLogData.workStatus = "Y";
        workRecordLogData.polyLine = [];
        workRecordLogData.colorOrder = item.colorOrder;
        workRecordLogListData.push(workRecordLogData);
    });

    return workRecordLogListData;
}