import BridgeCommands from "bridge/BridgeCommands";
import {
  TWeatherShortCategory,
  WeatherShortResponseItem,
} from "bridge/BridgeTypes";
import { WeatherCode } from "code/WeatherCode";
import WeatherData from "data/WeatherData";
import WeatherOpenData from "data/WeatherOpenData";

export interface ILocation {
  latitude: number;
  longitude: number;
}

/** weatherShortResponseToWeatherData에서 데이터 파싱 중 중간과정에 만들어지는 객체 형식  */
interface ITempShortDataAm {
  hum: number[];
  weather: number[];
  rainPercent: number[];
}
interface ITempShortData {
  temp: number[]; // 그 날의 각 시간대별 기온값
  am: ITempShortDataAm; // 그 날 오전의 각 시간대별 습도, 하늘, 강수확률
  pm: ITempShortDataAm; // 그 날 오후의 각 시간대별 습도, 하늘, 강수확률
}
/** 오전(오후)의 평균 습도, 평균 강수확률, 평균 적운량 계산 */
const getWeatherAverage = (data: ITempShortDataAm) => {
  const humAverage =
    data.hum.reduce((acc, min) => acc + min, 0) / data.hum.length;
  const rainAverage =
    data.rainPercent.reduce((acc, min) => acc + min, 0) /
    data.rainPercent.length;
  const weatherAverage =
    data.weather.reduce((acc, min) => acc + min, 0) / data.weather.length;

  return { humAverage, rainAverage, weatherAverage };
};

export function weatherOpenDataWeatherData(
  weatherOpenData: WeatherOpenData
): WeatherData {
  const weatherData: WeatherData = new WeatherData();
  return weatherData;
}

export const latlngToXy = (latlng: ILocation): { x: number; y: number } => {
  const { latitude, longitude } = latlng;
  //
  // LCC DFS 좌표변환을 위한 기초 자료
  //
  const RE = 6371.00877; // 지구 반경(km)
  const GRID = 5.0; // 격자 간격(km)
  const SLAT1 = 30.0; // 투영 위도1(degree)
  const SLAT2 = 60.0; // 투영 위도2(degree)
  const OLON = 126.0; // 기준점 경도(degree)
  const OLAT = 38.0; // 기준점 위도(degree)
  const XO = 43; // 기준점 X좌표(GRID)
  const YO = 136; // 기1준점 Y좌표(GRID)
  //
  // LCC DFS 좌표변환 ( code : "toXY"(위경도->좌표, v1:위도, v2:경도), "toLL"(좌표->위경도,v1:x, v2:y) )
  //

  const DEGRAD = Math.PI / 180.0;

  const re = RE / GRID;
  const slat1 = SLAT1 * DEGRAD;
  const slat2 = SLAT2 * DEGRAD;
  const olon = OLON * DEGRAD;
  const olat = OLAT * DEGRAD;

  let sn =
    Math.tan(Math.PI * 0.25 + slat2 * 0.5) /
    Math.tan(Math.PI * 0.25 + slat1 * 0.5);
  sn = Math.log(Math.cos(slat1) / Math.cos(slat2)) / Math.log(sn);
  let sf = Math.tan(Math.PI * 0.25 + slat1 * 0.5);
  sf = (Math.pow(sf, sn) * Math.cos(slat1)) / sn;
  let ro = Math.tan(Math.PI * 0.25 + olat * 0.5);
  ro = (re * sf) / Math.pow(ro, sn);

  var ra = Math.tan(Math.PI * 0.25 + latitude * DEGRAD * 0.5);
  ra = (re * sf) / Math.pow(ra, sn);
  var theta = longitude * DEGRAD - olon;
  if (theta > Math.PI) theta -= 2.0 * Math.PI;
  if (theta < -Math.PI) theta += 2.0 * Math.PI;
  theta *= sn;

  const rs: { x: number; y: number } = {
    x: Math.floor(ra * Math.sin(theta) + XO + 0.5),
    y: Math.floor(ro - ra * Math.cos(theta) + YO + 0.5),
  };

  return rs;
};

/** 브릿지를 통해 전달받은 단기(3일치) 기상데이터를 WeatherData 배열로 변환 */
export function weatherShortResponseToWeatherData(
  responseData: WeatherShortResponseItem[]
): WeatherData[] {
  const weatherDatas: WeatherData[] = [];

  // 파싱하기 편한 임시 객체 생성
  const tempDataPerDate: Record<string, ITempShortData> = {};
  for (const shortItem of responseData) {
    const { category, fcstDate, fcstTime, fcstValue } = shortItem;
    const amPm = fcstTime >= "1200" ? "pm" : "am";

    if (!tempDataPerDate[fcstDate])
      tempDataPerDate[fcstDate] = {
        temp: [],
        am: { hum: [], weather: [], rainPercent: [] },
        pm: { hum: [], weather: [], rainPercent: [] },
      };

    switch (category) {
      case "TMP": // 기온
        tempDataPerDate[fcstDate].temp.push(Number(fcstValue) || 0);
        break;
      case "POP": // 강수확률
        tempDataPerDate[fcstDate][amPm].rainPercent.push(
          Number(fcstValue) || 0
        );
        break;
      case "REH": // 습도
        tempDataPerDate[fcstDate][amPm].hum.push(Number(fcstValue) || 0);
        break;
      case "SKY": // 하늘상태 (=적운량)
        tempDataPerDate[fcstDate][amPm].weather.push(Number(fcstValue) || 0);
        break;
    }
  }

  // 임시 객체에 옮겨담은 데이터를 토대로 WeatehrData 생성
  Object.entries(tempDataPerDate).forEach(([date, tempData]) => {
    // 20230828로 단기예보 조회 시, 2023-08-31 00시 대의 기상데이터까지 응답받음. tempDataPerDate에서 2023-08-31의 기상데이터는 00시 대의 기상데이터 하나 뿐이고, 0828 0829 0830 3일치의 데이터는 이미 있으므로 이 경우에는 WeatherData를 만들지 않음.
    if (tempData.temp.length <= 2) return;

    const weatherData = new WeatherData();

    const lowTemp = Math.min(...tempData.temp);
    const highTemp = Math.max(...tempData.temp);

    const amAverage = getWeatherAverage(tempData.am);
    const pmAverage = getWeatherAverage(tempData.pm);

    weatherData.date = new Date(
      `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6)}`
    );
    weatherData.lowTemp = lowTemp;
    weatherData.highTemp = highTemp;
    weatherData.amWeather =
      amAverage.rainAverage > 70
        ? WeatherCode.RAINY
        : amAverage.weatherAverage >= 3
        ? WeatherCode.CLOUDY
        : WeatherCode.SUNNY;
    weatherData.amHumidity = amAverage.humAverage;
    weatherData.pmWeather =
      pmAverage.rainAverage > 70
        ? WeatherCode.RAINY
        : pmAverage.weatherAverage >= 3
        ? WeatherCode.CLOUDY
        : WeatherCode.SUNNY;
    weatherData.pmHumidity = pmAverage.humAverage;

    weatherDatas.push(weatherData);
  });

  return weatherDatas;
}

/** 위경도를 입력받아 최근 3일치 WeatherData 배열을 반환 () */
export const createShortWeatherDataList = ({point}: {point: ILocation;}): Promise<WeatherData[]> =>
  new Promise((resolve, reject) => {
    // 코드실행순서: 0
    const { x: targetX, y: targetY } = latlngToXy(point);

    // 코드실행순서: 4
    const onBrgMsgReceived = (e: MessageEvent) => {
      try {
        const parsedData = JSON.parse(e.data);
        const shortDataRes = parsedData.response.body.items.item;

        const weatherDataList = weatherShortResponseToWeatherData(shortDataRes);

        // document.removeEventListener(
        //   "message",
        //   onBrgMsgReceived as EventListener
        // );

        // 코드실행순서: 5 (끝)
        resolve(weatherDataList);
      } catch (e) {
        reject(e);
      }
    };

    // 코드실행순서: 1
    // document.addEventListener("message", onBrgMsgReceived as EventListener);

    // 코드실행순서: 2
    const now = new Date();
    const y = now.getFullYear();
    const m = (now.getMonth() + 1 + "").padStart(2, "0");
    const d = (now.getDate() + "").padStart(2, "0");
    const today = `${y}${m}${d}`; // 오늘날짜 "20230829"

    const message = {
      command: BridgeCommands.BRG_SHORT_FORECAST_INFO,
      data: {
        targetDate: today, // '20230829',
        targetX,
        targetY,
      },
    };

    // 코드실행순서: 3
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  });
