import WorkItemData from "ui/pages/WorkList/WorkItemData";
import WorkRegistrationData from "ui/pages/WorkRegistration/WorkRegistrationData";
import ProductItemData from "../ProductItem/ProductItemData";
import { mapComponentMode } from "code/Enumerator";
import MapComponentData from "../MapComponent/MapComponentData";
import WorkDetailData from "ui/pages/WorkDetail/WorkDetailData";
import WorkRecordDetailGroupDateData from "ui/pages/WorkRecordDetailGroupDate/WorkRecordDetailGroupDateData";
import { CropTypeCode } from "code/CropTypeCode";
import BuyListByFilterItemResData from "data/network/res/trade/BuyListByFilterItemResData";
import WorkTeamPlanData from "ui/pages/WorkTeamPlan/WorkTeamPlanData";

export default class MapPageData {
  private _location!: string;
  private _workRegistrationData: WorkRegistrationData = new WorkRegistrationData();
  private _workTeamPlanData: WorkTeamPlanData = new WorkTeamPlanData();  
  private _workListData: Array<WorkItemData> = new Array<WorkItemData>();
  private _productListData: Array<ProductItemData> = new Array<ProductItemData>();
  private _buyListData: Array<BuyListByFilterItemResData> = new Array<BuyListByFilterItemResData>();
  private _mapComponentData: MapComponentData = new MapComponentData();
  private _workDetailData: WorkDetailData = new WorkDetailData();
  private _workListYear: number = new Date().getFullYear();
  private _workListSearchCropTypeList: Array<CropTypeCode> = new Array<CropTypeCode>(CropTypeCode.RICE_STRAW, CropTypeCode.IRG, CropTypeCode.ETC);
  private _workRecordDetailGroupDateData: WorkRecordDetailGroupDateData = new WorkRecordDetailGroupDateData();
  /** 
   * 토지주알리기; landList의 필지 중 현재 선택된 필지의 idx를 담아두는 객체.
   * 예를들어 전체 4개의 필지 중 2번째(idx=1)와 4번째(idx=3)가 선택되었다면 {1: true, 3: true} 의 모양이 됨.
   */
  private _selectedWorkLandIdxMap: Record<number, boolean> = {};
  
  public originWorkRegistrationData: WorkRegistrationData = new WorkRegistrationData();
  public originWorkTeamPlanData: WorkTeamPlanData = new WorkTeamPlanData();
  // mapPageData: any;

  constructor(_location?: string) {
    if (_location) this.location = _location;
  }

  public get location(): string {
    return this._location;
  }
  public set location(value: string) {
    this._location = value;
  }
  public get mapComponentMode(): mapComponentMode {
    if (this._location === "/work") {
      return mapComponentMode.WORK_LIST;
    } else if (this._location?.includes("/work/detail")) {
      return mapComponentMode.WORK_DETAIL;
    } else if (this._location?.includes("/products")) {
      return mapComponentMode.PRODUCT_LIST;
    } else if (this._location?.includes("/work/registration")) {
      return mapComponentMode.WORK_REGISTRATION;
    } else if (this._location?.includes("/work/update")) {
      return mapComponentMode.WORK_UPDATE;
    } else if (this._location?.includes("/workRecordDetailGroupDate")) {
      return mapComponentMode.WORK_RECORD_DETAIL_GROUP_DATE;
    } else if (this._location?.includes("/notifyLandOwner")) {
      return mapComponentMode.NOTIFY_LANDOWNER;
    } else if (this._location?.includes("/workerWork")) {
      return mapComponentMode.WORKER_WORK_DETAIL;
    } else if (this._location?.includes("/workRecordGroupAddressList")) {
      return mapComponentMode.WORK_RECORD_GROUP_ADDRESS_LIST;
    } else if (this._location?.includes("/workRecordDailyList")) {
      return mapComponentMode.WORK_RECORD_DAILY_LIST;
    } else if (this._location?.includes("/workTeamPlan")) {
      return mapComponentMode.WORK_TEAM_PLAN;
    } else if (this._location?.includes("/workTeamUpdate")) {
      return mapComponentMode.WORK_TEAM_PLAN_UPDATE;
    }
    return mapComponentMode.WORK_LIST;
  }

  public get workListData(): Array<WorkItemData> {
    return this._workListData;
  }
  public set workListData(value: Array<WorkItemData>) {
    this._workListData = value;
  }
  public get workRegistrationData(): WorkRegistrationData {
    return this._workRegistrationData;
  }
  public set workRegistrationData(value: WorkRegistrationData) {
    this._workRegistrationData = value;
  }
  public get workTeamPlanData(): WorkTeamPlanData {
    return this._workTeamPlanData;
  }
  public set workTeamPlanData(value: WorkTeamPlanData) {
    this._workTeamPlanData = value;
  }
  public get productListData(): Array<ProductItemData> {
    return this._productListData;
  }
  public set productListData(value: Array<ProductItemData>) {
    this._productListData = value;
  }

  public get buyListData(): Array<BuyListByFilterItemResData> {
    return this._buyListData;
  }

  public set buyListData(value: Array<BuyListByFilterItemResData>) {
    this._buyListData = value;
  }

  public get mapComponentData(): MapComponentData {
    return this._mapComponentData;
  }
  public set mapComponentData(value: MapComponentData) {
    this._mapComponentData = value;
  }
  public get workDetailData(): WorkDetailData {
    return this._workDetailData;
  }
  public set workDetailData(value: WorkDetailData) {
    this._workDetailData = value;
  }
  public get workListYear(): number {
    return this._workListYear;
  }
  public set workListYear(value: number) {
    this._workListYear = value;
  }
  public get workListSearchCropTypeList(): Array<CropTypeCode> {
    return this._workListSearchCropTypeList;
  }
  public set workListSearchCropTypeList(value: Array<CropTypeCode>) {
    this._workListSearchCropTypeList = value;
  } 
  public get workRecordDetailGroupDateData(): WorkRecordDetailGroupDateData {
    return this._workRecordDetailGroupDateData;
  }
  public set workRecordDetailGroupDateData(
    value: WorkRecordDetailGroupDateData
  ) {
    this._workRecordDetailGroupDateData = value;
  }
  public get selectedWorkLandIdxMap(): Record<number, boolean> {
    return this._selectedWorkLandIdxMap;
  }
  public set selectedWorkLandIdxMap(value: Record<number, boolean>) {
    this._selectedWorkLandIdxMap = value;
  }
}
