import WorkRecordGroupDateItemData from "ui/components/Work/WorkRecordGroupDateList/WorkRecordGroupDateItemData";

export default class ProductRecordDailyItemData{
    public workDate!: string;
    public amHumidity!: number;
    public amWeather!: string;
    public pmWeather!: string;
    public pmHumidity!: number;
    public lowTemp!: number;
    public highTemp!: number;
    public landList : Array<LandItem> = [];
    public equipmentNames: Array<string> = [];
}

export class LandItem {
    public landAddress!: string;
    public recordOutput!: number;
}