import styled from '@emotion/styled';

interface AppBodyProps {
  appMode: string | null; // appMode 프롭스를 정의합니다.
}

export const AppBody = styled.div<AppBodyProps>`
  // AppBodyProps 타입을 사용하여 스타일을 정의합니다.
  /* padding-bottom: ${({ appMode }) =>
    appMode === 'WORKER' ? '0' : '60px'}; */
    position: relative;
    height: calc(100vh - 50px);
    max-width: 1080px;
    min-width: 350px;
    margin-left: auto;
    margin-right: auto;
    /* overflow: scroll; */
`;

export const AppPageContainer = styled.div`
  max-width: 1080px;
  width: 100%;
  position: relative;
  padding: 24px 16px;

  &.mh-100 {
    min-height: calc(100vh - 110px);
    display: flex;
    flex-direction: column;
  }
`;