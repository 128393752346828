import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { firebaseDataToPolygonData, workDetailResDataToWorkDetailData } from "converter/WorkConverter";
import { mapComponentMode, responseCode } from "code/Enumerator";
import WorkDetailReqData from "data/network/req/work/WorkDetailReqData";
import WorkDetailRequest from "network/work/WorkDetailRequest";
import WorkDetailResData from "data/network/res/work/WorkDetailResData";
import WorkRecordDetailGroupDateRequest from "network/workRecord/WorkRecordDetailGroupDateRequest";
import { workRecordDetailGroupDateResDataToWorkRecordDetailGroupDateData } from "converter/WorkRecordConverter";
import WorkRecordDetailGroupDateReqData from "data/network/req/workRecord/WorkRecordDetailGroupDateReqData";
import BridgeManager from "bridge/BridgeManager";
import WorkRecordDetailGroupDateResData from "data/network/res/workRecord/WorkRecordDetailGroupDateResData";
import WorkerMapPageData from "./WorkerMapPageData";
import BridgeCommands from "bridge/BridgeCommands";
import UserData from "data/UserData";
import DeviceData from 'data/DeviceData';
import WorkerLocalData from "data/WorkerLocalData";
import WorkRecordLogData from "ui/pages/WorkDetail/WorkRecordLogData";
import WorkRecordLogRegistrationRequest from "network/workRecord/WorkRecordLogRegistrationRequest";
import { WorkRecordLogValidator } from "validator/WorkValidator";
import WorkRecordLogReqData from "data/network/req/workRecord/WorkRecordLogReqData";
import WorkerMapComponent from "../WorkerMapComponent/WorkerMapComponent";
import { AppBody } from "./AppLayout.styles";
import { findMyWorkRecordLogByLandAddress, finedInLandList } from "common/utils/WorkerUtils";
import WorkerItemData from "ui/pages/WorkDetail/WorkerList/WorkerItemData";
import WorkRecordLogListReqData from "data/network/req/workRecord/WorkRecordLogListReqData";
import WorkRecordLogListRequest from "network/workRecord/WorkRecordLogListRequest";
import { workRecordLogListResDataToWorkRecordLogListData } from "converter/WorkRecordLogConverter";
import WorkRecordLogListResData from "data/network/res/workRecord/WorkRecordLogListResData";
import { getColorWithTeamCode, memoListToMarkerList, workRecordGroupAddressListToPolygonList, workRecordLogListToPolylineList } from "converter/KakaoConverter";
import MarkerData from "../MapComponent/MarkerData";
import PolyLineData from "../MapComponent/PolyLineData";
import PolygonData from "../MapComponent/PolygonData";
import ActionSheet from "../ActionSheet/ActionSheet";
import { WorkMemoModalProps } from "../Modals/WorkMemoModal/WorkMemoModal";
import WorkMemoItemData from "ui/pages/WorkDetail/WorkMemoItemData";
import { useAlertModal, useOneButtonModal, useTesterModal, useWorkMemoModal, useWorkModal } from "hooks/useModal";
import WorkerHeader from "../WorkerHeader";
import { Socket, io } from "socket.io-client";
import SocketCommands from "command/SocketCommands";
import WorkRecordGroupAddressItemData from "ui/pages/WorkRecordGroupAddressList/WorkRecordGroupAddressListItemData";
import { WorkModalProps } from "../Modals/WorkModal/WorkModal";
import WorkMemoRegistrationReqData from "data/network/req/workMemo/WorkMemoRegistrationReqData";
import WorkRecordRegistrationReqData from "data/network/req/workRecord/WorkRecordRegistrationReqData";
import LandAddRegistReqData from "data/network/req/work/LandAddRegistReqData";
import { colorOrderByEquipmentType, equipmentIconByEquipmentType } from "converter/EquipmentConverter";
import { EquipmentTypeCode, stringToEquipmentTypeCode } from "code/EquipmentTypeCode";
import { stringToLandMap } from "common/utils/StringUtils";
import WorkRecordGroupDateItemData from "../Work/WorkRecordGroupDateList/WorkRecordGroupDateItemData";
import BaseService from "network/BaseService";
import { collection, doc, getDocs, onSnapshot, updateDoc } from "firebase/firestore";
import {
  fireStoreManager,
} from '../../../firebase/FireStoreManager';
import EquipmentDistanceReqData from "data/network/req/equipment/EquipmentDistanceReqData";
import EquipmentDistanceRequest from "network/equipment/EquipmentDistanceRequest";
import EquipmentDistanceResData from "data/network/res/equipment/EquipmentDistanceResData";
import OtherSubWorkListReqData from "data/network/req/work/OtherSubWorkListReqData";
import OtherSubWorkListRequest from "network/work/OtherSubWorkListRequest";
import OtherSubWorkListResData from "data/network/res/work/OtherSubWorkListResData";
import { useRecoilState } from "recoil";
import { actionSheetState } from "atoms/actionSheet";
import LatestAppVersionRequest from "network/user/LatestAppVersionRequest";
import LatestAppVersionReqData from "data/network/req/login/LatestAppVersionReqData";
import LatestAppVersionResData from "data/network/res/login/LatestAppVersionResData";

let workerMapPageData: WorkerMapPageData = new WorkerMapPageData();
let bridgeManager: BridgeManager = new BridgeManager();
let socket: Socket;
const WorkerMapPage = () => {
  const { openWorkMemoModal } = useWorkMemoModal();
  const { openWorkModal } = useWorkModal();
  const { openAlertModal } = useAlertModal();
  const { openTesterModal } = useTesterModal();
  const { openOneButtonModal } = useOneButtonModal();

  const workDetailRequest: WorkDetailRequest = new WorkDetailRequest();
  const workRecordLogListRequest: WorkRecordLogListRequest = new WorkRecordLogListRequest();
  const workRecordDetailGroupDateRequest: WorkRecordDetailGroupDateRequest = new WorkRecordDetailGroupDateRequest();
  const workRecordLogRequest: WorkRecordLogRegistrationRequest = new WorkRecordLogRegistrationRequest();  
  const [viewModel, setViewModel] = useState({ workerMapPageData });
  const [myLatLng, setMyLatLng] = useState<number[]>([37.566535, 126.9779692]);
  const [markerList, setMarkerList] = useState<MarkerData[]>([]);
  const [polygonList, setPolygonList] = useState<PolygonData[]>([]);
  const [polylineList, setPolylineList] = useState<PolyLineData[]>([]);
  const [myMarker, setMyMarker] = useState<MarkerData>();
  const [myTeam, setMyTeam] = useState<string>("");
  const [workerMarkerList, setWorkerMarkerList] = useState<MarkerData[]>([]);
  const [mapCenter, setMapCenter] = useState<number[]>([37.566535, 126.9779692]);
  const [debugGpsLog, setDebugGpsLog] = useState<string>("");
  const [debugRecordLog, setDebugRecordLog] = useState<string>("");
  const [socketConnect, setSocketConnect] = useState<boolean>(true);
  const [connectedTime, setConnectedTime] = useState<number>(0);
  const [pinLatLng, setPinLatLng] = useState<number[]>([0, 0]);
  const [sheetState, setSheetState] = useRecoilState(actionSheetState);

  let hasConnectError = false;
  const location = useLocation();
  const navigate = useNavigate();
  let { workId, workerName } = useParams();
  let myWorkerItemData: WorkerItemData = new WorkerItemData();
  let originWorkerMarkerList : Array<MarkerData> = [];
  
  useEffect(() => {
    workerMapPageData.location = location.pathname;
    setViewModel({ workerMapPageData });
    let decodedWorkerName = "";
    if(workerName) {
      decodedWorkerName = workerName.replace(/}/g, '');
    }
    if (!validateWorkId(workId, decodedWorkerName)) { return; };
    async function init() {
      setMarkerList([]);
      setPolygonList([]);
      setPolylineList([]);
      setWorkerMarkerList([]);
      // 유효성 체크
      if (UserData.instance.appMode == null || UserData.instance.appMode === "") { UserData.instance.appMode = "WORKER"; };
      // parmas 체크
  
      if (bridgeManager.isMobile() && (workerMapPageData.mapComponentMode === mapComponentMode.WORKER_WORK_DETAIL || mapComponentMode.WORK_RECORD_GROUP_ADDRESS_LIST)) {
        bridgeManager.init();
        bridgeManager.setEventListener(BridgeCommands.BRG_ON_CURRENT_LOCATION_INFO_RECEIVED, (data: any) => {handleOnMyLocationReceived(data); console.log('location receive')});
        bridgeManager.setEventListener(BridgeCommands.BRG_ON_WORKING_LOCATION_INFO_RECEIVED, (data: any) => {handleOnMyLocationReceived(data); console.log('location receive')});
        bridgeManager.setEventListener(BridgeCommands.BRG_ON_SOCKET_DISCONNECT_REQUEST_RECEIVED, onSocketDisconnectRequestReceived);
        bridgeManager.setEventListener(BridgeCommands.BRG_ON_SOCKET_RECONNECT_REQUEST_RECEIVED, onSocketReconnectRequestReceived);    
        bridgeManager.bridgeCommand(BridgeCommands.BRG_DEVICE_INFO, "", onDeviceInfoReceived);
      }
  
      switch (workerMapPageData.mapComponentMode) {
        case mapComponentMode.WORKER_WORK_DETAIL:
          await reqWorkDetail();
          await reqWorkRecordLogList();
          await reqEquipmentDistance();
          break;
        case mapComponentMode.WORK_RECORD_GROUP_ADDRESS_LIST:
          // await reqWorkDetail();
          await reqWorkRecordLogList();
          workerMapPageData.workDetailData.landList.forEach((land) => {
            let polygonData = new PolygonData();
            polygonData.polygonAddress = land.landAddress!;
            polygonData.pointList = land.landMap;
            polygonData.polygonLineColor = getColorWithTeamCode(land.workTeam);
            polygonData.polygonTeam = land.workTeam;
            polygonList.push(polygonData);
          });
          setPolygonList(polygonList);
          setMarkerList(memoListToMarkerList(workerMapPageData.workDetailData.workMemoList));
          break;
        case mapComponentMode.WORK_RECORD_DETAIL_GROUP_DATE:
          reqWorkRecordDetailGroupDate();
          break;
        default:
          break;
      }
    }
    init();
    socket = io(BaseService.CHAT_URL, {
      reconnection: true,
      reconnectionDelay: 3000,
      reconnectionAttempts: 30,
      query: {
        workId: WorkerLocalData.instance.workId,
        workerName: WorkerLocalData.instance.workerName,
      }
    });

    socket.io.on("reconnect", () => {
      console.log("reconnect called");
      setSocketConnect(!socketConnect);
    });

    socket.on("disconnect", (reason) => {
      console.log('disconnect reason ' + reason);

      if (connectedTime !== null) {
        const disconnectedTime = Date.now(); // Get the current time in milliseconds when the socket is disconnected
        const connectionDuration = disconnectedTime - connectedTime; // Calculate the duration of the connection in milliseconds
        if(connectionDuration >= 4000) {
          socket.emit(SocketCommands.WORKER_LEAVE_ROOM, {workId: WorkerLocalData.instance.workId, workerName: workerName}); 
        }
      }
    });

    // 재연결 실패 시 알림을 표시하고 소켓 연결을 닫음
    socket.on("reconnect_failed", () => {
      console.log("reconnect_failed called");
      openAlertModal({ text: "서버 연결에 실패하였습니다. 앱 종료 후 다시 실행해주세요."});
      socket.close();
    });

    socket.on("connect", () => {
      console.log("Connected to the server", WorkerLocalData.instance.workId!);
      setConnectedTime(Date.now());
      socket.emit(SocketCommands.JOIN_ROOM, WorkerLocalData.instance.workId);
    });

    socket.on("connect_error", () => {
      setTimeout(() => {
        console.log("connect_error");
        hasConnectError = true;
      }, 1000);
    });

    // socket receiver
    // 소켓 1 점의위치 생성 시 receiver
    socket.on(SocketCommands.ON_WORKER_LOCATION_UPDATE_RECEIVED, (data: any) => {      
        onWorkRecordLogRecieved(data.workRecordLogData);
      }
    );

    // 소켓 2 필지 장비 작업 완료 receiver
    // firestore 로 대체
    socket.on(SocketCommands.ON_LAND_EQUIP_WORK_DONE_RECEIVED, (data: any) => {
      onLandEquipWorkDoneRecieved(data.workRecordLogData);
    });

    // 소켓 3. 필지 거래 완료 receiver
    // firestore 로 대체
    socket.on(SocketCommands.ON_LAND_TRADE_DONE_RECEIVED, (data: any) => {
      onLandTradeDoneRecieved(data.data);
    });

    socket.on(SocketCommands.ON_MEMO_REGISTED_RECEIVED, (data: any) => {
      onWorkMemoItemRegistRecieved(data.workMemoData);
    });

    // 소켓 4. 메모 기록 receiver
    socket.on(SocketCommands.ON_MEMO_UPDATE_RECEIVED, (data: any) => {
      onWorkMemoItemUpdateRecieved(data.workMemoData);
    });

    // 소켓 5. 작업 기록 receiver
    socket.on(SocketCommands.ON_WORK_RECORD_DONE_RECEIVED, (data: any) => {
      onWorkRecordRegistRecieved(data.workRecordData, data.workRecordGroupDateItemData);
    });

    // 소켓 6. 작업자 이탈 receiver
    socket.on(SocketCommands.ON_WORKER_LEAVE_ROOM_RECEIVED, (data: any) => {
      onWorkerLeaveRoomRecieved(data.workerName);
    });

    // 소켓 7. 작업자 마커 업데이트 receiver
    socket.on(SocketCommands.ON_WORKER_MARKER_UPDATE_RECEIVED, (data: any, callback) => {
      onWorkerMarkerDataRecieved(data.workerMarkerData);
    });

    // 소켓 8. 작업자 조인 receiver
    socket.on(SocketCommands.ON_NEW_USER_JOINED, (data: any) => {
      bridgeManager.bridgeCommand(BridgeCommands.BRG_CURRENT_LOCATION_INFO, "", null);
    });

    // 소켓 9. 작업 수정 삭제 receiver
    socket.on(SocketCommands.ON_WORK_REFRESH_RECEIVED, async (data: any) => {
      await reqWorkDetail();
      await reqWorkRecordLogList();
      await reqEquipmentDistance();
      
    });

    // 소켓 10. 장비 거리 업데이트 receiver
    socket.on(SocketCommands.ON_EQUIPMENT_DISTANCE_UPDATE_RECEIVED, (data: any) => {
      onEquipmentDistanceUpdateReceived(data.data.equipmentList);
    });

    // 소켓 11. 작업자 중복 체크 receiver
    socket.on(SocketCommands.ON_WORKER_ALREADY_WORKING_RECEIVED, () => {
      bridgeManager.bridgeCommand(BridgeCommands.BRG_WORKER_ALREADY_WORKING, "", null);
    })


    const workDocRef = doc(fireStoreManager, "workerWork", workId as string);
    const landAddressCollectionRef = collection(workDocRef, "landAddress");
    // 하위 컬렉션의 모든 문서 가져오기
    const getLandAddressDocs = async () => {
      const landAddressSnapshot = await getDocs(landAddressCollectionRef);
      const landAddressList = landAddressSnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      }));
      return landAddressList;
    };

    // Firestore fetch data
    getLandAddressDocs().then(landAddressList => {
      const polygonDataList = firebaseDataToPolygonData(landAddressList);
      let fireBasePolygon = polygonDataList.filter((polygonData) => {
        return polygonData.polygonTeam === myWorkerItemData.workTeam;
      });
      setPolygonList(fireBasePolygon);
    });

    // 필지 추가 리스너
    // 장비 작업 완료 리스너
    // 필지 거래 완료 리스너
    const fireStoreUnsub = onSnapshot(landAddressCollectionRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const isDuplicate = workerMapPageData.workDetailData.landList.some((landItem) => {
            return (
              landItem.landAddress === change.doc.data().polygonAddress
            ); // 메세지 내용도 비교
          });
          
          if(!isDuplicate) {
            const newLandItem: WorkRecordGroupAddressItemData = new WorkRecordGroupAddressItemData();
            newLandItem.landAddress = change.doc.data().polygonAddress;
            newLandItem.landTradeDone = "N";
            newLandItem.landMap = stringToLandMap(change.doc.data().pointList);
            newLandItem.workRecordLogList = [];
            newLandItem.workTeam = change.doc.data().polygonTeam;
            workerMapPageData.workDetailData.landList.push(newLandItem);
            
            const polygonData = new PolygonData();
            polygonData.polygonAddress = change.doc.data().polygonAddress;  
            polygonData.pointList = stringToLandMap(change.doc.data().pointList);
            polygonData.polygonColor = change.doc.data().polygonColor;
            polygonData.polygonLineColor = change.doc.data().polygonLineColor || "#39DE2A";
            polygonData.polygonTeam = change.doc.data().polygonTeam || "N";
            setPolygonList(prevPolygonList => [...prevPolygonList, polygonData]);
          }
          
        } else if (change.type === "modified") {
          setPolygonList(prevPolygonList => {
            const updatedList = prevPolygonList.map(polygon => {
              if (polygon.polygonAddress === change.doc.data().polygonAddress) {
                return {
                  polygonAddress: change.doc.data().polygonAddress,
                  polygonColor: change.doc.data().polygonColor,
                  pointList: stringToLandMap(change.doc.data().pointList),
                  polygonLineColor : change.doc.data().polygonLineColor || "#39DE2A",
                  polygonTeam : change.doc.data().polygonTeam || "N"
                } as PolygonData;
              }
              return polygon;
            });
            return updatedList;
          });
        }
      });
    });

    window.dataLayer.push({
      event: 'view_work',
      workId: `${WorkerLocalData.instance.workId}`,
    });

    return () => {
      socket.disconnect();
      fireStoreUnsub();
    };
  }, [socketConnect]);

  // workId validate 함수
  function validateWorkId( workId: string | undefined, workerName: string | undefined ): boolean {
    if (workId === undefined || workerName === undefined) {
      if (
        WorkerLocalData.instance.workId == null ||
        WorkerLocalData.instance.workId === "" ||
        WorkerLocalData.instance.workerName == null ||
        WorkerLocalData.instance.workerName === ""
      ) {
        // 화면 진입 막기
        // alert 모달 띄우기, 테스터용 문구, 버튼 추가
        
        // 심사용 코드
        // openTesterModal({
        //   text: `Welcome to Coweats.\nIn order to proceed next step, you need the URL for invitation.\nPlease click the URL if you have. Otherwise, the app will be terminated.\nFor Testers, please click 'TESTER' button below.`, 
        //   onConfirm: () => {
        //     bridgeManager.bridgeCommand(
        //       BridgeCommands.BRG_NO_INVITE_CODE,
        //       "",
        //       null
        //     );
        //   },
        //   onTester: () => {
        //     bridgeManager.bridgeCommand(
        //       BridgeCommands.BRG_TEST_BUTTON_CLICKED,
        //       "",
        //       null
        //     );
        //   } 
        // });

        openOneButtonModal({
          text: 
          `작업을 시작하려면 초대 코드 URL이 필요합니다.\nURL을 클릭하여 앱을 실행해주세요.`, 
          onConfirm: () => {
            bridgeManager.bridgeCommand(BridgeCommands.BRG_NO_INVITE_CODE, "", null);
          },
        });
        
        return false;
      }

      workId = WorkerLocalData.instance.workId;
      workerName = WorkerLocalData.instance.workerName;
      return true;
    } else {
      WorkerLocalData.instance.workId = workId;
      WorkerLocalData.instance.workerName = workerName;
      return true;
    }
  }  

  // 작업 상세 REQUEST
  async function reqWorkDetail() {
    let workDetailReqData = new WorkDetailReqData();
    workDetailReqData.workId = WorkerLocalData.instance.workId!;    

    const workDetailResData = (await workDetailRequest.send(workDetailReqData)) as WorkDetailResData;
    workerMapPageData.workDetailData = workDetailResDataToWorkDetailData(workDetailResData);   
    workerMapPageData.workId = WorkerLocalData.instance.workId!;
    workerMapPageData.workDetailData.workId = workerMapPageData.workId;
    myWorkerItemData = workerMapPageData.workDetailData.workerList.find((item) => 
      item.workerName === WorkerLocalData.instance.workerName
    )!;  

    if (myWorkerItemData === undefined) {
      bridgeManager.bridgeCommand(BridgeCommands.BRG_APP_TERMINATION, "", null);
      return;
    }

    setMyTeam(myWorkerItemData.workTeam);

    workerMapPageData.workDetailData.landList = workerMapPageData.workDetailData.landList.filter((landItem) => {
      return (landItem.workTeam === myWorkerItemData.workTeam);
    });

    const myIcon = equipmentIconByEquipmentType(stringToEquipmentTypeCode[myWorkerItemData.equipmentType]);
    const myMarker: MarkerData = new MarkerData();
    myMarker.markerIcon = myIcon;
    myMarker.info = WorkerLocalData.instance.workerName;
    setMyMarker(myMarker);
    
    setMarkerList(memoListToMarkerList(workerMapPageData.workDetailData.workMemoList));
    setViewModel({ workerMapPageData });
  }

  // 장비 거리 REQUEST
  async function reqEquipmentDistance() {
    let equipmentDistanceReqData = new EquipmentDistanceReqData();
    equipmentDistanceReqData.workId = WorkerLocalData.instance.workId!;
    equipmentDistanceReqData.workerName = WorkerLocalData.instance.workerName;
    equipmentDistanceReqData.equipmentName = myWorkerItemData.equipmentName;
    equipmentDistanceReqData.equipmentType = myWorkerItemData.equipmentType;

    const equipmentDistanceRequest: EquipmentDistanceRequest = new EquipmentDistanceRequest();
    const equipmentDistanceResData: EquipmentDistanceResData = await (equipmentDistanceRequest.send(equipmentDistanceReqData)) as EquipmentDistanceResData;
    workerMapPageData.workDetailData.workRecordDistance = Number(equipmentDistanceResData.equipmentDistance);
    setViewModel({ workerMapPageData });
  }

  // 날짜별 작업 이력 REQUEST
  async function reqWorkRecordDetailGroupDate() {
    const workRecordDetailGroupDateReqData: WorkRecordDetailGroupDateReqData = new WorkRecordDetailGroupDateReqData();
    workRecordDetailGroupDateReqData.workId = location.state.workId;
    const workRecordDetailGroupDateResData = (await workRecordDetailGroupDateRequest.send(workRecordDetailGroupDateReqData)) as WorkRecordDetailGroupDateResData;
    workerMapPageData.workRecordDetailGroupDateData = workRecordDetailGroupDateResDataToWorkRecordDetailGroupDateData(workRecordDetailGroupDateResData);
    setViewModel({ workerMapPageData });
  }

  // 작업 기록 등록 REQUEST
  async function reqWorkRecordLogRegistRequest(workRecordLog: WorkRecordLogData) {
    let workRecordLogReqData: WorkRecordLogReqData = new WorkRecordLogReqData();
    workRecordLogReqData.workId = WorkerLocalData.instance.workId;
    workRecordLogReqData.landAddress = workRecordLog.landAddress;
    workRecordLogReqData.workerName = workRecordLog.workerName;
    workRecordLogReqData.equipmentName = workRecordLog.equipmentName;
    workRecordLogReqData.equipmentType = workRecordLog.equipmentType;
    workRecordLogReqData.equipmentOrder = workRecordLog.equipmentOrder;
    workRecordLogReqData.colorOrder = workRecordLog.colorOrder;
    if (workRecordLog.equipmentType === EquipmentTypeCode.LAPPING || workRecordLog.equipmentType === EquipmentTypeCode.COMBINE) {
      workRecordLogReqData.landProgress = "Y";
    }
    await workRecordLogRequest.send(workRecordLogReqData);
    window.dataLayer.push({
      event: "write_work_note",
      work_id: `${WorkerLocalData.instance.workId}`,
      work_location: `${workRecordLog.landAddress}`,
      equipment: `${workRecordLog.equipmentType}`,
    });
    if (workerMapPageData.workDetailData.masterWorkId !== undefined && workerMapPageData.workDetailData.masterWorkId !== "") {
      let masterWorkRecordLogReqData : WorkRecordLogReqData = new WorkRecordLogReqData();
      masterWorkRecordLogReqData = workRecordLogReqData;
      masterWorkRecordLogReqData.workId = workerMapPageData.workDetailData.masterWorkId;
      await workRecordLogRequest.send(masterWorkRecordLogReqData);
    }
    

    //타입이 랩핑기나 복합기면 workModal 띄우고 사운드 재생}
    if (workRecordLog.equipmentType === EquipmentTypeCode.LAPPING || workRecordLog.equipmentType === EquipmentTypeCode.COMBINE) {
      const newWorkRecordGroupAddressItemData: WorkRecordGroupAddressItemData = workerMapPageData.workDetailData.landList.find(
        (item) => item.landAddress === workRecordLog.landAddress
      )!;

      const modalProps: Omit<WorkModalProps, "onClose"> = {
        workRecordGroupAddressItemData: newWorkRecordGroupAddressItemData,
        workDetailData: workerMapPageData.workDetailData,
        onWorkRecordRegist: handleOnWorkRecordRegist,
        onWorkRecordUpdate: handleOnWorkRecordUpdate,
        onSaveWorkMemo: handleSaveWorkMemo,
        onDeleteWorkMemo: handleDeleteWorkMemo,
        handleLandEquipmentDoneBtnClick: handleLandEquipmentDoneBtnClick,
      };
      openWorkModal(modalProps);

    }
  }

  // 작업 로그 REQUEST
  async function reqWorkRecordLogList() {
    if (WorkerLocalData.instance.workId == null || WorkerLocalData.instance.workId === "") {return;};
    const workRecordLogListReqData: WorkRecordLogListReqData = new WorkRecordLogListReqData();
    workRecordLogListReqData.workId = WorkerLocalData.instance.workId;
    const workRecordLogListResData = (await workRecordLogListRequest.send(workRecordLogListReqData)) as WorkRecordLogListResData;
    const workRecordLogListData: Array<WorkRecordLogData> = workRecordLogListResDataToWorkRecordLogListData(workRecordLogListResData);

    // 서버 데이터.

    if (!(workRecordLogListData && workRecordLogListData.length > 0)) {return;};

    workerMapPageData.workDetailData.landList.forEach((landItem) => {
      const findedLog = workRecordLogListData.filter((logItem) => {
        return landItem.landAddress === logItem.landAddress;
      });
      landItem.workRecordLogList = findedLog;
    });


    // 서버 데이터 -> 화면 데이터
    for(let i = 0; i < workerMapPageData.workDetailData.landList.length; i++){
      let workerNameList = "";
      for(let j=0; j < workerMapPageData.workDetailData.landList[i].workRecordLogList.length; j++){
        workerNameList += workerMapPageData.workDetailData.landList[i].workRecordLogList[j].workerName + ", ";
      }
      setDebugRecordLog(`[서버 데이터] addr = ${workerMapPageData.workDetailData.landList[i].landAddress},length = ${workerMapPageData.workDetailData.landList[i].workRecordLogList.length}, workerNameList = ${workerNameList}` );
    }
    

    workerMapPageData.workDetailData.lastWorkRecordLogData.equipmentType = myWorkerItemData.equipmentType;
    workerMapPageData.workDetailData.lastWorkRecordLogData.equipmentName = myWorkerItemData.equipmentName;
    workerMapPageData.workDetailData.lastWorkRecordLogData.workerName = myWorkerItemData.workerName;

    for(let i = 0; i < workerMapPageData.workDetailData.landList.length; i++) {
      for(let j = 0; j < workerMapPageData.workDetailData.landList[i].workRecordLogList.length; j++) {
        
        if(workerMapPageData.workDetailData.lastWorkRecordLogData.equipmentType === workerMapPageData.workDetailData.landList[i].workRecordLogList[j].equipmentType
          && workerMapPageData.workDetailData.lastWorkRecordLogData.equipmentName === workerMapPageData.workDetailData.landList[i].workRecordLogList[j].equipmentName
          && workerMapPageData.workDetailData.lastWorkRecordLogData.workerName === workerMapPageData.workDetailData.landList[i].workRecordLogList[j].workerName
          && workerMapPageData.workDetailData.lastWorkRecordLogData.equipmentOrder < workerMapPageData.workDetailData.landList[i].workRecordLogList[j].equipmentOrder 
          ){
          workerMapPageData.workDetailData.lastWorkRecordLogData = workerMapPageData.workDetailData.landList[i].workRecordLogList[j];
        }
      }
    }  
  }

  type LatLng = [number, number];
  type LatLngList = LatLng[];
  const myLatLngList: LatLngList = [];

  async function onDeviceInfoReceived(message:any) {
    // 버전 체크 통과하면.
    // if(!message.deviceInfo.appVersion) {
    //   openOneButtonModal({
    //     text: 
    //     `최신 버전으로 재설치해주세요.\n앱을 종료합니다.`, 
    //     onConfirm: () => {
    //       bridgeManager.bridgeCommand(BridgeCommands.BRG_APP_TERMINATION, "", null);
    //     },
    //   });
    //   return;
    // } else if (message.deviceInfo.needUpdateYn === 'Y') {
    //   // 업데이트 필요.
    //   openOneButtonModal({
    //     text: 
    //     `최신 버전으로 업데이트가 필요합니다.`, 
    //     onConfirm: () => {
    //       if(bridgeManager.isMobile()) {
    //         if(bridgeManager.isAndroid()) {
    //           const url = "https://play.google.com/store/apps/details?id=com.ms.coweatsworkerapp";
    //           window.location.href = url;
    //         } else if(bridgeManager.isIos()) {
    //         }
    //       }
    //     },
    //   });
    //   return;
    // }
    DeviceData.instance.appVersion = message.deviceInfo.appVersion;
    let needUpdate = await reqLatestAppversion(message.deviceInfo.deviceOsName, message.appMode);
    if (needUpdate) {
      return;
    }
    bridgeManager.bridgeCommand(BridgeCommands.BRG_START_WORKING, "", null);
  }

  async function reqLatestAppversion(os : string, appType : string): Promise<boolean> {
    let latestAppVersionRequest: LatestAppVersionRequest  = new LatestAppVersionRequest();
    let latestAppVersionReqData: LatestAppVersionReqData = new LatestAppVersionReqData();
    latestAppVersionReqData.os = os;
    latestAppVersionReqData.appType = appType;

    const response = await latestAppVersionRequest.send(latestAppVersionReqData) as LatestAppVersionResData;
    if (response.responseCode !== responseCode.OK) {
      return false;
    }  
    if (response.appVersion === DeviceData.instance.appVersion) {
      return false;
    }
    openOneButtonModal({
      text: 
      `최신 버전으로 업데이트가 필요합니다.`, 
      onConfirm: () => {
        if (bridgeManager.isAndroid()) {
          const url = 'https://play.google.com/store/apps/details?id=com.ms.coweatsworkerapp';
          window.location.href = url;
        } else {
          const url = 'https://apps.apple.com/kr/app/id6474243246';
          window.location.href = url;
        }
        return true;
      },
    });
    return true;
  }

  // 소켓 퇴장 리시버
  function onSocketDisconnectRequestReceived() {
    socket.disconnect();
  }

  // 소켓 재연결 리시버
  function onSocketReconnectRequestReceived() {
    setSocketConnect(!socketConnect);
  }

  // 내 위치 수신 함수
  async function handleOnMyLocationReceived(message: any) {
    const workingLocationInfo = message.workingLocationInfo;
    if (!workingLocationInfo) {return;};
    
    let latitude = Number(workingLocationInfo.lat);
    let longitude = Number(workingLocationInfo.lng);

    //디버그를 위한 코드    
    myLatLngList.push([latitude, longitude]);

    let distance = 0;
    if(myLatLngList.length > 1){
      let oldLat = myLatLngList[myLatLngList.length - 2][0];
      let oldLng = myLatLngList[myLatLngList.length - 2][1];
      distance = calculateDistance( {lat : oldLat, lng: oldLng}, {lat : latitude, lng : longitude});
    }

    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    let time = `${hours}:${minutes}:${seconds}`;

    setDebugGpsLog("lat = " + latitude + ", lng = " + longitude + ", 시간 = " + time  + ", 거리 = " + distance);
    //디버그 코드 끝


    workerMapPageData.mapComponentData.myLatLng = [latitude, longitude];
    setMyLatLng([latitude, longitude]);

    let myWorkerMarkerData : MarkerData = new MarkerData();
    myWorkerMarkerData.info = myWorkerItemData.workerName;
    myWorkerMarkerData.markerId = myWorkerItemData.workerName;
    myWorkerMarkerData.latitude = latitude;
    myWorkerMarkerData.longitude = longitude;
    myWorkerMarkerData.markerIcon = equipmentIconByEquipmentType(stringToEquipmentTypeCode[myWorkerItemData.equipmentType]);
    myWorkerMarkerData.workTeam = myWorkerItemData.workTeam;
    sendWorkerMarkerData(myWorkerMarkerData);

    if (WorkRecordLogValidator.validateDate(workerMapPageData.workDetailData.workStartDate, workerMapPageData.workDetailData.workEndDate) === false) {
      return false;
    }

    const finedLandIndex = finedInLandList(workerMapPageData.workDetailData.landList, latitude, longitude);
    if (finedLandIndex < 0) {
      console.log("작업지역이 아닙니다.");
      return;
    }

    if(workerMapPageData.workDetailData.landList[finedLandIndex].workTeam !== myWorkerItemData.workTeam){return;}

    if (workerMapPageData.workDetailData.landList[finedLandIndex].landTradeDone === "Y") {return;};

    let myWorkRecordLog: WorkRecordLogData;
    const finedRecordLogIndex = findMyWorkRecordLogByLandAddress(myWorkerItemData, workerMapPageData.workDetailData.landList[finedLandIndex].workRecordLogList);
    if (finedRecordLogIndex === -1) {
      myWorkRecordLog = new WorkRecordLogData();
      myWorkRecordLog.workId = WorkerLocalData.instance.workId!;
      myWorkRecordLog.landAddress = workerMapPageData.workDetailData.landList[finedLandIndex].landAddress;
      myWorkRecordLog.workerName = myWorkerItemData.workerName;
      myWorkRecordLog.equipmentName = myWorkerItemData.equipmentName;
      myWorkRecordLog.equipmentType = myWorkerItemData.equipmentType;
      myWorkRecordLog.colorOrder = colorOrderByEquipmentType(stringToEquipmentTypeCode[myWorkerItemData.equipmentType]);
      myWorkRecordLog.workTeam = myWorkerItemData.workTeam;
      workerMapPageData.workDetailData.landList[finedLandIndex].workRecordLogList.unshift(myWorkRecordLog);
    } else {
      myWorkRecordLog = workerMapPageData.workDetailData.landList[finedLandIndex].workRecordLogList[finedRecordLogIndex];
    }

    if (myWorkRecordLog.workStatus === "Y") {return;};

    if (myWorkRecordLog.polyLine.length > 0) {
      // 마지막 좌표와 현재 좌표의 거리 체크
      if (
        !WorkRecordLogValidator.validateDistanceWithPoint(
          myWorkRecordLog.polyLine[myWorkRecordLog.polyLine.length - 1][0],
          myWorkRecordLog.polyLine[myWorkRecordLog.polyLine.length - 1][1],
          latitude,
          longitude
        )
      ) {
        return;
      }
    }

    myWorkRecordLog.polyLine.push([latitude, longitude]);
    const workRecordDistance = workerMapPageData.workDetailData.workRecordDistance ?? 10;
    const minPolylineLength = Math.ceil(workRecordDistance / 3);

    if (myWorkRecordLog.polyLine && myWorkRecordLog.polyLine.length >= minPolylineLength) {
      myWorkRecordLog.workStatus = "Y";
      let workOrder = getMaxEquipmentOrder(myWorkRecordLog.equipmentName, myWorkRecordLog.equipmentType, WorkerLocalData.instance.workerName);

      myWorkRecordLog.equipmentOrder = workOrder;
      reqWorkRecordLogRegistRequest(myWorkRecordLog);

      

      workerMapPageData.workDetailData.landList[finedLandIndex].workRecordLogList.splice(finedRecordLogIndex, 1);
      workerMapPageData.workDetailData.landList[finedLandIndex].workRecordLogList.unshift(myWorkRecordLog);

      const targetAddress = myWorkRecordLog.landAddress;
      const polygonList = workRecordGroupAddressListToPolygonList(workerMapPageData.workDetailData.landList);
      updateFireStoreLandInfo(targetAddress, polygonList);
      updateFireStoreMasterLandInfo(targetAddress, polygonList);

      sendLandEquipWorkDone(myWorkRecordLog);
      if (myWorkRecordLog.equipmentType === EquipmentTypeCode.LAPPING || myWorkRecordLog.equipmentType === EquipmentTypeCode.COMBINE) {
        bridgeManager.bridgeCommand(BridgeCommands.BRG_SOUND_ALERT_START, "", null);
      }     
    }

    const allWorkRecordLogList: Array<WorkRecordLogData> = [];
    workerMapPageData.workDetailData.landList.forEach((landItem) => {
      landItem.workRecordLogList.forEach((workRecordLogItem) => {
        if (workRecordLogItem.workStatus === "N") {
          allWorkRecordLogList.push(workRecordLogItem);
        }
      });
    });
    setPolylineList(workRecordLogListToPolylineList(allWorkRecordLogList));
    sendWorkRecordLogData(myWorkRecordLog);
  }

  // 파이어 스토어 등록 함수
  async function updateFireStoreLandInfo(targetAddress:string, polygonList:PolygonData[]) {
    const matchedPolygon = polygonList.find((polygon) => polygon.polygonAddress === targetAddress);
    if(matchedPolygon){
      const polygonColor = matchedPolygon?.polygonColor;
      try {
        const newRef = doc(fireStoreManager, `workerWork`, workId as string, 'landAddress', matchedPolygon!.polygonAddress as string);
        const data = {
          polygonAddress: matchedPolygon!.polygonAddress,
          polygonColor: matchedPolygon!.polygonColor,
          polygonLineColor: matchedPolygon!.polygonLineColor,
          polygonTeam: matchedPolygon!.polygonTeam,
        };
        await updateDoc(newRef, data);
      } catch (error) {
        // setDoc 메서드가 실패한 경우, 에러를 처리하고 필요한 작업 수행
        console.error('Error occurred while saving the document:', error);
      }
    }
  }

  // 상위 작업 파이어 스토어 업데이트 함수
  async function updateFireStoreMasterLandInfo(targetAddress: string, polygonList: PolygonData[]) {
    let masterWorkId = workerMapPageData.workDetailData.masterWorkId;
    if (!masterWorkId) return;
    const matchedPolygon = polygonList.find((polygon) => polygon.polygonAddress === targetAddress);
    if (matchedPolygon) {
      const polygonColor = matchedPolygon?.polygonColor;
      try {
        const newRef = doc(fireStoreManager, `workerWork`, masterWorkId, 'landAddress', matchedPolygon!.polygonAddress as string);
        const data = {
          polygonAddress: matchedPolygon!.polygonAddress,
          polygonColor: matchedPolygon!.polygonColor,
          polygonLineColor: matchedPolygon!.polygonLineColor,
          polygonTeam: matchedPolygon!.polygonTeam,
        };
        await updateDoc(newRef, data);
      } catch (error) {
        // setDoc 메서드가 실패한 경우, 에러를 처리하고 필요한 작업 수행
        console.error('Error occurred while saving the document:', error);
      }
    }
  }

  // 장비 order 함수
  function getMaxEquipmentOrder(equipmentName: string, equipmentType:string, workerName:string): number {
    let maxEquipmentOrder = 1;

    if(workerMapPageData.workDetailData.lastWorkRecordLogData.equipmentOrder > 0
      && workerMapPageData.workDetailData.lastWorkRecordLogData.equipmentType === equipmentType
      && workerMapPageData.workDetailData.lastWorkRecordLogData.equipmentName === equipmentName
      && workerMapPageData.workDetailData.lastWorkRecordLogData.workerName === workerName)
      {
      maxEquipmentOrder = workerMapPageData.workDetailData.lastWorkRecordLogData.equipmentOrder + 1;
      workerMapPageData.workDetailData.lastWorkRecordLogData.equipmentOrder = maxEquipmentOrder;
      return maxEquipmentOrder;
    }
    
    workerMapPageData.workDetailData.lastWorkRecordLogData.equipmentOrder = maxEquipmentOrder;
    return maxEquipmentOrder;
  }

  // 폴리곤 클릭 이벤트
  function handleOnPolygonClick(polygonAddress: string) {
    if (polygonAddress === undefined) {return;};
    let newWorkRecordGroupAddressItemData: WorkRecordGroupAddressItemData = new WorkRecordGroupAddressItemData();

    const landIndex = workerMapPageData.workDetailData.landList?.findIndex((item) => item.landAddress === polygonAddress);
    if (landIndex! > -1) {
      newWorkRecordGroupAddressItemData = workerMapPageData.workDetailData.landList![landIndex!];
    };
    
    const modalProps: Omit<WorkModalProps, "onClose"> = {
      workRecordGroupAddressItemData: newWorkRecordGroupAddressItemData,
      workDetailData: workerMapPageData.workDetailData,
      onWorkRecordRegist: handleOnWorkRecordRegist,
      onWorkRecordUpdate: handleOnWorkRecordUpdate,
      onSaveWorkMemo: handleSaveWorkMemo,
      onDeleteWorkMemo: handleDeleteWorkMemo,
      handleLandEquipmentDoneBtnClick: handleLandEquipmentDoneBtnClick
    };
    openWorkModal(modalProps);
  }

  // 작업 메모 함수
  function handleSaveWorkMemo(workMemo: WorkMemoItemData) {    
    const findedIndex = workerMapPageData.workDetailData.workMemoList.findIndex((item) => {
      return item.landAddress === workMemo.landAddress;
    });
    if(findedIndex > -1){
      workerMapPageData.workDetailData.workMemoList[findedIndex] = workMemo;
      sendWorkMemoItemUpdate(workMemo);
    }
    else{
      workerMapPageData.workDetailData.workMemoList.push(workMemo);
      sendWorkMemoItemRegist(workMemo);
    }
    setMarkerList(memoListToMarkerList(workerMapPageData.workDetailData.workMemoList));
  }

  // 작업 메모 삭제 함수
  function handleDeleteWorkMemo(workMemo: WorkMemoItemData) {
    const findedIndex = workerMapPageData.workDetailData.workMemoList.findIndex((item) => {
      return item.landAddress === workMemo.landAddress;
    });
    if (findedIndex > -1) {
      workerMapPageData.workDetailData.workMemoList[findedIndex] = workMemo;
      workerMapPageData.workDetailData.workMemoList = workerMapPageData.workDetailData.workMemoList.filter((item) => {
        return item.landAddress !== workMemo.landAddress;
      });
      sendWorkMemoItemUpdate(workMemo);      
    }
    setMarkerList(memoListToMarkerList(workerMapPageData.workDetailData.workMemoList));
  }

  // 작업 기록 등록 함수
  function handleOnWorkRecordRegist(workRecordRegistrationReqData: WorkRecordRegistrationReqData) {
    const finedLandIndex = workerMapPageData.workDetailData.landList.findIndex((landItem) => {
        return workRecordRegistrationReqData.landAddress === landItem.landAddress;
      }
    );
    if (finedLandIndex < 0) {return;}

    workerMapPageData.workDetailData.landList[finedLandIndex].landOutput = workRecordRegistrationReqData.landOutput;
    
    const findedWorkRecordGroupDate = workerMapPageData.workDetailData.workRecordGroupDateList.findIndex((item) => {
      return item.workDate === workRecordRegistrationReqData.workDate;
    });
    
    let workRecordGroupDateItemData:WorkRecordGroupDateItemData;
    if(findedWorkRecordGroupDate > -1){
      workerMapPageData.workDetailData.workRecordGroupDateList[findedWorkRecordGroupDate].dateOutput += workRecordRegistrationReqData.landOutput;
      workRecordGroupDateItemData = workerMapPageData.workDetailData.workRecordGroupDateList[findedWorkRecordGroupDate];
    }
    else{      
      workRecordGroupDateItemData = new WorkRecordGroupDateItemData();
      workRecordGroupDateItemData.amHumidity = workRecordRegistrationReqData.amHumidity;
      workRecordGroupDateItemData.highTemp = workRecordRegistrationReqData.highTemp;
      workRecordGroupDateItemData.lowTemp = workRecordRegistrationReqData.lowTemp;
      workRecordGroupDateItemData.pmHumidity = workRecordRegistrationReqData.pmHumidity;
      workRecordGroupDateItemData.workDate = workRecordRegistrationReqData.workDate;
      workRecordGroupDateItemData.dateOutput = workRecordRegistrationReqData.landOutput;
      workRecordGroupDateItemData.amWeather = workRecordRegistrationReqData.amWeather;
      workRecordGroupDateItemData.pmWeather = workRecordRegistrationReqData.pmWeather;
      workerMapPageData.workDetailData.workRecordGroupDateList.push(workRecordGroupDateItemData);      
    }

    setViewModel({ workerMapPageData });
    sendWorkRecordRegist(workRecordRegistrationReqData, workRecordGroupDateItemData);
  }

  // 작업 기록 수정 함수
  function handleOnWorkRecordUpdate(workRecordRegistrationReqData: WorkRecordRegistrationReqData, oldLandOutput: number): void {
    const finedLandIndex = workerMapPageData.workDetailData.landList.findIndex((landItem) => {
        return workRecordRegistrationReqData.landAddress === landItem.landAddress;
      }
    );
    if (finedLandIndex < 0) { return;}

    workerMapPageData.workDetailData.landList[finedLandIndex].landOutput = workRecordRegistrationReqData.landOutput;
    const findedWorkRecordGroupDate = workerMapPageData.workDetailData.workRecordGroupDateList.findIndex((item) => {
      return item.workDate === workRecordRegistrationReqData.workDate;
    });
    
    let workRecordGroupDateItemData:WorkRecordGroupDateItemData;
    if(findedWorkRecordGroupDate < 0){return;}
    workerMapPageData.workDetailData.workRecordGroupDateList[findedWorkRecordGroupDate].dateOutput += (workRecordRegistrationReqData.landOutput - oldLandOutput);
    workRecordGroupDateItemData = workerMapPageData.workDetailData.workRecordGroupDateList[findedWorkRecordGroupDate];
    setViewModel({ workerMapPageData });
    sendWorkRecordRegist(workRecordRegistrationReqData, workRecordGroupDateItemData);
  }

  // 마커 클릭 이벤트
  function handleOnMarkerClick(markerId: string) {
    const clickedMarker = markerList.find((marker) => marker.markerId === markerId);
    if (!clickedMarker) {return;};

    let newWorkMemoItemData: WorkMemoItemData = new WorkMemoItemData();

    const memoIndex = workerMapPageData.workDetailData.workMemoList?.findIndex((item) => item.landAddress === markerId);
    if (memoIndex! < 0) {return;}

    newWorkMemoItemData = workerMapPageData.workDetailData.workMemoList![memoIndex!];
    
    const modalProps: Omit<WorkMemoModalProps, "onClose"> = {
      workMemoItemData: newWorkMemoItemData,
      masterWorkId: workerMapPageData.workDetailData.masterWorkId,
      onSave: handleSaveWorkMemo,
      onDelete: handleDeleteWorkMemo
    };
    openWorkMemoModal(modalProps);
  }

  // 필지 이동 함수
  function handleMoveToLand(latitude : number, longitude: number) {
    setMapCenter([latitude, longitude]);
  }

    // 지도 클릭 시 pin 표시
  function handlePin (latitude: number, longitude: number) {
    setSheetState('minimum');
    setPinLatLng([latitude, longitude]);
  };

  // 장비 완료 버튼 이벤트
  async function handleLandEquipmentDoneBtnClick(landAddress: string) {
    myWorkerItemData = workerMapPageData.workDetailData.workerList.find((item) => 
      item.workerName === WorkerLocalData.instance.workerName
    )!;
    
    let workRecordLogData: WorkRecordLogData = new WorkRecordLogData();    
    let workOrder = getMaxEquipmentOrder(myWorkerItemData.equipmentName, myWorkerItemData.equipmentType, WorkerLocalData.instance.workerName);

    workRecordLogData.equipmentOrder = workOrder;
    workRecordLogData.workId = WorkerLocalData.instance.workId;
    workRecordLogData.landAddress = landAddress;
    workRecordLogData.workerName = myWorkerItemData.workerName;
    workRecordLogData.equipmentType = myWorkerItemData.equipmentType;
    workRecordLogData.equipmentName = myWorkerItemData.equipmentName;
    workRecordLogData.workTeam = myWorkerItemData.workTeam;
    workRecordLogData.workStatus = 'Y';
    workRecordLogData.colorOrder = colorOrderByEquipmentType(stringToEquipmentTypeCode[myWorkerItemData.equipmentType]);

    const finedLandIndex = workerMapPageData.workDetailData.landList.findIndex((landItem) => {return (landAddress === landItem.landAddress)});

    const finedRecordLogIndex = workerMapPageData.workDetailData.landList[finedLandIndex].workRecordLogList.findIndex((item) => {
        return (
          item.workerName === workRecordLogData.workerName &&
          item.equipmentType === workRecordLogData.equipmentType &&
          item.equipmentName === workRecordLogData.equipmentName
        );
      }
    );

    // if(myWorkerItemData.equipmentType === EquipmentTypeCode.BAILER){      
    //   let raykeyDone = workerMapPageData.workDetailData.landList[finedLandIndex].workRecordLogList.findIndex((item) => 
    //       item.equipmentType === EquipmentTypeCode.RAYKEY
    //     );     
    //   if(raykeyDone === -1){
    //     openAlertModal({ text: '레이키 작업을 완료해주세요.'});
    //     return;
    //   }
    // }

    // if(myWorkerItemData.equipmentType === EquipmentTypeCode.LAPPING || myWorkerItemData.equipmentType === EquipmentTypeCode.COMBINE){
    //   let bailerDone = workerMapPageData.workDetailData.landList[finedLandIndex].workRecordLogList.findIndex((item) => 
    //       item.equipmentType === EquipmentTypeCode.BAILER
    //     )  
    //   if(bailerDone === -1){
    //     openAlertModal({text: '베일러 작업을 완료해주세요'});
    //     return;
    //   }
    // }

    if((finedRecordLogIndex > -1) && (workerMapPageData.workDetailData.landList[finedLandIndex].workRecordLogList[finedRecordLogIndex].workStatus === 'Y')){
      openAlertModal({text: "이미 완료된 작업입니다."});
      return;
    } 
    if(finedRecordLogIndex > -1){     
      workerMapPageData.workDetailData.landList[finedLandIndex].workRecordLogList.splice(finedRecordLogIndex, 1);
    };

    workerMapPageData.workDetailData.landList[finedLandIndex].workRecordLogList.unshift(workRecordLogData);    
    reqWorkRecordLogRegistRequest(workRecordLogData);

    const targetAddress = workRecordLogData.landAddress;
    const polygonList = workRecordGroupAddressListToPolygonList(workerMapPageData.workDetailData.landList);
    updateFireStoreLandInfo(targetAddress, polygonList);
    updateFireStoreMasterLandInfo(targetAddress, polygonList);
    setPolygonList(workRecordGroupAddressListToPolygonList(workerMapPageData.workDetailData.landList));
    sendLandEquipWorkDone(workRecordLogData);
  
    openAlertModal({text: "작업이 완료되었습니다."});    
  }

  // 작업자 앱 종료 함수
  function handleWorkerAppExit() {
    if (window.confirm("작업을 종료하시겠습니까?")) {
      socket.emit(SocketCommands.WORKER_LEAVE_ROOM, {workId: WorkerLocalData.instance.workId, workerName: workerName});
      let bridgeManager: BridgeManager = new BridgeManager();
      bridgeManager.bridgeCommand(BridgeCommands.BRG_APP_TERMINATION, "", null);
      return;
    }
  }
  
  // 작업 로그 소켓 센더
  function sendWorkRecordLogData(workRecordLogData: WorkRecordLogData) {
    socket.emit(SocketCommands.WORKER_LOCATION_UPDATE, { workId: WorkerLocalData.instance.workId, workRecordLogData: workRecordLogData });
    if (workerMapPageData.workDetailData.masterWorkId !== undefined && workerMapPageData.workDetailData.masterWorkId !== "") {
      socket.emit(SocketCommands.WORKER_LOCATION_UPDATE, { workId: workerMapPageData.workDetailData.masterWorkId, workRecordLogData: workRecordLogData });
    }
  } 

  // 거리 계산 함수
  function calculateDistance(
    userPosition: { lat: number; lng: number },
    oldPosition: { lat: number; lng: number }
  ) {
    let lat1 = userPosition?.lat;
    let lon1 = userPosition?.lng;
    let lat2 = oldPosition?.lat;
    let lon2 = oldPosition?.lng;

    const R = 6371e3; // Earth's radius in meters
    const φ1 = (lat1 * Math.PI) / 180; // Convert to radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in meters
  }
  
  // 작업 로그 소켓 리시버
  function onWorkRecordLogRecieved(workRecordLogData: WorkRecordLogData) {

    //디버그를 위한 코드
    let lat1 = workRecordLogData.polyLine[workRecordLogData.polyLine.length - 2][0];
    let lon1 =  workRecordLogData.polyLine[workRecordLogData.polyLine.length - 2][1];
    let lat2 = workRecordLogData.polyLine[workRecordLogData.polyLine.length - 1][0];
    let lon2 = workRecordLogData.polyLine[workRecordLogData.polyLine.length - 1][1];

    let distance = calculateDistance({lat:lat1, lng:lon1}, {lat:lat2, lng:lon2});

    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    let time = `${hours}:${minutes}:${seconds}`;

    // setDebugRecordLog(`이름 =  `+ workRecordLogData.workerName +` key:${workRecordLogData.polyLine.length - 1}` +" 좌표 = " + workRecordLogData.polyLine[workRecordLogData.polyLine.length - 1] +  "거리 ="  + distance + " 시간 = " + time);
    //디버그 코드 끝

    if(workRecordLogData.workTeam !== myWorkerItemData.workTeam){return;}

    let newLandList : Array<WorkRecordGroupAddressItemData> = [...workerMapPageData.workDetailData.landList];
    const findedLandItem = newLandList.find(
      (landItem) => {
        return landItem.landAddress === workRecordLogData.landAddress;
      }
    );

    const findedWorkRecordLogIndex = findedLandItem?.workRecordLogList.findIndex((item) => {
        return (
          item.workerName === workRecordLogData.workerName &&
          item.equipmentType === workRecordLogData.equipmentType &&
          item.equipmentName === workRecordLogData.equipmentName
        );
      });

    if (findedWorkRecordLogIndex! > -1) {      
      findedLandItem!.workRecordLogList[findedWorkRecordLogIndex!] = workRecordLogData;
    } else {     
      findedLandItem!.workRecordLogList.unshift(workRecordLogData);   
    }

    workerMapPageData.workDetailData.landList = newLandList;

    const allWorkRecordLogList: Array<WorkRecordLogData> = [];
    workerMapPageData.workDetailData.landList.forEach((landItem) => {
      landItem.workRecordLogList.forEach((workRecordLogItem) => {
        if (workRecordLogItem.workStatus === "N") {
          allWorkRecordLogList.push(workRecordLogItem);
        }
      });
    });
    
    setPolylineList(workRecordLogListToPolylineList(allWorkRecordLogList));
  }

  // 작업자 마커 소켓 센더
  function sendWorkerMarkerData(workerMarkerData: MarkerData) {
    if(!workerMarkerData.info || !workerMarkerData.markerIcon) {return;}
    socket.emit(SocketCommands.WORKER_MARKER_UPDATE, {workId: WorkerLocalData.instance.workId, workerMarkerData: workerMarkerData});
    if (workerMapPageData.workDetailData.masterWorkId !== undefined && workerMapPageData.workDetailData.masterWorkId !== "") {
      socket.emit(SocketCommands.WORKER_MARKER_UPDATE, {workId: workerMapPageData.workDetailData.masterWorkId, workerMarkerData: workerMarkerData});
    }
  } 

  // 작업자 마커 소켓 리시버
  function onWorkerMarkerDataRecieved(workerMarkerData: MarkerData) {
    if(workerMarkerData.workTeam !== myWorkerItemData.workTeam){return;}
    const foundWorkerIndex = originWorkerMarkerList.findIndex((item) => {
      return (
        item.markerId === workerMarkerData.markerId &&
        item.markerIcon === workerMarkerData.markerIcon
      );
    });
    if (foundWorkerIndex! > -1) {
      const updatedWorkerMarkerList = [...originWorkerMarkerList];
      updatedWorkerMarkerList[foundWorkerIndex] = workerMarkerData;
      setWorkerMarkerList(updatedWorkerMarkerList);
      originWorkerMarkerList = updatedWorkerMarkerList;
    } else {
      const updatedWorkerMarkerList = [...originWorkerMarkerList, workerMarkerData];
      setWorkerMarkerList(updatedWorkerMarkerList);
      originWorkerMarkerList = updatedWorkerMarkerList;
    };
  }

  // 작업자 퇴장 소켓 리시버
  function onWorkerLeaveRoomRecieved(workerName: string) {
    const foundWorkerIndex = originWorkerMarkerList.findIndex((item) => item.markerId === workerName);
   
    if (foundWorkerIndex > -1) {
      const updatedWorkerMarkerList = [...originWorkerMarkerList];
      updatedWorkerMarkerList.splice(foundWorkerIndex, 1);
      setWorkerMarkerList(updatedWorkerMarkerList);
      originWorkerMarkerList = updatedWorkerMarkerList;
    }

    let newLandList : Array<WorkRecordGroupAddressItemData> = [...workerMapPageData.workDetailData.landList];
    
    newLandList.forEach((landItem) => {
      landItem.workRecordLogList = landItem.workRecordLogList.filter(workRecordLogItem => {
        return !(workRecordLogItem.workerName === workerName && workRecordLogItem.workStatus === "N");
      });      
    });
    
    workerMapPageData.workDetailData.landList = newLandList;

    const allWorkRecordLogList: Array<WorkRecordLogData> = [];
    workerMapPageData.workDetailData.landList.forEach((landItem) => {
      landItem.workRecordLogList.forEach((workRecordLogItem) => {
        if (workRecordLogItem.workStatus === "N") {
          allWorkRecordLogList.push(workRecordLogItem);
        }
      });
    });
    setPolylineList(workRecordLogListToPolylineList(allWorkRecordLogList));
  }

  //Firebase
  //장비 완료 소켓 센더
  async function sendLandEquipWorkDone(workRecordLogData: WorkRecordLogData) {
    if (socket == null || socket.disconnected) {return;};
    socket.emit(SocketCommands.LAND_EQUIP_WORK_DONE, { workId: WorkerLocalData.instance.workId, workRecordLogData: workRecordLogData });
    if (workerMapPageData.workDetailData.masterWorkId !== undefined && workerMapPageData.workDetailData.masterWorkId !== "") {
      socket.emit(SocketCommands.LAND_EQUIP_WORK_DONE, { workId: workerMapPageData.workDetailData.masterWorkId, workRecordLogData: workRecordLogData });
    }
  }

  // 장비 완료 소켓 리시버
  function onLandEquipWorkDoneRecieved(workRecordLogData: WorkRecordLogData) {
    
    const findedLandItem = workerMapPageData.workDetailData.landList.find((landItem) => {
        return landItem.landAddress === workRecordLogData.landAddress;
      }
    );
    const findedWorkRecordLogIndex = findedLandItem?.workRecordLogList.findIndex((item) => {
        return (
          item.workerName === workRecordLogData.workerName &&
          item.equipmentType === workRecordLogData.equipmentType &&
          item.equipmentName === workRecordLogData.equipmentName
        );
      });
    

    if (findedWorkRecordLogIndex! > -1) {
      const doneWorkRecordLog = findedLandItem!.workRecordLogList[findedWorkRecordLogIndex!];
      doneWorkRecordLog.workStatus = workRecordLogData.workStatus;
      findedLandItem!.workRecordLogList.splice(findedWorkRecordLogIndex!, 1);
      findedLandItem!.workRecordLogList.unshift(doneWorkRecordLog);
      setDebugRecordLog(`[찾았다.]`);
      // findedLandItem!.workRecordLogList[findedWorkRecordLogIndex!] = workRecordLogData
    } else {      
      findedLandItem!.workRecordLogList = [workRecordLogData, ...findedLandItem!.workRecordLogList];//.unshift(workRecordLogData);
      setDebugRecordLog(`[못찾았다.]`);
    };

    for(let i = 0; i < workerMapPageData.workDetailData.landList.length; i++){
      let workerNameList = "";
      for(let j=0; j < workerMapPageData.workDetailData.landList[i].workRecordLogList.length; j++){
        workerNameList += workerMapPageData.workDetailData.landList[i].workRecordLogList[j].workerName + ", ";
      }
      setDebugRecordLog(`[workRecordLog lenght] addr = ${workerMapPageData.workDetailData.landList[i].landAddress},length = ${workerMapPageData.workDetailData.landList[i].workRecordLogList.length}, workerNameList = ${workerNameList}` );
   }
    // setPolygonList(workRecordGroupAddressListToPolygonList(workerMapPageData.workDetailData.landList));
    
    const allWorkRecordLogList: Array<WorkRecordLogData> = [];
    workerMapPageData.workDetailData.landList.forEach((landItem) => {
      landItem.workRecordLogList.forEach((workRecordLogItem) => {
        if (workRecordLogItem.workStatus === "N") {
          allWorkRecordLogList.push(workRecordLogItem);
        }
      });
    });
    setPolylineList(workRecordLogListToPolylineList(allWorkRecordLogList));   
  }

  // 거래 완료 소켓 리시버
  function onLandTradeDoneRecieved(landAddress: string) {
    let findedLand = workerMapPageData.workDetailData.landList.find((landItem) => {
      return landItem.landAddress === landAddress;
      }
    );

    if(!findedLand) {return;}
    findedLand.landTradeDone = "Y";
  }

  // 작업 메모 등록 소켓 센더
  function sendWorkMemoItemRegist(workMemoData: WorkMemoRegistrationReqData) {
    if (socket == null || socket.disconnected) {return;};
    socket.emit(SocketCommands.MEMO_REGISTED, { workId: WorkerLocalData.instance.workId, workMemoData: workMemoData });
    if (workerMapPageData.workDetailData.masterWorkId !== undefined && workerMapPageData.workDetailData.masterWorkId !== "") {
      workMemoData.workId = workerMapPageData.workDetailData.masterWorkId;
      socket.emit(SocketCommands.MEMO_REGISTED, { workId: workerMapPageData.workDetailData.masterWorkId, workMemoData: workMemoData });
    }
  }

  // 작업 메모 수정 소켓 센더
  function sendWorkMemoItemUpdate(workMemoData: WorkMemoRegistrationReqData) {    
    if (socket == null || socket.disconnected) {return;};
    socket.emit(SocketCommands.MEMO_UPDATE, { workId: WorkerLocalData.instance.workId, workMemoData: workMemoData });
    if (workerMapPageData.workDetailData.masterWorkId !== undefined && workerMapPageData.workDetailData.masterWorkId !== "") {
      workMemoData.workId = workerMapPageData.workDetailData.masterWorkId;
      socket.emit(SocketCommands.MEMO_UPDATE, { workId: workerMapPageData.workDetailData.masterWorkId, workMemoData: workMemoData });
    }
  }

  // 작업 메모 등록 소켓 리시버
  function onWorkMemoItemRegistRecieved(workMemoData: WorkMemoItemData) {
    let myLandList : Array<WorkRecordGroupAddressItemData> = new Array<WorkRecordGroupAddressItemData>();
    myLandList = workerMapPageData.workDetailData.landList.filter((landItem) => {
      return (landItem.workTeam === myWorkerItemData.workTeam);
    });
    const finedLandIndex = finedInLandList(myLandList, workMemoData.commentLatitude, workMemoData.commentLongitude);
    if (finedLandIndex > -1) {
      workerMapPageData.workDetailData.workMemoList.push(workMemoData);
      setMarkerList(memoListToMarkerList(workerMapPageData.workDetailData.workMemoList));
    }
  }

  // 작업 메모 수정 소켓 리시버
  function onWorkMemoItemUpdateRecieved(workMemoData: WorkMemoItemData) {
    const findedMemoIndex = workerMapPageData.workDetailData.workMemoList.findIndex((item) => {
      return (item.landAddress === workMemoData.landAddress);
  });
    
    if (findedMemoIndex < 0) {return;}
    if (workMemoData.comment === "") {
      workerMapPageData.workDetailData.workMemoList = workerMapPageData.workDetailData.workMemoList.filter((item) => {
        return item.landAddress !== workMemoData.landAddress;
      });
    } else {
      workerMapPageData.workDetailData.workMemoList[findedMemoIndex] = workMemoData;
    }
    setMarkerList(memoListToMarkerList(workerMapPageData.workDetailData.workMemoList));
  }  

  // 작업 기록 등록 소켓 센더
  function sendWorkRecordRegist(workRecordData: WorkRecordRegistrationReqData, workRecordGroupDateItemData: WorkRecordGroupDateItemData) {
    if (socket == null || socket.disconnected) {return;};
    socket.emit(SocketCommands.WORK_RECORD_DONE, { workId: WorkerLocalData.instance.workId, workRecordData: workRecordData, workRecordGroupDateItemData : workRecordGroupDateItemData });
    if (workerMapPageData.workDetailData.masterWorkId !== undefined && workerMapPageData.workDetailData.masterWorkId !== "") {
      socket.emit(SocketCommands.WORK_RECORD_DONE, { workId: workerMapPageData.workDetailData.masterWorkId, workRecordData: workRecordData, workRecordGroupDateItemData : workRecordGroupDateItemData });
    }
  }

  // 작업 기록 등록 소켓 리시버
  function onWorkRecordRegistRecieved(workRecordRegistrationReqData: WorkRecordRegistrationReqData, workRecordGroupDateItemData: WorkRecordGroupDateItemData) {   
    const findLandIndex = workerMapPageData.workDetailData.landList.findIndex((item) => {
      return item.landAddress === workRecordRegistrationReqData.landAddress;
    });
    if (findLandIndex < 0) { return;}    
    workerMapPageData.workDetailData.landList[findLandIndex].landOutput = workRecordRegistrationReqData.landOutput;    
    const findedWorkRecordGroupDateIndex = workerMapPageData.workDetailData.workRecordGroupDateList.findIndex((item) => {
      return item.workDate === workRecordRegistrationReqData.workDate;
    });

    if(findedWorkRecordGroupDateIndex < 0){
      workerMapPageData.workDetailData.workRecordGroupDateList.push(workRecordGroupDateItemData);
    }
    else{
      workerMapPageData.workDetailData.workRecordGroupDateList[findedWorkRecordGroupDateIndex] = workRecordGroupDateItemData;
    }
    
    setViewModel({ workerMapPageData });
  } 

  // 필지 추가 소켓 리시버
  function onLandAddRegistRecieved(landAddRegistReqData: LandAddRegistReqData) { 

    landAddRegistReqData.landList.forEach((landItem) => {
      let socketLandList: WorkRecordGroupAddressItemData = new WorkRecordGroupAddressItemData();
      socketLandList.landAddress = landItem.landAddress;
      socketLandList.landTradeDone = "N";
      socketLandList.landMap = stringToLandMap(landItem.landMap);
      socketLandList.workRecordLogList = [];
      workerMapPageData.workDetailData.landList.push(socketLandList);
    });
    setPolygonList(workRecordGroupAddressListToPolygonList(workerMapPageData.workDetailData.landList));
  }

  function onEquipmentDistanceUpdateReceived(equipmentList: any) {
    let newDistance = 5;
    newDistance = Number(equipmentList.find((item: any) => item.equipmentName === myWorkerItemData.equipmentName).equipmentDistance);
    workerMapPageData.workDetailData.workRecordDistance = newDistance;
  }


  // 지도 중심 이동 함수
  function handleMapCenter(lat: number, lng: number) {
    setMapCenter([lat, lng]);
  }

  return (
    <>
      <WorkerHeader
        title="작업자 작업 상세"
        headerRight={null}
        hasBack={location.pathname.includes("workRecord") || location.pathname.includes("workerWorkRecordGroupAddressList")}
      />
      <AppBody appMode={UserData.instance.appMode}>
        <WorkerMapComponent
          mapCenter={mapCenter}
          myLatLng={myLatLng}
          myTeam={myTeam}
          markerList={markerList}
          polygonList={polygonList}
          polylineList={polylineList}
          myMarker={myMarker}
          pinLatLng={pinLatLng}
          // landInfoList= {landInfoList}
          workerMarkerList={workerMarkerList}
          onClickMarker={handleOnMarkerClick}
          onClickPolygon={handleOnPolygonClick}
          handleMapCenter={handleMapCenter}
        />
        <ActionSheet>
          {(() => {
            switch (viewModel.workerMapPageData.mapComponentMode) {
              case mapComponentMode.WORKER_WORK_DETAIL:
                return (
                  <Outlet context={{ 
                    workDetailData: viewModel.workerMapPageData.workDetailData, 
                    debugGpsLog: debugGpsLog, 
                    debugRecordLog : debugRecordLog,
                    onHandleWorkerExitBtnClick: handleWorkerAppExit,
                    onHandlePinClick: handlePin }} />
                );            
              case mapComponentMode.WORK_RECORD_DETAIL_GROUP_DATE:
                return (
                  <Outlet context={{ workRecordDetailGroupDate: viewModel.workerMapPageData.workRecordDetailGroupDateData }} />
                );             
              case mapComponentMode.WORKER_WORK_RECORD_GROUP_ADDRESS_LIST:
                return (
                  <Outlet context={{ 
                      workDetailData: viewModel.workerMapPageData.workDetailData,
                      handleMoveToLand: handleMoveToLand,
                      onHandlePinClick: handlePin,
                    }}
                  />
                );
              default:
                return <></>;
            }
          })()}
        </ActionSheet>
      </AppBody>
    </>
  );
};
export default WorkerMapPage;
