import { appMode, userDataCode } from "code/Enumerator";
import { isObject } from "common/utils/ObjectUtils";
import AgreementRegistReqData from "./network/req/agreement/AgreementRegistReqData";
import BridgeManager from "bridge/BridgeManager";
import BridgeCommands from "bridge/BridgeCommands";

let bridgeManager = new BridgeManager();
export default class UserData{
    private static _instance: UserData;
    private constructor() {
        bridgeManager.init();
    }    
    public static get instance(): UserData {return this._instance || (this._instance = new this());}
 
    public gotoHome(): void {
        switch(this.appMode) {
            case appMode.SELLER:
                const sellerMainUrl = "/main";
                window.location.href = sellerMainUrl; 
                const sellerData = {
                    toUrl: sellerMainUrl,
                }
                bridgeManager.bridgeCommand(BridgeCommands.BRG_SET_WEBVIEW_URL, sellerData, null);
                break;
            case appMode.BUYER:
                const buyerMainUrl = "/buyerMain";
                window.location.href = buyerMainUrl; 
                const buyerData = {
                    toUrl: buyerMainUrl,
                }
                bridgeManager.bridgeCommand(BridgeCommands.BRG_SET_WEBVIEW_URL, buyerData, null);
                break;
            default:
                window.location.href = "/";
                break;
        }
    };
    public set appMode(appMode: string) {
        if(!appMode) appMode = "";
        localStorage.setItem(userDataCode.APP_MODE, isObject(appMode)?JSON.stringify(appMode):appMode);
    }
    public get appMode(): string {
        const result = localStorage.getItem(userDataCode.APP_MODE) as any; 
        return isObject(result)?JSON.parse(result) : result;
    }
    public set userId(userId: string) {
        if(!userId) userId = "";
        localStorage.setItem(userDataCode.USER_ID, isObject(userId)?JSON.stringify(userId):userId);
    }
    public get userId(): string {
        const result = localStorage.getItem(userDataCode.USER_ID) as any; 
        return isObject(result)?JSON.parse(result) : result;
    }
    public set userName(userName: string) {
        if(!userName) userName = "";
        localStorage.setItem(userDataCode.USER_NAME, isObject(userName)?JSON.stringify(userName):userName);
    }
    public get userName(): string {
        const result = localStorage.getItem(userDataCode.USER_NAME) as any; 
        return isObject(result)?JSON.parse(result) : result;
    }
    public set userTel(userTel: string) {
        if(!userTel) userTel = "";
        localStorage.setItem(userDataCode.USER_TEL, isObject(userTel)?JSON.stringify(userTel):userTel);
    }
    public get userTel(): string {
        const result = localStorage.getItem(userDataCode.USER_TEL) as any; 
        return isObject(result)?JSON.parse(result) : result;
    }
    public set snsType(snsType: string) {
        if(!snsType) snsType = "";
        localStorage.setItem(userDataCode.SNS_TYPE, isObject(snsType)?JSON.stringify(snsType):snsType);
    }
    public get snsType(): string {
        const result = localStorage.getItem(userDataCode.SNS_TYPE) as any; 
        return isObject(result)?JSON.parse(result) : result;
    }
    public get snsId(): string {
        const result = localStorage.getItem(userDataCode.SNS_ID) as any; 
        return isObject(result)?JSON.parse(result) : result;
    }
    public set snsId(snsId: string) {
        if(!snsId) snsId = "";
        localStorage.setItem(userDataCode.SNS_ID, isObject(snsId)?JSON.stringify(snsId):snsId);
    }
    public set accessToken(accessToken: string) {
        if(!accessToken) accessToken = "";
        localStorage.setItem(userDataCode.ACCESS_TOKEN, isObject(accessToken)?JSON.stringify(accessToken):accessToken);
    }
    public get accessToken(): string {
        const result = localStorage.getItem(userDataCode.ACCESS_TOKEN) as any; 
        return isObject(result)?JSON.parse(result) : result;
    }
    public logOut(): void {
        localStorage.clear();
        window.location.href = "/";
    }
    public get agreementList(): Array<AgreementRegistReqData> {
        const result = localStorage.getItem(userDataCode.AGREEMENT_REGIST_LIST) as any; 
        return JSON.parse(result) as Array<AgreementRegistReqData>;
    }
    public set agreementList(agreementRegistList: Array<AgreementRegistReqData> ) {
        if(!agreementRegistList) agreementRegistList = new Array<AgreementRegistReqData>();
        localStorage.setItem(userDataCode.AGREEMENT_REGIST_LIST, JSON.stringify(agreementRegistList));
    }
}