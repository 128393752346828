import { findMyWorkRecordLogByLandAddress, finedInLandList } from "common/utils/WorkerUtils";
import WorkerLocalData from "data/WorkerLocalData";
import pointInPolygon from "point-in-polygon";
import WorkDetailData from "ui/pages/WorkDetail/WorkDetailData";
import WorkerItemData from "ui/pages/WorkDetail/WorkerList/WorkerItemData";
import WorkLandItemData from "ui/pages/WorkRegistration/WorkLandList/WorkLandItemData";

export class WorkLandListValidator{
    static validate(landList: Array<WorkLandItemData>):boolean{
        if(landList===undefined || landList===null ||landList.length===0 ){return false;}
        //const lastFirstFullAddress=landList[0].landFullAddress;
        //if(landList.find((landItem)=>{lastFirstFullAddress!==landItem.landFullAddress})>0){return false;}
        return true;
    }
}
export class EquipmentListValidator{
    static validate(equipmentList: Array<string>):boolean{
        if(equipmentList===undefined || equipmentList===null ||equipmentList.length===0 ){return false;}
        return true;
    }
}

export class WorkerListValidator{
    static validate(workerList: Array<WorkerItemData>):boolean{
        if(workerList===undefined || workerList===null ||workerList.length===0 ){return false;}
        
        return true;
    }
}

export class WorkRecordLogValidator{
    static validate(currentPolyLine:number[][], workDetailData:WorkDetailData, lat:number, lng: number):boolean{
        if(WorkRecordLogValidator.validateDate(workDetailData.workStartDate, workDetailData.workEndDate)===false){return false;}
        const findedIndex = finedInLandList(workDetailData.landList, lat, lng);
        if(findedIndex < 0){  
            currentPolyLine = [];
            return false;
        }
        
        if(currentPolyLine.length < 1){return true;}
        const lastCurrentPoint = currentPolyLine[currentPolyLine.length-1];
        if(!WorkRecordLogValidator.validateDistanceWithPoint(lat, lng, lastCurrentPoint[0],lastCurrentPoint[1])){return false;}
        
        const finedLand = workDetailData.landList[findedIndex];
        const me = workDetailData.workerList.find(workerItem => workerItem.workerName === WorkerLocalData.instance.workerName);        
        if(me===undefined || me===null){return false;}
        const finedWorkRecordLogIndex = findMyWorkRecordLogByLandAddress(me, finedLand.workRecordLogList);        
        if(finedWorkRecordLogIndex<0){return true;}
        if(finedLand.workRecordLogList[finedWorkRecordLogIndex].polyLine.length<1){return true;}
        const lastPointIndex = finedLand.workRecordLogList[finedWorkRecordLogIndex].polyLine.length-1;
        const lastPoint = finedLand.workRecordLogList[finedWorkRecordLogIndex].polyLine[lastPointIndex];
        return WorkRecordLogValidator.validateDistanceWithPoint(lat, lng, lastPoint[0], lastPoint[1]);
    }

    public static validateDate(startDate:string, endDate:string){
        const today = new Date();
        const kstOffset = 9 * 60 * 60 * 1000; // Korea Standard Time is UTC+9
    
        const start = new Date(new Date(startDate).getTime() - kstOffset);
        const end = new Date(new Date(endDate).getTime() - kstOffset + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000);
    
        if (today < start || today > end) {
            return false;
        }
        return true;
    }

    public static validateInLand(polygon:number[][], lat:number, lng: number){
        const codinate = [lng, lat];       
        if(! pointInPolygon(codinate, polygon)){return false;};
        return true;
    }

    public static validateDistanceWithPoint(lat:number, lng:number, lastLat:number, lastLng:number){
        const distance = WorkRecordLogValidator.calculateDistance({lat:lat, lng:lng}, {lat:lastLat, lng:lastLng});
        if(distance<3){
            return false;
        }
        return true;
    }
    private static calculateDistance(
        userPosition: { lat: number; lng: number },
        oldPosition: { lat: number; lng: number }
      ) {
        let lat1 = userPosition?.lat;
        let lon1 = userPosition?.lng;
        let lat2 = oldPosition?.lat;
        let lon2 = oldPosition?.lng;
        const R = 6371e3; // Earth's radius in meters
        const φ1 = (lat1 * Math.PI) / 180; // Convert to radians
        const φ2 = (lat2 * Math.PI) / 180;
        const Δφ = ((lat2 - lat1) * Math.PI) / 180;
        const Δλ = ((lon2 - lon1) * Math.PI) / 180;
    
        const a =
          Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
          Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distance in meters
    }
}

