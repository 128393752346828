import { useEffect } from 'react';
import { AuthPagesBodyBlock, AuthPagesHeaderBlock } from './AuthPages.styles';

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = "카우잇츠 - 개인정보처리방침";    
  }, []);
  
  const renderHTML = () => {
    return { __html: `
    <!DOCTYPE html>
    <html>
    <body>
    <div style="text-align: left;" align="left"><b style="font-family: 
    &quot;맑은 고딕&quot;, sans-serif; font-size: 10pt; text-align: 
    center;"><u><span style="font-size: 18pt; line-height: 25.68px;">농가온 
    개인정보 처리방침<span lang="EN-US"> 
    (2023-12-01)</span></span></u></b></div><p class="MsoNormal" align="left" 
    style="text-align: left; margin: 0cm 0cm 8pt; line-height: 14.2667px; 
    font-size: 10pt; font-family: &quot;맑은 고딕&quot;, 
    sans-serif;"><b><u><span lang="EN-US" style="font-size: 5pt; line-height: 
    7.13333px;">&nbsp;</span></u></b></p><p class="MsoNormal" 
    style="text-align: left; margin: 0cm 0cm 8pt; line-height: 14.2667px; 
    font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" 
    align="left"><b><span style="font-size: 14pt; line-height: 
    19.9733px;">목차<span lang="EN-US"><o:p></o:p></span></span></b></p><p 
    class="MsoNormal" style="margin: 0cm 0cm 8pt; text-align: left; 
    line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 
    고딕&quot;, sans-serif;" align="left">&nbsp;</p><div style="text-align: 
    left;" align="left"><span lang="EN-US" style="font-size: 12pt; 
    line-height: 17.12px;">1. </span><span style="font-size: 12pt; 
    line-height: 17.12px;">총칙</span></div><span style="font-size: 12pt; 
    line-height: 17.12px;"><div style="text-align: left;" align="left"><span 
    lang="EN-US" style="font-size: 12pt;">2. </span><span style="font-size: 
    12pt;">개인정보의 처리 목적</span><span lang="EN-US" style="font-size: 
    12pt;">, </span><span style="font-size: 12pt;">항목 및 
    보유기간</span></div><span lang="EN-US"><div style="text-align: left;" 
    align="left"><span lang="EN-US" style="font-size: 12pt;">3. </span><span 
    style="font-size: 12pt;">개인정보의 파기절차 및 
    파기방법</span></div></span><span lang="EN-US"><div style="text-align: 
    left;"><span lang="EN-US" style="font-size: 12pt;">4. </span><span 
    style="font-size: 12pt;">개인정보 처리의 위탁</span></div></span><span 
    lang="EN-US"><div style="text-align: left;"><span lang="EN-US" 
    style="font-size: 12pt;">5. </span><span style="font-size: 
    12pt;">개인정보의 제</span><span lang="EN-US" style="font-size: 
    12pt;">3</span><span style="font-size: 12pt;">자 
    제공</span></div></span><span lang="EN-US"><div style="text-align: 
    left;"><span lang="EN-US" style="font-size: 12pt;">6. </span><span 
    style="font-size: 12pt;">개인정보의 안전성 
    확보조치</span></div></span><span lang="EN-US"><div style="text-align: 
    left;"><span lang="EN-US" style="font-size: 12pt;">7. </span><span 
    style="font-size: 12pt;">개인정보 자동 수집 장치의 설치ㆍ운영 및 거부에 
    관한 사항</span></div></span><span lang="EN-US"><div style="text-align: 
    left;"><span lang="EN-US" style="font-size: 12pt;">8. </span><span 
    style="font-size: 12pt;">고객</span><span lang="EN-US" style="font-size: 
    12pt;">, </span><span style="font-size: 12pt;">법정대리인의 권리와 의무 및 
    그 행사방법</span></div></span><span lang="EN-US"><div style="text-align: 
    left;"><span lang="EN-US" style="font-size: 12pt;">9. </span><span 
    style="font-size: 12pt;">고객의 권익침해에 대한 
    구제방법</span></div></span><span lang="EN-US"><div style="text-align: 
    left;"><span lang="EN-US" style="font-size: 12pt;">10. </span><span 
    style="font-size: 12pt;">개인정보 보호책임자 및 담당자</span><span 
    lang="EN-US" style="font-size: 12pt;">, </span><span style="font-size: 
    12pt;">업무처리 부서</span></div></span><span lang="EN-US"><div 
    style="text-align: left;"><span lang="EN-US" style="font-size: 12pt;">11. 
    </span><span style="font-size: 12pt;">개인정보 처리방침의 적용 
    범위</span></div></span><span lang="EN-US"><div style="text-align: 
    left;"><span lang="EN-US" style="font-size: 12pt;">12. </span><span 
    style="font-size: 12pt;">개인정보 처리방침의 변경에 관한 
    사항</span></div></span></span><p>&nbsp;</p><p class="MsoNormal" 
    style="text-align: left; margin: 0cm 0cm 8pt; line-height: 14.2667px; 
    font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" 
    align="left"><span lang="EN-US">&nbsp;</span></p><p class="MsoNormal" 
    style="text-align: left; margin: 0cm 0cm 8pt; line-height: 14.2667px; 
    font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" 
    align="left"><b><span lang="EN-US" style="font-size: 14pt; line-height: 
    19.9733px;">1. </span></b><b><span style="font-size: 14pt; line-height: 
    19.9733px;">총칙<span lang="EN-US"><o:p></o:p></span></span></b></p><p 
    class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt; 
    line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 
    고딕&quot;, sans-serif;" align="left">농가온 주식회사<span 
    lang="EN-US">(</span>이하<span lang="EN-US"> “</span>회사<span 
    lang="EN-US">”)</span>는 「개인정보보호법」 제<span 
    lang="EN-US">30</span>조에 따라 고객님의 개인정보를 보호하고 이와 관련한 
    고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 
    처리방침을 수립ㆍ공개합니다<span lang="EN-US">.<o:p></o:p></span></p><p 
    class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt; 
    line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 
    고딕&quot;, sans-serif;" align="left"><span 
    lang="EN-US">&nbsp;</span></p><p class="MsoNormal" style="text-align: 
    left; margin: 0cm 0cm 8pt; line-height: 14.2667px; font-size: 10pt; 
    font-family: &quot;맑은 고딕&quot;, sans-serif;" align="left"><b><span 
    lang="EN-US" style="font-size: 14pt; line-height: 19.9733px;">2. 
    </span></b><b><span style="font-size: 14pt; line-height: 
    19.9733px;">개인정보의 처리 목적<span lang="EN-US">, </span>항목 및 
    보유기간<span lang="EN-US"><o:p></o:p></span></span></b></p><p 
    class="MsoNormal" style="margin: 0cm 0cm 8pt 10pt; text-align: left; 
    line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 
    고딕&quot;, sans-serif; text-indent: -10pt;" align="left">&nbsp;</p><div 
    style="text-align: left;" align="left"><span lang="EN-US" 
    style="font-size: 10pt; text-indent: -10pt;">&nbsp; &nbsp;1) </span><span 
    style="font-size: 10pt; text-indent: -10pt;">회사는 다음의 목적을 위하여 
    아래와 같이 개인정보 항목을 처리하고 있습니다</span><span lang="EN-US" 
    style="font-size: 10pt; text-indent: -10pt;">.</span></div><span 
    lang="EN-US"><div style="text-align: left;" align="left"><span 
    lang="EN-US" style="font-size: 10pt; text-indent: -10pt;">&nbsp; &nbsp; - 
    </span><span style="font-size: 10pt; text-indent: -10pt;">법령에 따른 또는 
    고객님으로부터 개인정보를 수집 시에 동의 받은 개인정보에 한해 
    보유하고</span><span lang="EN-US" style="font-size: 10pt; text-indent: 
    -10pt;">, </span><span style="font-size: 10pt; text-indent: 
    -10pt;">처리목적과 이용기간 내에서 개인정보를 처리</span><span 
    lang="EN-US" style="font-size: 10pt; text-indent: -10pt;">, </span><span 
    style="font-size: 10pt; text-indent: -10pt;">보유합니다</span><span 
    lang="EN-US" style="font-size: 10pt; text-indent: 
    -10pt;">.</span></div></span><span lang="EN-US"><div style="text-align: 
    left;"><span lang="EN-US" style="font-size: 10pt; text-indent: 
    -10pt;">&nbsp; &nbsp; - </span><span style="font-size: 10pt; text-indent: 
    -10pt;">처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 
    않으며</span><span lang="EN-US" style="font-size: 10pt; text-indent: 
    -10pt;">, </span><span style="font-size: 10pt; text-indent: -10pt;">이용 
    목적</span><span lang="EN-US" style="font-size: 10pt; text-indent: 
    -10pt;">, </span><span style="font-size: 10pt; text-indent: -10pt;">항목이 
    변경되는 경우에는 「개인정보보호법」 제 </span><span lang="EN-US" 
    style="font-size: 10pt; text-indent: -10pt;">18</span><span 
    style="font-size: 10pt; text-indent: -10pt;">조에 따라 
    별도의</span></div></span><span lang="EN-US"><div style="text-align: 
    left;"><span lang="EN-US" style="font-size: 10pt; text-indent: 
    -10pt;">&nbsp; &nbsp; &nbsp;&nbsp;</span><span style="font-size: 10pt; 
    text-indent: -10pt;">동의를 받는 등 필요한 조치를 이행할 
    예정입니다</span><span lang="EN-US" style="font-size: 10pt; text-indent: 
    -10pt;">.</span></div></span><p>&nbsp;</p><div align="left" 
    style="font-size: medium; text-align: left;"><table class="MsoTableGrid 
    __se_tbl_ext" border="1" cellspacing="0" cellpadding="0" 
    style="border-collapse: collapse; border: none;"><tbody><tr style="height: 
    5.666666666666667px;"><td style="width: 160.33333333333334px; border: 1pt 
    solid windowtext; padding: 0cm 5.4pt; height: 5.666666666666667px;"><p 
    class="MsoNormal" align="center" style="margin: 0cm; text-align: center; 
    line-height: normal; font-size: 10pt; font-family: '맑은 고딕', 
    sans-serif;"><b>서비스 구분<span 
    lang="EN-US"><o:p></o:p></span></b></p></td><td style="width: 
    292.8666666666667px; border-top: 1pt solid windowtext; border-right: 1pt 
    solid windowtext; border-bottom: 1pt solid windowtext; border-image: 
    initial; border-left: none; padding: 0cm 5.4pt; height: 
    5.666666666666667px;"><p class="MsoNormal" align="center" style="margin: 
    0cm; text-align: center; line-height: normal; font-size: 10pt; 
    font-family: '맑은 고딕', sans-serif;"><b>수집ㆍ이용 목적<span 
    lang="EN-US"><o:p></o:p></span></b></p></td><td style="width: 359.2px; 
    border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; 
    border-bottom: 1pt solid windowtext; border-image: initial; border-left: 
    none; padding: 0cm 5.4pt; height: 5.666666666666667px;"><p 
    class="MsoNormal" align="center" style="margin: 0cm; text-align: center; 
    line-height: normal; font-size: 10pt; font-family: '맑은 고딕', 
    sans-serif;"><b>수집 항목<span 
    lang="EN-US"><o:p></o:p></span></b></p></td><td style="width: 
    160.66666666666666px; border-top: 1pt solid windowtext; border-right: 1pt 
    solid windowtext; border-bottom: 1pt solid windowtext; border-image: 
    initial; border-left: none; padding: 0cm 5.4pt; height: 
    5.666666666666667px;"><p class="MsoNormal" align="center" style="margin: 
    0cm; text-align: center; line-height: normal; font-size: 10pt; 
    font-family: '맑은 고딕', sans-serif;"><b>보유 기간<span 
    lang="EN-US"><o:p></o:p></span></b></p></td></tr><tr style="height: 
    73.66666666666667px;"><td style="width: 160.33333333333334px; 
    border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; 
    border-left: 1pt solid windowtext; border-image: initial; border-top: 
    none; padding: 0cm 5.4pt; height: 73.66666666666667px;"><p 
    class="MsoNormal" align="center" style="margin: 0cm; text-align: center; 
    line-height: normal; font-size: 10pt; font-family: '맑은 고딕', 
    sans-serif;">카우잇츠 모바일<span lang="EN-US"><o:p></o:p></span></p><p 
    class="MsoNormal" align="center" style="margin: 0cm; text-align: center; 
    line-height: normal; font-size: 10pt; font-family: '맑은 고딕', 
    sans-serif;">서비스<span lang="EN-US"><o:p></o:p></span></p></td><td 
    style="width: 292.8666666666667px; border-top: none; border-left: none; 
    border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; 
    padding: 0cm 5.4pt; height: 73.66666666666667px;"><p class="MsoNormal" 
    align="left" style="margin: 0cm; line-height: normal; font-size: 10pt; 
    font-family: '맑은 고딕', sans-serif;">양질의 조사료 수급 및 유통 서비스 
    제공<span lang="EN-US"><o:p></o:p></span></p></td><td style="width: 
    359.2px; border-top: none; border-left: none; border-bottom: 1pt solid 
    windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; 
    height: 73.66666666666667px;"><p class="MsoNormal" align="left" 
    style="margin: 0cm; line-height: normal; font-size: 10pt; font-family: 
    '맑은 고딕', sans-serif;">성명<span lang="EN-US">, </span>연락처<span 
    lang="EN-US">, </span>주소<span 
    lang="EN-US"><o:p></o:p></span></p></td><td style="width: 
    160.66666666666666px; border-top: none; border-left: none; border-bottom: 
    1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 
    5.4pt; height: 73.66666666666667px;"><p class="MsoNormal" align="center" 
    style="margin: 0cm; text-align: center; line-height: normal; font-size: 
    10pt; font-family: '맑은 고딕', sans-serif;">회원탈퇴 시까지<span 
    lang="EN-US"><o:p></o:p></span></p><p class="MsoNormal" align="center" 
    style="margin: 0cm; text-align: center; line-height: normal; font-size: 
    10pt; font-family: '맑은 고딕', sans-serif;"><span 
    lang="EN-US">(</span>휴면계정 전환시<span lang="EN-US"> 1</span>년 후 
    자동파기<span lang="EN-US">)<o:p></o:p></span></p></td></tr><tr 
    style="height: 47.800000000000004px;"><td style="width: 
    160.33333333333334px; border-right: 1pt solid windowtext; border-bottom: 
    1pt solid windowtext; border-left: 1pt solid windowtext; border-image: 
    initial; border-top: none; padding: 0cm 5.4pt; height: 
    47.800000000000004px;"><p class="MsoNormal" align="center" style="margin: 
    0cm; text-align: center; line-height: normal; font-size: 10pt; 
    font-family: '맑은 고딕', sans-serif;">이벤트 맞춤형 서비스<span 
    lang="EN-US"><o:p></o:p></span></p></td><td style="width: 
    292.8666666666667px; border-top: none; border-left: none; border-bottom: 
    1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 
    5.4pt; height: 47.800000000000004px;"><p class="MsoNormal" align="left" 
    style="margin: 0cm; line-height: normal; font-size: 10pt; font-family: 
    '맑은 고딕', sans-serif;">이벤트 및 맞춤형 서비스 안내<span 
    lang="EN-US"><o:p></o:p></span></p></td><td style="width: 359.2px; 
    border-top: none; border-left: none; border-bottom: 1pt solid windowtext; 
    border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 
    47.800000000000004px;"><p class="MsoNormal" align="left" style="margin: 
    0cm; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', 
    sans-serif;">성명<span lang="EN-US">, </span>연락처<span lang="EN-US">, 
    </span>주소<span lang="EN-US"><o:p></o:p></span></p></td><td style="width: 
    160.66666666666666px; border-top: none; border-left: none; border-bottom: 
    1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 
    5.4pt; height: 47.800000000000004px;"><p class="MsoNormal" align="center" 
    style="margin: 0cm; text-align: center; line-height: normal; font-size: 
    10pt; font-family: '맑은 고딕', sans-serif;">회원 탈퇴 시까지<span 
    lang="EN-US"><o:p></o:p></span></p><p class="MsoNormal" align="center" 
    style="margin: 0cm; text-align: center; line-height: normal; font-size: 
    10pt; font-family: '맑은 고딕', sans-serif;"><span 
    lang="EN-US">(</span>휴면계정 전환시<span lang="EN-US"> 1</span>년 후 
    자동파기<span 
    lang="EN-US">)<o:p></o:p></span></p></td></tr></tbody></table></div><p 
    class="MsoNormal" style="margin: 0cm 0cm 8pt 40pt; text-align: left; 
    line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 
    고딕&quot;, sans-serif; text-indent: -10pt;" align="left">&nbsp;</p><div 
    style="text-align: left;" align="left"><span lang="EN-US" 
    style="font-size: 10pt; text-indent: -10pt;">- </span><span 
    style="font-size: 10pt; text-indent: -10pt;">회사는 고객문의</span><span 
    lang="EN-US" style="font-size: 10pt; text-indent: -10pt;">(</span><span 
    style="font-size: 10pt; text-indent: -10pt;">상담</span><span lang="EN-US" 
    style="font-size: 10pt; text-indent: -10pt;">) </span><span 
    style="font-size: 10pt; text-indent: -10pt;">응대</span><span lang="EN-US" 
    style="font-size: 10pt; text-indent: -10pt;">, </span><span 
    style="font-size: 10pt; text-indent: -10pt;">신규업체 등록 등 개별 서비스 
    이용 과정에서 추가 개인정보 수집이 발생할 수 있습니다</span><span 
    lang="EN-US" style="font-size: 10pt; text-indent: -10pt;">. </span><span 
    style="font-size: 10pt; text-indent: -10pt;">추가로 개인정보를 수집할 
    경우에는</span></div><div style="text-align: left;" align="left"><span 
    style="font-size: 10pt; text-indent: -10pt;">해당 개인정보 수집 시점에서 
    이용자에게 </span><span lang="EN-US" style="font-size: 10pt; text-indent: 
    -10pt;">‘</span><span style="font-size: 10pt; text-indent: 
    -10pt;">수집하는 개인정보 항목</span><span lang="EN-US" style="font-size: 
    10pt; text-indent: -10pt;">, </span><span style="font-size: 10pt; 
    text-indent: -10pt;">수집 및 이용목적</span><span lang="EN-US" 
    style="font-size: 10pt; text-indent: -10pt;">, </span><span 
    style="font-size: 10pt; text-indent: -10pt;">보유기간</span><span 
    lang="EN-US" style="font-size: 10pt; text-indent: -10pt;">’</span><span 
    style="font-size: 10pt; text-indent: -10pt;">에 대해 안내 드리고 동의를 
    받습니다</span><span lang="EN-US" style="font-size: 10pt; text-indent: 
    -10pt;">.</span></div><p>&nbsp;</p><p class="MsoNormal" style="margin: 0cm 
    0cm 8pt 30pt; text-align: left; line-height: 14.2667px; font-size: 10pt; 
    font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -30pt;" 
    align="left">&nbsp;</p><div style="text-align: left;" align="left"><span 
    lang="EN-US" style="font-size: 10pt; text-indent: -30pt;">&nbsp; &nbsp;2) 
    </span><span style="font-size: 10pt; text-indent: -30pt;">단</span><span 
    lang="EN-US" style="font-size: 10pt; text-indent: -30pt;">, </span><span 
    style="font-size: 10pt; text-indent: -30pt;">관계법령의 규정에 의하여 
    보존할 의무가 있는 경우 회사는 고객의 개인정보를 보관합니다</span><span 
    lang="EN-US" style="font-size: 10pt; text-indent: -30pt;">. </span><span 
    style="font-size: 10pt; text-indent: -30pt;">이 경우 회사는 보관하는 
    정보를 그 보관의 목적으로만 이용</span></div><div style="text-align: 
    left;" align="left"><span style="font-size: 10pt; text-indent: 
    -30pt;">하며 보존기간은 다음과 같습니다</span><span lang="EN-US" 
    style="font-size: 10pt; text-indent: 
    -30pt;">.</span></div><p>&nbsp;</p><div align="left" style="font-size: 
    medium; text-align: left;"><table class="MsoTableGrid __se_tbl_ext" 
    border="1" cellspacing="0" cellpadding="0" style="border-collapse: 
    collapse; border: none;"><tbody><tr style="height: 
    28.666666666666668px;"><td style="width: 528.8666666666666px; border: 1pt 
    solid windowtext; padding: 0cm 5.4pt; height: 28.666666666666668px;"><p 
    class="MsoNormal" align="center" style="margin: 0cm; text-align: center; 
    line-height: normal; font-size: 10pt; font-family: '맑은 고딕', 
    sans-serif;"><b>보존사항</b><span 
    lang="EN-US"><o:p></o:p></span></p></td><td style="width: 8cmpx; 
    border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; 
    border-bottom: 1pt solid windowtext; border-image: initial; border-left: 
    none; padding: 0cm 5.4pt; height: 28.666666666666668px;"><p 
    class="MsoNormal" align="center" style="margin: 0cm; text-align: center; 
    line-height: normal; font-size: 10pt; font-family: '맑은 고딕', 
    sans-serif;"><b>보존이유<span 
    lang="EN-US"><o:p></o:p></span></b></p></td><td style="width: 
    141.79999999999998px; border-top: 1pt solid windowtext; border-right: 1pt 
    solid windowtext; border-bottom: 1pt solid windowtext; border-image: 
    initial; border-left: none; padding: 0cm 5.4pt; height: 
    28.666666666666668px;"><p class="MsoNormal" align="center" style="margin: 
    0cm; text-align: center; line-height: normal; font-size: 10pt; 
    font-family: '맑은 고딕', sans-serif;"><b>보존기간</b><span 
    lang="EN-US"><o:p></o:p></span></p></td></tr><tr style="height: 
    10.066666666666666px;"><td style="width: 528.8666666666666px; 
    border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; 
    border-left: 1pt solid windowtext; border-image: initial; border-top: 
    none; padding: 0cm 5.4pt; height: 10.066666666666666px;"><p 
    class="MsoNormal" align="center" style="margin: 0cm; text-align: center; 
    line-height: normal; font-size: 10pt; font-family: '맑은 고딕', 
    sans-serif;">회사의 상업장부와 영업에 관한 중요서류 및 전표 등에 관련된 
    정보<span lang="EN-US"><o:p></o:p></span></p></td><td style="width: 8cmpx; 
    border-top: none; border-left: none; border-bottom: 1pt solid windowtext; 
    border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 
    10.066666666666666px;"><p class="MsoNormal" align="center" style="margin: 
    0cm; text-align: center; line-height: normal; font-size: 10pt; 
    font-family: '맑은 고딕', sans-serif;">상법<span 
    lang="EN-US"><o:p></o:p></span></p></td><td style="width: 
    141.79999999999998px; border-top: none; border-left: none; border-bottom: 
    1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 
    5.4pt; height: 10.066666666666666px;"><p class="MsoNormal" align="center" 
    style="margin: 0cm; text-align: center; line-height: normal; font-size: 
    10pt; font-family: '맑은 고딕', sans-serif;">중요서류 <span lang="EN-US">- 
    10</span>년<span lang="EN-US"><o:p></o:p></span></p><p class="MsoNormal" 
    align="center" style="margin: 0cm; text-align: center; line-height: 
    normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;">전표 <span 
    lang="EN-US">– 5</span>년<span 
    lang="EN-US"><o:p></o:p></span></p></td></tr><tr style="height: 
    26.8px;"><td style="width: 528.8666666666666px; border-right: 1pt solid 
    windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid 
    windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; 
    height: 26.8px;"><p class="MsoNormal" align="center" style="margin: 0cm; 
    text-align: center; line-height: normal; font-size: 10pt; font-family: 
    '맑은 고딕', sans-serif;">모든 거래에 관한 장부 및 증빙서류와 관련된 
    정보<span lang="EN-US"><o:p></o:p></span></p></td><td style="width: 8cmpx; 
    border-top: none; border-left: none; border-bottom: 1pt solid windowtext; 
    border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 
    26.8px;"><p class="MsoNormal" align="center" style="margin: 0cm; 
    text-align: center; line-height: normal; font-size: 10pt; font-family: 
    '맑은 고딕', sans-serif;">국세기본법<span lang="EN-US">, 
    </span>법인세법<span lang="EN-US"><o:p></o:p></span></p></td><td 
    style="width: 141.79999999999998px; border-top: none; border-left: none; 
    border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; 
    padding: 0cm 5.4pt; height: 26.8px;"><p class="MsoNormal" align="center" 
    style="margin: 0cm; text-align: center; line-height: normal; font-size: 
    10pt; font-family: '맑은 고딕', sans-serif;"><span 
    lang="EN-US">5</span>년<span 
    lang="EN-US"><o:p></o:p></span></p></td></tr><tr style="height: 
    26.8px;"><td style="width: 528.8666666666666px; border-right: 1pt solid 
    windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid 
    windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; 
    height: 26.8px;"><p class="MsoNormal" align="center" style="margin: 0cm; 
    text-align: center; line-height: normal; font-size: 10pt; font-family: 
    '맑은 고딕', sans-serif;">계약 또는 청약철회 등에 관한 기록<span 
    lang="EN-US">, </span>대금결제 및 재화 등의 공급에 관한 기록<span 
    lang="EN-US"><o:p></o:p></span></p></td><td style="width: 8cmpx; 
    border-top: none; border-left: none; border-bottom: 1pt solid windowtext; 
    border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 
    26.8px;"><p class="MsoNormal" align="center" style="margin: 0cm; 
    text-align: center; line-height: normal; font-size: 10pt; font-family: 
    '맑은 고딕', sans-serif;">전자상거래 등에서의 소비자보호에 관한 법률<span 
    lang="EN-US"><o:p></o:p></span></p></td><td style="width: 
    141.79999999999998px; border-top: none; border-left: none; border-bottom: 
    1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 
    5.4pt; height: 26.8px;"><p class="MsoNormal" align="center" style="margin: 
    0cm; text-align: center; line-height: normal; font-size: 10pt; 
    font-family: '맑은 고딕', sans-serif;"><span lang="EN-US">5</span>년<span 
    lang="EN-US"><o:p></o:p></span></p></td></tr><tr style="height: 
    26.8px;"><td style="width: 528.8666666666666px; border-right: 1pt solid 
    windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid 
    windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; 
    height: 26.8px;"><p class="MsoNormal" align="center" style="margin: 0cm; 
    text-align: center; line-height: normal; font-size: 10pt; font-family: 
    '맑은 고딕', sans-serif;">소비자의 불만 또는 분쟁처리에 관한 기록<span 
    lang="EN-US"><o:p></o:p></span></p></td><td style="width: 8cmpx; 
    border-top: none; border-left: none; border-bottom: 1pt solid windowtext; 
    border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 
    26.8px;"><p class="MsoNormal" align="center" style="margin: 0cm; 
    text-align: center; line-height: normal; font-size: 10pt; font-family: 
    '맑은 고딕', sans-serif;">전자상거래 등에서의 소비자보호에 관한 법률<span 
    lang="EN-US"><o:p></o:p></span></p></td><td style="width: 
    141.79999999999998px; border-top: none; border-left: none; border-bottom: 
    1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 
    5.4pt; height: 26.8px;"><p class="MsoNormal" align="center" style="margin: 
    0cm; text-align: center; line-height: normal; font-size: 10pt; 
    font-family: '맑은 고딕', sans-serif;"><span lang="EN-US">3</span>년<span 
    lang="EN-US"><o:p></o:p></span></p></td></tr><tr style="height: 
    26.8px;"><td style="width: 528.8666666666666px; border-right: 1pt solid 
    windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid 
    windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; 
    height: 26.8px;"><p class="MsoNormal" align="center" style="margin: 0cm; 
    text-align: center; line-height: normal; font-size: 10pt; font-family: 
    '맑은 고딕', sans-serif;">장부와 교부한 세금계산서 또는 영수증<span 
    lang="EN-US"><o:p></o:p></span></p></td><td style="width: 8cmpx; 
    border-top: none; border-left: none; border-bottom: 1pt solid windowtext; 
    border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 
    26.8px;"><p class="MsoNormal" align="center" style="margin: 0cm; 
    text-align: center; line-height: normal; font-size: 10pt; font-family: 
    '맑은 고딕', sans-serif;">부가가치세법<span 
    lang="EN-US"><o:p></o:p></span></p></td><td style="width: 
    141.79999999999998px; border-top: none; border-left: none; border-bottom: 
    1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 
    5.4pt; height: 26.8px;"><p class="MsoNormal" align="center" style="margin: 
    0cm; text-align: center; line-height: normal; font-size: 10pt; 
    font-family: '맑은 고딕', sans-serif;"><span lang="EN-US">5</span>년<span 
    lang="EN-US"><o:p></o:p></span></p></td></tr><tr style="height: 
    26.8px;"><td style="width: 528.8666666666666px; border-right: 1pt solid 
    windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid 
    windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; 
    height: 26.8px;"><p class="MsoNormal" align="center" style="margin: 0cm; 
    text-align: center; line-height: normal; font-size: 10pt; font-family: 
    '맑은 고딕', sans-serif;">「통신비밀보호법」에 따른 서비스이용기록<span 
    lang="EN-US">, </span>접속로그<span lang="EN-US">, </span>접속<span 
    lang="EN-US">IP</span>정보<span 
    lang="EN-US"><o:p></o:p></span></p></td><td style="width: 8cmpx; 
    border-top: none; border-left: none; border-bottom: 1pt solid windowtext; 
    border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 
    26.8px;"><p class="MsoNormal" align="center" style="margin: 0cm; 
    text-align: center; line-height: normal; font-size: 10pt; font-family: 
    '맑은 고딕', sans-serif;">통신비밀보호법<span 
    lang="EN-US"><o:p></o:p></span></p></td><td style="width: 
    141.79999999999998px; border-top: none; border-left: none; border-bottom: 
    1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 
    5.4pt; height: 26.8px;"><p class="MsoNormal" align="center" style="margin: 
    0cm; text-align: center; line-height: normal; font-size: 10pt; 
    font-family: '맑은 고딕', sans-serif;"><span 
    lang="EN-US">3</span>개월<span 
    lang="EN-US"><o:p></o:p></span></p></td></tr></tbody></table></div><p 
    class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt; 
    line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 
    고딕&quot;, sans-serif;" align="left"><b><span lang="EN-US" 
    style="font-size: 11pt; line-height: 15.6933px;">&nbsp;</span></b></p><p 
    class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt; 
    line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 
    고딕&quot;, sans-serif;" align="left"><b><span lang="EN-US" 
    style="font-size: 14pt; line-height: 19.9733px;">3. </span></b><b><span 
    style="font-size: 14pt; line-height: 19.9733px;">개인정보의 파기절차 및 
    파기방법<span lang="EN-US"><o:p></o:p></span></span></b></p><p 
    class="MsoNormal" style="margin: 0cm 0cm 8pt 26.6pt; text-align: left; 
    line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 
    고딕&quot;, sans-serif; text-indent: -26.6pt;" align="left">&nbsp;</p><div 
    style="text-align: left;" align="left"><span lang="EN-US" 
    style="font-size: 10pt; text-indent: -26.6pt;">&nbsp; &nbsp;1) 
    </span><span style="font-size: 10pt; text-indent: -26.6pt;">회사는 다른 
    법률에 따라 개인정보를 보존하여야 하는 경우가 아닌 한</span><span 
    lang="EN-US" style="font-size: 10pt; text-indent: -26.6pt;">, </span><span 
    style="font-size: 10pt; text-indent: -26.6pt;">개인정보 보유기간의 
    경과</span><span lang="EN-US" style="font-size: 10pt; text-indent: 
    -26.6pt;">, </span><span style="font-size: 10pt; text-indent: 
    -26.6pt;">처리목적 달성 등 수집한 개인정보가 불필요하게 되었을 
    때</span></div><div style="text-align: left;" align="left"><span 
    style="font-size: 10pt; text-indent: -26.6pt;">에는 지체없이 해당 
    개인정보를 파기합니다</span><span lang="EN-US" style="font-size: 10pt; 
    text-indent: -26.6pt;">.</span></div><p>&nbsp;</p><p class="MsoNormal" 
    style="text-align: left; margin: 0cm 0cm 8pt 26.6pt; line-height: 
    14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, 
    sans-serif; text-indent: -26.6pt;" align="left"><span 
    lang="EN-US">&nbsp;&nbsp; 2) </span>회사는 위치정보의 보호 및 이용 등에 
    관한 법률 제<span lang="EN-US">24</span>조 제<span 
    lang="EN-US">4</span>항의 규정에 의하여 고객님이 동의의 전부 또는 일부를 
    철회한 경우에는 지체 없이 수집된 개인위치정보 및 개인위치정보 
    수집ㆍ이용ㆍ제공 사실 확인 자료<span lang="EN-US">(</span>동의의 일부를 
    철회하는 경우에는 철회하는 부분의 개인위치정보 및 개인위치정보의 수집<a 
    name="_Hlk136601819">ㆍ</a>이용ㆍ제공 사실 확인 자료에 한함<span 
    lang="EN-US">)</span>를 파기하며<span lang="EN-US">, </span>파기절차 및 
    파기방법은 본 조항에 따릅니다<span lang="EN-US">.<o:p></o:p></span></p><p 
    class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt 26.6pt; 
    line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 
    고딕&quot;, sans-serif; text-indent: -26.6pt;" align="left"><span 
    lang="EN-US">&nbsp;&nbsp; 3) </span>고객님으로부터 동의 받은 개인정보 
    보유기간이 경과하거나 처리 목적이 달성되었음에도 불구하고 다른 법령에 의한 
    근거 및 내부 방침 및 정보보호 사유<span lang="EN-US">(</span>보유 및 
    이용기간 참조<span lang="EN-US">)</span>에 따라 개인정보를 계속 보존하여야 
    하는 경우에는 해당 개인정보를 별도의 데이터베이스<span 
    lang="EN-US">(DB)</span>로 옮기거나 보관장소를 달리하여<span 
    lang="EN-US">(</span>종이의 경우 별도의 서류함<span lang="EN-US">) 
    </span>보존합니다<span lang="EN-US">. </span>별도 <span 
    lang="EN-US">DB</span>로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 
    보유되는 목적 이외의 다른 목적으로 이용되지 않습니다<span 
    lang="EN-US">.<o:p></o:p></span></p><p class="MsoNormal" style="margin: 
    0cm 0cm 8pt 26.6pt; text-align: left; line-height: 14.2667px; font-size: 
    10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: 
    -26.6pt;" align="left">&nbsp;</p><div style="text-align: left;" 
    align="left"><span lang="EN-US" style="font-size: 10pt; text-indent: 
    -26.6pt;">&nbsp; &nbsp;4) </span><span style="font-size: 10pt; 
    text-indent: -26.6pt;">전자적 파일 형태로 기록ㆍ저장된 개인정보는 복원이 
    불가능한 방법으로 영구 삭제</span><span lang="EN-US" style="font-size: 
    10pt; text-indent: -26.6pt;">(</span><span style="font-size: 10pt; 
    text-indent: -26.6pt;">다만</span><span lang="EN-US" style="font-size: 
    10pt; text-indent: -26.6pt;">, </span><span style="font-size: 10pt; 
    text-indent: -26.6pt;">기술적 특성으로 영구 삭제가 현저히 곤란한 경우에는 
    법 제</span><span lang="EN-US" style="font-size: 10pt; text-indent: 
    -26.6pt;">58</span><span style="font-size: 10pt; text-indent: 
    -26.6pt;">조의 </span><span lang="EN-US" style="font-size: 10pt; 
    text-indent: -26.6pt;">2</span><span style="font-size: 10pt; text-indent: 
    -26.6pt;">에 해당하는 정보로 처리하여 복원이 불가능하도록 조치</span><span 
    lang="EN-US" style="font-size: 10pt; text-indent: -26.6pt;">)</span><span 
    style="font-size: 10pt; text-indent: -26.6pt;">하며</span><span 
    lang="EN-US" style="font-size: 10pt; text-indent: -26.6pt;">, </span><span 
    style="font-size: 10pt; text-indent: -26.6pt;">종이 문서에 기록ㆍ저장된 
    개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다</span><span 
    lang="EN-US" style="font-size: 10pt; text-indent: 
    -26.6pt;">.</span></div><span lang="EN-US"><div style="text-align: left;" 
    align="left"><span lang="EN-US" style="font-size: 10pt; text-indent: 
    -26.6pt;">&nbsp;</span><span lang="EN-US" style="text-indent: -26.6pt; font-size: 9pt; line-height: 12.84px;">* [</span><span style="text-indent: -26.6pt; font-size: 9pt; line-height: 12.84px;">법 제<span lang="EN-US">58</span>조의 <span lang="EN-US">2] </span>시간ㆍ비용ㆍ기술 등을 합리적으로 고려 할 때 다른 정보를 사용하여도 더 이상 개인을 알아볼 수 
    없는 정보로 처리하여 복원이 불가능하도록 조치</span></div></span><p>&nbsp;</p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt 26.6pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -26.6pt;" align="left"><span lang="EN-US">&nbsp;</span></p><p class="MsoNormal" style="text-align: 
    left; margin: 0cm 0cm 8pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" align="left"><b><span lang="EN-US" style="font-size: 14pt; line-height: 19.9733px;">4. </span></b><b><span style="font-size: 14pt; line-height: 19.9733px;">개인정보 처리의 위탁<span lang="EN-US"><o:p></o:p></span></span></b></p><p 
    class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt 26.6pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -26.6pt;" align="left"><span lang="EN-US">&nbsp;&nbsp; 1) </span>회사는 개인정보를 위탁하여 처리하고 있지 않습니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoNormal" 
    style="text-align: left; margin: 0cm 0cm 8pt 31.5pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -16.6pt;" align="left"><span lang="EN-US">2) </span>다만<span lang="EN-US">, </span>원활한 개인정보 처리를 위하여 위탁 계약에 의거<span lang="EN-US">, </span>개인정보 처리 업무를 위탁 할 수 있으나 이 
    때에는 반드시 고객님께 사전 동의를 구할 것입니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt 26.6pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -26.6pt;" align="left"><span lang="EN-US">&nbsp;&nbsp; 3) </span>또한 회사는 위탁계약 체결 
    시 「개인정보보호법」 제 <span lang="EN-US">26</span>조에 따라 위탁업무 수행목적 외 개인정보 처리금지<span lang="EN-US">, </span>기술적ㆍ관리적 보호조치<span lang="EN-US">, </span>재위탁 제한<span lang="EN-US">, </span>수탁자에 대한 관리 및 감독<span lang="EN-US">, </span>손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고<span lang="EN-US">, 
    </span>수탁자가 개인정보를 안전하게 처리하는지를 감독 할 것입니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt 30pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -30pt;" align="left"><span lang="EN-US">&nbsp;&nbsp; 4) </span>위탁업무의 
    신규 생성<span lang="EN-US">, </span>이 후 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt 30pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: 
    -30pt;" align="left"><span lang="EN-US">&nbsp;</span></p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" align="left"><b><span lang="EN-US" style="font-size: 14pt; line-height: 19.9733px;">5. </span></b><b><span style="font-size: 14pt; 
    line-height: 19.9733px;">개인정보의 제<span lang="EN-US">3</span>자 제공<span lang="EN-US"><o:p></o:p></span></span></b></p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt 26.6pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -26.6pt;" align="left"><span lang="EN-US">&nbsp;&nbsp; 
    1) </span>회사는 고객님의 개인정보를 <span lang="EN-US">‘2.</span>개인정보의 처리 목적<span lang="EN-US">, </span>항목 및 보유기간<span lang="EN-US">’</span>에서 명시한 범위 내에서만 처리하며<span lang="EN-US">, </span>고객님의 동의<span lang="EN-US">, </span>법률의 특별한 규정 등 「개인정보 <span style="font-variant-numeric: normal; 
    font-variant-east-asian: normal; font-variant-caps: small-caps; font-variant-alternates: normal; font-variant-position: normal;">보호법」 제<span lang="EN-US">17</span>조 및 제<span lang="EN-US">18</span>조<span lang="EN-US">, </span>「위치정보의 보호 및 이용 등에 관한 법률」 제<span lang="EN-US">19</span>조 및 제<span lang="EN-US">21</span>조에 해당하는 
    경우에만 개인정보 및 개인위치정보를 제<span lang="EN-US">3</span>자에게 제공합니다<span lang="EN-US">.</span></span><span lang="EN-US"><o:p></o:p></span></p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt 26.6pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -26.6pt;" 
    align="left"><span lang="EN-US">&nbsp;&nbsp; 2) </span>회사는 다음과 같이 개인정보 및 개인위치정보를 제<span lang="EN-US">3</span>자에게 제공하고 있습니다<span lang="EN-US">.<o:p></o:p></span></p><div align="left" style="font-size: medium; text-align: left;"><table class="MsoTableGrid __se_tbl_ext" border="1" cellspacing="0" cellpadding="0" 
    style="border-collapse: collapse; border: none;"><tbody><tr style="height: 38px;"><td style="width: 122.53333333333336px; border: 1pt solid windowtext; padding: 0cm 5.4pt; height: 38px;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><b>구분<span 
    lang="EN-US"><o:p></o:p></span></b></p></td><td style="width: 122.60000000000001px; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0cm 5.4pt; height: 38px;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: 
    normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><b>서비스명<span lang="EN-US"><o:p></o:p></span></b></p></td><td style="width: 151.46666666666664px; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0cm 5.4pt; height: 38px;"><p 
    class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><b>제공받는 자<span lang="EN-US"><o:p></o:p></span></b></p></td><td style="width: 274.0666666666667px; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid 
    windowtext; border-image: initial; border-left: none; padding: 0cm 5.4pt; height: 38px;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><b>제공받는 자의 수집 ㆍ이용 목적<span lang="EN-US"><o:p></o:p></span></b></p></td><td style="width: 
    207.86666666666667px; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0cm 5.4pt; height: 38px;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', 
    sans-serif;"><b>제공 항목<span lang="EN-US"><o:p></o:p></span></b></p></td><td style="width: 108.46666666666665px; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0cm 5.4pt; height: 38px;"><p class="MsoNormal" align="center" style="margin: 0cm; 
    text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><b>제공받는 자의 보유기간<span lang="EN-US"><o:p></o:p></span></b></p></td></tr><tr style="height: 62.60000000000001px;"><td style="width: 122.53333333333336px; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid 
    windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 62.60000000000001px;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;">농가온<span lang="EN-US"><o:p></o:p></span></p></td><td style="width: 122.60000000000001px; 
    border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 62.60000000000001px;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;">카우잇츠 서비스<span 
    lang="EN-US"><o:p></o:p></span></p></td><td style="width: 151.46666666666664px; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 62.60000000000001px;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; 
    font-family: '맑은 고딕', sans-serif;"><span lang="EN-US">LS</span>엠트론<span lang="EN-US"><o:p></o:p></span></p></td><td style="width: 274.0666666666667px; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 62.60000000000001px;"><p class="MsoNormal" align="center" 
    style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;">조사료 수급 및 유통 관리<span lang="EN-US">,<o:p></o:p></span></p><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;">이벤트 맞춤형 서비스 
    분석 및 기획<span lang="EN-US">,<o:p></o:p></span></p><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;">신사업 도출<span lang="EN-US"><o:p></o:p></span></p></td><td style="width: 207.86666666666667px; border-top: none; border-left: none; border-bottom: 
    1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 62.60000000000001px;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;">성명<span lang="EN-US">, </span>연락처<span lang="EN-US">, </span>주소<span 
    lang="EN-US"><o:p></o:p></span></p></td><td style="width: 108.46666666666665px; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 62.60000000000001px;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; 
    font-family: '맑은 고딕', sans-serif;">처리 종료시<span lang="EN-US"><o:p></o:p></span></p><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;">까지<span lang="EN-US"><o:p></o:p></span></p></td></tr></tbody></table></div><p class="MsoNormal" 
    style="text-align: left; margin: 0cm 0cm 8pt 10.65pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -10.65pt;" align="left"><span lang="EN-US" style="font-size: 4pt; line-height: 5.70667px;">&nbsp;</span></p><p class="MsoNormal" style="margin: 0cm 0cm 8pt 24.5pt; text-align: left; line-height: 
    14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -9.1pt;" align="left">&nbsp;</p><div style="text-align: left;" align="left"><span lang="EN-US" style="font-size: 10pt; text-indent: -9.1pt;">3) </span><span style="font-size: 10pt; text-indent: -9.1pt; font-variant-numeric: normal; font-variant-east-asian: normal; 
    font-variant-caps: small-caps; font-variant-alternates: normal; font-variant-position: normal;">회사는 고객님의 개인위치정보를 고객님께서 지정하는 제<span lang="EN-US">3</span>자에게 제공하는 경우 개인위치정보를 수집한 당해 통신 단말 장치로 매회 고객님께 제공받는 자<span lang="EN-US">, </span>제공</span></div><span style="font-variant-numeric: normal; 
    font-variant-east-asian: normal; font-variant-caps: small-caps; font-variant-alternates: normal; font-variant-position: normal;"><div style="text-align: left;" align="left"><span style="font-size: 10pt; text-indent: -9.1pt; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-variant-position: 
    normal;"><span lang="EN-US">&nbsp;</span>일시</span><span style="font-size: 10pt; text-indent: -9.1pt;"> 및 제공 목적을 즉시 통보합니다</span><span lang="EN-US" style="font-size: 10pt; text-indent: -9.1pt;">. </span><span style="font-size: 10pt; text-indent: -9.1pt;">단</span><span lang="EN-US" style="font-size: 10pt; text-indent: -9.1pt;">, </span><span 
    style="font-size: 10pt; text-indent: -9.1pt;">다음에 해당하는 경우에는 고객님께서 미리 특정하여 지정한 통신 단말 장치 또는 </span><span lang="EN-US" style="font-size: 10pt; text-indent: -9.1pt;">E-mil</span><span style="font-size: 10pt; text-indent: -9.1pt;">로 통보합니다</span><span lang="EN-US" style="font-size: 10pt; text-indent: 
    -9.1pt;">.</span></div></span><p>&nbsp;</p><p class="MsoNormal" style="margin: 0cm 0cm 8pt 30pt; text-align: left; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" align="left">&nbsp;</p><div style="text-align: left;" align="left"><span lang="EN-US" style="font-size: 10pt;">- </span><span style="font-size: 
    10pt;">개인위치정보를 수집한 당해 통신 단말 장치가 문자</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">음성 또는 영상의 수신 기능을 갖추지 않은 경우</span></div><span lang="EN-US"><div style="text-align: left;" align="left"><span lang="EN-US" style="font-size: 10pt;">- </span><span style="font-size: 
    10pt;">개인위치정보 주체가 개인위치정보를 수집한 당해 통신 단말 장치 외의 통신 단말 장치 또는 </span><span lang="EN-US" style="font-size: 10pt;">E-mail </span><span style="font-size: 10pt;">등으로 통보할 것을 미리 요청한 경우</span></div></span><div style="text-align: left;"><span style="font-size: 10pt;">※ 단</span><span lang="EN-US" style="font-size: 
    10pt;">, </span><span style="font-size: 10pt;">최대 </span><span lang="EN-US" style="font-size: 10pt;">30</span><span style="font-size: 10pt;">일의 범위에서 다음 각 시행에 따라 정보제공내역을 모아서 통보할 수 있습니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></div><span lang="EN-US"><div style="text-align: left;"><span lang="EN-US" 
    style="font-size: 10pt;">&nbsp; &nbsp;- </span><span style="font-size: 10pt;">횟수</span><span lang="EN-US" style="font-size: 10pt;">: 10</span><span style="font-size: 10pt;">회</span><span lang="EN-US" style="font-size: 10pt;">, 20</span><span style="font-size: 10pt;">회</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 
    10pt;">또는 </span><span lang="EN-US" style="font-size: 10pt;">30</span><span style="font-size: 10pt;">회 등 </span><span lang="EN-US" style="font-size: 10pt;">10</span><span style="font-size: 10pt;">배수의 횟수</span></div></span><span lang="EN-US"><div style="text-align: left;"><span lang="EN-US" style="font-size: 10pt;">&nbsp; &nbsp;- </span><span 
    style="font-size: 10pt;">기간</span><span lang="EN-US" style="font-size: 10pt;">: 10</span><span style="font-size: 10pt;">일</span><span lang="EN-US" style="font-size: 10pt;">, 20</span><span style="font-size: 10pt;">일 또는 </span><span lang="EN-US" style="font-size: 10pt;">30</span><span style="font-size: 10pt;">일</span></div></span><p>&nbsp;</p><p 
    class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt 30pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -30pt;" align="left"><span lang="EN-US">&nbsp;</span></p><p class="MsoNormal" style="margin: 0cm 0cm 8pt; text-align: left; line-height: 14.2667px; font-size: 10pt; font-family: 
    &quot;맑은 고딕&quot;, sans-serif;" align="left">&nbsp;</p><div style="text-align: left;" align="left"><b style="font-size: 10pt;"><span lang="EN-US" style="font-size: 14pt; line-height: 19.9733px;">6. </span></b><b style="font-size: 10pt;"><span style="font-size: 14pt; line-height: 19.9733px;">개인정보의 안전성 확보조치</span></b></div><b><div 
    style="text-align: left;" align="left"><b style="font-size: 10pt;"><span lang="EN-US">&nbsp; &nbsp;&nbsp;</span>※ 개인정보의 기술적<span lang="EN-US">/</span>관리적 보호 대책</b></div></b><p>&nbsp;</p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt 14.2pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, 
    sans-serif; text-indent: -14.2pt;" align="left"><span lang="EN-US">&nbsp;&nbsp; </span>회사는 고객의 개인정보를 처리함에 있어 개인정보의 분실<span lang="EN-US">, </span>도난<span lang="EN-US">, </span>누출<span lang="EN-US">, </span>변조 또는 훼손되지 않도록 안전성 확보를 위하여 아래와 같은 기술적<span lang="EN-US">/</span>관리적 대책을 적용받고 
    있습니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoListParagraph" style="text-align: left; margin: 0cm 0cm 8pt 28.8pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -13.8pt;" align="left"><span lang="EN-US">1)<span style="font-variant-numeric: normal; font-variant-east-asian: normal; 
    font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-variant-position: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp; </span></span>기술적 조치<span lang="EN-US"> (AWS RDS: Amazon Web 
    Service Relational Database Service)<o:p></o:p></span></p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt 15pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" align="left"><span lang="EN-US">&nbsp;&nbsp; AWS RDS (Relational Database Service)</span>의 보안 체계는 데이터베이스의 안전성을 보장하고 
    고객의 데이터를 보호하기 위해 다양한 기능과 조치로 구성됩니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoListParagraph" style="text-align: left; margin: 0cm 0cm 0cm 55pt; line-height: normal; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -18pt;" align="left"><span lang="EN-US">①<span 
    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-variant-position: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New 
    Roman&quot;;">&nbsp;&nbsp;&nbsp; </span></span>데이터 암호화<span lang="EN-US">: RDS</span>는 데이터베이스 내의 데이터를 저장 및 전송 중에 암호화합니다<span lang="EN-US">. </span>데이터베이스 볼륨은<span lang="EN-US"> AWS Key Management Service (KMS)</span>를 사용하여 암호화되며<span lang="EN-US">, </span>데이터베이스 엔진이 암호화를 처리합니다<span 
    lang="EN-US">. </span>데이터베이스 스냅샷과 자동 백업 역시 암호화됩니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoListParagraph" style="text-align: left; margin: 0cm 0cm 0cm 55pt; line-height: normal; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -18pt;" align="left"><span lang="EN-US">②<span 
    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-variant-position: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New 
    Roman&quot;;">&nbsp;&nbsp;&nbsp; </span></span>네트워크 보안<span lang="EN-US">: RDS </span>인스턴스는<span lang="EN-US"> Amazon Virtual Private Cloud (VPC) </span>내에서 실행되며<span lang="EN-US">, VPC </span>보안 그룹과 네트워크<span lang="EN-US"> ACL</span>을 사용하여 인바운드 및 아웃바운드 트래픽을 제어합니다<span lang="EN-US">. </span>이로써 
    네트워크에서의 액세스를 효과적으로 관리할 수 있습니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoListParagraph" style="text-align: left; margin: 0cm 0cm 0cm 55pt; line-height: normal; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -18pt;" align="left"><span lang="EN-US">③<span style="font-variant-numeric: 
    normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-variant-position: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp; 
    </span></span>엑세스 제어<span lang="EN-US">: <span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-caps: small-caps; font-variant-alternates: normal; font-variant-position: normal;">AWS Identity and Access Management (IAM)</span></span><span style="font-variant-numeric: normal; font-variant-east-asian: normal; 
    font-variant-caps: small-caps; font-variant-alternates: normal; font-variant-position: normal;">을 사용하여 데이터베이스 인스턴스에 대한 액세스를 제어하고<span lang="EN-US">, RDS </span>자체에서 데이터베이스 사용자</span> 및 권한을 관리합니다<span lang="EN-US">. </span>데이터베이스 엔진의 액세스 제어도 설정할 수 있습니다<span 
    lang="EN-US">.<o:p></o:p></span></p><p class="MsoListParagraph" style="text-align: left; margin: 0cm 0cm 0cm 55pt; line-height: normal; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -18pt;" align="left"><span lang="EN-US">④<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: 
    normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-variant-position: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp; </span></span>보안 그룹<span lang="EN-US">: RDS </span>보안 그룹을 사용하여 특정<span 
    lang="EN-US"> IP </span>주소나<span lang="EN-US"> IP </span>대역에서 데이터베이스에 대한 액세스를 허용 또는 거부할 수 있습니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoListParagraph" style="text-align: left; margin: 0cm 0cm 0cm 55pt; line-height: normal; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -18pt;" 
    align="left"><span lang="EN-US">⑤<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-variant-position: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: 
    &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp; </span></span>로그 및 감사<span lang="EN-US">: RDS</span>는 로그 및 감사 기능을 제공하여 데이터베이스 활동을 추적하고 모니터링할 수 있습니다<span lang="EN-US">. </span>이벤트 및 로그 데이터를 분석하여 보안 문제를 식별할 수 있습니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoListParagraph" 
    style="text-align: left; margin: 0cm 0cm 0cm 55pt; line-height: normal; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -18pt;" align="left"><span lang="EN-US">⑥<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; 
    font-feature-settings: normal; font-variation-settings: normal; font-variant-position: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp; </span></span>물리적 보안<span lang="EN-US">: AWS </span>데이터 센터는 물리적으로 보호되며<span lang="EN-US">, </span>무단 접근을 방지하기 
    위한 보안 장치와 절차를 갖추고 있습니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoListParagraph" style="text-align: left; margin: 0cm 0cm 0cm 55pt; line-height: normal; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -18pt;" align="left"><span lang="EN-US">⑦<span style="font-variant-numeric: normal; 
    font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-variant-position: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp; </span></span>백업과 
    리커버리<span lang="EN-US">: RDS</span>는 자동 백업 및 스냅샷을 지원하여 데이터의 손실을 방지하고 데이터 리커버리를 용이하게 합니다<span lang="EN-US">.</span><span lang="EN-US"><o:p></o:p></span></p><p class="MsoListParagraph" style="text-align: left; margin: 0cm 0cm 0cm 55pt; line-height: normal; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, 
    sans-serif;" align="left"><b><span lang="EN-US">&nbsp;</span></b></p><p class="MsoNormal" style="margin: 0cm 0cm 8pt 10pt; text-align: left; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" align="left">&nbsp;</p><div style="text-align: left;" align="left"><span lang="EN-US" style="font-size: 10pt;">2) 
    </span><span style="font-size: 10pt;">관리적 조치</span></div><span lang="EN-US"><div style="text-align: left;" align="left"><span lang="EN-US" style="font-size: 10pt;">&nbsp; &nbsp;&nbsp;</span><span style="font-size: 10pt;">① 개인정보관리체계 수립</span><span lang="EN-US" style="font-size: 10pt;">: </span><span style="font-size: 10pt;">개인정보를 
    안전하게 관리하기 위하여 회사 내부적으로 개인정보 관리체계를 수립하여 운영하고 있습니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></div></span><p>&nbsp;</p><p class="MsoNormal" style="margin: 0cm 0cm 8pt 44.2pt; text-align: left; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: 
    -44.2pt;" align="left">&nbsp;</p><div style="text-align: left;" align="left"><span lang="EN-US" style="font-size: 10pt; text-indent: -44.2pt;">&nbsp; &nbsp; &nbsp;&nbsp;</span><span style="font-size: 10pt; text-indent: -44.2pt;">② 개인정보보호 위원회 운영</span><span lang="EN-US" style="font-size: 10pt; text-indent: -44.2pt;">: </span><span 
    style="font-size: 10pt; text-indent: -44.2pt;">회사의 개인정보보호를 위한 위원회를 구성하여 연</span><span lang="EN-US" style="font-size: 10pt; text-indent: -44.2pt;"> 1</span><span style="font-size: 10pt; text-indent: -44.2pt;">회 이상 위원회 회의를 개최하고 개인정보관리체계의 운영 및 개인정보보호</span></div><div style="text-align: left;" 
    align="left"><span style="font-size: 10pt; text-indent: -44.2pt;">이슈 등의 사항에 대하여 개선하고 바로잡기 위한 노력을 기울이고 있습니다</span><span lang="EN-US" style="font-size: 10pt; text-indent: -44.2pt;">.</span></div><p>&nbsp;</p><p class="MsoNormal" style="margin: 0cm 0cm 8pt 44.2pt; text-align: left; line-height: 14.2667px; font-size: 10pt; 
    font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -44.2pt;" align="left">&nbsp;</p><div style="text-align: left;" align="left"><span lang="EN-US" style="font-size: 10pt; text-indent: -44.2pt;">&nbsp; &nbsp; &nbsp;&nbsp;</span><span style="font-size: 10pt; text-indent: -44.2pt;">③ 개인정보 취급자 관리</span><span lang="EN-US" style="font-size: 
    10pt; text-indent: -44.2pt;">: </span><span style="font-size: 10pt; text-indent: -44.2pt;">고객의 개인정보를 처리하는 개인정보취급자를 대상으로 정보보호 서약서를 제출 받고</span><span lang="EN-US" style="font-size: 10pt; text-indent: -44.2pt;">, </span><span style="font-size: 10pt; text-indent: -44.2pt;">연</span><span lang="EN-US" style="font-size: 
    10pt; text-indent: -44.2pt;"> 1</span><span style="font-size: 10pt; text-indent: -44.2pt;">회 이상의 개인정보보호 교육을 수행하여</span></div><div style="text-align: left;" align="left"><span style="font-size: 10pt; text-indent: -44.2pt;">고객정보의 중요성을 이해하고 안전하게 관리하도록 하고 있습니다</span><span lang="EN-US" style="font-size: 10pt; 
    text-indent: -44.2pt;">. </span><span style="font-size: 10pt; text-indent: -44.2pt;">또한 개인정보처리자의 권한 관리를 통하여 불필요한 고객의 개인정보에 대한 접근과 노출을 최소화하고 있습니다</span><span lang="EN-US" style="font-size: 10pt; text-indent: -44.2pt;">.</span></div><p>&nbsp;</p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt 
    44.2pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -14.8pt;" align="left">④ 관련 규정 준수<span lang="EN-US">: AWS</span>의 경우 다양한 규정 및 규제 준수를 지원하며<span lang="EN-US">, AWS RDS</span>는<span lang="EN-US"> GDPR, HIPAA, SOC </span>및<span lang="EN-US"> PCI DSS</span>와 같은 규정 
    준수 요구 사항을 준수하기 위해 대비되어 있습니다<span lang="EN-US">. </span>또한<span lang="EN-US"> ISO 27001/27001, ISMS </span>인증을 취득<span lang="EN-US">, </span>유지하고 있습니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt 44.2pt; line-height: 14.2667px; font-size: 10pt; font-family: 
    &quot;맑은 고딕&quot;, sans-serif; text-indent: -44.2pt;" align="left"><span lang="EN-US">&nbsp;</span></p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" align="left"><b><span lang="EN-US" style="font-size: 14pt; line-height: 19.9733px;">7. 
    </span></b><b><span style="font-size: 14pt; line-height: 19.9733px;">개인정보 자동 수집 장치의 설치ㆍ운영 및 거부에 관한 사항<span lang="EN-US"><o:p></o:p></span></span></b></p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" align="left"><span 
    lang="EN-US">&nbsp;&nbsp; 1) </span>회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는 <span lang="EN-US">‘</span>쿠키<span lang="EN-US">(Cookie)’</span>를 사용할 수 있습니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt; line-height: 14.2667px; 
    font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" align="left"><span lang="EN-US">&nbsp;&nbsp; 2) </span><span style="letter-spacing: -0.3pt;">쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며<span lang="EN-US">, </span>이용자들의 <span lang="EN-US">PC </span>컴퓨터 내의 하드디스크에 
    저장되기도 합니다<span lang="EN-US">.</span></span><span lang="EN-US"><o:p></o:p></span></p><p class="MsoNormal" style="margin: 0cm 0cm 8pt 44.8pt; text-align: left; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -44.8pt;" align="left">&nbsp;</p><div style="text-align: left;" align="left"><span 
    lang="EN-US" style="font-size: 10pt; text-indent: -44.8pt;">&nbsp; &nbsp; &nbsp;&nbsp;</span><span style="font-size: 10pt; text-indent: -44.8pt;">① 쿠키의 사용목적</span><span lang="EN-US" style="font-size: 10pt; text-indent: -44.8pt;">: </span><span style="font-size: 10pt; text-indent: -44.8pt;">이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 
    이용형태</span><span lang="EN-US" style="font-size: 10pt; text-indent: -44.8pt;">, </span><span style="font-size: 10pt; text-indent: -44.8pt;">보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해</span></div><span style="letter-spacing: -0.2pt;"><div style="text-align: left;" align="left"><span style="font-size: 10pt; text-indent: -44.8pt; 
    letter-spacing: -0.2pt;">사용됩니다<span lang="EN-US">. </span>고객은 쿠키 설치에 대한 선택권을 가지고 있습니다<span lang="EN-US">. </span>따라서<span lang="EN-US">, </span>고객은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나<span lang="EN-US">, </span>쿠키가 저장될</span><span style="font-size: 10pt; text-indent: -44.8pt;"> 때마다 확인을 
    거치거나</span><span lang="EN-US" style="font-size: 10pt; text-indent: -44.8pt;">, </span><span style="font-size: 10pt; text-indent: -44.8pt;">아니면 모든 쿠키의 저장을 거부할 수도 있습니다</span><span lang="EN-US" style="font-size: 10pt; text-indent: -44.8pt;">.</span></div></span><p>&nbsp;</p><p class="MsoNormal" style="text-align: left; margin: 0cm 
    0cm 8pt 44.8pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -44.8pt;" align="left"><span lang="EN-US">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>② 쿠키의 설치ㆍ운영 및 거부<span lang="EN-US">: </span>쿠키 설정을 거부하는 방법으로는 고객이 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 
    허용하거나 쿠키를 저장할 때마다 확인을 거치거나<span lang="EN-US">, </span>모든 쿠키의 저장을 거부할 수 있습니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoListParagraph" style="margin: 0cm 0cm 8pt 48pt; text-align: left; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -18pt;" 
    align="left">&nbsp;</p><div style="text-align: left;" align="left"><span lang="EN-US" style="font-size: 10pt; text-indent: -18pt;">※<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; 
    font-variant-position: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style="font-size: 10pt; text-indent: -18pt;">설정방법</span></div><span lang="EN-US"><div style="text-align: left;" align="left"><span lang="EN-US" style="font-size: 10pt; 
    text-indent: -18pt;">- Microsoft Edge: </span><span style="font-size: 10pt; text-indent: -18pt;">웹 브라우저 상단의 설정 </span><span lang="EN-US" style="font-size: 10pt; text-indent: -18pt;">&gt; </span><span style="font-size: 10pt; text-indent: -18pt;">쿠키 및 사이트 권한 </span><span lang="EN-US" style="font-size: 10pt; text-indent: -18pt;">&gt; 
    </span><span style="font-size: 10pt; text-indent: -18pt;">설정 방법 선택</span></div></span><span lang="EN-US"><div style="text-align: left;"><span lang="EN-US" style="font-size: 10pt; text-indent: -18pt;">- Google Chrome: </span><span style="font-size: 10pt; text-indent: -18pt;">웹 브라우저 상단의 설정 </span><span lang="EN-US" style="font-size: 10pt; 
    text-indent: -18pt;">&gt; </span><span style="font-size: 10pt; text-indent: -18pt;">개인정보 및 보안 </span><span lang="EN-US" style="font-size: 10pt; text-indent: -18pt;">&gt; </span><span style="font-size: 10pt; text-indent: -18pt;">쿠키 및 기타 사이트 데이터 </span><span lang="EN-US" style="font-size: 10pt; text-indent: -18pt;">&gt; </span><span 
    style="font-size: 10pt; text-indent: -18pt;">설정 방법 선택</span></div></span><p>&nbsp;</p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" align="left"><span lang="EN-US">&nbsp;</span></p><p class="MsoNormal" style="margin: 0cm 0cm 8pt; 
    text-align: left; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" align="left">&nbsp;</p><div style="text-align: left;" align="left"><b style="font-size: 10pt;"><span lang="EN-US" style="font-size: 14pt; line-height: 19.9733px;">8. </span></b><b style="font-size: 10pt;"><span style="font-size: 14pt; line-height: 
    19.9733px;">고객<span lang="EN-US">, </span>법정대리인의 권리와 의무 및 그 행사방법</span></b></div><span lang="EN-US"><div style="text-align: left;" align="left"><span lang="EN-US" style="font-size: 10pt;">&nbsp; &nbsp;1) </span><span style="font-size: 10pt;">고객 또는 법정대리인</span><span lang="EN-US" style="font-size: 10pt;">(</span><span 
    style="font-size: 10pt;">만 </span><span lang="EN-US" style="font-size: 10pt;">14</span><span style="font-size: 10pt;">세 미만의 아동인 경우</span><span lang="EN-US" style="font-size: 10pt;">)</span><span style="font-size: 10pt;">은 회사에 대하여 언제든지 개인정보 수집ㆍ이용ㆍ제공 등의 동의를 철회</span><span lang="EN-US" style="font-size: 
    10pt;">(</span><span style="font-size: 10pt;">가입해지</span><span lang="EN-US" style="font-size: 10pt;">)</span><span style="font-size: 10pt;">할 수 있으며 개인정보</span></div></span><span lang="EN-US"><div style="text-align: left;"><span lang="EN-US" style="font-size: 10pt;">&nbsp; &nbsp; &nbsp;&nbsp;</span><span style="font-size: 
    10pt;">열람</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">정정</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">삭제</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">처리정지 요구 등의 권리를 행사할 수 있습니다</span><span 
    lang="EN-US" style="font-size: 10pt;">.</span></div></span><p>&nbsp;</p><p class="MsoNormal" style="margin: 0cm 0cm 8pt 30pt; text-align: left; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -30pt;" align="left">&nbsp;</p><div style="text-align: left;" align="left"><span lang="EN-US" 
    style="font-size: 10pt; text-indent: -30pt;">&nbsp; &nbsp;2) </span><span style="font-size: 10pt; text-indent: -30pt;">고객 또는 법정대리인은 위와 같은 권리 행사를 온라인에서는 고객 상담 창구를 통해 하실 수 있고</span><span lang="EN-US" style="font-size: 10pt; text-indent: -30pt;">, </span><span style="font-size: 10pt; text-indent: 
    -30pt;">서면</span><span lang="EN-US" style="font-size: 10pt; text-indent: -30pt;">, </span><span style="font-size: 10pt; text-indent: -30pt;">전화 또는 이메일 등을 통하여 회사의 개인정보보호</span></div><div style="text-align: left;" align="left"><span style="font-size: 10pt; text-indent: -30pt;">책임자 및 담당자에게 연락하는 방법으로 하실 수 
    있으며</span><span lang="EN-US" style="font-size: 10pt; text-indent: -30pt;">, </span><span style="font-size: 10pt; text-indent: -30pt;">회사는 이에 대해 지체없이 조치하겠습니다</span><span lang="EN-US" style="font-size: 10pt; text-indent: -30pt;">.</span></div><p>&nbsp;</p><p class="MsoNormal" style="margin: 0cm 0cm 8pt 30pt; text-align: left; 
    line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -30pt;" align="left">&nbsp;</p><div style="text-align: left;" align="left"><span lang="EN-US" style="font-size: 10pt; text-indent: -30pt;">&nbsp; &nbsp;3) </span><span style="font-size: 10pt; text-indent: -30pt; letter-spacing: -0.3pt;">제<span 
    lang="EN-US">1, 2</span>항에 따른 권리 행사는 고객님의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다<span lang="EN-US">. </span>이 경우 <span lang="EN-US">“</span>개인정보 처리 방법에 관한 고시<span lang="EN-US">(</span>제<span lang="EN-US">2020-7</span>호<span lang="EN-US">)”</span></span></div><div style="text-align: left;" 
    align="left"><span style="font-size: 10pt; text-indent: -30pt;">별지 제</span><span lang="EN-US" style="font-size: 10pt; text-indent: -30pt;">11</span><span style="font-size: 10pt; text-indent: -30pt;">호 서식에 따른 위임장을 제출하셔야 합니다</span><span lang="EN-US" style="font-size: 10pt; text-indent: -30pt;">.</span></div><p>&nbsp;</p><p 
    class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" align="left"><span lang="EN-US">&nbsp;&nbsp; 4) </span>개인정보 열람 및 처리정지 요구는 개인정보보호법 제<span lang="EN-US">35</span>조 제<span lang="EN-US">4</span>항<span lang="EN-US">, </span>제<span 
    lang="EN-US">37</span>조 제<span lang="EN-US">2</span>항에 의하여 고객님의 권리가 제한될 수 있습니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" align="left"><span lang="EN-US">&nbsp;&nbsp; 5) 
    </span>개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" align="left"><span 
    lang="EN-US">&nbsp;&nbsp; 6) </span>회사는 고객님의 권리에 따른 열람의 요구<span lang="EN-US">, </span>정정<span lang="EN-US">, </span>삭제의 요구<span lang="EN-US">, </span>처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoNormal" style="text-align: left; margin: 0cm 
    0cm 8pt 30pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -30pt;" align="left"><span lang="EN-US">&nbsp;&nbsp; 7) </span><span style="letter-spacing: -0.2pt;">고객이 개인정보 오류정정을 요구하신 경우<span lang="EN-US">, </span>회사는 오류정정을 완료하기 전까지 당해 개인정보를 이용ㆍ제공하지 
    않으며<span lang="EN-US">, </span>이미 제<span lang="EN-US">3</span>자에게 당해 개인정보를 제공한</span> 경우에는 제<span lang="EN-US">3</span>자에게 지체없이 통지하여 오류 정정이 이루어지도록 하고 있습니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt 30pt; line-height: 14.2667px; font-size: 
    10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -30pt;" align="left"><span lang="EN-US">&nbsp;&nbsp; 8) </span><span style="letter-spacing: -0.1pt;">고객 또는 법정 대리인이 동의철회<span lang="EN-US">(</span>가입해지<span lang="EN-US">)</span>한 경우<span lang="EN-US">, </span>회사는 지체없이 파기하는 것을 원칙으로 하나 관계법령에서 
    의무적으로 보유하도록 한 경우에는 개인정보</span> 처리방침 <span lang="EN-US">‘2.</span>개인정보의 처리 목적<span lang="EN-US">, </span>항목 및 보유기간<span lang="EN-US">’</span>에 따라 처리하고<span lang="EN-US">, </span>반드시 필요한 경우에만 열람 또는 이용이 가능하도록 조치하고 있습니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoNormal" 
    style="margin: 0cm 0cm 8pt; text-align: left; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" align="left">&nbsp;</p><div style="text-align: left;" align="left"><span lang="EN-US" style="font-size: 10pt;">&nbsp; &nbsp;9) (</span><span style="font-size: 10pt;">개인위치정보</span><span lang="EN-US" 
    style="font-size: 10pt;">) 8</span><span style="font-size: 10pt;">세 이하의 아동 등의 보호를 위한 개인위치정보 수집 시 보호의무자의 권리ㆍ의무와 그 행사방법</span></div><span lang="EN-US"><div style="text-align: left;" align="left"><span lang="EN-US" style="font-size: 10pt;">&nbsp; &nbsp; &nbsp;&nbsp;</span><span style="font-size: 10pt;">① </span><span 
    style="font-size: 10pt; letter-spacing: -0.1pt;">다음 중 어느 하나에 해당하는 자<span lang="EN-US">(</span>이하 <span lang="EN-US">“8</span>세 이하의 아동 등<span lang="EN-US">”)</span>의 보호의무자가 <span lang="EN-US">8</span>세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 <span lang="EN-US">8</span>세 이하의 아동 등의</span></div></span><span 
    lang="EN-US"><div style="text-align: left;"><span lang="EN-US" style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-size: 10pt;">개인위치정보의 수집이용제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></div></span><span lang="EN-US"><div style="text-align: 
    left;"><span lang="EN-US" style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;- 8</span><span style="font-size: 10pt;">세 이하의 아동</span></div></span><span lang="EN-US"><div style="text-align: left;"><span lang="EN-US" style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;- </span><span style="font-size: 
    10pt;">피성년후견인</span></div></span><span lang="EN-US"><div style="text-align: left;"><span lang="EN-US" style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;- </span><span style="font-size: 10pt;">「장애인복지법」 제</span><span lang="EN-US" style="font-size: 10pt;">2</span><span style="font-size: 10pt;">조 제</span><span lang="EN-US" 
    style="font-size: 10pt;">2</span><span style="font-size: 10pt;">항 제</span><span lang="EN-US" style="font-size: 10pt;">2</span><span style="font-size: 10pt;">호의 규정에 의한 정신적 장애를 가진 자로서 장애인 고용촉진 및 직업재활법 제</span><span lang="EN-US" style="font-size: 10pt;">2</span><span style="font-size: 10pt;">조 제</span><span lang="EN-US" 
    style="font-size: 10pt;">2</span><span style="font-size: 10pt;">호의 규정에 의한 중증 장애인에</span></div></span><span lang="EN-US"><div style="text-align: left;"><span lang="EN-US" style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-size: 10pt;">해당하는 자</span><span lang="EN-US" style="font-size: 
    10pt;">(</span><span style="font-size: 10pt;">「장애인복지법」 제</span><span lang="EN-US" style="font-size: 10pt;">32</span><span style="font-size: 10pt;">조에 따라 장애인 등록을 한 자에 한정</span><span lang="EN-US" style="font-size: 10pt;">)</span></div></span><p>&nbsp;</p><p class="MsoNormal" style="margin: 0cm 0cm 8pt; text-align: left; line-height: 
    14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif;" align="left">&nbsp;</p><div style="text-align: left;" align="left"><span lang="EN-US" style="font-size: 10pt;">&nbsp; &nbsp; &nbsp;&nbsp;</span><span style="font-size: 10pt;">② </span><span lang="EN-US" style="font-size: 10pt;">8</span><span style="font-size: 10pt;">세 이하의 
    아동 등의 보호의무자는 </span><span lang="EN-US" style="font-size: 10pt;">8</span><span style="font-size: 10pt;">세 이하의 아동 등을 사실상 보호하는 자로서 다음 중 어느 하나에 해당하는 자를 의미합니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></div><span lang="EN-US"><div style="text-align: left;" align="left"><span lang="EN-US" 
    style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;- 8</span><span style="font-size: 10pt;">세 이하의 아동의 법정대리인 또는 「보호시설에 있는 미성년자의 후견 직무에 관한 법률」 제</span><span lang="EN-US" style="font-size: 10pt;">3</span><span style="font-size: 10pt;">조에 따른 후견인</span></div></span><span lang="EN-US"><div style="text-align: 
    left;"><span lang="EN-US" style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;- </span><span style="font-size: 10pt;">피성년후견인의 법정대리인</span></div></span><span lang="EN-US"><div style="text-align: left;"><span lang="EN-US" style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;- </span><span style="font-size: 10pt; letter-spacing: 
    -0.5pt;">제<span lang="EN-US">1</span>항제<span lang="EN-US">3</span>호의 자의 법정대리인 또는 「장애인복지법」 제<span lang="EN-US">58</span>조 제<span lang="EN-US">1</span>항 제<span lang="EN-US">1</span>호에 따른 장애인 거주시설<span lang="EN-US">(</span>국가 또는 지방자치단체가 설치ㆍ운영하는 시설로 한정한다<span lang="EN-US">)</span>의 장<span 
    lang="EN-US">,</span></span></div></span><span lang="EN-US"><div style="text-align: left;"><span lang="EN-US" style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-size: 10pt;">「정신건강증진 및 정신질환자 복지서비스 지원에 관한 법률」 제</span><span lang="EN-US" style="font-size: 10pt;">22</span><span 
    style="font-size: 10pt;">조에 따른 정신요양시설의 장 및 같은 법 제</span><span lang="EN-US" style="font-size: 10pt;">26</span><span style="font-size: 10pt;">조에 따른 정신재활시설</span><span lang="EN-US" style="font-size: 10pt;">(</span><span style="font-size: 10pt;">국가 또는 지방</span></div></span><span lang="EN-US"><div style="text-align: 
    left;"><span lang="EN-US" style="font-size: 10pt;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-size: 10pt;">자치단체가 설치ㆍ운영하는 시설로 한정한다</span><span lang="EN-US" style="font-size: 10pt;">)</span><span style="font-size: 10pt;">의 장</span></div></span><p>&nbsp;</p><p class="MsoNormal" style="margin: 0cm 0cm 8pt 43.4pt; 
    text-align: left; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -14pt;" align="left">&nbsp;</p><div style="text-align: left;" align="left"><span style="font-size: 10pt; text-indent: -14pt;">③ </span><span lang="EN-US" style="font-size: 10pt; text-indent: -14pt; letter-spacing: 
    -0.2pt;">8</span><span style="font-size: 10pt; text-indent: -14pt; letter-spacing: -0.2pt;">세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 수집ㆍ이용ㆍ제공에 동의를 하려는 경우 <span lang="EN-US">8</span>세 이하의 아동 등의 보호의무자임을 증명하기</span></div><div style="text-align: left;" align="left"><span style="font-size: 10pt; 
    text-indent: -14pt;">위하여 서면동의서에 다음 사항을 기재하고 그 보호의무자가 기명날인 또는 서명한 후 회사에 제출하여야 합니다</span><span lang="EN-US" style="font-size: 10pt; text-indent: -14pt;">.</span></div><span lang="EN-US"><div style="text-align: left;" align="left"><span lang="EN-US" style="font-size: 10pt; text-indent: -14pt;">- 8</span><span 
    style="font-size: 10pt; text-indent: -14pt;">세 이하의 아동 등의 성명</span><span lang="EN-US" style="font-size: 10pt; text-indent: -14pt;">, </span><span style="font-size: 10pt; text-indent: -14pt;">주소 및 생년월일</span></div></span><span lang="EN-US"><div style="text-align: left;"><span lang="EN-US" style="font-size: 10pt; text-indent: -14pt;">- 
    </span><span style="font-size: 10pt; text-indent: -14pt;">보호의무자의 성명</span><span lang="EN-US" style="font-size: 10pt; text-indent: -14pt;">, </span><span style="font-size: 10pt; text-indent: -14pt;">주소 및 연락처</span></div></span><span lang="EN-US"><div style="text-align: left;"><span lang="EN-US" style="font-size: 10pt; text-indent: -14pt;">- 
    </span><span style="font-size: 10pt; text-indent: -14pt;">개인위치정보 수집</span><span lang="EN-US" style="font-size: 10pt; text-indent: -14pt;">, </span><span style="font-size: 10pt; text-indent: -14pt;">이용 또는 제공의 목적이 </span><span lang="EN-US" style="font-size: 10pt; text-indent: -14pt;">8</span><span style="font-size: 10pt; text-indent: 
    -14pt;">세 이하의 아동 등의 생명 또는 신체의 보호에 한정된다는 사실</span></div></span><span lang="EN-US"><div style="text-align: left;"><span lang="EN-US" style="font-size: 10pt; text-indent: -14pt;">- </span><span style="font-size: 10pt; text-indent: -14pt;">동의의 연월일</span></div></span><p>&nbsp;</p><p class="MsoNormal" style="margin: 0cm 0cm 8pt 
    42.7pt; text-align: left; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -13.3pt;" align="left">&nbsp;</p><div style="text-align: left;" align="left"><span style="font-size: 10pt; text-indent: -13.3pt;">④ </span><span style="font-size: 10pt; text-indent: -13.3pt; letter-spacing: 
    -0.1pt;">보호의무자가 <span lang="EN-US">8</span>세 이하의 아동 등의 개인위치정보의 수집ㆍ이용ㆍ제공에 동의한 경우 <span lang="EN-US">“</span>고객<span lang="EN-US">(</span>개인위치정보주체<span lang="EN-US">)”</span>은 보호의무자로 보며<span lang="EN-US">, </span>회사에 대하여 언제든지</span></div><div style="text-align: left;" align="left"><span 
    style="font-size: 10pt; text-indent: -13.3pt;">「위치정보의 보호 및 이용 등에 관한 법률」 제</span><span lang="EN-US" style="font-size: 10pt; text-indent: -13.3pt;">24</span><span style="font-size: 10pt; text-indent: -13.3pt;">조에 따른 권리를 행사할 수 있습니다</span><span lang="EN-US" style="font-size: 10pt; text-indent: -13.3pt;">. </span><span 
    style="font-size: 10pt; text-indent: -13.3pt;">또한</span><span lang="EN-US" style="font-size: 10pt; text-indent: -13.3pt;">, </span><span style="font-size: 10pt; text-indent: -13.3pt;">고객으로서 권리와 의무 및 그 행사방법은 본 조항에 따릅니다</span><span lang="EN-US" style="font-size: 10pt; text-indent: -13.3pt;">.</span></div><p>&nbsp;</p><p 
    class="MsoNormal" style="text-align: left; margin: 0cm 10pt 8pt 42.7pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -13.3pt;" align="left"><span lang="EN-US">&nbsp;</span></p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt 42.7pt; line-height: 14.2667px; font-size: 10pt; 
    font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -13.3pt;" align="left"><span lang="EN-US">&nbsp;</span></p><p class="MsoNormal" style="text-align: left; margin: 0cm 0cm 8pt 14pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -14pt;" align="left"><b><span lang="EN-US" style="font-size: 
    14pt; line-height: 19.9733px;">9. </span></b><b><span style="font-size: 14pt; line-height: 19.9733px;">고객의 권익침해에 대한 구제방법<span lang="EN-US"><o:p></o:p></span></span></b></p><p class="MsoNormal" style="margin: 0cm 0cm 8pt 10pt; text-align: left; line-height: 14.2667px;" align="left">&nbsp;</p><div style="font-family: &quot;맑은 고딕&quot;, 
    sans-serif; font-size: 10pt; text-align: left;" align="left"><span style="font-size: 10pt;">고객님께서는 아래의 기관에 대해 개인정보 침해에 대한 피해구제</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">상담 등을 문의하실 수 있습니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></div><span 
    lang="EN-US" style=""><div style="text-align: left;" align="left"><font face="맑은 고딕, sans-serif"><span style="font-size: 13.3333px;"><br></span></font></div></span><font face="맑은 고딕, sans-serif"><div style="text-align: left;"><font face="맑은 고딕, sans-serif"><span style="font-size: 10pt;">아래의 기관은 회사와는 별개의 기관으로서 회사의 자체적인 
    개인정보 불만처리</span></font><span lang="EN-US" style="font-size: 10pt;">, </span><font face="맑은 고딕, sans-serif"><span style="font-size: 10pt;">피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시다면 당사 개인정보보호 책임자 또는 담당자에게</span></font><span lang="EN-US" style="font-size: 10pt;">(’10. </span><font face="맑은 고딕, 
    sans-serif"><span style="font-size: 10pt;">개인정보 보호책임자 및 담당자</span></font><span lang="EN-US" style="font-size: 10pt;">, </span><font face="맑은 고딕, sans-serif"><span style="font-size: 10pt;">업무처리 부서</span></font><span lang="EN-US" style="font-size: 10pt;">’) </span><font face="맑은 고딕, sans-serif"><span style="font-size: 
    10pt;">문의하여 주시기 바랍니다</span></font><span lang="EN-US" style="font-size: 10pt;">.</span></div></font><p>&nbsp;</p><div align="left" style="font-size: medium; text-align: left;"><table class="MsoTableGrid __se_tbl_ext" border="1" cellspacing="0" cellpadding="0" style="border-collapse: collapse; border: none;"><tbody><tr style="height: 
    26.266666666666666px;"><td style="width: 231.13333333333333px; border: 1pt solid windowtext; padding: 0cm 5.4pt; height: 26.266666666666666px;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><b>기관명<span 
    lang="EN-US"><o:p></o:p></span></b></p></td><td style="width: 354.4666666666667px; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0cm 5.4pt; height: 26.266666666666666px;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: 
    center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><b>소관업무<span lang="EN-US"><o:p></o:p></span></b></p></td><td style="width: 6cmpx; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0cm 5.4pt; height: 
    26.266666666666666px;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><b>홈페이지<span lang="EN-US"><o:p></o:p></span></b></p></td><td style="width: 192.33333333333334px; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; 
    border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0cm 5.4pt; height: 26.266666666666666px;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><b>전화<span lang="EN-US"><o:p></o:p></span></b></p></td></tr><tr><td 
    style="width: 231.13333333333333px; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', 
    sans-serif;">개인정보 침해신고센터<span lang="EN-US"><br>(</span>한국인터넷진흥원 운영<span lang="EN-US">)<o:p></o:p></span></p></td><td style="width: 354.4666666666667px; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt;"><p class="MsoNormal" align="center" style="margin: 
    0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;">개인정보 침해사실 신고<span lang="EN-US">, </span>상담 신청<span lang="EN-US"><o:p></o:p></span></p></td><td style="width: 6cmpx; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 
    5.4pt;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><span lang="EN-US">privacy.kisa.or.kr<o:p></o:p></span></p></td><td style="width: 192.33333333333334px; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt 
    solid windowtext; padding: 0cm 5.4pt;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><span lang="EN-US">(</span>국번없이<span lang="EN-US">) 118<o:p></o:p></span></p></td></tr><tr><td style="width: 231.13333333333333px; border-right: 1pt solid 
    windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;">개인정보 분쟁조정위원회<span 
    lang="EN-US"><o:p></o:p></span></p></td><td style="width: 354.4666666666667px; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', 
    sans-serif;">개인정보 분쟁조정신청<span lang="EN-US">, </span>집단분쟁조정<span lang="EN-US">(</span>민사적 해결<span lang="EN-US">)<o:p></o:p></span></p></td><td style="width: 6cmpx; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt;"><p class="MsoNormal" align="center" 
    style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><span lang="EN-US"><a href="http://www.kopico.go.kr">www.kopico.go.kr</a><o:p></o:p></span></p></td><td style="width: 192.33333333333334px; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid 
    windowtext; padding: 0cm 5.4pt;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><span lang="EN-US">(</span>국번없이<span lang="EN-US">) 1833-6972<o:p></o:p></span></p></td></tr><tr><td style="width: 231.13333333333333px; border-right: 1pt solid 
    windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;">대검찰청<span lang="EN-US"><o:p></o:p></span></p></td><td 
    style="width: 354.4666666666667px; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><span 
    lang="EN-US">-<o:p></o:p></span></p></td><td style="width: 6cmpx; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><span 
    lang="EN-US"><a href="http://www.spo.go.kr">www.spo.go.kr</a><o:p></o:p></span></p></td><td style="width: 192.33333333333334px; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; 
    font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><span lang="EN-US">(</span>국번없이<span lang="EN-US">) 1301<o:p></o:p></span></p></td></tr><tr><td style="width: 231.13333333333333px; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 
    5.4pt;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;">경찰청<span lang="EN-US"><o:p></o:p></span></p></td><td style="width: 354.4666666666667px; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid 
    windowtext; padding: 0cm 5.4pt;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><span lang="EN-US">-<o:p></o:p></span></p></td><td style="width: 6cmpx; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid 
    windowtext; padding: 0cm 5.4pt;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><span lang="EN-US">ecrm.cyber.go.kr<o:p></o:p></span></p></td><td style="width: 192.33333333333334px; border-top: none; border-left: none; border-bottom: 1pt solid 
    windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><span lang="EN-US">(</span>국버없이<span lang="EN-US">) 182<o:p></o:p></span></p></td></tr></tbody></table></div><p class="MsoNormal" 
    align="left" style="margin: 0cm 0cm 8pt 10pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -10pt; text-align: left;"><span lang="EN-US">&nbsp;</span></p><p class="MsoNormal" align="left" style="margin: 0cm 0cm 8pt 14pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, 
    sans-serif; text-indent: -14pt; text-align: left;"><b><span lang="EN-US" style="font-size: 14pt; line-height: 19.9733px;">10. </span></b><b><span style="font-size: 14pt; line-height: 19.9733px;">개인정보보호책임자 및 담당자<span lang="EN-US">, </span>업무처리 부서<span lang="EN-US"><br></span></span></b><span lang="EN-US">1) </span>회사는 개인정보 처리에 
    관한 업무를 총괄해서 책임지고<span lang="EN-US">, </span>개인정보 처리와 관련한 고객의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자<span lang="EN-US"><br>&nbsp;&nbsp; </span>및 담당자를 지정하고 있습니다<span lang="EN-US">.<o:p></o:p></span></p><div align="left" style="font-size: medium; text-align: left;"><table class="MsoTableGrid 
    __se_tbl_ext" border="1" cellspacing="0" cellpadding="0" style="border-collapse: collapse; border: none;"><tbody><tr style="height: 26.266666666666666px;"><td colspan="2" style="width: 982.4666666666667px; border: 1pt solid windowtext; padding: 0cm 5.4pt; height: 26.266666666666666px;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: 
    center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><b>개인정보보호 정책 및 총괄<span lang="EN-US"><o:p></o:p></span></b></p></td></tr><tr><td style="width: 502.3333333333333px; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: 
    none; padding: 0cm 5.4pt;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;">개인정보보호책임자<span lang="EN-US"><o:p></o:p></span></p></td><td style="width: 480.13333333333344px; border-top: none; border-left: none; border-bottom: 1pt solid 
    windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt;"><p class="MsoNormal" align="center" style="margin: 0cm; text-align: center; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;">개인정보보호담당자<span lang="EN-US"><o:p></o:p></span></p></td></tr><tr style="height: 105.60000000000001px;"><td style="width: 
    502.3333333333333px; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 105.60000000000001px;"><p class="MsoListParagraph" align="left" style="margin: 0cm 0cm 0cm 40pt; line-height: normal; font-size: 10pt; font-family: '맑은 
    고딕', sans-serif;"><span lang="EN-US">- </span>성명<span lang="EN-US">: &nbsp;</span>지세룡 법인장<span lang="EN-US"><o:p></o:p></span></p><p class="MsoListParagraph" align="left" style="margin: 0cm 0cm 0cm 40pt; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><span lang="EN-US">- </span>소속<span lang="EN-US">: 
    &nbsp;</span>㈜농가온<span lang="EN-US"><o:p></o:p></span></p></td><td style="width: 480.13333333333344px; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 105.60000000000001px;"><p class="MsoListParagraph" align="left" style="margin: 0cm 0cm 0cm 40pt; line-height: 
    normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><span lang="EN-US">- </span>성명<span lang="EN-US">: &nbsp;</span>권동원 차장<span lang="EN-US"><o:p></o:p></span></p><p class="MsoListParagraph" align="left" style="margin: 0cm 0cm 0cm 40pt; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><span lang="EN-US">- 
    </span>소속부서<span lang="EN-US">: &nbsp;</span>보안담당<span lang="EN-US"><o:p></o:p></span></p><p class="MsoListParagraph" align="left" style="margin: 0cm 0cm 0cm 40pt; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><span lang="EN-US">- </span>전화번호<span lang="EN-US">: 063-279-5794<o:p></o:p></span></p><p 
    class="MsoListParagraph" align="left" style="margin: 0cm 0cm 0cm 40pt; line-height: normal; font-size: 10pt; font-family: '맑은 고딕', sans-serif;"><span lang="EN-US">- </span>이메일<span lang="EN-US">: &nbsp;seri_1004@lsmtron.com<o:p></o:p></span></p></td></tr></tbody></table></div><p class="MsoNormal" align="left" style="margin: 0cm 0cm 8pt 9pt; 
    line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -9pt; text-align: left;"><b><span lang="EN-US" style="font-size: 3pt; line-height: 4.28px;">&nbsp;</span></b></p><p class="MsoNormal" align="left" style="margin: 0cm 0cm 8pt 26.6pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 
    고딕&quot;, sans-serif; text-indent: -12.4pt; text-align: left;"><span lang="EN-US">2) </span><span style="letter-spacing: -0.2pt;">고객님께서는 회사의 서비스<span lang="EN-US">(</span>또는 사업<span lang="EN-US">)</span>을 이용하시면서 발생한 모든 개인정보보호 관련 문의<span lang="EN-US">, </span>불만처리<span lang="EN-US">, </span>피해구제<span 
    lang="EN-US">, </span>개인정보 열람청구 등에 관한 사항을 요청하실 수</span><span lang="EN-US"><br></span>있으며<span lang="EN-US">, </span>이를 위한 담당부서는 위 <span lang="EN-US">‘</span>서비스별 개인정보보호 책임자 및 담당자<span lang="EN-US">’</span>에서 확인하실 수 있습니다<span lang="EN-US">. </span>회사는 고객님의 문의에 대해 지체없이 답변 및 
    처리해드릴 것입니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoNormal" align="left" style="margin: 0cm 0cm 8pt 37.1pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -12.9pt; text-align: left;">※ <span style="letter-spacing: -0.2pt;">다만<span lang="EN-US">, </span>개인정보 보호 관련 
    문의<span lang="EN-US">, </span>불만 및 피해관련 내용 이외의 내용으로 발송하시는 이메일은 답변 및 처리가 어려우며<span lang="EN-US">, </span>담당자의 동의 없이 발송하는 영리목적의</span><span lang="EN-US"><br></span>광고성 이메일에 대해서는 「정보통신망 이용촉진 및 정보호호 등에 관한 법률」 제<span lang="EN-US">50</span>조부터 제<span 
    lang="EN-US">50</span>조의<span lang="EN-US">8</span>의 규정에 따라 관계기관에 신고 등의 조치가 이루어질 수 있습니다<span lang="EN-US">.<o:p></o:p></span></p><p class="MsoNormal" align="left" style="margin: 0cm 0cm 8pt 37.1pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -12.9pt; text-align: 
    left;"><span lang="EN-US">&nbsp;</span></p><p class="MsoNormal" align="left" style="margin: 0cm 0cm 8pt 28pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -28pt; text-align: left;"><b><span lang="EN-US" style="font-size: 14pt; line-height: 19.9733px;">11. </span></b><b><span style="font-size: 14pt; 
    line-height: 19.9733px;">개인정보 처리방침의 적용 범위<span lang="EN-US"><o:p></o:p></span></span></b></p><p class="MsoNormal" align="left" style="margin: 0cm 0cm 8pt 20pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-align: left;"><span style="letter-spacing: -0.2pt;">본 개인정보 처리방침은 회사의 
    홍보<span lang="EN-US">, </span>각 사업부의 판매<span lang="EN-US">, </span>정비서비스 및 온라인 제반 서비스<span lang="EN-US">(</span>모바일 웹<span lang="EN-US">/</span>앱 포함<span lang="EN-US">)</span>에 적용되며<span lang="EN-US">, </span>일부 개별 서비스의 경우 별도의 개인정보</span> 처리방침이 적용될 수 있습니다<span 
    lang="EN-US">.<o:p></o:p></span></p><p class="MsoNormal" align="left" style="margin: 0cm 0cm 8pt 20pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-align: left;"><span lang="EN-US">&nbsp;</span></p><p class="MsoNormal" align="left" style="margin: 0cm 0cm 8pt 28pt; line-height: 14.2667px; font-size: 10pt; 
    font-family: &quot;맑은 고딕&quot;, sans-serif; text-indent: -28pt; text-align: left;"><b><span lang="EN-US" style="font-size: 14pt; line-height: 19.9733px;">12. </span></b><b><span style="font-size: 14pt; line-height: 19.9733px;">개인정보 처리방침의 변경에 관한 사항<span lang="EN-US"><o:p></o:p></span></span></b></p><p class="MsoNormal" align="left" 
    style="margin: 0cm 0cm 8pt 20pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-align: left;"><span style="letter-spacing: -0.2pt;">회사는 본 개인정보 처리방침을 변경하는 경우 그 이유 및 변경내용을 홈페이지 공지사항에 사전 공지한 후 변경 및 적용하고 있습니다<span lang="EN-US">. </span>통합 개인정보 처리방침 
    변경 시<span lang="EN-US">, </span>이전 버전은 처리방침 상단 메뉴바에서 확인하실 수 있습니다<span lang="EN-US">.<o:p></o:p></span></span></p><p class="MsoNormal" align="left" style="margin: 0cm 0cm 8pt 20pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-align: left;"><span lang="EN-US" 
    style="letter-spacing: -0.2pt;">&nbsp;</span></p><p class="MsoNormal" align="left" style="margin: 0cm 0cm 8pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-align: left;"><b>공고일<span lang="EN-US">: 2023.12.01.(</span>금<span lang="EN-US">)<br></span></b></p>
    <p class="MsoNormal" align="left" style="margin: 0cm 0cm 8pt; line-height: 14.2667px; font-size: 10pt; font-family: &quot;맑은 고딕&quot;, sans-serif; text-align: left;"><b>시행일<span lang="EN-US">: 2023.12.01.(</span>금<span lang="EN-US">)<br></span></b></p>
    <br>
    </body>
    </html>` };
  };
  return (
    <>
      <AuthPagesHeaderBlock>
        <h2>농가온 개인정보 처리방침</h2>
      </AuthPagesHeaderBlock>
      <AuthPagesBodyBlock>
        <div dangerouslySetInnerHTML={renderHTML()} />
      </AuthPagesBodyBlock>
    </>
  );
};

export default PrivacyPolicy;
