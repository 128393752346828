import styled from '@emotion/styled';

export const LandNumberBlock = styled.div`
  position: relative;
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  border-radius: 4px;
  border: 1px solid var(--gray-300);

  font-size: 20px;
  font-weight: 400;
  line-height: normal;

  svg {
    position: absolute;
    right: 12px;
  }

  p {
    color: var(--gray-500, #666);
    width: 92%;
  }
`;

export const FoldButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

font-size: 14px;
font-weight: bold;
  color: var(--gray-400, #999999);
`


export const ArrowDownWrapper = styled.div<IsActive>`
  svg {
    transform: ${({ isActive }) => (isActive ? 'rotate(0)' : 'rotate(180deg)')};
  }
`;