export default class MySubWorkLandItemData{
    private _subWorkId!: string;
    private _landAddress!: string;

    public get subWorkId(): string {
        return this._subWorkId;
    }
    public set subWorkId(value: string) {
        this._subWorkId = value;
    }    
    public get landAddress(): string {
        return this._landAddress;
    }
    public set landAddress(value: string) {
        this._landAddress = value;
    }
}