import { AuthPagesBodyBlock, AuthPagesHeaderBlock } from './AuthPages.styles';
import withDraw01 from 'assets/images/googleAuth/withDraw01.png';
import withDraw02 from 'assets/images/googleAuth/withDraw02.png';
import withDraw03 from 'assets/images/googleAuth/withDraw03.png';
import withDraw04 from 'assets/images/googleAuth/withDraw04.png';

const WithDrawGuide = () => {

  
  return (
    <>
      <AuthPagesHeaderBlock>
        <h2>회원 탈퇴 가이드라인</h2>
      </AuthPagesHeaderBlock>
      <AuthPagesBodyBlock>
        <div style={{display: 'block', margin: '0 auto'}}>
          <img src={withDraw01} alt="회원 탈퇴 가이드라인01" width="800px"/>
          <br/>
          <img src={withDraw02} alt="회원 탈퇴 가이드라인02" width="800px"/>
          <br/>
          <img src={withDraw03} alt="회원 탈퇴 가이드라인03" width="800px"/>
          <br/>
          <img src={withDraw04} alt="회원 탈퇴 가이드라인04" width="800px"/>
        </div>
      </AuthPagesBodyBlock>
    </>
  );
};

export default WithDrawGuide;
