import dayjs from "dayjs";
import WorkerItemData from "../WorkDetail/WorkerList/WorkerItemData";
import WorkLandItemData from "../WorkRegistration/WorkLandList/WorkLandItemData";

export default class WorkTeamPlanData {
    public workStartDate!: string;
    public workEndDate!: string;
    public masterWorkId!: string;
    public landList: WorkLandItemData[] =[];
    public workerList: WorkerItemData[] = [];
    public unavailableLandList: string[] = [];
    public unavailableDateList: Array<dayjs.Dayjs> = [];
    public originTeamList : string[] = [];

    public get workDeletable(): boolean{
        const today = new Date();
        if (this.workStartDate === undefined) {
            return false;
        }
        const startDate = new Date(this.workStartDate);
        if (startDate <= today) {
            return false;
        }
        return true;
    }


}