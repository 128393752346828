export default class WorkRecordDetailGroupDateItemData {
  private _landAddress!: string;
  private _landSize!: number;
  private _landTradeDone!: string;
  private _landOutput!: number;
  private _amHumidity!: string;
  private _pmHumidity!: string;
  private _lowTemp!: string;
  private _highTemp!: string;
  private _amWeather!: string;
  private _pmWeather!: string;
  private _workDate!: string;
  private _workerName!: string;
  private _equipmentName!: string;
  private _equipmentType!: string;

  public get landAddress(): string {
    return this._landAddress;
  }
  public set landAddress(value: string) {
    this._landAddress = value;
  }
  public get landSize(): number {
    return this._landSize;
  }
  public set landSize(value: number) {
    this._landSize = value;
  }
  public get landTradeDone(): string {
    return this._landTradeDone;
  }
  public set landTradeDone(value: string) {
    this._landTradeDone = value;
  }
  public get landOutput(): number {
    return this._landOutput;
  }
  public set landOutput(value: number) {
    this._landOutput = value;
  }

  public get amHumidity(): string {
    return this._amHumidity;
  }
  public set amHumidity(value: string) {
      this._amHumidity = value;
  }

  public get pmHumidity(): string {
      return this._pmHumidity;
  }
  public set pmHumidity(value: string) {
      this._pmHumidity = value;
  }

  public get lowTemp(): string {
      return this._lowTemp;
  }
  public set lowTemp(value: string) {
      this._lowTemp = value;
  }

  public get highTemp(): string {
      return this._highTemp;
  }
  public set highTemp(value: string) {
      this._highTemp = value;
  }

  public get amWeather(): string {
      return this._amWeather;
  }
  public set amWeather(value: string) {
      this._amWeather = value;
  }

  public get pmWeather(): string {
      return this._pmWeather;
  }
  public set pmWeather(value: string) {
      this._pmWeather = value;
  }

  public get workDate(): string {
      return this._workDate;
  }
  public set workDate(value: string) {
      this._workDate = value;
  }

  public get workerName(): string {
      return this._workerName;
  }
  public set workerName(value: string) {
      this._workerName = value;
  }

  public get equipmentName(): string {
      return this._equipmentName;
  }
  public set equipmentName(value: string) {
      this._equipmentName = value;
  }

  public get equipmentType(): string {
      return this._equipmentType;
  }
  public set equipmentType(value: string) {
      this._equipmentType = value;
  } 
}
