const SocketCommands = {
    JOIN_ROOM: "JOIN_ROOM",
    LEAVE_ROOM: "LEAVE_ROOM",
    ON_WORKER_ALREADY_WORKING_RECEIVED: "ON_WORKER_ALREADY_WORKING_RECEIVED",

    // 소켓 1. 점의위치 생성 시
    WORKER_LOCATION_UPDATE: "WORKER_LOCATION_UPDATE",
    ON_WORKER_LOCATION_UPDATE_RECEIVED: "ON_WORKER_LOCATION_UPDATE_RECEIVED",

    // 소켓 2. 필지 장비 작업 완료 시
    LAND_EQUIP_WORK_DONE: "LAND_EQUIP_WORK_DONE",
    ON_LAND_EQUIP_WORK_DONE_RECEIVED: "ON_LAND_EQUIP_WORK_DONE_RECEIVED",

    // 소켓 3. 필지 거래 완료
    LAND_TRADE_DONE: "LAND_TRADE_DONE",
    ON_LAND_TRADE_DONE_RECEIVED: "ON_LAND_TRADE_DONE_RECEIVED",

    // 소켓 4. 메모 수정
    MEMO_UPDATE: "MEMO_UPDATED",
    ON_MEMO_UPDATE_RECEIVED: "ON_MEMO_UPDATED_RECEIVED",

    // 소켓 5. 메모 등록
    MEMO_REGISTED: "MEMO_REGISTED",
    ON_MEMO_REGISTED_RECEIVED: "ON_MEMO_REGISTED_RECEIVED",

    MEMO_DELETED: "MEMO_DELETED",
    ON_MEMO_DELETED_RECEIVED: "ON_MEMO_DELETED_RECEIVED",

    // 소켓 6. 작업 기록
    WORK_RECORD_DONE: "WORK_RECORD_DONE",
    ON_WORK_RECORD_DONE_RECEIVED: "ON_WORK_RECORD_DONE_RECEIVED",

    // 소켓 7. 필지 추가
    NEW_LAND_ADD: "NEW_LAND_ADD",
    ON_NEW_LAND_ADD_RECEIVED: "ON_NEW_LAND_ADD_RECEIVED",

    // 소켓 8. 나 외의 다른 작업자 위치 마커 업데이트
    WORKER_MARKER_UPDATE: "WORKER_MARKER_UPDATE", 
    ON_WORKER_MARKER_UPDATE_RECEIVED: "ON_WORKER_MARKER_UPDATE_RECEIVED",

    // 소켓 9. 작업자 앱 종료
    WORKER_LEAVE_ROOM: "WORKER_LEAVE",
    ON_WORKER_LEAVE_ROOM_RECEIVED: "ON_WORKER_LEAVE_RECEIVED",

    ON_NEW_USER_JOINED: "ON_NEW_USER_JOINED",

    WORK_REFRESH: "WORK_REFRESH",
    ON_WORK_REFRESH_RECEIVED: "ON_WORK_REFRESH_RECEIVED",

    EQUIPMENT_DISTANCE_UPDATE: "EQUIPMENT_DISTANCE_UPDATE",
    ON_EQUIPMENT_DISTANCE_UPDATE_RECEIVED: "ON_EQUIPMENT_DISTANCE_UPDATE_RECEIVED",

    BRG_APP_TERMINATION: "BRG_APP_TERMINATION"
}

export default SocketCommands;