import {
  EquipmentTypeCode,
  stringToEquipmentTypeCode,
} from "code/EquipmentTypeCode";
import MarkerData from "ui/components/MapComponent/MarkerData";
import PolyLineData from "ui/components/MapComponent/PolyLineData";
import PolygonData from "ui/components/MapComponent/PolygonData";
import ProductItemData from "ui/components/ProductItem/ProductItemData";
import FarmItemData from "ui/pages/ForageManagement/ForageManagementItem/FarmItemData";
import WorkRecordGroupAddressItemData from "ui/pages/WorkRecordGroupAddressList/WorkRecordGroupAddressListItemData";
import WorkRecordLogData from "ui/pages/WorkDetail/WorkRecordLogData";
import WorkItemData from "ui/pages/WorkList/WorkItemData";
import WorkLandItemData from "ui/pages/WorkRegistration/WorkLandList/WorkLandItemData";
import workIcon from "assets/images/markerIcon/workIcon.svg";
import productIcon from "assets/images/markerIcon/productIcon.svg";
import farmIcon from "assets/images/markerIcon/farmIcon.svg";
import memoIcon from "assets/images/icon_marker_alert.svg";
import WorkMemoItemData from "ui/pages/WorkDetail/WorkMemoItemData";

export function getColorWithTeamCode(workTeam: string) {
  let resultColor = "#81DAF5";

  switch (workTeam) {
    case ("A"): {
      // resultColor = "#9e00ff"; // A팀 보라색
      resultColor = "#FFA500"; // A팀 주황색
      break;
    }
    case ("B"): {
      // resultColor = "#ff00d6"; // B팀 분홍색
      resultColor = "#008080"; // B팀 청록색
      break;
    }
    case ("C"): {
      // resultColor = "#ff8a00"; // C팀 주황색
      resultColor = "#808000"; // C팀 올리브색
      break;
    }
    case ("D"): {
      // resultColor = "#ffd600"; // D팀 노랑색
      resultColor = "#FFC0CB"; // D팀 연한핑크
      break;
    }
    case ("E"): {
      // resultColor = "#007505"; // E팀 쑥색
      resultColor = "#D2B48C"; // E팀 탄색
      break;
    }
    case ("N"): {
      // resultColor = "#81DAF5"; // N팀 팥색
      resultColor = "#39DE2A"; // N팀 초록색
      break;
    }
  }
  return resultColor;
}

export function getLineColorWithEquipmentTypeCode(equipmentTypeCode: EquipmentTypeCode) {
  switch (equipmentTypeCode) {
    case EquipmentTypeCode.RAYKEY: {
      return "#ffffcc"; // 레이키 연노랑색
    }
    case EquipmentTypeCode.BAILER: {
      return "#00cc00"; // 베일러 초록색
    }
    case EquipmentTypeCode.LAPPING: {
      return "#ff0000"; // 래핑 빨강색
    }
    case EquipmentTypeCode.MORE: {
      return "#e1fff0"; // 모어 연민트색
    }
    case EquipmentTypeCode.TEDDER: {
      return "#ffd966"; // 테더 노랑색
    }
    case EquipmentTypeCode.ETC: {
      return "#333333"; // 기타 진회색
    }
    case EquipmentTypeCode.COMBINE: {
      return "#ff0000"; // 복합기 래핑기랑 동일
    }
    case EquipmentTypeCode.HARVESTER: {
      return "#0000cc"; // 옥수수수확기 파랑색
    }
    case EquipmentTypeCode.ETC2: {
      return '#c55a11'; // 기타2 갈색
    }
  }
}

export function getPolygonColorWithWorkRecordLogList({
  landTradeDone,
  workRecordLogList,
  selected = false,
}: {
  landTradeDone: string;
  workRecordLogList: Array<WorkRecordLogData>;
  /** 토지주알리기; 선택된 폴리곤(필지)인지 여부 */
  selected?: boolean;
}) {
  if (selected) return "#660000"; // FIXME: 임의로 지정한 색상이므로 내부 협의 하에 교체 필요
  if (landTradeDone === "Y") {
    return "#C0C0C0";
  }

  if (workRecordLogList.length <= 0) {
    return "#81DAF5";
  }
  
  //유이 : workRecordLogList안의 colorOrder를 기준으로 색상을 정하도록 수정

  let resultColor = "#81DAF5";

  let firstItem: WorkRecordLogData = new WorkRecordLogData();

  const compareWorRecordLogData = (a: WorkRecordLogData, b: WorkRecordLogData) => {
    return a.colorOrder - b.colorOrder;
  }

  workRecordLogList.sort(compareWorRecordLogData);

  for (let i = 0; i < workRecordLogList.length; i++) {
    if (workRecordLogList[i].workStatus === "Y") {
      firstItem = workRecordLogList[i];
      break;
    }
  }

  if (!firstItem.landAddress) {
    return resultColor;
  }

  switch (firstItem.workStatus === "Y") {
    case firstItem.equipmentType === EquipmentTypeCode.RAYKEY.toString(): {
      resultColor = "#FFFFCC";
      break;
    }
    case firstItem.equipmentType === EquipmentTypeCode.BAILER.toString(): {
      resultColor = "#00cc00";
      break;
    }
    case firstItem.equipmentType === EquipmentTypeCode.LAPPING.toString(): {
      resultColor = "#FF0000";
      break;
    }
    case firstItem.equipmentType === EquipmentTypeCode.MORE.toString(): {
      resultColor = "#e1fff0";
      break;
    }
    case firstItem.equipmentType === EquipmentTypeCode.TEDDER.toString(): {
      resultColor = "#ffd966";
      break;
    }
    case firstItem.equipmentType === EquipmentTypeCode.ETC.toString(): {
      resultColor = "#333333";
      break;
    }
    case firstItem.equipmentType === EquipmentTypeCode.COMBINE.toString(): {
      resultColor = "#FF0000";
      break;
    }
    case firstItem.equipmentType === EquipmentTypeCode.HARVESTER.toString(): {
      resultColor = "#0000cc";
      break;
    }
    case firstItem.equipmentType === EquipmentTypeCode.ETC2.toString(): {
      resultColor = "#c55a11";
      break;
    }
  }
  return resultColor;
}

export function workRecordGroupAddressListToPolylineList(workRecordGroupAddressList: Array<WorkRecordGroupAddressItemData>): Array<PolyLineData> {
  let polylineList: Array<PolyLineData> = [];
  workRecordGroupAddressList?.forEach((workRecordGroupAddressItem) => {
    const innerPolylineList = workRecordLogListToPolylineList(workRecordGroupAddressItem.workRecordLogList) as Array<PolyLineData>;
    polylineList.concat(innerPolylineList);
  });
  return polylineList;
}
export function workRecordLogListToPolylineList(workRecordLogList: WorkRecordLogData[]): Array<PolyLineData> {
  const polylineList: Array<PolyLineData> = [];
  workRecordLogList?.forEach((workRecordLogItem: WorkRecordLogData) => {
    const polylineData = new PolyLineData();
    polylineData.polyLineId = workRecordLogItem.workerName;
    polylineData.pointList = workRecordLogItem.polyLine;
    polylineData.polyLineColor = getLineColorWithEquipmentTypeCode(stringToEquipmentTypeCode[workRecordLogItem.equipmentType]);
    polylineData.polyLineTeam = workRecordLogItem.workTeam;
    polylineList.push(polylineData);
  });
  return polylineList;
}
export function workRecordGroupAddressListToPolygonList(  workRecordGroupAddressList: Array<WorkRecordGroupAddressItemData>,  selectedIdxMap: Record<number, boolean> = {}): Array<PolygonData> {
  const polygonList: Array<PolygonData> = [];
  workRecordGroupAddressList?.forEach((workRecordGroupAddressItem, i) => {
    const polygonData = new PolygonData();
    polygonData.pointList = workRecordGroupAddressItem.landMap;
    polygonData.polygonAddress = workRecordGroupAddressItem.landAddress!;
    polygonData.polygonColor = getPolygonColorWithWorkRecordLogList({
      landTradeDone: workRecordGroupAddressItem.landTradeDone,
      workRecordLogList: workRecordGroupAddressItem.workRecordLogList,
      selected: !!selectedIdxMap[i],
    });
    polygonData.polygonTeam = workRecordGroupAddressItem.workTeam;
    polygonData.polygonLineColor = getColorWithTeamCode(workRecordGroupAddressItem.workTeam);
    polygonList.push(polygonData);
  });

  return polygonList;
}
export function landListToPolygonList(landList: Array<WorkLandItemData>): Array<PolygonData> {
  let polygonList: Array<PolygonData> = [];
  landList.forEach((landData) => {
    const polygonData = new PolygonData();
    polygonData.pointList = landData.landMap;
    polygonData.polygonAddress = landData.landFullAddress!;
    polygonData.polygonTeam = landData.workTeam;
    polygonData.polygonLineColor = getColorWithTeamCode(landData.workTeam);
    polygonList.push(polygonData);
  });
  return polygonList;
}

export function workListToWorkMarkerList(workList: Array<WorkItemData>): Array<MarkerData> {
  const markerList: Array<MarkerData> = [];
  workList?.forEach((workItemData) => {
    const markerData = new MarkerData();
    markerData.latitude = workItemData.workLatitude;
    markerData.longitude = workItemData.workLongitude;
    markerData.markerId = workItemData.workId;
    markerData.markerIcon = workIcon;
    markerData.clickable = true;
    markerList.push(markerData);
  });
  return markerList;
}

export function productListToMarkerList(productList: Array<Pick<ProductItemData, "productId" | "latitude" | "longitude">>): Array<MarkerData> {
  const markerList: Array<MarkerData> = [];
  productList?.forEach((productItemData) => {
    const markerData = new MarkerData();
    markerData.latitude = productItemData.latitude;
    markerData.longitude = productItemData.longitude;
    markerData.markerId = productItemData.productId;
    markerData.markerIcon = productIcon;
    markerData.clickable = true;
    markerList.push(markerData);
  });
  return markerList;
}

export function productListToBuyerMarkerList(productList: Array<Pick<ProductItemData, "productId" | "latitude" | "longitude">>): Array<MarkerData> {
  const markerList: Array<MarkerData> = [];
  productList?.forEach((productItemData) => {
    const markerData = new MarkerData();
    markerData.latitude = productItemData.latitude;
    markerData.longitude = productItemData.longitude;
    markerData.markerId = productItemData.productId;
    markerData.markerIcon = farmIcon;
    markerData.clickable = true;
    markerList.push(markerData);
  });
  return markerList;
}

export function farmListToMarkerList(farmList: Array<FarmItemData>): Array<MarkerData> {
  const markerList: Array<MarkerData> = [];
  farmList?.forEach((farmItemData) => {
    const markerData = new MarkerData();
    markerData.latitude = farmItemData.farmLatitude;
    markerData.longitude = farmItemData.farmLongitude;
    markerData.markerId = farmItemData.farmId;
    markerData.markerIcon = farmIcon;
    markerData.clickable = true;
    markerList.push(markerData);
  });
  return markerList;
}

export function memoListToMarkerList(memoList: Array<WorkMemoItemData>): Array<MarkerData> {
  const markerList: Array<MarkerData> = [];
  memoList?.forEach((memoItemData) => {
    const markerData = new MarkerData();
    markerData.latitude = memoItemData.commentLatitude;
    markerData.longitude = memoItemData.commentLongitude;
    markerData.markerId = memoItemData.landAddress;
    markerData.markerIcon = memoIcon;
    markerData.clickable = true;
    markerList.push(markerData);
  });
  return markerList;
}
