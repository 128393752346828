import styled from '@emotion/styled';
import { HorizontalBar, RowSpaceBetween } from './common.styles';

interface WorkPageBodyProps {
  scrollable: boolean;
}

export const WorkPageList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const WorkHorizontalBar = styled(HorizontalBar)`
  margin: 24px 0;
`;

export const WeatherContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const WeatherBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: var(--humidity, #52a0fc);
    font-weight: 700;
    text-align: center;
    font-size: 14px;
    line-height: normal;
  }

  svg {
    width: 32px;
    height: 32px;
  }
`;

export const WeatherDegreeBlock = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.42px;

  span.highest_temperature {
    color: var(--red, #f22d2d);
  }

  span.lowest_temperature {
    color: var(--blue, #0047ff);
  }
`;

export const WorkerList = styled.ul`
  display: flex;
  gap: 16px;

  overflow: auto;
  padding: 8px 0;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ViewAll = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center;
  cursor: pointer;
  gap: 0 !important;

  span {
    color: var(--gray-400, #999999) !important;
    font-size: 14px;
    line-height: normal;
    text-decoration-line: underline;
  }

  svg {
    path {
      fill: var(--gray-400, #999999);
    }
  }
`;

export const SubTitleWrapper = styled(RowSpaceBetween)`
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #666;

  h2 {
    margin: 0;
  }
`;

export const WorkAddButton = styled.button`
  color: var(--green-100, #00b140);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px; /* 116.667% */

  border-radius: 6px;
  border: 1px solid var(--green-100, #00b140);
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
`;

export const WorkManageButton = styled.button`
  color: var(--green-100, #00b140);
  font-size: 12px;
  font-weight: 500;
  line-height: 21px; /* 116.667% */

  border-radius: 6px;
  border: 1px solid var(--green-100, #00b140);
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;

  :hover {
    background: var(--green-100, #00b140);
    color: var(--white, #ffffff);
  }
`;

export const WorkList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 100%;
`;

export const WorkerListItem = styled(RowSpaceBetween)`
  gap: 10px;

  svg {
    display: block;
    flex-shrink: 0;
  }
`;

export const EquipmentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const EquipmentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  line-height: normal;
  text-align: left !important;
`;

export const EquipmentItem = styled(RowSpaceBetween)`
  display: flex;
  gap: 10px;
`;

export const EquipmentIcon = styled.div`
  overflow: hidden;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 8px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const EquipmentName = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
`;

export const EquipmentWorker = styled.div`
  width: 30%;

  input {
    width: 100%;
    color: var(--black, #000000);
    font-size: 14px;
    line-height: normal;
    border-radius: 4px;
    border: 1px solid var(--gray-300, #ccc);
    height: 40px;
    padding: 8px;
  }
  input:disabled {
    text-align: right;
    text-overflow: cover;
    margin-left: 25%;
    width: 70%;
    background: var(--white, #ffffff);
    border: none;
  }
`;

export const EquipmentInput = styled.div`
  width: 100%;
  flex: 1;

  input {
    width: 100%;
    color: var(--black, #000000);
    font-size: 14px;
    line-height: normal;
    border-radius: 4px;
    border: 1px solid var(--gray-300, #ccc);
    height: 42px;
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
  }
`;

export const DistanceSelectContainer = styled.div`
display: flex;
align-items: center;
width: 100px;

& > div:last-of-type {
  width: 100px;
}
`

export const NoSelectDMsg = styled.div`
  font-size: 12px;
  color: var(--red);
  margin-top: 10px;
  font-weight: 500;
`;

export const NoListMsg = styled.div`
  font-size: 16px;
  font-weight: semi-bold;
  color: var(--gray-400);
  margin-top: 20px;
  border-top: 1px solid var(--gray-200);
  border-bottom: 1px solid var(--gray-200);
  padding: 70px 0;
  text-align: center;
`;

export const WrapFlexBox = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FlexBox = styled.div`
  flex: 1;
`;

export const FlexBtnBox = styled.div`
  margin-top: 35px;
`;

export const EquipmentTopWrapper = styled(SubTitleWrapper)`
  gap: 10px;
  margin-bottom: 0;
`;

export const EquipmentTopInputBox = styled.div`
  flex: 1;
  display: flex;
  gap: 10px;

  .react-select-container {
    max-width: 150px;
  }

  .react-select-container .react-select__control {
    padding: 0 8px;
    width: 100%;
  }

  input {
    flex: 1;
    width: 100%;
    color: var(--black, #000000);
    font-size: 14px;
    line-height: normal;
    border-radius: 4px;
    border: 1px solid var(--gray-300, #ccc);
    height: 42px;
    padding: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const AllDistanceSelectContainer = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: center;
  margin-right: 35px;

  & > p {
    line-height: 42px;
    padding-right: 10px;
  }

  & > div {
    width: 100px;
  }
`

export const EquipmentAddButton = styled(WorkAddButton)`
  padding: 4px 10px;
  height: 42px;
`;

export const EquipmentButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const EquipmentButton = styled.button`
  border-radius: 8px;
  width: 100%;

  display: flex;
  height: 50px;
  padding: 13px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  font-size: 16px;
  font-weight: 500;
  line-height: normal;
`;

export const EquipmentSaveButton = styled(EquipmentButton)`
  color: var(--white, #ffffff);
  background: var(--green-100, #00b140);
`;

export const EquipmentCancelButton = styled(EquipmentButton)`
  color: var(--gray-400, #999);
  border: 1px solid var(--gray-300, #ccc);
`;

export const EquipmentRemoveButton = styled(EquipmentButton)`
  color: var(--white, #ffffff);
  background: var(--red-100, #e61919 );
`;


export const CheckBoxContianer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 50px; */
  height: 40px;
  cursor: pointer;
`

export const SelectButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 32px;
  border: 1px solid var(--green-100, #00b140);
  border-radius: 4px;
  color: #00b140;
  font-size: 14px;
  transition: 0.2s;

  :hover {
    background: var(--green-100, #00b140);
    color: var(--white, #ffffff);
  }
`