import WorkerItemData from "ui/pages/WorkDetail/WorkerList/WorkerItemData";
import BaseReqData from "../BaseReqData";
import ImageReqData from "../ImageReqData";
import LandRegistrationItemReqData from "./LandRegistrationItemReqData";
import WorkerRegistrationItemReqData from "../worker/WorkerRegistrationItemReqData";

export default class WorkRegistrationReqData extends BaseReqData {
    public workId?:string;
    public workAddress1!:string;
    public workAddress2!:string;
    public workAddress3!:string;
    public workStartDate!:string;
    public workEndDate!:string;
    public workLatitude!:number;
    public workLongitude!:number;
    public landList!:Array<LandRegistrationItemReqData>;
    public cropKind!:string;
    public workType!:string
    public workerList!:Array<WorkerRegistrationItemReqData>;
    public masterWorkId?:string;
}