import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class WorkDeleteRequest extends BaseRequest {

  private readonly URL_WORK_BASE = `${BaseService.API_URL}/work`;
  private readonly URL_WORK_DELETE = `${this.URL_WORK_BASE}/workDelete`;

  getURL(): string {
    return this.URL_WORK_DELETE;
  }
}
