import { css } from '@emotion/react';

const modalStyles = css`
  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Overlay {
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 9999;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ReactModal__Overlay {
    & > div {
      outline: none;
    }
  }
`;

export const modalContainerStyles = css`
  /* position: absolute; */
  /* top: 40%; */

  background: var(--white, #ffffff);
  border-radius: 8px;

  @media all and (max-width: 1080px) {
    width: calc(100% - 32px);
    left: 16px;
  }

  @media all and (min-width: 1080px) {
    width: 1048px;
    left: calc(50% - 524px);
  }
  max-height: 90%;
  overflow: auto;
`;

export const modalBodyStyles = css`
  padding: 24px 16px;
`;

export default modalStyles;
