export enum WorkAvaliableStatusCode{
    //불가, 위험, 조심
    IMPOSSIBLE = "IMPOSSIBLE",
    DANGER = "DANGER",
    CAUTION = "CAUTION",
}

export const stringToWorkAvaliableStatusCode: { [key: string]: WorkAvaliableStatusCode } = {
    "IMPOSSIBLE": WorkAvaliableStatusCode.IMPOSSIBLE,
    "DANGER": WorkAvaliableStatusCode.DANGER,
    "CAUTION": WorkAvaliableStatusCode.CAUTION,
};