import { useCallback, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { actionSheetState } from '../atoms/actionSheet';
import { throttle } from 'lodash';
import { SwipeEventData } from 'react-swipeable';
import BridgeManager from 'bridge/BridgeManager';

const useActionSheet = () => {
  const ref = useRef<HTMLDivElement>(null);
  const bridgeManager = new BridgeManager();
  const [isTop, setIsTop] = useState(true);
  const [sheetState, setSheetState] = useRecoilState(actionSheetState);

  const onScroll = useMemo(
    () =>
      throttle(() => {
        setIsTop(ref.current?.scrollTop === 0);
      }, 300),
    []
  );

  const onSwipedUp = useMemo(() => {
    if (sheetState === 'maximum') return;

    return () => {
      setSheetState((state) => {
        if (state === 'minimum') {
          return 'middle';
        }

        return 'maximum';
      });
    };
  }, [setSheetState, sheetState]);

  const onSwipedDown = useMemo(() => {
    if (sheetState === 'maximum' && !isTop) return;

    return () => {
      setSheetState((state) => {
        if (state === 'maximum') {
          return 'middle';
        }

        return 'minimum';
      });
    };
  }, [sheetState, isTop, setSheetState]);

  const onSwipedDownHeader = useCallback(
    ({ event }: SwipeEventData) => {
      event.stopPropagation();
      setSheetState((state) => {
        if (state === 'maximum') {
          return 'middle';
        }

        return 'minimum';
      });
    },
    [setSheetState]
  );

  const changeSheetState = () => {
    if (sheetState === 'maximum') {
      setSheetState('minimum');
    } else if (sheetState === 'minimum') {
      setSheetState('middle');
    } else {
      setSheetState('maximum');
    }
  }

  const onClickButton = useCallback(
    bridgeManager.isMobile()
    ? () => setSheetState('middle')
    : changeSheetState,
    [sheetState]
  );

  return {
    ref,
    onScroll,
    onSwipedUp,
    onSwipedDown,
    onSwipedDownHeader,
    onClickButton,
  };
};

export default useActionSheet;
