import styled from '@emotion/styled';

export const WorkDetailContainer = styled.div`
`

export const WorkDetailButtonGroup = styled.div`
  display: flex;
  gap: 12px;
  width: fit-content;
  margin-left: auto;
  margin-bottom: 24px;
  margin-top: 8px;
`;

export const WorkDetailButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  border-radius: 4px;

  font-size: 14px;
  line-height: 21px; /* 150% */
  background-color: #fff;
  font-weight: 500;
`;

export const WorkDetailUpdateButton = styled(WorkDetailButton)`
  border: 1px solid var(--green-100, #00b140);
  background: var(--green-100, #00b140);
  color: var(--white, #ffffff);
`;

export const WorkDetailDeleteButton = styled(WorkDetailButton)`
  border: 1px solid var(--gray-300, #ccc);
  color: var(--gray-400, #666666);
`;

export const WorkDetailPostButton = styled(WorkDetailButton)`
  border: 1px solid var(--gray-300, #ccc);
  color: var(--gray-400, #666666);
`;

export const WorkEquipmentContainer = styled.div`
`;

export const WorkDetailInviteButton = styled(WorkDetailButton)`
  border: 1px solid var(--green-100, #00b140);
  background: var(--green-100, #00b140);
  color: var(--white, #ffffff);
`;

export const NotifyLandOwner = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-top: 24px;
  margin-left: auto;
  font-weight: 500;
  cursor: pointer;
`;

export interface ToastProps {
  isToastVisible: boolean;
}

export const CloseAppButton = styled(WorkDetailButton)`
  width: 100%;
  margin-top: 50px;
  /* border: 1px solid var(--green-100, #00b140); */
  /* background: var(--green-100, #00b140); */
  background: var(--red-100, #e61919);
  color: var(--white, #ffffff);
`

export const WorkRecordDownloadButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

export const ClipBoardToast = styled.div<ToastProps>`
  z-index: 50;
  @media all and (max-width: 1080px) {
    width: calc(100% - 32px);
    left: 16px;
  }
  @media all and (min-width: 1080px) {
    width: 1048px;
    left: calc(50% - 524px);
  }
  height: 50px;
  left: 16px;
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, ${({ isToastVisible }) => (isToastVisible ? '0.8' : '0')});
  transition: background-color 0.5s ease-in-out;
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;