import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useCallback } from 'react';

export const transactionState = {
  safe: '안심거래',
  DEALCOMPLETE: '거래완료',
  DEALABLE: '거래가능',
  paymentCompleted: '결제완료',
  DEALCANCEL: '거래취소',
  // 추가된 부분
  workScheduled: '진행 전',
  working: '진행 중',
  workCompleted: '진행 완료',
} as const;

export type TransactionState = keyof typeof transactionState;

interface TagProps {
  state: TransactionState;
}

const Tag = ({ state }: TagProps) => {
  const checkTransactionState = useCallback((state: TransactionState) => {
    return transactionState[state];
  }, []);

  return (
    <TagBlock state={state as TransactionState}>
      {checkTransactionState(state)}
    </TagBlock>
  );
};

const TagBlock = styled.div<TagProps>`
  border-radius: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 22px;
  padding: 4px 12px;

  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.54px;

  ${({ state }) =>
    (state === 'safe' || state === 'working') &&
    css`
      color: #ffb800;
      background: rgba(255, 184, 0, 0.14);
    `}
  ${({ state }) =>
    state === 'DEALCOMPLETE' &&
    css`
      color: #29b1dc;
      background: rgba(62, 148, 226, 0.14);
    `}
  ${({ state }) =>
    (state === 'DEALABLE' || state === 'workCompleted') &&
    css`
      color: #00b140;
      background: rgba(0, 177, 64, 0.14);
    `}
  ${({ state }) =>
    (state === 'paymentCompleted' || state === 'workScheduled') &&
    css`
      color: #f28825;
      background: rgba(242, 136, 37, 0.14);
    `}
  ${({ state }) =>
    state === 'DEALCANCEL' &&
    css`
      color: #999999;
      background: rgba(102, 102, 102, 0.14);
    `}
`;

export default Tag;
