import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class WorkUpdateRequest extends BaseRequest {
  // FIXME: URL 수정 필요
  private readonly URL_WORK_BASE = `${BaseService.API_URL}/work`;
  private readonly URL_WORK_UPDATE = `${this.URL_WORK_BASE}/workUpdate`;

  getURL(): string {
    return this.URL_WORK_UPDATE;
  }
}
