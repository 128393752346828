import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class WorkDetailRequest extends BaseRequest{
    private readonly URL_WORK_BASE = `${BaseService.API_URL}/work`;
    private readonly URL_WORK_DETAIL_MASTER = `${this.URL_WORK_BASE}/workDetail/master`;
    
    getURL(): string {
        return this.URL_WORK_DETAIL_MASTER;
    }
}