import { atom } from 'recoil';

const initialSelectedDate = {
  startDate: null, // 예시로 현재 날짜로 초기화
  endDate: null,   // 예시로 현재 날짜로 초기화
};

export const selectedDateState = atom<ISelectedDate>({
  key: 'selectedDateState',
  default: { 
    startDate: null, 
    endDate: null
  }
});

// 다른 값을 설정할 때 사용할 새 Atom을 생성
export const updatedSelectedDateState = atom<ISelectedDate>({
  key: 'updatedSelectedDateState',
  default: initialSelectedDate, // 초기값을 initialSelectedDate로 설정
});


// export const selectedDateState = atom<ISelectedDate>({
//   key: 'selectedDateState',
//   default: {
//     startDate: null,
//     endDate: null,
//   },
// });

