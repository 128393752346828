import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class PurchaseListRequest extends BaseRequest {
    private readonly URL_MYPAGE_BASE = `${BaseService.API_URL}/myPage`;
    private readonly URL_PURCHASE_LIST = `${this.URL_MYPAGE_BASE}/purchaseList`;

    getURL(): string {
        return this.URL_PURCHASE_LIST;
    }    
}
