import { appMode } from "code/Enumerator";
import UserData from "data/UserData";

const BridgeCommands = {
    BRG_DEVICE_INFO: "BRG_DEVICE_INFO",
    BRG_ON_DEVICE_INFO_RECEIVED : "BRG_ON_DEVICE_INFO_RECEIVED",

    BRG_GEOMETRY_INFO: "BRG_GEOMETRY_INFO",
    BRG_ON_GEOMETRY_INFO_RECEIVED : "BRG_ON_GEOMETRY_INFO_RECEIVED",

    BRG_ON_CHAT_MSG_RECEIVED : "BRG_ON_CHAT_MSG_RECEIVED",
    BRG_ON_CHAT_MSG_FLAG_RECEIVED: "BRG_ON_CHAT_MSG_FLAG_RECEIVED",

    BRG_CHECK_PERMISSIONS : "BRG_CHECK_PERMISSIONS",
    BRG_ON_CHECK_PERMISSIONS_RECEIVED: "BRG_ON_CHECK_PERMISSIONS_RECEIVED",

    BRG_CHECK_LOCATION_PERMISSIONS : "BRG_CHECK_LOCATION_PERMISSIONS",
    BRG_ON_CHECK_LOCATION_PERMISSIONS_RECEIVED: "BRG_ON_CHECK_LOCATION_PERMISSIONS_RECEIVED",

    BRG_SHARE_INVITE_CODE : "BRG_SHARE_INVITE_CODE",

    BRG_TOGGLE_WORKING_BUTTON : "BRG_TOGGLE_WORKING",

    BRG_ON_WORKING_LOCATION_INFO_RECEIVED : "BRG_ON_WORKING_LOCATION_INFO_RECEIVED",

    BRG_START_WORKING : "BRG_START_WORKING",
    BRG_END_WORKING : "BRG_END_WORKING",

    BRG_APP_TERMINATION : "BRG_APP_TERMINATION",

    BRG_FORECAST_INFO : "BRG_FORECAST_INFO",
    BRG_ON_FORECAST_INFO_RECEIVED : "BRG_ON_FORECAST_INFO_RECEIVED",

    //심사 완료 후 수정할 커맨드
    BRG_SHORT_FORECAST_INFO : "BRG_SHORT_FORECAST_INFO",
    // BRG_SHORT_FORECAST_INFO : "BRG_SHORT_FORECAST_INFO",
    BRG_ON_SHORT_FORECAST_INFO_RECEIVED : "BRG_ON_SHORT_FORECAST_INFO_RECEIVED",

    BRG_MID_FORECAST_INFO : "BRG_MID_FORECAST_INFO",
    BRG_ON_MID_FORECAST_INFO_RECEIVED : "BRG_ON_MID_FORECAST_INFO_RECEIVED",

    BRG_NO_INVITE_CODE : "BRG_NO_INVITE_CODE",

    BRG_TOGGLE_ALARM_BTN : "BRG_TOGGLE_ALARM_BTN",
    BRG_ON_TOGGLE_ALARM_BTN_RECEIVED : "BRG_ON_TOGGLE_ALARM_BTN_RECEIVED",

    BRG_GET_CURRENT_ALARM_STATUS : "BRG_GET_CURRENT_ALARM_STATUS",
    BRG_ON_GET_CURRENT_ALARM_STATUS_RECEVIED : "BRG_ON_GET_CURRENT_ALARM_STATUS_RECEVIED",

    BRG_ON_NOTIFICATION_CLICKED : "BRG_ON_NOTIFICATION_CLICKED",

    BRG_ON_TRADE_CONFIRM_RECEIVED: "BRG_ON_TRADE_CONFIRM_RECEIVED",

    BRG_AUTH_SUCCESS: "BRG_AUTH_SUCCESS",
    BRG_ON_AUTH_SUCCESS_RECEIVED: "BRG_ON_AUTH_SUCCESS_RECEIVED",
    
    BRG_ON_SOCKET_DISCONNECT_REQUEST_RECEIVED : "BRG_ON_SOCKET_DISCONNECT_REQUEST_RECEIVED",
    BRG_ON_SOCKET_RECONNECT_REQUEST_RECEIVED : "BRG_ON_SOCKET_RECONNECT_REQUEST_RECEIVED",

    BRG_OPEN_NICE_URL: "BRG_OPEN_NICE_URL",
    BRG_ON_OPEN_NICE_URL_RECEIVED: "BRG_ON_OPEN_NICE_URL_RECEIVED",

    BRG_CHECK_IMAGE_UPLOAD_PERMISSIONS : "BRG_CHECK_IMAGE_UPLOAD_PERMISSIONS",
    BRG_ON_CHECK_IMAGE_UPLOAD_PERMISSIONS_RECEIVED: "BRG_ON_CHECK_LOCATION_PERMISSIONS_RECEIVED",

    BRG_TEST_BUTTON_CLICKED : "BRG_TEST_BUTTON_CLICKED",
    BRG_ON_TEST_BUTTON_CLICKED_RECEIVED: "BRG_ON_TEST_BUTTON_CLICKED_RECEIVED",

    BRG_ON_SEND_NAVIGATION_URL_RECEIVED : "BRG_ON_SEND_NAVIGATION_URL_RECEIVED",

    BRG_SET_WEBVIEW_URL : "BRG_SET_WEBVIEW_URL",

    BRG_CURRENT_LOCATION_INFO : "BRG_CURRENT_LOCATION_INFO",
    BRG_ON_CURRENT_LOCATION_INFO_RECEIVED : "BRG_ON_CURRENT_LOCATION_INFO_RECEIVED",

    BRG_SOUND_ALERT_START: "BRG_SOUND_ALERT_START",
    BRG_SOUND_ALERT_STOP: "BRG_SOUND_ALERT_STOP",

    BRG_WORKER_ALREADY_WORKING: "BRG_WORKER_ALREADY_WORKING",

    BRG_OPEN_KAKAO_NAVI: "BRG_OPEN_KAKAO_NAVI"
}

export default BridgeCommands;