import { MapLevel } from 'code/Enumerator';
import PolygonData from '../MapComponent/PolygonData';
import MarkerData from '../MapComponent/MarkerData';
const { kakao } = window;
export default class BuyerMapComponentData {
  private _kakaoMap: any;
  private _myLocationKakaoMarker?: any;
  private _searchLandMarker?: any;  
  private _kakaoMarkerList: any[] = [];
  private _kakaoPolygonList: any[] = [];
  private _myLatLng: number[] = [37.566535, 126.9779692];
  private _mapCenter: number[] = [37.566535, 126.9779692];
  private _mapLevel: MapLevel = MapLevel.ZOOM_LEVEL_LIST;  
  private _markerList: MarkerData[] = [];
  private _polygonList: PolygonData[] = [];

  public get kakaoMap(): any {
    return this._kakaoMap;
  }
  public set kakaoMap(value: any) {
    this._kakaoMap = value;
    setTimeout(() => {
      this._kakaoMap.relayout();
    }, 1000);
    this._kakaoMap.setZoomable(true);
    const mapTypeControl = new kakao.maps.MapTypeControl();
    this._kakaoMap.addControl(
      mapTypeControl,
      kakao.maps.ControlPosition.TOPRIGHT
    );

    const myLocationMarker = new kakao.maps.Marker({
      position: new kakao.maps.LatLng(this._myLatLng[0], this._myLatLng[1]),
      clickable: false,
    });
    myLocationMarker.setMap(this._kakaoMap);
    this._myLocationKakaoMarker = myLocationMarker;
  }
  public get myLocationMarker(): any {
    return this._myLocationKakaoMarker;
  }
  public set myLocationMarker(value: any) {
    this._myLocationKakaoMarker = value;
  }
  public get searchLandMarker(): any {
    return this._searchLandMarker;
  }
  public set searchLandMarker(value: any) {
    this._searchLandMarker = value;    
  }
  public get kakaoMarkerList(): any[] {
    return this._kakaoMarkerList;
  }
  public set kakaoMarkerList(value: any[]) {
    this._kakaoMarkerList = value;
  }
  public get kakaoPolygonList(): any[] {
    return this._kakaoPolygonList;
  }
  public set kakaoPolygonList(value: any[]) {
    this._kakaoPolygonList = value;
  } 
  public get myLatLng(): number[] {
    return this._myLatLng;
  }
  public set myLatLng(value: number[]) {
    this._myLatLng = value;
    this._myLocationKakaoMarker?.setPosition(
      new kakao.maps.LatLng(this._myLatLng[0], this._myLatLng[1])
    );
  }
  public get mapCenter(): number[] {
    return this._mapCenter;
  }
  public set mapCenter(value: number[]) {
    this._mapCenter = value;
    this._kakaoMap?.setCenter(new kakao.maps.LatLng(this._mapCenter[0], this._mapCenter[1]));
  }
  public get mapLevel(): MapLevel {
    return this._mapLevel;
  }
  public set mapLevel(value: MapLevel) {
    this._mapLevel = value;
  }
  public get markerList(): MarkerData[] {
    return this._markerList;
  }
  public set markerList(value: MarkerData[]) {
    this._markerList = value;
    this._kakaoMarkerList.forEach((markerItem) => {
      markerItem.setMap(null);
    });
    this._kakaoMarkerList = [];

    this._markerList?.forEach((markerItem) => {
      let latitude = markerItem.latitude;
      let longitude = markerItem.longitude;
      let icon = new kakao.maps.MarkerImage(
        markerItem.markerIcon,
        new kakao.maps.Size(40, 40),
      );
      const kakaoMarker = new kakao.maps.Marker({
        position: new kakao.maps.LatLng(latitude, longitude),
        clickable: true,
        image: icon
      });
      kakaoMarker.markerId = markerItem.markerId;
      kakaoMarker.setMap(this._kakaoMap);
      this._kakaoMarkerList.push(kakaoMarker);
    });
  }
  public get polygonList(): PolygonData[] {
    return this._polygonList;
  }
  public set polygonList(value: PolygonData[]) {
    this._polygonList = value;
    // 초기화
    this._kakaoPolygonList.forEach((polygonItem) => {
      polygonItem.setMap(null);
    });
    this._kakaoPolygonList = [];

    this._polygonList?.forEach((polygonItem) => {
      let polygonPath: any[] = [];
      for (let i = 0; i < polygonItem.pointList?.length; i++) {
        const lat = polygonItem.pointList![i][1];
        const lng = polygonItem.pointList![i][0];
        polygonPath.push(new kakao.maps.LatLng(lat, lng));
      }

      const polygon = new kakao.maps.Polygon({
        path: polygonPath, // 그려질 다각형의 좌표 배열
        strokeWeight: 3, // 선의 두께
        strokeColor: '#39DE2A', // 선의 색깔
        strokeOpacity: 0.8, // 선의 불투명도
        strokeStyle: 'solid', // 선의 스타일
        fillColor: polygonItem.polygonColor?polygonItem.polygonColor:'#81DAF5', // 채우기 색깔
        fillOpacity: 0.7, // 채우기 불투명도
      });

      polygon.polygonId = polygonItem.polygonAddress;
      polygon.setMap(this._kakaoMap);
      this._kakaoPolygonList.push(polygon);
    });
  } 

  public moveToMap(latitude: number, longitude: number) {
    this._kakaoMap?.panTo(new kakao.maps.LatLng(latitude, longitude));
    this._kakaoMap?.setLevel(MapLevel.ZOOM_LEVEL_DETAIL);
  }
}
