import BaseReqData from "../BaseReqData";
import WorkerDeleteItemReqData from "../worker/WorkerDeleteItemReqData";
import WorkerRegistrationItemReqData from "../worker/WorkerRegistrationItemReqData";
import WorkerUpdateItemReqData from "../worker/WorkerUpdateItemReqData";
import LandDeleteItemReqData from "./LandDeleteItemReqData";
import LandRegistrationItemReqData from "./LandRegistrationItemReqData";
import LandUpdateReqData from "./LandUpdateReqData";

export default class WorkUpdateReqData extends BaseReqData {
    public workId!:string;
    public workAddress1!:string;
    public workAddress2!:string;
    public workAddress3!:string;
    public workStartDate!:string;
    public workEndDate!:string;
    public workLatitude!:number;
    public workLongitude!:number;
    public cropKind!:string;
    public workType!:string
    public workerList:Array<WorkerRegistrationItemReqData> = [];
    public deleteWorkerList:Array<WorkerDeleteItemReqData> = [];
    public editWorkerList:Array<WorkerUpdateItemReqData> = [];
    public landList:Array<LandRegistrationItemReqData> = [];
    public deleteLandList:Array<LandDeleteItemReqData> = [];
    public editLandList:Array<LandUpdateReqData> = [];
}