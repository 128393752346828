import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class WorkRegistrationRequest extends BaseRequest{
    private readonly URL_WORK_BASE = `${BaseService.API_URL}/work`;
    private readonly URL_WORK_REGISTRATION = `${this.URL_WORK_BASE}/workRegist`;

    getURL(): string {
        return this.URL_WORK_REGISTRATION;
    }
}