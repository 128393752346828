import {
  ActionSheetBody,
  ActionSheetContainer,
  ActionSheetHeader,
  ActionSheetHeaderButton,
  actionSheetStyles,
  SActionSheet,
} from './ActionSheet.styles';
import { useRecoilValue } from 'recoil';
import { actionSheetState } from 'atoms/actionSheet';
import { useSwipeable } from 'react-swipeable';
import { PropsWithChildren } from 'react';
import useActionSheet from 'hooks/useActionSheet';
import { Global } from '@emotion/react';

import { ReactComponent as SheetChevronIcon } from 'assets/images/sheet_chevron.svg';
import BridgeManager from 'bridge/BridgeManager';
import {isLoading} from 'types/isLoading.types'
import Loading from '../Loading';

const ActionSheet = ({ children }: PropsWithChildren) => {
  const sheetState = useRecoilValue(actionSheetState);
  const {
    ref,
    onScroll,
    onSwipedUp,
    onSwipedDown,
    onSwipedDownHeader,
    onClickButton,
  } = useActionSheet();

  const handlers = useSwipeable({ onSwipedUp, onSwipedDown });
  const headerHandlers = useSwipeable({ onSwipedDown: onSwipedDownHeader });
  const bridgeManager = new BridgeManager();

  return (
    bridgeManager.isMobile()
    ? <>
        <Global styles={actionSheetStyles} />

        <SActionSheet
          isOpen
          sheetState={sheetState}
          overlayClassName="action-sheet"
        >
          {/* 스와이프 감지를 위한 컨테이너 */}
          <ActionSheetContainer {...handlers}>
            {/* 액션시트 헤더 */}
            <ActionSheetHeader {...headerHandlers}>
              {sheetState === 'middle' ? (
                <span />
              ) : (
                <ActionSheetHeaderButton
                  sheetState={sheetState}
                  onClick={onClickButton}
                >
                  <SheetChevronIcon />
                  <b>{sheetState === 'minimum' ? '열기' : '닫기'}</b>
                </ActionSheetHeaderButton>
              )}
            </ActionSheetHeader>
            {/* 액션시트 바디 */}
            {
              <ActionSheetBody
                ref={ref}
                sheetState={sheetState}
                onScroll={onScroll}
              >
                {children}
              </ActionSheetBody>
            }
          </ActionSheetContainer>
        </SActionSheet>
      </>
    : <>
        <Global styles={actionSheetStyles} />

          <SActionSheet
            isOpen
            sheetState={sheetState}
            overlayClassName="action-sheet"
          >
            {/* 스와이프 감지를 위한 컨테이너 */}
            <ActionSheetContainer>
              {/* 액션시트 헤더 */}
              <ActionSheetHeader>
                {
                  <ActionSheetHeaderButton
                    sheetState={sheetState}
                    onClick={onClickButton}
                  >
                    <SheetChevronIcon />
                    <b>{sheetState !== 'maximum' ? '열기' : '닫기'}</b>
                  </ActionSheetHeaderButton>
                }
              </ActionSheetHeader>
              {/* 액션시트 바디 */}
              {
                <ActionSheetBody
                  ref={ref}
                  sheetState={sheetState}
                  onScroll={onScroll}
                >
                  {children}
                </ActionSheetBody>
              }
            </ActionSheetContainer>
          </SActionSheet>
      </>
  );
};

export default ActionSheet;
