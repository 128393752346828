import { MapLevel } from 'code/Enumerator';
import MarkerData from './MarkerData';
import PolyLineData from './PolyLineData';
import PolygonData from './PolygonData';
import myLocationIcon from 'assets/images/markerIcon/myLocation.jpg';
import 'styles/customOverlay.styles.ts';
import { getColorWithTeamCode } from 'converter/KakaoConverter';

const { kakao } = window;
export default class MapComponentData {
  private _kakaoMap: any;
  private _myLocationKakaoMarker?: any;
  private _searchLandMarker?: any;  
  private _myInfoWindow?: any;
  // private _landInfoList: LandInfo[] = [];
  private _kakaoMarkerList: any[] = [];
  private _kakaoWorkerMarkerList: any[] = [];
  private _kakaoPolygonList: any[] = [];
  private _kakaoPolyLineList: any[] = [];
  private _myLatLng: number[] = [37.566535, 126.9779692];
  private _mapCenter: number[] = [37.566535, 126.9779692];
  private _mapLevel: MapLevel = MapLevel.ZOOM_LEVEL_LIST;  
  private _markerList: MarkerData[] = [];
  private _workerMarkerList: MarkerData[] = [];
  private _polygonList: PolygonData[] = [];
  private _polylineList: PolyLineData[] = [];

  public get kakaoMap(): any {
    return this._kakaoMap;
  }
  public set kakaoMap(value: any) {
    this._kakaoMap = value;
    setTimeout(() => {
      this._kakaoMap.relayout();
    }, 1000);
    this._kakaoMap.setZoomable(true);
    const mapTypeControl = new kakao.maps.MapTypeControl();   
    
    this._kakaoMap.addControl(
      mapTypeControl,
      kakao.maps.ControlPosition.TOPRIGHT
    );
    
    this._kakaoMap.setMapTypeId(kakao.maps.MapTypeId.SKYVIEW);

    const myIcon = new kakao.maps.MarkerImage(
      myLocationIcon,
      new kakao.maps.Size(30, 30),
    );

    const myLocationMarker = new kakao.maps.Marker({
      position: new kakao.maps.LatLng(this._myLatLng[0], this._myLatLng[1]),
      clickable: false,
      // image : myIcon,
      zIndex: 4
    });
    myLocationMarker.setMap(this._kakaoMap);
    this._myLocationKakaoMarker = myLocationMarker;
  }
  public get myLocationMarker(): any {
    return this._myLocationKakaoMarker;
  }
  public set myLocationMarker(value: any) {
    this._myLocationKakaoMarker = value;
  }
  public get searchLandMarker(): any {
    return this._searchLandMarker;
  }
  public set searchLandMarker(value: any) {
    this._searchLandMarker = value;    
  }
  public get myInfoWindow(): any {
    return this._myInfoWindow;
  }
  public set myInfoWindow(value: any) {
    this._myInfoWindow = value;
  }  
  // public get landInfoList(): Array<LandInfo> {
  //   return this._landInfoList;
  // }
  // public removeLandInfo() {
  //   this._landInfoList?.forEach((landInfoItem) => {
  //     landInfoItem.kakaoCustomOverlay.setMap(null);
  //   });
  //   this._landInfoList = new Array<LandInfo>();
  // }
  // public updateLandInfo(landInfo:LandInfo){
  //   const findedLandInfoIndex = this._landInfoList?.findIndex((landInfoItem) => {
  //     return landInfoItem.landAddress === landInfo.landAddress;
  //   });
  //   if(findedLandInfoIndex < 0){return;}    
  //   this._landInfoList[findedLandInfoIndex].kakaoCustomOverlay.setMap(null);
  //   this._landInfoList = this._landInfoList.filter((landInfoItem) => {
  //     return landInfoItem.landAddress !== landInfo.landAddress;
  //   });
  //   this.addLandInfo(landInfo);
  // }
  // public addLandInfo(landInfo: LandInfo) {
  //   this._landInfoList?.push(landInfo);
  //   const content = `<div class="map--custom-overlay">`
  //   +`<div class="map--custom-overlay-block1">`
  //   +`<span>`+ landInfo.workerName +`</span>`
  //   +`<img src=`+ equipmentIconByEquipmentTypeString(landInfo.equipmentType) +` alt="" style="width: 30px; height: 30px" />`
  //   +`</div>`
  //   +`<div class="map--custom-overlay-block2">`
  //   +`<span>순서 :`+ landInfo.equipmentOrder +`</span>`
  //   +`<span>생산량 : `+ landInfo.landOutput + `/` + Math.floor(landInfo.landSize * 0.0025) +`</span>`
  //   +`</div>`
  //   +`</div>`;
  //   const landInfoCustomOverlay = new kakao.maps.InfoWindow({
  //     content: content,
  //     position: new kakao.maps.LatLng(landInfo.latitude, landInfo.longitude),
  //   });
  //   landInfoCustomOverlay.open(this._kakaoMap);
  //   landInfo.kakaoCustomOverlay = landInfoCustomOverlay;
  // }
  public get kakaoMarkerList(): any[] {
    return this._kakaoMarkerList;
  }
  public set kakaoMarkerList(value: any[]) {
    this._kakaoMarkerList = value;
  }
  public get kakaoWorkerMarkerList(): any[] {
    return this._kakaoWorkerMarkerList;
  }
  public set kakaoWorkerMarkerList(value: any[]) {
    this._kakaoWorkerMarkerList = value;
  }
  public get kakaoPolygonList(): any[] {
    return this._kakaoPolygonList;
  }
  public set kakaoPolygonList(value: any[]) {
    this._kakaoPolygonList = value;
  }
  public get kakaoPolyLineList(): any[] {
    return this._kakaoPolyLineList;
  }
  public set kakaoPolyLineList(value: any[]) {
    this._kakaoPolyLineList = value;
  }
  public get myLatLng(): number[] {
    return this._myLatLng;
  }
  public set myLatLng(value: number[]) {
    this._myLatLng = value;
    this._myLocationKakaoMarker?.setPosition(
      new kakao.maps.LatLng(this._myLatLng[0], this._myLatLng[1])
    );
    this._myInfoWindow?.setPosition(new kakao.maps.LatLng(this._myLatLng[0], this._myLatLng[1]));
  }
  public get mapCenter(): number[] {
    return this._mapCenter;
  }
  public set mapCenter(value: number[]) {
    this._mapCenter = value;
    this._kakaoMap?.setCenter(new kakao.maps.LatLng(this._mapCenter[0], this._mapCenter[1]));
  }
  public get mapLevel(): MapLevel {
    return this._mapLevel;
  }
  public set mapLevel(value: MapLevel) {
    this._mapLevel = value;
  }
  public get markerList(): MarkerData[] {
    return this._markerList;
  }
  public set markerList(value: MarkerData[]) {
    this._markerList = value;
    this._kakaoMarkerList.forEach((markerItem) => {
      markerItem.setMap(null);
    });
    this._kakaoMarkerList = [];

    if (this._markerList === undefined) return;
    if (this._markerList.length === 0) return;

    this._markerList?.forEach((markerItem) => {
      let latitude = markerItem.latitude;
      let longitude = markerItem.longitude;
      let icon = new kakao.maps.MarkerImage(
        markerItem.markerIcon,
        new kakao.maps.Size(40, 40),
      );
      const kakaoMarker = new kakao.maps.Marker({
        position: new kakao.maps.LatLng(latitude, longitude),
        clickable: true,
        image :icon,
      });
      kakaoMarker.markerId = markerItem.markerId;
      kakaoMarker.setMap(this._kakaoMap);
      this._kakaoMarkerList.push(kakaoMarker);
    });
  }
  public get workerMarkerList(): MarkerData[] {
    return this._workerMarkerList;
  }
  public set workerMarkerList(value: MarkerData[]) {
    this._workerMarkerList = value;

    this._kakaoWorkerMarkerList.forEach((markerItem) => {
      markerItem.info.setMap(null);
      markerItem.setMap(null);
    });
    this._kakaoWorkerMarkerList = [];

    if (this._workerMarkerList === undefined) return;
    if (this._workerMarkerList.length === 0) return;

    this._workerMarkerList?.forEach((markerItem) => {
      const latitude = markerItem.latitude;
      const longitude = markerItem.longitude;
      const icon = new kakao.maps.MarkerImage(
        markerItem.markerIcon,
        new kakao.maps.Size(30, 30),
      );
      const kakaoMarker = new kakao.maps.Marker({
        position: new kakao.maps.LatLng(latitude, longitude),
        clickable: false,
        image :icon,
      });
      kakaoMarker.markerId = markerItem.markerId;
      kakaoMarker.setMap(this._kakaoMap);
      this._kakaoWorkerMarkerList.push(kakaoMarker);
      const info = new kakao.maps.CustomOverlay({
        map: this._kakaoMap,      
        content: markerItem.workTeam === 'N'
        ? `<div style="padding: 4px 8px; display: flex;  gap: 10px; width: fit-content; background-color: white; transform:translateY(-100%); margin-top:-30px; border: 1px solid;">`
        + `<span>`+ markerItem.info +`</span>`
        + `</div>`
        : `<div style="padding: 4px 8px; display: flex; gap: 10px; width: fit-content; background-color: white; transform: translateY(-100%); margin-top: -30px; border: 1px solid;">`
        + `<span><span style="color: ${getColorWithTeamCode(markerItem.workTeam)}; font-weight: bold;">`+ markerItem.workTeam + '</span> | ' + markerItem.info +`</span>`,
        position: new kakao.maps.LatLng(latitude, longitude),
        zIndex: 4,
      });      
      kakaoMarker.info = info;
    });
  }
  public get polygonList(): PolygonData[] {
    return this._polygonList;
  }
  public set polygonList(value: PolygonData[]) {
    this._polygonList = value;
    // 초기화
    this._kakaoPolygonList.forEach((polygonItem) => {
      polygonItem.setMap(null);
    });
    this._kakaoPolygonList = [];

    if (this._polygonList === undefined) return;
    if (this._polygonList.length === 0) return;
    this._polygonList?.forEach((polygonItem) => {
      let polygonPath: any[] = [];
      let polylinePath: any[] = [];
      for (let i = 0; i < polygonItem.pointList?.length; i++) {
        const lat = polygonItem.pointList![i][1];
        const lng = polygonItem.pointList![i][0];
        polygonPath.push(new kakao.maps.LatLng(lat, lng));
        polylinePath.push(new kakao.maps.LatLng(lat, lng));
      }

      const polygon = new kakao.maps.Polygon({
        path: polygonPath, // 그려질 다각형의 좌표 배열
        strokeWeight: 3, // 선의 두께
        strokeColor: polygonItem.polygonLineColor?polygonItem.polygonLineColor:'#39DE2A', // 선의 색깔
        strokeOpacity: 0.8, // 선의 불투명도
        strokeStyle: 'solid', // 선의 스타일
        fillColor: polygonItem.polygonColor?polygonItem.polygonColor:'#81DAF5', // 채우기 색깔
        fillOpacity: 0.7, // 채우기 불투명도
      });

      const polyline = new kakao.maps.Polyline({
        path: polylinePath,
        strokeWeight: 6,
        strokeColor: polygonItem.polygonLineColor?polygonItem.polygonLineColor:'#39DE2A',
      })

      polygon.polygonId = polygonItem.polygonAddress;
      polygon.setMap(this._kakaoMap);
      polyline.setMap(this._kakaoMap);
      this._kakaoPolygonList.push(polygon, polyline);
    });
  }
  public get polylineList(): PolyLineData[] {
    return this._polylineList;
  }
  public set polylineList(value: PolyLineData[]) {
    this._polylineList = value;

    // 초기화
    this._kakaoPolyLineList.forEach((polylineItem) => {
      polylineItem.setMap(null);
    });
    this._kakaoPolyLineList = [];
    if (this._polylineList === undefined) return;
    if (this._polylineList.length === 0) return;

    this._polylineList?.forEach((polylineItem: PolyLineData) => {
      let polylinePath: any[] = [];

      for (let i = 0; i < polylineItem.pointList?.length; i++) {
        const latitude = polylineItem.pointList![i][0];
        const longitude = polylineItem.pointList![i][1];
        polylinePath.push(new kakao.maps.LatLng(latitude, longitude));
      }
      const polyline = new kakao.maps.Polyline({
        path: polylinePath, // 그려질 선의 좌표 배열
        strokeWeight: 10, // 선의 두께
        strokeColor: polylineItem.polyLineColor, // 선의 색깔
        strokeOpacity: 0.8, // 선의 불투명도
        strokeStyle: 'solid', // 선의 스타일
      });
      polyline.setMap(this._kakaoMap);
      this._kakaoPolyLineList.push(polyline);
    });
  }

  public moveToMap(latitude: number, longitude: number) {
    this._kakaoMap?.setLevel(MapLevel.ZOOM_LEVEL_DETAIL);
    let moveLatLng = new kakao.maps.LatLng(latitude, longitude);
    this._kakaoMap?.panTo(moveLatLng);
  }  
}
