import styled from '@emotion/styled';
import Spinner from 'assets/images/loadingSpinner.gif';

const Loading = () => {
    return (
        <LoadingContainer>
            <img src={Spinner} alt="loadingSpinner" />
            <LoadingText>데이터를 로딩 중입니다.</LoadingText>
        </LoadingContainer>
    );
};

export default Loading;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 1080px;
    min-width: 300px;
    height: 100%;
    margin: 0 auto;
    padding: 50px 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9999999;
    cursor: wait;
    pointer-events: none;
`

const LoadingText = styled.h2`
    font-size: 18px;
    font-weight: 500;
    text-align: center;
`