import { Global } from '@emotion/react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import AppRoutes from 'routes/AppRoutes';
import globalStyles from 'styles/global.styles';
import Modal from 'react-modal';
import Modals from 'ui/components/Modals/Modals';
import Loading from 'ui/components/Loading';
import { isLoading } from 'types/isLoading.types';

Modal.setAppElement('#root');
function App() {
  return (
    <>
      <Global styles={globalStyles} />
      <RecoilRoot>
        <BrowserRouter>
          {/* {isLoading === true && <Loading />} */}
          <AppRoutes />
          <Modals />
        </BrowserRouter>
      </RecoilRoot>
    </>
  );
}

export default App;
