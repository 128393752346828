export const colors = {
  /** #F5F6F8 */
  gray100: '#F5F6F8',
  /** #EBEBEB */
  gray200: '#EBEBEB',
  /** #CCCCCC */
  gray300: '#CCCCCC',
  /** #999999 */
  gray400: '#999999',
  /** #666666 */
  gray500: '#666666',
  /** #333333 */
  gray600: '#333333',
  /** #111111 */
  gray700: '#111111',

  /** #000000 */
  black: '#000000',
  /** #FFFFFF */
  white: '#FFFFFF',

  /** #00B140 */
  green100: '#00B140',
  /** #90C916 */
  green200: '#90C916',

  /** #DEA028 */
  orange: '#DEA028',
  /** #F22D2D */
  red: '#F22D2D',
  /** #56b8d7 */
  skyBlue: '#56b8d7',
} as const;
