import useHeaderOptions from 'hooks/useHeaderOptions';
import {
  ProductListItemImageWrapper,
  ProductListItemBlock,
  ProductListItemMetaBlock,
  NoListMsg,
} from './KeepList.styles';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import KeepListRequest from 'network/myPage/KeepListRequest';
import KeepListData from './KeepListData';
import KeepListReqData from 'data/network/req/myPage/KeepListReqData';
import KeepListResData from 'data/network/res/myPage/KeepListResData';
import { responseCode } from 'code/Enumerator';
import { KeepListResDataToKeepListData } from 'converter/MyPageConverter';
import noImage from 'assets/images/no-image.jpg';
import BaseService from 'network/BaseService';
import { getDistanceFromLatLonInKm } from 'common/utils/DistanceUtils';
import { getTimeAgoString } from 'common/utils/TimeUtils';
import { formatPrice } from 'common/utils/FormatPrice';

let keepListData=new KeepListData();
const KeepList = () => {
  useHeaderOptions({
    title: '찜 목록',
    hasBack: true,
  });
  const keepListRequest:KeepListRequest=new KeepListRequest();
  const [keepList, setKeepList] = useState(keepListData.keepList);
  const [userLatitude, setUserLatitude] = useState(keepListData.userLatitude);
  const [userLongitude, setUserLongitude] = useState(keepListData.userLongitude);

  useEffect(() => {
    document.title = "카우잇츠 - 한우농가 찜목록";
    init()
    return () => {
      keepListData=new KeepListData();
    };
  }, []);
  function init(){
    reqKeepList();
    navigator.geolocation.getCurrentPosition(function (position) {
      keepListData.userLatitude=position.coords.latitude;
      setUserLatitude(keepListData.userLatitude);
      keepListData.userLongitude=position.coords.longitude;
      setUserLongitude(keepListData.userLongitude);
    });
  }
  async function reqKeepList(){
    const keepListReqData=new KeepListReqData();
    const result=await keepListRequest.send(keepListReqData) as KeepListResData
    if(result.responseCode!==responseCode.OK){return};
    keepListData.keepList=KeepListResDataToKeepListData(result);
    setKeepList(keepListData.keepList)
  }

  return (
    <>
      <div style={{ padding: '24px 16px' }}>
      {keepList.length > 0 ? (
        (keepList?.map((item, i) => (
        <Link to={`/product/${item.productId}`} key={i}>
          <ProductListItemBlock>
            <ProductListItemImageWrapper>
            {item.productImage ? 
              <img src={BaseService.IMG_URL+'/'+item.productImage} alt="상품이미지" /> : 
              <img src={noImage} alt="상품이미지가 등록되지 않은 게시글입니다." />
            }
            </ProductListItemImageWrapper>

            <ProductListItemMetaBlock>
              <h4>{item.productTitle}</h4>
              <span>
                {getDistanceFromLatLonInKm(item.workLatitude,item.workLongitude,userLatitude,userLongitude)?.toFixed(2)}km · {getTimeAgoString(new Date(item.updDtm))}
              </span>
              <strong>{formatPrice(item.productPrice)}원</strong>
            </ProductListItemMetaBlock>
          </ProductListItemBlock>
        </Link>
          )))
          ) : (
          <NoListMsg style={{ marginTop: 8 }}>
            찜한 내역이 없습니다.
          </NoListMsg>
          )}
      </div>
    </>
  );
};

export default KeepList;
