import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TActionSheetState } from 'atoms/actionSheet';
import Modal from 'react-modal';

interface IActionSheetState {
  sheetState: TActionSheetState;
}

export const actionSheetStyles = css`
  .ReactModal__Overlay.action-sheet {
    position: fixed;
    inset: 0;
    overflow: hidden;
    background-color: transparent !important;
    z-index: 50;

    pointer-events: none;
  }

  .ReactModal__Overlay {
    & > div {
      outline: none;
    }
  }
`;

export const SActionSheet = styled(Modal)<IActionSheetState>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 13vw;
  // minumum 일 때 height

  max-width: 1080px;
  min-width: 350px;
  
  margin-left: auto;
  margin-right: auto;

  background: var(--white, #ffffff);
  border-radius: 12px 12px 0 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px -2px 16px;
  transition: all 0.2s ease-out;

  display: flex;
  flex-direction: column;

  pointer-events: auto;

  ${({ sheetState }) =>
    sheetState !== 'minimum' &&
    css`
      height: ${sheetState === 'middle' ?
      'calc(50vh)' // middle 일 때 height
      : '90vh' // maximum 일 때 height
      };
    `}
`;

export const ActionSheetContainer = styled.div`
  height: 100%;
`;

export const ActionSheetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;

  span {
    width: 36px;
    height: 4px;
    border-radius: 99px;
    background-color: rgb(221, 221, 221);
  }
`;

interface ActionSheetHeaderButtonProps {
  sheetState: TActionSheetState;
}

export const ActionSheetHeaderButton = styled.button<ActionSheetHeaderButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 8px;

  b {
    color: #ddd;
    text-align: center;
    font-family: Pretendard Variable;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
  }

  ${({ sheetState }) =>
    sheetState === 'maximum' &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

export const ActionSheetBody = styled.div<IActionSheetState>`
  padding: 0 16px;
  padding-bottom: 50px;
  height: calc(100% - 50px); // 시트 헤더 높이
  overflow: auto;
  touch-action: ${({ sheetState }) => sheetState !== 'maximum' && 'pan-y'}; 
`;