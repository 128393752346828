import { CropTypeCode } from "code/CropTypeCode";
import BaseReqData from "../BaseReqData";

export default class FarmBuyStatusReqData extends BaseReqData {
  public farmId!: string;
  public buyStatus!: string;
  public buyTitle!: string;
  public comment: string = "";
  public cropList!: ICropBuyCountReqItem[];
}

export interface ICropBuyCountReqItem {
  cropKind: CropTypeCode;
  etcName?: string;
  buyCount: number;
}
